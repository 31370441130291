import toast from 'react-hot-toast'
import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'

import { StatusType } from '../../v2/interfaces'
import {
  PACKING_SLIP_ALLOWED_FILE_TYPES,
  PackingSlipDetails,
  PackingSlipError,
  PackingSlipLoading,
  UploadPackingSlip
} from '../../v2/components/PackingSlip/components'
import { createToast } from '../../v2/components/Toast'
import { ImageLibraryImageInterface } from '../../v2/hooks'
import OverlayPortal from '../../v2/components/OverlayPortal'
import { getCsvOrderPackingSlip, getCsvOrderPackingSlipProgress } from '../../selectors/csvUpload'
import { removeCsvPackingSlip, uploadCsvPackingSlipFromUrlV2 } from '../../actions/csvUpload/uploadPackingSlip'

const PACKING_SLIP_UPLOAD_ERROR_TOAST_ID = 'packingSlipUploadErrorToast'

type V1StatusType = 'LOADING' | 'SUCCESS' | 'ERROR' | 'IDLE'

export function CsvPackingSlipUploadV2({ orderId }: { orderId: string }) {
  const dispatch = useDispatch()
  const packingSlip: string = useSelector((state) => getCsvOrderPackingSlip(state, orderId))
  const packingSlipProgress: V1StatusType = useSelector(
    (state) => getCsvOrderPackingSlipProgress(state, orderId).status
  )

  const [savePackingSlipStatus, setSavePackingSlipStatus] = useState<StatusType>('idle')
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    }
  }, [])

  async function handleUploadPackingUpdate(imageLibraryImage: ImageLibraryImageInterface) {
    setIsImageLibraryModalOpen(false)
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setSavePackingSlipStatus('loading')
    window.analytics.track('Added custom packing slip: CSV order editor')

    if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      createToast({
        content: `${
          imageLibraryImage.file_type
        } is not supported for packing slips. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        duration: Infinity,
        heading: 'Failed to update packing slip',
        id: PACKING_SLIP_UPLOAD_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setSavePackingSlipStatus('error')
      return
    }

    dispatch(uploadCsvPackingSlipFromUrlV2(imageLibraryImage.url, orderId))
    setSavePackingSlipStatus('success')
  }

  function removePackingSlip() {
    toast.dismiss(PACKING_SLIP_UPLOAD_ERROR_TOAST_ID)
    setSavePackingSlipStatus('loading')
    dispatch(removeCsvPackingSlip(orderId))
    setSavePackingSlipStatus('success')
    window.analytics.track('Removed custom packing slip: CSV order editor')
  }

  const isChangeLoading = savePackingSlipStatus === 'loading'

  if (packingSlipProgress === 'LOADING') {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <PackingSlipLoading />
      </PackingSlipContainer>
    )
  }

  if (packingSlipProgress === 'ERROR') {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <PackingSlipError
          className="btn-secondary"
          onOpenImageLibrary={() => window.analytics.track('Image library opened for packing slip: CSV')}
          isImageLibraryModalOpen={isImageLibraryModalOpen}
          isLoading={false}
          setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
          handleUploadPackingUpdate={handleUploadPackingUpdate}
        />
      </PackingSlipContainer>
    )
  }

  if (!packingSlip) {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <UploadPackingSlip
          className="btn-secondary"
          isImageLibraryModalOpen={isImageLibraryModalOpen}
          isLoading={isChangeLoading}
          onOpenImageLibrary={() => window.analytics.track('Image library opened for packing slip: CSV')}
          handleUploadPackingUpdate={handleUploadPackingUpdate}
          setIsImageLibraryModalOpen={setIsImageLibraryModalOpen}
        />
      </PackingSlipContainer>
    )
  }

  if (isChangeLoading) {
    return (
      <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
        <PackingSlipLoading />
      </PackingSlipContainer>
    )
  }

  return (
    <PackingSlipContainer savePackingSlipStatus={savePackingSlipStatus}>
      <PackingSlipDetails isLoading={isChangeLoading} packingSlip={packingSlip} removePackingSlip={removePackingSlip} />
    </PackingSlipContainer>
  )
}

function PackingSlipContainer({
  children,
  savePackingSlipStatus
}: {
  children: React.ReactNode
  savePackingSlipStatus: StatusType
}) {
  return (
    <div className="tailwind">
      <div className="isolate mt-12 w-full">
        <div className="flex flex-col">
          <h2 className="text-[16px] font-bold">Packing slip</h2>

          {children}

          <span className="text-sm text-gray-600">
            Only included for global prints and wall art shipped from UK, EU, AUS and USA. Packing slips are printed in
            black & white A4/standard letter size.
          </span>
        </div>

        {savePackingSlipStatus === 'loading' && <OverlayPortal />}
      </div>
    </div>
  )
}
