// @flow

import type { Node } from 'react'
import React from 'react'
import cx from 'classnames'
import { Link } from 'react-router-dom'
import FetchingShippingOptions from '../components/FetchingShippingOptions'
import MerchantOrderIdInputField from '../components/MerchantOrderIdInputField'
import Button from '../../../components/Button'
import Address from '../../../components/Address'
import Page from '../../../components/Page'
import styles from './OrderSummary.module.css'
import type {
  V4ProductDetails,
  CatalogueItem,
  Quote,
  CustomerAddress,
  MultiAssetBasketItem,
  RsaaStatus,
  RsaaStatusProps,
  Dictionary,
  Status,
  MultiAssetTemplates
} from '../../../types'
import { IDLE } from '../../../data/rsaa'
import type { Match } from 'react-router'
import SummaryItemListV4 from './SummaryItemListV4'
import ShippingAndTotalV4 from './ShippingAndTotalV4'
import OrderCreationStatus from './OrderCreationStatus'
import type { InsertDataType } from '../../../types/branding'
import { FEATURE_NAMES } from '../../../split-io/feature-names'

// $FlowFixMe: TypeScript component
import { PackingSlipUploadV2 } from './PackingSlip/PackingSlipUploadV2.component'
// $FlowFixMe: TypeScript component
import { OrderSummaryBrandedInserts } from './OrderSummaryBrandedInserts.component'
// $FlowFixMe: TypeScript file
import { useSplitToggle } from '../../../v2/hooks'
// $FlowFixMe: TypeScript file
import { areStringArraysEqual } from '../../../v2/helpers'

type Props = {|
  hasQuotes: boolean,
  orderId: string,
  brandedInsertsFetchStatus: 'idle' | 'loading' | 'success' | 'error',
  selectedQuote: ?Quote,
  isPriceAndShippingLoading: boolean,
  hasPricingAndShippingError: boolean,
  disableOrderSubmission: boolean,
  orderError: boolean,
  errorMessage: string,
  match: Match,
  basketItems: MultiAssetBasketItem[],
  catalogueDataRsaaStatus: RsaaStatusProps,
  catalogueItems: Dictionary<CatalogueItem>,
  orderBrandedInserts: InsertDataType[],
  customer: CustomerAddress,
  hasToSetUpCard: boolean,
  addressIsValid: boolean,
  deliveryCountry: string,
  merchantOrderId: string,
  currencyCode: ?string,
  createOrder: () => Promise<void>,
  pathname: string,
  orderCreationStatus: RsaaStatus,
  imageStatus: Dictionary<Status>,
  templateStatus: Dictionary<Status>,
  templates: Dictionary<MultiAssetTemplates>,
  v4ProductDetails: Dictionary<V4ProductDetails>,
  updatePackingSlipStatus: 'idle' | 'loading' | 'success' | 'error',
  setUpdatePackingSlipStatus: (status: 'idle' | 'loading' | 'success' | 'error') => void,
  changeSelectedPrintAreaForBasketItem: (basketItemId: string, selectedPrintArea: string) => void,
  resetOrderCreationStatus: () => void,
  clearOrderData: () => void,
  onChangeOrderBrandedInserts: (newBrandedInserts: InsertDataType[]) => void,
  viewCreatedOrder: () => void,
  navigateToBasket: () => void,
  placeAnotherOrder: () => void,
  changeMerchantOrderId: (merchantOrderId: string) => void,
  updatePriceToUser: (priceToUserAsInt: number, basketItemId: number) => void
|}

function OrderSummaryLayout({
  orderId,
  brandedInsertsFetchStatus,
  isPriceAndShippingLoading,
  disableOrderSubmission,
  hasPricingAndShippingError,
  catalogueDataRsaaStatus,
  orderError,
  errorMessage,
  match,
  clearOrderData,
  basketItems,
  customer,
  orderBrandedInserts,
  hasToSetUpCard,
  addressIsValid,
  deliveryCountry,
  merchantOrderId,
  changeMerchantOrderId,
  currencyCode,
  createOrder,
  pathname,
  imageStatus,
  templateStatus,
  updatePriceToUser,
  changeSelectedPrintAreaForBasketItem,
  orderCreationStatus,
  resetOrderCreationStatus,
  navigateToBasket,
  viewCreatedOrder,
  placeAnotherOrder,
  templates,
  selectedQuote,
  hasQuotes,
  catalogueItems,
  onChangeOrderBrandedInserts,
  v4ProductDetails,
  updatePackingSlipStatus,
  setUpdatePackingSlipStatus
}: Props): Node {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  if (orderCreationStatus.status !== IDLE) {
    return (
      <OrderCreationStatus
        orderCreationStatus={orderCreationStatus}
        goBack={navigateToBasket}
        orderId={orderId}
        viewOrder={viewCreatedOrder}
        placeAnotherOrder={placeAnotherOrder}
      />
    )
  }

  const userSelectedInsertIds = orderBrandedInserts?.map((insert) => insert.id) ?? []
  const quoteInsertIds = selectedQuote?.branding?.inserts?.map((insert) => insert.id) ?? []
  const doesQuoteIncludeAllInserts = areStringArraysEqual(quoteInsertIds, userSelectedInsertIds)

  return (
    <Page
      title="Summary"
      primaryAction={{
        disabled: disableOrderSubmission,
        loading: brandedInsertsFetchStatus === 'loading',
        title: hasToSetUpCard ? 'Please set up payment details' : 'Submit order',
        onClick: () => {
          createOrder()
        }
      }}
      showActionsInFooter={true}
    >
      {isPriceAndShippingLoading || hasPricingAndShippingError ? (
        <FetchingShippingOptions
          back="/orders/create/address"
          error={hasPricingAndShippingError}
          inProgress={isPriceAndShippingLoading}
        />
      ) : (
        <div className={styles.container}>
          <div className={cx(styles.packingSlip, styles.section)}>
            {isBrandingSettingsOn ? (
              <div className="tailwind">
                <OrderSummaryBrandedInserts
                  orderBrandedInserts={orderBrandedInserts}
                  onChangeOrderBrandedInserts={onChangeOrderBrandedInserts}
                />
              </div>
            ) : (
              <PackingSlipUploadV2
                updatePackingSlipStatus={updatePackingSlipStatus}
                setUpdatePackingSlipStatus={setUpdatePackingSlipStatus}
              />
            )}
          </div>

          <section className={cx(styles.customer, styles.section)}>
            <h2 className={styles.title}>Customer</h2>
            {addressIsValid ? (
              <div>
                <Address address={customer} destinationCountry={deliveryCountry} />
              </div>
            ) : (
              <div>
                <p className={styles.warning}>Please enter an address</p>
              </div>
            )}
            <div>
              <Link to="/orders/create/address" className={styles.edit}>
                <Button icon="pencil" size="small" dataTest="edit-address-button">
                  Edit
                </Button>
              </Link>
            </div>
          </section>

          <section className={cx(styles.merchantReference, styles.section)}>
            <h2 className={styles.title}>Your order reference</h2>
            <MerchantOrderIdInputField
              merchantOrderId={merchantOrderId}
              changeMerchantOrderId={changeMerchantOrderId}
            />
            <p className={styles.description}>Tag this order with your own internal reference.</p>
          </section>

          <section className={cx(styles.itemsContainer, styles.section)}>
            {basketItems.length !== 0 ? (
              hasQuotes && (
                <SummaryItemListV4
                  basketItems={basketItems}
                  isLoadingCatalogueData={catalogueDataRsaaStatus.loading}
                  catalogueItems={catalogueItems}
                  v4ProductDetails={v4ProductDetails}
                  changeSelectedPrintAreaForBasketItem={changeSelectedPrintAreaForBasketItem}
                  updatePriceToUser={updatePriceToUser}
                  imageStatus={imageStatus}
                  templates={templates}
                  templateStatus={templateStatus}
                  quote={((selectedQuote: any): Quote)}
                />
              )
            ) : (
              <p className={styles.warning}>Please add some items</p>
            )}
          </section>

          <section className={cx(styles.pricing, styles.section)}>
            <header className={styles.sectionHeader}>
              <h2 className={styles.title}>Product &amp; shipping pricing</h2>
            </header>
            <ShippingAndTotalV4 doesQuoteIncludeAllInserts={doesQuoteIncludeAllInserts} />
          </section>
        </div>
      )}
    </Page>
  )
}

export default OrderSummaryLayout
