export enum SalesChannelPlatformEnum {
  Prodigi = 'Prodigi',
  WooCommerce = 'WooCommerce',
  Etsy = 'Etsy',
  Wix = 'Wix',
  Squarespace = 'Squarespace',
  AdobeCommerce = 'AdobeCommerce',
  BigCommerce = 'BigCommerce',
  Shopify = 'Shopify',
  Amazon = 'Amazon'
}
