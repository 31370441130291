import { AnimatePresence, motion } from 'motion/react'
import { useHistory, useLocation } from 'react-router'
import { useCallback, useEffect, useMemo, useState } from 'react'

import IconCross from '../IconCross'
import { useSplitToggle } from '../../hooks'
import { QUERY_PARAMS } from '../../constants'
import FilterPill, { AppliedFiltersType } from '../FilterPill'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { formatFileFormatTypeFilter, formatFileTypeFilter, formatOrientationFilter, formatTagFilter } from './helpers'

export default function ImageLibraryAppliedFilters() {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [appliedFilters, setAppliedFilters] = useState<AppliedFiltersType | undefined>(undefined)

  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  const handleTypeClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
    history.push({ pathname, search: searchParams.toString() })
  }, [history, pathname, searchParams])

  const handleFileTypeClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    history.push({ pathname, search: searchParams.toString() })
  }, [history, pathname, searchParams])

  const handleOrientationClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)
    history.push({ pathname, search: searchParams.toString() })
  }, [history, pathname, searchParams])

  const handleTagClear = useCallback(() => {
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TAG)
    history.push({ pathname, search: searchParams.toString() })
  }, [history, pathname, searchParams])

  function handleClearAll() {
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)
    searchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TAG)
    history.push({ pathname, search: searchParams.toString() })
  }

  useEffect(() => {
    const updatedAppliedFilters: AppliedFiltersType = {}
    searchParams.forEach((value, param) => {
      if (param === QUERY_PARAMS.IMAGE_LIBRARY.TYPE) {
        updatedAppliedFilters['Type'] = {
          value: formatFileTypeFilter(value, isBrandingSettingsOn),
          onClearFilter: handleTypeClear
        }
      } else if (param === QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE) {
        updatedAppliedFilters['File format'] = {
          value: formatFileFormatTypeFilter(value),
          onClearFilter: handleFileTypeClear
        }
      } else if (param === QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION) {
        updatedAppliedFilters['Orientation'] = {
          value: formatOrientationFilter(value),
          onClearFilter: handleOrientationClear
        }
      } else if (param === QUERY_PARAMS.IMAGE_LIBRARY.TAG) {
        updatedAppliedFilters['Tag'] = {
          value: formatTagFilter(value),
          onClearFilter: handleTagClear
        }
      }
    })
    setAppliedFilters(updatedAppliedFilters)
  }, [handleTypeClear, handleOrientationClear, handleTagClear, handleFileTypeClear, searchParams, isBrandingSettingsOn])

  if (!appliedFilters) {
    return null
  }

  const numberOfAppliedFilters = Object.keys(appliedFilters).length

  return (
    <AnimatePresence initial={false}>
      {numberOfAppliedFilters > 0 && (
        <motion.ul
          initial={{ height: 0 }}
          animate={{ height: 'auto' }}
          exit={{ height: 0 }}
          transition={{ type: 'tween' }}
          className="relative mt-8 flex max-w-full gap-6 overflow-x-auto text-sm"
        >
          <AnimatePresence initial={false}>
            {Object.entries(appliedFilters).map(([name, value]) => (
              <FilterPill key={name} filterName={name} filterData={value} />
            ))}
            {numberOfAppliedFilters > 1 && (
              <motion.li
                key="clearFilters"
                className="min-w-fit"
                layout
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
              >
                <motion.button
                  whileHover={'hover'}
                  whileTap={'tap'}
                  variants={{
                    tap: {
                      scale: 0.95
                    }
                  }}
                  className="flex h-full items-center rounded-lg border border-gray-200 px-4 py-1"
                  onClick={handleClearAll}
                >
                  Clear all
                  <IconCross
                    className="ml-2 h-5 w-5"
                    variants={{
                      hover: {
                        scale: 1.25
                      },
                      tap: {
                        scale: 1
                      }
                    }}
                  />
                </motion.button>
              </motion.li>
            )}
          </AnimatePresence>
        </motion.ul>
      )}
    </AnimatePresence>
  )
}
