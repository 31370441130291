import { useLocation } from 'react-router'
import { useHotkeys } from '@mantine/hooks'
import { AnimatePresence } from 'motion/react'
import { useEffect, useMemo, useState } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'

import {
  OrderFilterActiveCount,
  OrderFilterSelection,
  OrderFilterStatus,
  OrderFilterDate,
  OrderFilterSource
} from './components'
import { OrderFilterType } from './types'
import { QUERY_PARAMS } from '../../constants'
import { useMerchantService } from '../../hooks'
import { HOTKEYS } from '../../constants/hotkeys.const'
import { Popover, PopoverContent, PopoverTrigger } from '../Popover'

export default function OrdersListActionFilter() {
  const { isLoading: isLoadingMerchantDetails, merchantDetailsFetchError } = useMerchantService()
  const isSalesChannelsAvailable = !isLoadingMerchantDetails && !merchantDetailsFetchError

  const [isOpen, setIsOpen] = useState(false)

  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const [openedFilter, setOpenedFilter] = useState<OrderFilterType>(null)
  const [selectedFilters, setSelectedFilters] = useState<OrderFilterType[]>([])

  useEffect(() => {
    const selectedFiltersFromQueryParams: OrderFilterType[] = []
    if (searchParams.get(QUERY_PARAMS.ORDERS.STATUS)) {
      selectedFiltersFromQueryParams.push('status')
    }
    if (searchParams.get(QUERY_PARAMS.ORDERS.START_DATE || QUERY_PARAMS.ORDERS.END_DATE)) {
      selectedFiltersFromQueryParams.push('date')
    }
    if (searchParams.get(QUERY_PARAMS.ORDERS.SOURCE)) {
      selectedFiltersFromQueryParams.push('source')
    }
    setSelectedFilters(selectedFiltersFromQueryParams)
  }, [searchParams])

  function handleHotKeyFilter(filter: NonNullable<OrderFilterType>) {
    setOpenedFilter(filter)
    if (!isOpen) {
      setIsOpen(true)
    }
  }

  useHotkeys([
    [HOTKEYS.ORDERS.STATUS.TRIGGER, () => handleHotKeyFilter('status')],
    [HOTKEYS.ORDERS.DATE.TRIGGER, () => handleHotKeyFilter('date')],
    [HOTKEYS.ORDERS.SOURCE.TRIGGER, () => isSalesChannelsAvailable && handleHotKeyFilter('source')]
  ])

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
        // Ensures we start with <OrderFilterSelection /> when re-opening the popover
        if (!open) {
          setOpenedFilter(null)
        }
      }}
      modal={true}
    >
      <PopoverTrigger data-test="orders-list-actions__filters">
        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Bars3Icon className="h-7 w-7 text-gray-500" aria-hidden="true" />
        </span>
        <span className="ml-9">Filter by</span>
        <OrderFilterActiveCount activeFilterCount={selectedFilters.length} />
      </PopoverTrigger>

      <PopoverContent align="end">
        <div className="sm:min-w-[240px]">
          <AnimatePresence initial={false} mode="wait">
            {!openedFilter && (
              <OrderFilterSelection
                isSalesChannelsAvailable={isSalesChannelsAvailable}
                key="filters"
                selectedFilters={selectedFilters}
                onChangeFilter={setOpenedFilter}
              />
            )}
            {openedFilter === 'status' && <OrderFilterStatus key="status" onChangeFilter={setOpenedFilter} />}
            {openedFilter === 'date' && <OrderFilterDate key="date" onChangeFilter={setOpenedFilter} />}
            {isSalesChannelsAvailable && openedFilter === 'source' && (
              <OrderFilterSource key="source" onChangeFilter={setOpenedFilter} />
            )}
          </AnimatePresence>
        </div>
      </PopoverContent>
    </Popover>
  )
}
