// @flow
import { NO_SELECTION, ALL } from '../data/catalogue'
import attributeLabelFormat from './attributeLabelFormat'
import { FRAME_COLOUR, GLAZE, MOUNT_COLOUR } from '../data/productAttributes'
import { ACCESSORIES, APPAREL, HOMEWARES, DRINKWARE } from '../data/productCategories'

export function getAttrNameForCategory(attrName: string, category: string): string {
  const upperCaseCategory = category.toUpperCase()
  if (
    attrName === FRAME_COLOUR &&
    category !== APPAREL &&
    category !== HOMEWARES &&
    category !== ACCESSORIES &&
    category !== NO_SELECTION &&
    upperCaseCategory !== DRINKWARE.toUpperCase() &&
    category !== ALL
  ) {
    return 'Frame Colour'
  } else if (attrName === GLAZE && category === ALL) {
    return 'Glaze / Coating'
  } else if (attrName === GLAZE && category.toLowerCase().includes('canvas')) {
    return 'Coating'
  } else if (attrName === MOUNT_COLOUR && category.toLowerCase().includes('framed')) {
    return 'Mount Colour'
  }

  return attributeLabelFormat(attrName)
}
