import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { cn } from '../../../helpers'
import Skeleton from '../../Skeleton'
import { formatBillingCost } from '../helpers'
import { SALES_EMAIL } from '../../SalesLink/constants'
import { SubscriptionTierType } from '../../../interfaces'
import { ScheduledUpdateMessage } from './ScheduledUpdateMessage.component'
import { SubscriptionScheduledChangesInterface, useUserSubscription } from '../hooks'

const features = [
  'Free packing slips',
  '50% off first sample order',
  'Free product mockups',
  '10Gb image library storage',
  'Email & live chat support'
]

export function CoreSubscriptionPanel({
  currency,
  currentSubscription,
  userHasToAddBillingDetails,
  setIsCancelProSubscriptionModalOpen
}: {
  currency: string
  currentSubscription: SubscriptionTierType
  userHasToAddBillingDetails: boolean
  setIsCancelProSubscriptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-between gap-y-4 rounded-2xl border-2 border-gray-200 bg-white p-8 transition-colors duration-300',
        currentSubscription === 'Core' && 'border-purple-700 bg-purple-100/75'
      )}
    >
      <div>
        <div className="flex justify-between">
          <h3 className="m-0 text-xl font-medium lg:text-2xl">Core</h3>
        </div>

        <p className="mt-1 text-gray-600">For new &amp; small businesses</p>

        <ul className="my-8 max-w-fit list-inside list-disc">
          {features.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <SubscriptionDetails
        currency={currency}
        userHasToAddBillingDetails={userHasToAddBillingDetails}
        setIsCancelProSubscriptionModalOpen={setIsCancelProSubscriptionModalOpen}
      />
    </div>
  )
}

function SubscriptionDetails({
  currency,
  userHasToAddBillingDetails,
  setIsCancelProSubscriptionModalOpen
}: {
  currency: string
  userHasToAddBillingDetails: boolean
  setIsCancelProSubscriptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
}) {
  const { subscription, isLoading, error } = useUserSubscription()

  if (error) {
    return null
  }

  if (isLoading || !subscription) {
    return (
      <div>
        <div className="h-[38px]"></div>
        <div className="mt-4">
          <Skeleton className="h-[43px] w-[130px]" />
        </div>
      </div>
    )
  }

  const isUsersCurrentSubscription = subscription.subscriptionTier === 'Core'

  return (
    <div>
      <div className="flex flex-wrap items-center justify-between gap-2">
        <p className="text-4xl font-medium text-purple-600">
          {formatBillingCost({ cost: 0, currency })}/mo <span className="text-xl">(free forever)</span>
        </p>
      </div>

      <CorePlanButton
        currentSubscription={subscription.subscriptionTier}
        scheduledChanges={subscription.scheduledChanges}
        userHasToAddBillingDetails={userHasToAddBillingDetails}
        setIsModalOpen={() => setIsCancelProSubscriptionModalOpen(true)}
      />

      {subscription.scheduledChanges.changeHasBeenScheduled && (
        <ScheduledUpdateMessage
          isUsersCurrentSubscription={isUsersCurrentSubscription}
          scheduledChanges={subscription.scheduledChanges}
        />
      )}

      {!isUsersCurrentSubscription && !subscription.scheduledChanges.changeHasBeenScheduled && (
        <p className="mt-4 text-gray-600">This will cancel your current plan</p>
      )}
    </div>
  )
}

function CorePlanButton({
  currentSubscription,
  scheduledChanges,
  userHasToAddBillingDetails,
  setIsModalOpen
}: {
  currentSubscription: SubscriptionTierType
  scheduledChanges: SubscriptionScheduledChangesInterface
  userHasToAddBillingDetails: boolean
  setIsModalOpen: (open: boolean) => void
}) {
  const isUsersCurrentSubscription = currentSubscription === 'Core'
  const buttonText = isUsersCurrentSubscription ? `You currently have a free account` : 'Subscribe to a free account'

  if (currentSubscription === 'Enterprise') {
    return (
      <a
        className={cn(
          'mt-4 w-fit transition-colors duration-300',
          isUsersCurrentSubscription
            ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
            : 'btn btn-secondary'
        )}
        href={`mailto:${SALES_EMAIL}`}
        rel="noreferrer"
        target="_blank"
      >
        Contact sales to switch to Core
      </a>
    )
  }

  return (
    <button
      className={cn(
        'mt-4 w-fit transition-colors duration-300',
        isUsersCurrentSubscription
          ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
          : 'btn btn-secondary'
      )}
      disabled={userHasToAddBillingDetails || isUsersCurrentSubscription || scheduledChanges.changeHasBeenScheduled}
      onClick={() => setIsModalOpen(true)}
    >
      {isUsersCurrentSubscription ? (
        <>
          <CheckCircleIcon className="h-10 w-10" />
          {buttonText}
        </>
      ) : (
        buttonText
      )}
    </button>
  )
}
