import { useLocation } from 'react-router'
import { useHotkeys } from '@mantine/hooks'
import { AnimatePresence } from 'motion/react'
import { useEffect, useMemo, useState } from 'react'

import {
  ImageLibraryFilterActiveCount,
  ImageLibraryFilterContainer,
  ImageLibraryFilterSelection,
  ImageLibraryListAction,
  ImageLibraryListActionFileFormat
} from '.'
import { useSplitToggle } from '../../../hooks'
import { QUERY_PARAMS } from '../../../constants'
import { ImageLibraryFiltersType } from '../types'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { HOTKEYS } from '../../../constants/hotkeys.const'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { Popover, PopoverContent, PopoverTrigger } from '../../Popover'
import { ImageLibraryListActionTag } from './ImageLibraryListActionTag.component'

const ORIENTATION_OPTIONS = [
  {
    content: <span className="pr-10">Portrait</span>,
    value: 'portrait',
    searchLabel: 'Portrait'
  },
  {
    content: <span className="pr-10">Landscape</span>,
    value: 'landscape',
    searchLabel: 'Landscape'
  },
  {
    content: <span className="pr-10">Square</span>,
    value: 'square',
    searchLabel: 'Square'
  }
]
export function ImageLibraryFilter() {
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [isOpen, setIsOpen] = useState(false)
  const [openedFilter, setOpenedFilter] = useState<ImageLibraryFiltersType>(null)
  const [selectedFilters, setSelectedFilters] = useState<ImageLibraryFiltersType[]>([])

  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  useEffect(() => {
    const selectedFiltersFromQueryParams: ImageLibraryFiltersType[] = []
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)) {
      selectedFiltersFromQueryParams.push('type')
    }
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)) {
      selectedFiltersFromQueryParams.push('file-format')
    }
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION)) {
      selectedFiltersFromQueryParams.push('orientation')
    }
    if (searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG)) {
      selectedFiltersFromQueryParams.push('tag')
    }
    setSelectedFilters(selectedFiltersFromQueryParams)
  }, [searchParams])

  function handleHotKeyFilter(filter: NonNullable<ImageLibraryFiltersType>) {
    setOpenedFilter(filter)
    if (!isOpen) {
      setIsOpen(true)
    }
  }

  useHotkeys([
    [HOTKEYS.IMAGE_LIBRARY.TYPE.TRIGGER, () => handleHotKeyFilter('type')],
    [HOTKEYS.IMAGE_LIBRARY.FILE_FORMAT.TRIGGER, () => handleHotKeyFilter('file-format')],
    [HOTKEYS.IMAGE_LIBRARY.ORIENTATION.TRIGGER, () => handleHotKeyFilter('orientation')],
    [HOTKEYS.IMAGE_LIBRARY.TAG.TRIGGER, () => handleHotKeyFilter('tag')]
  ])

  const typeOptions = [
    {
      content: <span className="pr-10">Artwork</span>,
      value: 'artwork',
      searchLabel: 'Artwork'
    },
    {
      content: <span className="pr-10">{isBrandingSettingsOn ? 'Insert' : 'Packing slip'}</span>,
      value: 'packing',
      searchLabel: isBrandingSettingsOn ? 'Insert' : 'Packing slip'
    },
    {
      content: <span className="pr-10">Book</span>,
      value: 'book',
      searchLabel: 'Book'
    }
  ]

  return (
    <Popover
      open={isOpen}
      onOpenChange={(open) => {
        setIsOpen(open)
        // Ensures we start with selector component when re-opening the popover
        if (!open) {
          setOpenedFilter(null)
        }
      }}
      modal={true}
    >
      <PopoverTrigger data-test="image-library-list-actions__filters">
        <span className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <Bars3Icon className="h-7 w-7 text-gray-500" aria-hidden="true" />
        </span>
        <span className="ml-9">Filter by</span>
        <ImageLibraryFilterActiveCount activeFilterCount={selectedFilters.length} />
      </PopoverTrigger>

      <PopoverContent align="end">
        <div className="sm:min-w-[240px]">
          <AnimatePresence initial={false} mode="wait">
            {!openedFilter && (
              <ImageLibraryFilterSelection
                key="filters"
                selectedFilters={selectedFilters}
                onChangeFilter={setOpenedFilter}
              />
            )}

            {openedFilter === 'type' && (
              <ImageLibraryFilterContainer
                key="type"
                queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TYPE}
                title="Type"
                onChangeFilter={setOpenedFilter}
              >
                <ImageLibraryListAction
                  title="type"
                  options={typeOptions}
                  queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TYPE}
                />
              </ImageLibraryFilterContainer>
            )}

            {openedFilter === 'file-format' && (
              <ImageLibraryFilterContainer
                key="file-format"
                queryParam={QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE}
                title="File format"
                onChangeFilter={setOpenedFilter}
              >
                <ImageLibraryListActionFileFormat />
              </ImageLibraryFilterContainer>
            )}

            {openedFilter === 'orientation' && (
              <ImageLibraryFilterContainer
                key="orientation"
                queryParam={QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION}
                title="Orientation"
                onChangeFilter={setOpenedFilter}
              >
                <ImageLibraryListAction
                  title="orientation"
                  options={ORIENTATION_OPTIONS}
                  queryParam={QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION}
                />
              </ImageLibraryFilterContainer>
            )}

            {openedFilter === 'tag' && (
              <ImageLibraryFilterContainer
                key="tag"
                queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TAG}
                title="Tagged"
                onChangeFilter={setOpenedFilter}
              >
                <ImageLibraryListActionTag />
              </ImageLibraryFilterContainer>
            )}
          </AnimatePresence>
        </div>
      </PopoverContent>
    </Popover>
  )
}
