// @flow
import type { MultiAssetBasketItem, DispatchFunc, GetStateFunc, ThunkAsync, CatalogueItem } from '../../types'
import type { SetupRecentItems, SetupRecentItemsSuccess, SetupRecentItemsError, StartSetupRecentItems } from '../types'
import { selectRecentItems } from '../../selectors/recentItems'
import {
  selectAvailableRecentItems,
  selectDeliveryCountry,
  selectRecentItemsStatus
} from '../../selectors/manualOrderForm'
import { fetchPrices, searchProductsBySkuList, SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS } from '../catalogue'
import { mapToEnsureBackwardCompatibility } from './backwardCompatibility'
import { values } from '../../helpers/dictionary'
import type { PrintAreaArtwork } from '../../types/basketItem'

// $FlowFixMe: TypeScript file
import { getProductFileCompatibilityData } from 'src/v2/helpers/getProductFileCompatibilityData.helper'

export const RECENT_ITEMS_LOGGER_ID = 'RECENT_ITEMS'
export const SETUP_RECENT_ITEMS = 'SETUP_RECENT_ITEMS'
export const SETUP_RECENT_ITEMS_ERROR = 'SETUP_RECENT_ITEMS_ERROR'
export const SETUP_RECENT_ITEMS_SUCCESS = 'SETUP_RECENT_ITEMS_SUCCESS'

export function setupRecentItems(): ?ThunkAsync<SetupRecentItems> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const recentItemsStatus = selectRecentItemsStatus(getState())

    if (recentItemsStatus === 'initialised') {
      return dispatch({ type: 'NO_ACTION' })
    }

    const availableRecentItems = selectAvailableRecentItems(getState()).filter((recentItem, index) => {
      const hasImageLibraryIdForAllArtworks = values<PrintAreaArtwork>(recentItem.printAreas ?? {}).every(
        (printAreaData) => {
          return Boolean(printAreaData.artwork?.imageLibraryId)
        }
      )
      return hasImageLibraryIdForAllArtworks
    })

    if (availableRecentItems && availableRecentItems.length > 0) {
      return dispatch(onSetupRecentItemsSuccess(availableRecentItems))
    }

    try {
      dispatch(onStartSetupRecentItems())
      const allAvailableRecentItems = await dispatch(setupRecentItemsForCurrentDeliveryCountry())
      return dispatch(onSetupRecentItemsSuccess(allAvailableRecentItems))
    } catch (err) {
      return dispatch(onSetupRecentItemsError(err.message))
    }
  }
}

function getUniqueSkus(basketItems: MultiAssetBasketItem[]): string[] {
  return Array.from(new Set(basketItems.map((item) => item.sku)))
}

function setupRecentItemsForCurrentDeliveryCountry(): ThunkAsync<MultiAssetBasketItem[]> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const allRecentItems = selectRecentItems(getState())
    const backwardCompatibleRecentItems = mapToEnsureBackwardCompatibility(allRecentItems)

    if (backwardCompatibleRecentItems.length === 0) {
      return []
    }

    const allRecentSkus = getUniqueSkus(backwardCompatibleRecentItems)

    const availableRecentItems = await dispatch(
      getRecentItemsAvailableInDevlieryCountry(backwardCompatibleRecentItems, allRecentSkus)
    )

    dispatch(fetchPricesForAllAvailableSkus(availableRecentItems))

    return availableRecentItems
  }
}

function getRecentItemsAvailableInDevlieryCountry(
  recentItems: MultiAssetBasketItem[],
  recentSkus: string[]
): ThunkAsync<MultiAssetBasketItem[]> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const deliveryCountry = selectDeliveryCountry(getState())
    const searchResult = await dispatch(searchProductsBySkuList(recentSkus, deliveryCountry, RECENT_ITEMS_LOGGER_ID))

    if (searchResult.type !== SEARCH_PRODUCTS_BY_SKU_LIST_SUCCESS) {
      throw new Error(`Searching products failed. SKUs: ${JSON.stringify(recentSkus)}`)
    }

    const recentProductsAvailableInDeliveryCountry = searchResult.payload
    return filterAvailableRecentItems(recentItems, recentSkus, recentProductsAvailableInDeliveryCountry)
  }
}

function fetchPricesForAllAvailableSkus(availableRecentItems: MultiAssetBasketItem[]) {
  return (dispatch: DispatchFunc) => {
    const availableSkus = availableRecentItems.map((availableRecentItem) => availableRecentItem.sku)
    dispatch(fetchPrices(availableSkus, RECENT_ITEMS_LOGGER_ID))
  }
}

function filterAvailableRecentItems(
  recentItems: MultiAssetBasketItem[],
  recentSkus: string[],
  availableProducts: CatalogueItem[]
): MultiAssetBasketItem[] {
  const availableSkus = availableProducts.map((product) => product.sku.toLowerCase())
  const availableRecentSkus = recentSkus.filter((recentSku) => availableSkus.includes(recentSku.toLowerCase()))

  return recentItems.filter((recentItem) => {
    const catalogueDetails = availableProducts?.find((availableProduct) => availableProduct?.sku === recentItem?.sku)

    if (catalogueDetails) {
      const productFileCompatibilityData = getProductFileCompatibilityData({
        productType: catalogueDetails?.productType
      })
      if (productFileCompatibilityData) {
        /**
         * Since product has compatibility data, it might mean that the current artwork
         * may not be supported on this product, so remove from recent items and let user select again
         */
        return false
      }
    }

    return availableRecentSkus.includes(recentItem.sku)
  })
}

function onStartSetupRecentItems(): StartSetupRecentItems {
  return {
    type: SETUP_RECENT_ITEMS
  }
}

function onSetupRecentItemsSuccess(recentItems: MultiAssetBasketItem[]): SetupRecentItemsSuccess {
  return {
    type: SETUP_RECENT_ITEMS_SUCCESS,
    availableRecentItems: recentItems
  }
}

function onSetupRecentItemsError(message: string): SetupRecentItemsError {
  return {
    type: SETUP_RECENT_ITEMS_ERROR,
    availableRecentItems: [],
    message
  }
}
