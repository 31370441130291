import clsx from 'clsx'
import { Link } from 'react-router-dom'
import { AnimatePresence, motion } from 'motion/react'
import { Cog8ToothIcon, PhotoIcon } from '@heroicons/react/24/outline'

import Switch from '../../Switch'
import { ROUTE_PATHS } from '../../../constants'
import { SalesChannelInterface } from '../../../interfaces'
import { SalesChannelProductInterface } from '../../../hooks'

interface ProductTilePropsInterface {
  className?: string
  disabled: boolean
  isFulfilled: boolean
  salesChannel: SalesChannelInterface
  salesChannelProduct: SalesChannelProductInterface
  onChangeFulfilment: (shouldFulfill: boolean) => void
}

export function ProductTile({
  className = '',
  disabled,
  isFulfilled,
  salesChannel,
  salesChannelProduct,
  onChangeFulfilment
}: ProductTilePropsInterface) {
  const imageUrl = salesChannelProduct.imageUrls[0]

  return (
    <div
      className={clsx(
        'mx-auto flex min-h-[360px] w-[250px] flex-col justify-between bg-gray-100 antialiased',
        className
      )}
    >
      <motion.div animate={{ opacity: isFulfilled ? 1 : 0.5 }} className="m-1 flex-1 bg-white p-1 pb-4" initial={false}>
        <figure>
          {imageUrl ? (
            <img
              alt={salesChannelProduct.name}
              className="block h-[230px] w-[240px] object-cover object-center"
              loading="lazy"
              src={imageUrl}
            />
          ) : (
            <div className="grid h-[230px] w-[240px] place-content-center bg-gray-100">
              <PhotoIcon className="h-10 w-10 text-gray-400" />
            </div>
          )}
          <figcaption className="line-clamp-2 break-words px-3 pt-3 font-medium">{salesChannelProduct.name}</figcaption>
        </figure>
        <div className="break-words px-3 pt-1 text-sm text-gray-600">{salesChannelProduct.platformId}</div>
      </motion.div>

      <div className="flex min-h-[50px] items-center justify-between px-5 py-4">
        <ProductTileFooter
          disabled={disabled}
          isFulfilled={isFulfilled}
          prodigiProductId={salesChannelProduct.id}
          salesChannel={salesChannel}
          salesChannelProduct={salesChannelProduct}
          onChangeFulfilment={onChangeFulfilment}
        />
      </div>
    </div>
  )
}

interface ProductTileFooterPropsInterface {
  disabled: boolean
  isFulfilled: boolean
  prodigiProductId: number
  salesChannel: SalesChannelInterface
  salesChannelProduct: SalesChannelProductInterface
  onChangeFulfilment: (shouldFulfill: boolean) => void
}

function ProductTileFooter({
  disabled,
  isFulfilled,
  prodigiProductId,
  salesChannel,
  salesChannelProduct,
  onChangeFulfilment
}: ProductTileFooterPropsInterface) {
  return (
    <>
      <div className="flex items-center gap-3">
        <Switch
          className="disabled:cursor-wait"
          checked={isFulfilled}
          disabled={disabled}
          onCheckedChange={onChangeFulfilment}
        />

        <AnimatePresence initial={false}>
          {isFulfilled && !disabled && (
            <motion.span
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.2 }}
            >
              {salesChannelProduct.numberOfFulfilledVariants} of {salesChannelProduct.totalNumberOfVariants}
            </motion.span>
          )}
        </AnimatePresence>
      </div>

      {isFulfilled ? (
        <div className="bg-white">
          <Link
            className="btn btn-secondary btn-sm"
            to={`${ROUTE_PATHS.SALES_CHANNELS.INDEX}/${salesChannel.id}/${prodigiProductId}`}
            onClick={() => window.analytics.track('Sales channels - clicked product configure')}
          >
            <Cog8ToothIcon className="mr-2 h-7 w-7" />
            Configure
          </Link>
        </div>
      ) : (
        <span className="text-md">Not fulfilled by Prodigi</span>
      )}
    </>
  )
}
