import { Link } from 'react-router-dom'
import { SquaresPlusIcon } from '@heroicons/react/24/solid'
import { Squares2X2Icon, BuildingOfficeIcon, PhotoIcon, DocumentCurrencyDollarIcon } from '@heroicons/react/24/outline'

import { useSplitTreatment } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupCatalogue({ className }: { className?: string }) {
  const { isTreatmentOn: isProductLibraryOn } = useSplitTreatment(FEATURE_NAMES.PRODUCT_LIBRARY)

  if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return null
  }

  return (
    <div className={className}>
      <NavGroupTitle>Catalogue</NavGroupTitle>

      <NavGroupListContainer>
        {isProductLibraryOn && (
          <li>
            <Link to={ROUTE_PATHS.PRODUCT_LIBRARY} className="side-nav-item">
              <SquaresPlusIcon className="h-7 w-7" />
              Your products
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.PRODUCT_CATALOGUE} className="side-nav-item">
            <Squares2X2Icon className="h-7 w-7" />
            Browse products
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.SALES_CHANNELS.INDEX} className="side-nav-item">
            <BuildingOfficeIcon className="h-7 w-7" />
            Sales channels
          </Link>
        </li>

        <li>
          <Link to="/pricing/" className="side-nav-item">
            <DocumentCurrencyDollarIcon className="h-7 w-7" />
            Pricing sheets
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.IMAGE_LIBRARY.INDEX} className="side-nav-item">
            <PhotoIcon className="h-7 w-7" />
            Image library
          </Link>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
