import { useEffect } from 'react'

import { SettingsSubscriptions } from '../../../components/Settings'
import { ANALYTICS_PAGE_CATEGORIES, DEFAULT_DOCUMENT_TITLE } from '../../../constants'

export default function SettingsSubscriptionsPage() {
  useEffect(() => {
    document.title = 'Subscription Settings | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.SETTINGS, 'Subscriptions')

    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <SettingsSubscriptions />
    </main>
  )
}
