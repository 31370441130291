import { useLocalStorage } from '@mantine/hooks'

import {
  FormattedOrderHistoryDateEventsInterface,
  FormattedOrderHistoryEventInterface,
  OrderDetailHistoryInterface
} from '../interfaces'
import { formatOrderHistoryResponse } from '../helpers'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from '../../Accordion'

interface OrderDetailOrderHistoryInterface {
  history: OrderDetailHistoryInterface[]
}

const historyAccordianValue = 'history'

export function OrderDetailOrderHistory({ history }: OrderDetailOrderHistoryInterface) {
  const [isOpen, setIsOpen] = useLocalStorage({
    key: 'prodigiOrderHistoryToggle',
    defaultValue: false,
    getInitialValueInEffect: false
  })

  const formattedHistory = formatOrderHistoryResponse(history)

  if (history.length === 0) {
    return null
  }

  return (
    <Accordion
      className="isolate w-full"
      collapsible
      data-test="order-detail-history"
      onValueChange={(value: string) => setIsOpen(value === historyAccordianValue)}
      type="single"
      value={isOpen ? historyAccordianValue : ''}
    >
      <AccordionItem value={historyAccordianValue}>
        <AccordionTrigger className="text-lg">Order history</AccordionTrigger>

        <AccordionContent className="overflow-x-auto">
          {formattedHistory.map((date, index) => (
            <OrderHistoryForDate dateEvents={date} key={date.date} lastDate={index === formattedHistory.length - 1} />
          ))}
        </AccordionContent>
      </AccordionItem>
    </Accordion>
  )
}

function OrderHistoryForDate({
  dateEvents,
  lastDate
}: {
  dateEvents: FormattedOrderHistoryDateEventsInterface
  lastDate: boolean
}) {
  return (
    <div className="mt-2 w-full">
      <span className="col-span-3 font-medium uppercase text-gray-700">{dateEvents.date}</span>
      <div>
        {dateEvents.events.map((event: FormattedOrderHistoryEventInterface, index: number) => (
          <OrderHistoryEvent key={`${event.time}-${index}`} event={event} lastDate={lastDate} />
        ))}
      </div>
    </div>
  )
}

function OrderHistoryEvent({ event, lastDate }: { event: FormattedOrderHistoryEventInterface; lastDate: boolean }) {
  return (
    <div className="group mt-2 grid grid-cols-[20px_20px_1fr] gap-x-0 gap-y-2">
      <div className="col-span-1 group-first:min-h-[15px] group-first:border-r group-first:border-gray-300" />

      <div className="col-span-2 row-start-2 text-center">{event.time}</div>
      <div className={'col-start-3 row-start-2 ml-4 inline-flex font-medium md:ml-8 lg:ml-12'}>{event.title}</div>

      <div className="col-start-3 row-start-3 ml-4 md:ml-8 lg:ml-12">{event.content}</div>

      <div
        className={`col-span-1 row-start-3 min-h-[20px] border-r border-gray-300 group-last:min-h-[50px] ${
          lastDate ? 'group-last:h-0 group-last:min-h-0 group-last:border-r-0' : ''
        }`}
      />
    </div>
  )
}
