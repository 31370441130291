import { Link } from 'react-router-dom'

import Skeleton from '../../Skeleton'
import SupportLink from '../../SupportLink'
import { formatCost } from '../../../helpers'
import { ROUTE_PATHS } from '../../../constants'
import { SalesChannelInterface } from '../../../interfaces'
import { SalesChannelPackingSlip } from './SalesChannelPackingSlip.component'
import { BrandingDetailsType, useBrandingDetails, useMerchantBranding } from '../../../hooks'

export function SalesChannelInsertsSettings({ salesChannel }: { salesChannel: SalesChannelInterface }) {
  const { brandingDetails, isLoadingBrandingDetails, brandingDetailsFetchError } = useBrandingDetails()
  const { merchantBrandingResponse, isLoadingMerchantBranding, merchantBrandingFetchError } = useMerchantBranding()

  if (brandingDetailsFetchError) {
    return <InsertsError code={`BDFE-${brandingDetailsFetchError?.status ?? 0}`} />
  }

  if (merchantBrandingFetchError) {
    return <InsertsError code={`MBFE-${merchantBrandingFetchError?.status ?? 0}`} />
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <InsertsLoading />
  }

  const merchantBranding = merchantBrandingResponse.data
  const insertSetDataForChannel = merchantBranding?.insertSets?.find(
    (insertSet) => insertSet.id === salesChannel?.insertsSet?.id
  )

  if (!insertSetDataForChannel) {
    return <NoInsertSetDisplay salesChannel={salesChannel} />
  }

  const brandingDetailsForInsertSet: BrandingDetailsType[] = []

  insertSetDataForChannel.inserts.forEach((insert) => {
    const brandingDetailForInsert = brandingDetails.branding.find((brandingDetail) => brandingDetail.id === insert.id)
    if (!brandingDetailForInsert) {
      return
    }
    brandingDetailsForInsertSet.push(brandingDetailForInsert)
  })

  const insertSetCost = {
    amount: brandingDetailsForInsertSet.reduce(
      (amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount,
      0
    ),
    currency: brandingDetailsForInsertSet[0]?.cost?.currency
  }

  const formattedInsertSetCost = formatCost({
    amount: insertSetCost.amount.toString(),
    currencyCode: insertSetCost.currency
  })

  return (
    <div>
      <div className="font-medium" style={{ wordBreak: 'break-word' }}>
        {insertSetDataForChannel.name}{' '}
        {formattedInsertSetCost && <span className="font-normal">({formattedInsertSetCost})</span>}
      </div>
      <div className="mt-2">
        {brandingDetailsForInsertSet.length > 0 ? (
          <span>{brandingDetailsForInsertSet.map((brandingDetail) => brandingDetail.name).join(', ')}</span>
        ) : (
          <span>No inserts in this set</span>
        )}
      </div>
      <div className="mt-4">
        <InsertsEditButton />
      </div>
    </div>
  )
}

export function InsertsEditButton() {
  return (
    <Link className="btn btn-secondary btn-sm w-fit" to={ROUTE_PATHS.SETTINGS.BRANDING}>
      Edit inserts
    </Link>
  )
}

function InsertsError({ code }: { code: string }) {
  return (
    <div className="text-red-500">
      An error occurred while loading inserts. Please try again later or{' '}
      <SupportLink className="text-inherit underline">contact support</SupportLink> if the issue persists (code {code})
    </div>
  )
}

function InsertsLoading() {
  return (
    <div className="flex flex-col gap-2">
      <Skeleton className="h-[20px] w-60" />
      <Skeleton className="h-[20px] w-48" />
      <Skeleton className="h-[20px] w-48" />
    </div>
  )
}

function NoInsertSetDisplay({ salesChannel }: { salesChannel: SalesChannelInterface }) {
  if (salesChannel.packingSlipImageId) {
    return <SalesChannelPackingSlip salesChannel={salesChannel} />
  }

  return (
    <div>
      <div>No insert set assigned to this channel</div>
      <div className="mt-4">
        <InsertsEditButton />
      </div>
    </div>
  )
}
