import { motion } from 'motion/react'
import { twMerge } from 'tailwind-merge'
import * as SwitchPrimitive from '@radix-ui/react-switch'

interface PropTypes {
  className?: string
  checked: boolean
  disabled?: boolean
  required?: boolean
  onCheckedChange: (isChecked: boolean) => void
}

const switchVariants = {
  checked: { x: 17 },
  unChecked: { x: 1 }
}

export default function Switch({
  className = '',
  checked,
  disabled = false,
  required = false,
  onCheckedChange
}: PropTypes) {
  return (
    <SwitchPrimitive.Root
      checked={checked}
      className={twMerge(
        'relative h-[20px] w-[36px] rounded-[9999px] transition-colors disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:bg-purple-600 data-[state=unchecked]:bg-gray-300',
        className
      )}
      disabled={disabled}
      required={required}
      onCheckedChange={onCheckedChange}
    >
      <SwitchPrimitive.Thumb asChild className="h-[18px] w-[18px] rounded-full bg-white">
        <motion.div
          initial={false}
          animate={checked ? 'checked' : 'unChecked'}
          transition={{ type: 'tween', duration: 0.15 }}
          variants={switchVariants}
        />
      </SwitchPrimitive.Thumb>
    </SwitchPrimitive.Root>
  )
}
