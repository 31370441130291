import { OrderDetailInterface } from '../../../interfaces'
import { fetcher, mapV3AttributesToV4 } from '../../../helpers'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { AddLineItemEditorDataType } from '../OrderEditAddLineItem.component'

interface OrderDetailResponseInterface {
  outcome: string
  traceParent: string
}

interface SaveNewLineItemSuccessResponseInterface extends OrderDetailResponseInterface {
  data: {
    draftCosts?: {
      costSummary: {
        numberOfItems: number
        totalQuantityOfItems: number
        totalCost?: CostInterface
        items?: CostInterface
        shipping?: CostInterface
        tax?: CostInterface
      }
      shipping: Record<string, CostInterface>
      items: Record<string, OrderDetailResponseInterface>
    }
    order: OrderDetailInterface
    lineItemId: string
  }
}

export async function saveNewLineItem({
  newLineItemData,
  orderId
}: {
  newLineItemData: AddLineItemEditorDataType
  orderId: string
}) {
  return fetcher<SaveNewLineItemSuccessResponseInterface>(
    `${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/items`,
    {
      body: JSON.stringify({
        prodigiSkuDetails: {
          skuName: newLineItemData?.selectedProductData?.sku,
          attributes: newLineItemData?.selectedProductData?.attributes
            ? mapV3AttributesToV4(newLineItemData?.selectedProductData?.attributes)
            : null,
          metadata: newLineItemData?.selectedProductData?.metaData,
          assets: newLineItemData?.assetRequirementData,
          productType: newLineItemData?.selectedProductData?.productType
        },
        printAreasArtwork: newLineItemData?.artworkData
          ? Object.entries(newLineItemData.artworkData).reduce(
              (printAreasArtworkAcc, [printAreaName, printAreasArtworkData]) => {
                return {
                  ...printAreasArtworkAcc,
                  [printAreaName]: {
                    additionalUiDetail: printAreasArtworkData,
                    additionalUiDetailVersion: '1.0',
                    printReadyUrl: printAreasArtworkData?.printImageUrl,
                    pageCount:
                      printAreasArtworkData?.artwork?.pageCount && printAreasArtworkData?.artwork?.pageCount > 0
                        ? printAreasArtworkData.artwork.pageCount
                        : undefined
                  }
                }
              },
              {}
            )
          : null
      }),
      method: 'PUT'
    }
  )
}
