import * as React from 'react'
import * as CheckboxPrimitive from '@radix-ui/react-checkbox'

import { cn } from '../../helpers'
import { IconCheckMark } from '../InlineMenu/components'

/**
 * Component adapted from shadcn/ui
 * Visit https://ui.shadcn.com/docs/components/checkbox for more info and docs
 */
const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>
>(({ className, checked, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    className={cn(
      'flex h-8 w-8 shrink-0 items-center justify-center border-2 border-gray-400 bg-white focus-visible:outline-none focus-visible:outline focus-visible:outline-1 focus-visible:outline-black disabled:cursor-not-allowed disabled:opacity-50 data-[state=checked]:border-purple-600 data-[state=checked]:bg-purple-600',
      className
    )}
    checked={checked}
    {...props}
  >
    <CheckboxPrimitive.Indicator forceMount asChild>
      <>
        <IconCheckMark
          className="h-5 w-5 text-white"
          dataTest="checkbox-check"
          isVisible={checked === true}
          strokeWidth={4}
        />
      </>
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
))
Checkbox.displayName = CheckboxPrimitive.Root.displayName

export { Checkbox }
