// @flow
export const FEATURE_NAMES = {
  TRANSORM_URLS: 'dashboard_transform_urls',
  SALES_CHANNELS_DELETE_ORDERS: 'sales_channels_delete_orders',
  COSTED_ATTRIBUTES: 'dashboard_costed_attributes',
  SALES_CHANNEL_VOTE_FOR_PLATFORM: 'sales_channel_vote_for_platform',
  BRANDING_SETTINGS: 'dashboard_branding_settings',
  DASHBOARD_PRODIGI_PRO: 'dashboard_prodigi_pro',
  UNLESS_CHAT_BOT: 'dashboard_unless_chatbot',
  DIRECT_DEBIT: 'dashboard_direct_debit',
  SALES_CHANNEL_AMAZON: 'sales_channel_amazon',
  DASHBOARD_SELECT_COUNTRY_V2: 'dashboard_select_country',
  ADD_DRAFT_ORDER_ITEM: 'dashboard_add_draft_line_item',
  PRODUCT_LIBRARY: 'dashboard_product_library'
}
