import { useHistory } from 'react-router'
import { useEffect, useState } from 'react'
import { AnimatePresence } from 'motion/react'

import Modal from '../../Modal'
import { createToast } from '../../Toast'
import { truncateSalesChannelName } from '../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { SalesChannelInterface } from '../../../interfaces'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { useMerchantService, useSplitToggle } from '../../../hooks'
import { UpdateSalesChannelNameStep } from './UpdateSalesChannelNameStep.component'
import { AddSalesChannelPackingSlip } from './AddSalesChannelPackingSlip.component'
import { UpdateSalesChannelDefaultProductFulfillmentStep } from './UpdateSalesChannelDefaultProductFulfillmentStep.component'

interface SalesChannelOnboardingModalPropsInterface {
  newSalesChannelId: string
  open: boolean
  onCompleteOnboarding: () => void
  setOpen: (open: boolean) => void
}

interface SalesChannelOnboardingModalDataInterface {
  title: string | null
  subtitle: string | null | React.ReactNode
}

export default function SalesChannelOnboardingModal({
  newSalesChannelId,
  open,
  onCompleteOnboarding,
  setOpen
}: SalesChannelOnboardingModalPropsInterface) {
  const [modalData, setModalData] = useState<SalesChannelOnboardingModalDataInterface>({ title: null, subtitle: null })

  return (
    <Modal
      className="w-90vw max-w-[600px]"
      closeOnInteractionOutside={false}
      title={modalData.title}
      subtitle={modalData.subtitle}
      open={open}
      setOpen={setOpen}
    >
      <SalesChannelOnboarding
        newSalesChannelId={newSalesChannelId}
        onCompleteOnboarding={onCompleteOnboarding}
        onUpdateModalData={setModalData}
      />
    </Modal>
  )
}

interface SalesChannelOnboardingPropsInterface {
  newSalesChannelId: string
  onCompleteOnboarding: () => void
  onUpdateModalData: (modalData: SalesChannelOnboardingModalDataInterface) => void
}

export function SalesChannelOnboarding({
  newSalesChannelId,
  onCompleteOnboarding,
  onUpdateModalData
}: SalesChannelOnboardingPropsInterface) {
  const { merchantDetails } = useMerchantService()

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  const newSalesChannel = merchantDetails.salesChannels.find((salesChannel) => salesChannel.id === newSalesChannelId)

  if (!newSalesChannel) {
    createToast({
      duration: Infinity,
      footer: 'Sales channel not found',
      heading: 'An error occurred while onboarding',
      id: 'sales-channel-onboarding-find',
      type: 'error-with-close'
    })
    throw Error('Newly connected sales channel not found')
  }

  switch (newSalesChannel.platform) {
    case SalesChannelPlatformEnum.Etsy:
    case SalesChannelPlatformEnum.Wix:
    case SalesChannelPlatformEnum.AdobeCommerce:
    case SalesChannelPlatformEnum.BigCommerce:
    case SalesChannelPlatformEnum.WooCommerce:
    case SalesChannelPlatformEnum.Squarespace:
    case SalesChannelPlatformEnum.Shopify:
    case SalesChannelPlatformEnum.Amazon:
      return (
        <AnimatePresence initial={false}>
          <DefaultSalesChannelOnboarding
            newSalesChannel={newSalesChannel}
            onCompleteOnboarding={onCompleteOnboarding}
            onUpdateModalData={onUpdateModalData}
          />
        </AnimatePresence>
      )
    case SalesChannelPlatformEnum.Prodigi:
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn('Prodigi sales channel onboarding not implemented')
      }
      return null
    default:
      const platformNameWithUnhandledCase: never = newSalesChannel.platform
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}

interface DefaultSalesChannelOnboardingPropsInterface {
  newSalesChannel: SalesChannelInterface
  onCompleteOnboarding: () => void
  onUpdateModalData: (modalData: SalesChannelOnboardingModalDataInterface) => void
}

type OnboardingStepType = 1 | 2 | 3

export function DefaultSalesChannelOnboarding({
  newSalesChannel,
  onCompleteOnboarding,
  onUpdateModalData
}: DefaultSalesChannelOnboardingPropsInterface) {
  const history = useHistory()
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  const [step, setStep] = useState<OnboardingStepType>(1)

  useEffect(() => {
    onUpdateModalData(
      getModalData({ platformName: newSalesChannel.platform, salesChannelName: newSalesChannel.description, step })
    )
  }, [newSalesChannel.description, newSalesChannel.platform, onUpdateModalData, step])

  function handleCompleteOnboarding() {
    history.push(ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(newSalesChannel.id))
    onCompleteOnboarding()
  }

  switch (step) {
    case 1:
      return <UpdateSalesChannelNameStep newSalesChannelId={newSalesChannel.id} onStepAdvance={() => setStep(2)} />
    case 2:
      return (
        <UpdateSalesChannelDefaultProductFulfillmentStep
          newSalesChannelId={newSalesChannel.id}
          onStepAdvance={() => {
            if (isBrandingSettingsOn) {
              handleCompleteOnboarding()
            } else {
              setStep(3)
            }
          }}
        />
      )
    case 3:
      return (
        <AddSalesChannelPackingSlip newSalesChannelId={newSalesChannel.id} onStepAdvance={handleCompleteOnboarding} />
      )
    default:
      const unhandledStep: never = step
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`No title for onboarding step ${unhandledStep}. Add a new case to fix this.`)
      }
      return null
  }
}

function getModalData({
  salesChannelName,
  step
}: {
  platformName: string
  salesChannelName: string
  step: OnboardingStepType
}): SalesChannelOnboardingModalDataInterface {
  switch (step) {
    case 1:
      return {
        title: 'Name this new channel',
        subtitle: <span>Choose how this channel appears in your Prodigi account</span>
      }
    case 2:
      return {
        title: 'Product fulfillment',
        subtitle: <span>{`When a new product is added to ${truncateSalesChannelName(salesChannelName)}`}</span>
      }
    case 3:
      return {
        title: 'Optional: add a default packing slip',
        subtitle: (
          <>
            <p>Only included for global prints and wall art shipped from UK, EU, AUS and USA.</p>
            <p>Packing slips are printed in black & white A4/standard letter size.</p>
          </>
        )
      }
    default:
      const unhandledStep: never = step
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`No title for onboarding step ${unhandledStep}. Add a new case to fix this.`)
      }
      return { title: null, subtitle: null }
  }
}
