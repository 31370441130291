import { DictionaryInterface } from '../../interfaces'
import { MockupViewInterface, useMockups3D } from '../../hooks'
import { Mockups3DViewDisplay, MockupPigViewDisplay } from './components'

export default function MockupsView({
  attributes,
  orientation,
  productSku,
  selectedMockupView,
  onSelectMockupView
}: {
  attributes: DictionaryInterface<string>
  orientation: string
  productSku: string
  selectedMockupView: string | null
  onSelectMockupView: (view: string) => void
}) {
  const { isLoadingMockups, mockups, mockupsFetchError } = useMockups3D({ attributes, orientation, productSku })

  if (mockupsFetchError) {
    return null
  }

  if (isLoadingMockups || !mockups) {
    return null
  }

  const views3D: Record<string, MockupViewInterface> = {}
  Object.entries(mockups.views).forEach(([viewKey, viewData]) => {
    if (viewData.renderer === 'PorcusLardum') {
      views3D[viewKey] = viewData
    }
  })

  if (Object.keys(views3D).length > 0) {
    return (
      <Mockups3DViewDisplay
        selectedMockupView={selectedMockupView}
        views3D={views3D}
        onSelectMockupView={onSelectMockupView}
      />
    )
  }

  const viewsPig: Record<string, MockupViewInterface> = {}
  Object.entries(mockups.views).forEach(([viewKey, viewData]) => {
    if (viewKey === 'cover' && viewData.renderer === 'PIG') {
      viewsPig[viewKey] = viewData
    }
  })

  if (Object.keys(viewsPig).length > 0) {
    return (
      <MockupPigViewDisplay
        selectedMockupView={selectedMockupView}
        viewsPig={viewsPig}
        onSelectMockupView={onSelectMockupView}
      />
    )
  }

  return null
}
