// @flow

import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import React, { useEffect, useState } from 'react'
import { CheckIcon, PencilSquareIcon } from '@heroicons/react/20/solid'

import SelectField from '../../../../components/inputs/SelectField'
import styles from '../AttributeSelector/AttributeSelector.module.css'
import type { MultiAssetBasketItem } from '../../../../types/basketItem'
import type { CatalogueItem } from '../../../../types/catalogue/catalogue'
import { updateBasketItemMetaData } from '../../../../actions/manualOrderForm/manualOrderForm'

// $FlowFixMe: TypeScript file
import Button from '../../../../v2/components/Button'
// $FlowFixMe: TypeScript file
import TextField from '../../../../v2/components/TextField'
// $FlowFixMe: TypeScript file
import { getProductFileCompatibilityData } from 'src/v2/helpers'
import {
  DEFAULT_PHOTOBOOK_META_DATA,
  PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS,
  PHOTOBOOK_SPINE_TEXT_MAX_LENGTH,
  PHOTOBOOK_SPINE_TEXT_REGEX,
  PHOTOBOOK_SPINE_TEXT_SIZE_OPTIONS
  // $FlowFixMe: TypeScript file
} from '../../../../v2/constants'

type BasketItemMetaDataPropsType = {|
  basketItem: MultiAssetBasketItem,
  catalogueItem: CatalogueItem
|}

export function BasketItemMetaData({ basketItem, catalogueItem }: BasketItemMetaDataPropsType): React$Node {
  const isPhotobookMetaDataRequired =
    getProductFileCompatibilityData?.({ productType: catalogueItem?.productType })?.metaDataType === 'photobook'

  if (isPhotobookMetaDataRequired) {
    return <PhotobookMetaData basketItem={basketItem} catalogueItem={catalogueItem} />
  }

  return null
}

function PhotobookMetaData({ basketItem, catalogueItem }: BasketItemMetaDataPropsType) {
  const dispatch = useDispatch()

  useEffect(() => {
    if (basketItem.metaData?.spineText === undefined || basketItem.metaData?.spineText === null) {
      dispatch(
        updateBasketItemMetaData(basketItem.id, {
          ...basketItem.metaData,
          spineText: DEFAULT_PHOTOBOOK_META_DATA.spineText
        })
      )
    }

    if (!basketItem.metaData?.spineTextSizePx) {
      dispatch(
        updateBasketItemMetaData(basketItem.id, {
          ...basketItem.metaData,
          spineTextSizePx: DEFAULT_PHOTOBOOK_META_DATA.spineTextSizePx
        })
      )
    }

    if (!basketItem.metaData?.spineTextFontFamily) {
      dispatch(
        updateBasketItemMetaData(basketItem.id, {
          ...basketItem.metaData,
          spineTextFontFamily: DEFAULT_PHOTOBOOK_META_DATA.spineTextFontFamily
        })
      )
    }

    if (!basketItem.metaData?.spineBackgroundColourHexCode) {
      dispatch(
        updateBasketItemMetaData(basketItem.id, {
          ...basketItem.metaData,
          spineBackgroundColourHexCode: DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
        })
      )
    }

    if (!basketItem.metaData?.spineTextColourHexCode) {
      dispatch(
        updateBasketItemMetaData(basketItem.id, {
          ...basketItem.metaData,
          spineTextColourHexCode: DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
        })
      )
    }
  }, [basketItem.id, basketItem.metaData, dispatch])

  return (
    <>
      <tr className={styles.row}>
        <td className={styles.label}>Spine Text:</td>
        <td className={styles.value}>
          <div className="tailwind">
            <SpineTextInput basketItem={basketItem} />
          </div>
        </td>
      </tr>

      <tr className={styles.row}>
        <td className={styles.label}>Spine Text Font:</td>
        <td className={styles.value}>
          <div className="tailwind">
            <SpineTextFontFamily basketItem={basketItem} />
          </div>
        </td>
      </tr>

      <tr className={styles.row}>
        <td className={styles.label}>Spine Text Size:</td>
        <td className={styles.value}>
          <div className="tailwind">
            <SpineTextSize basketItem={basketItem} />
          </div>
        </td>
      </tr>

      <tr className={styles.row}>
        <td className={styles.label}>Spine Background Colour:</td>
        <td className={styles.value}>
          <div className="tailwind">
            <TextField
              className="h-12 w-24 cursor-pointer"
              type="color"
              value={
                basketItem.metaData?.spineBackgroundColourHexCode ??
                DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
              }
              onChange={(event) => {
                const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
                dispatch(
                  updateBasketItemMetaData(basketItem.id, {
                    ...basketItem.metaData,
                    spineBackgroundColourHexCode: value
                  })
                )
              }}
            />
          </div>
        </td>
      </tr>

      <tr className={styles.row}>
        <td className={styles.label}>Spine Text Colour:</td>
        <td className={styles.value}>
          <div className="tailwind">
            <TextField
              className="h-12 w-24 cursor-pointer"
              type="color"
              value={basketItem.metaData?.spineTextColourHexCode ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode}
              onChange={(event) => {
                const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
                dispatch(
                  updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineTextColourHexCode: value })
                )
              }}
            />
          </div>
        </td>
      </tr>
    </>
  )
}

function SpineTextInput({ basketItem }: SpineTextInputPropsType) {
  const dispatch = useDispatch()

  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText)

  if (!isEditing) {
    return (
      <div className="flex items-center">
        {(basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText) && (
          <div className="mr-2" style={{ wordBreak: 'break-word' }}>
            {basketItem.metaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText}
          </div>
        )}
        <Button
          variant="tertiary"
          size="sm"
          startIcon={<PencilSquareIcon className="h-6 w-6" />}
          onClick={() => setIsEditing(true)}
        >
          Edit spine text
        </Button>
      </div>
    )
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const isValueInvalid = value !== '' && !PHOTOBOOK_SPINE_TEXT_REGEX.test(value)

        if (isValueInvalid) {
          toast.error('Your text contains an unsupported character.')
          return
        }

        dispatch(updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineText: value }))
        setIsEditing(false)
      }}
    >
      <TextField
        autoFocus
        className="mb-2 mt-0 h-auto w-[150px] px-4 py-2 lg:w-[250px]"
        type="text"
        maxLength={PHOTOBOOK_SPINE_TEXT_MAX_LENGTH}
        value={value}
        onChange={(event) => {
          setValue(event.target.value ?? '')
        }}
      />
      <Button variant="primary" size="sm" startIcon={<CheckIcon className="h-6 w-6" />} type="submit">
        Save
      </Button>
    </form>
  )
}

function SpineTextSize({ basketItem }: SpineTextInputPropsType) {
  const dispatch = useDispatch()

  return (
    <SelectField
      value={basketItem.metaData?.spineTextSizePx ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextSizePx}
      style={{ paddingLeft: '4px', width: '80px' }}
      event={(value) => {
        dispatch(updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineTextSizePx: Number(value) }))
      }}
    >
      {PHOTOBOOK_SPINE_TEXT_SIZE_OPTIONS.map((textSizeOption) => {
        return (
          <option key={textSizeOption.value} value={textSizeOption.value}>
            {textSizeOption.name}
          </option>
        )
      })}
    </SelectField>
  )
}

function SpineTextFontFamily({ basketItem }: SpineTextInputPropsType) {
  const dispatch = useDispatch()

  return (
    <SelectField
      value={basketItem.metaData?.spineTextFontFamily ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextFontFamily}
      style={{ paddingLeft: '4px', width: '170px' }}
      event={(value) => {
        dispatch(updateBasketItemMetaData(basketItem.id, { ...basketItem.metaData, spineTextFontFamily: value }))
      }}
    >
      {PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS.map((fontFamilyOption) => {
        return (
          <option key={fontFamilyOption.value} value={fontFamilyOption.value}>
            {fontFamilyOption.name}
          </option>
        )
      })}
    </SelectField>
  )
}

type SpineTextInputPropsType = {| basketItem: MultiAssetBasketItem |}
