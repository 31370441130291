import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { Elements } from '@stripe/react-stripe-js'

import Button from '../../../../../Button'
import FormItem from '../../../../../FormItem'
import TextField from '../../../../../TextField'
import { useUser } from '../../../../../../hooks'
import { createToast } from '../../../../../Toast'
import { StatusEnum } from '../../../../../../enums'
import OverlayPortal from '../../../../../OverlayPortal'
import { APP_ENVIRONMENTS } from '../../../../../../constants'
import { createErrorToast } from '../../../../../Toast/helpers'
import ConfirmationModal from '../../../../../ConfirmationModal'
import { SettingsPanel } from '../../../SettingsPanel.component'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { UpdatePaymentMethod } from './UpdatePaymentMethod.component'
import { FetchErrorInterface, StatusType } from '../../../../../../interfaces'
import { BillingStripeRedirectModal } from './BillingStripeRedirectModal.component'
import { fetcher, formatToSentenceCase, splitByPascalCase } from '../../../../../../helpers'
import { DEFAULT_STRIPE_OPTIONS, stripePromise } from '../../../../../../helpers/stripePromise.helper'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'

const DASHBOARD_DEFAULT_PAYMENT_METHOD_CONFIG = {
  TEST: 'pmc_0Qm9v0aVSNtMkLgKbVhOlRfT',
  LIVE: 'pmc_0Qm9vhaVSNtMkLgKE63SZ3es'
}

export function PaymentMethod() {
  const { user } = useUser()
  const [stripeSetupIntentStatus, setStripeSetupIntentStatus] = useState<StatusType>('idle')
  const [stripeSetupIntentClientSecret, setStripeSetupIntentClientSecret] = useState<string | null>(null)

  if (!user) {
    throw Error('No user')
  }

  async function handleEditBilling() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setStripeSetupIntentStatus('loading')

    try {
      const setupIntentUrl = new URL(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/requestsetupintent`)
      const configId =
        process.env.REACT_APP_ENV === APP_ENVIRONMENTS.LIVE
          ? DASHBOARD_DEFAULT_PAYMENT_METHOD_CONFIG.LIVE
          : DASHBOARD_DEFAULT_PAYMENT_METHOD_CONFIG.TEST
      setupIntentUrl.searchParams.set('configId', configId)

      const response = await fetcher<{ data: string; statusCode: number; statusTxt: string }>(setupIntentUrl.toString())
      setStripeSetupIntentClientSecret(response.data)
      setStripeSetupIntentStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        errorCode: errorResponse.status ?? errorResponse.message,
        heading: 'Failed to edit billing details',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setStripeSetupIntentStatus('error')
    }
  }

  const showEditBillingDetailsForm = stripeSetupIntentClientSecret && stripeSetupIntentStatus === 'success'

  return (
    <>
      <SettingsPanel className="border-x" title={<h2>Payment method</h2>}>
        <div className="max-w-3xl">
          {showEditBillingDetailsForm ? (
            <Elements
              stripe={stripePromise}
              options={{
                appearance: { variables: { colorPrimary: '#4630D4', fontFamily: 'objektiv-mk2' } },
                fonts: DEFAULT_STRIPE_OPTIONS.fonts,
                clientSecret: stripeSetupIntentClientSecret
              }}
            >
              <UpdatePaymentMethod
                stripeSetupIntentClientSecret={stripeSetupIntentClientSecret}
                onClose={() => setStripeSetupIntentStatus('idle')}
              />
            </Elements>
          ) : (
            <>
              <PaymentMethodInfo />
              <div className="mt-12 flex items-center gap-3">
                <Button
                  className="flex"
                  isLoading={stripeSetupIntentStatus === 'loading'}
                  variant="primary"
                  onClick={handleEditBilling}
                >
                  <span>
                    Edit<span className="hidden md:inline"> payment details</span>
                  </span>
                </Button>

                <RemovePaymentMethodModalButton />
              </div>
            </>
          )}
        </div>
      </SettingsPanel>

      <SettingsPanel className="border" title={<h2>Invoices</h2>}>
        <FormItem
          inputField={
            <TextField
              className="max-w-lg"
              disabled
              value={formatToSentenceCase(splitByPascalCase(user.billing.invoiceFrequency))}
              type="text"
            />
          }
          labelClassName="mt-0 pt-0"
          labelText="Invoice frequency"
        />
      </SettingsPanel>

      <BillingStripeRedirectModal />
    </>
  )
}

function PaymentMethodInfo() {
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  let formattedPaymentMethodType = 'N/A'
  if (user.billing.paymentMethod?.type === 'paypal') {
    formattedPaymentMethodType = 'PayPal'
  } else if (user.billing.paymentMethod?.type) {
    formattedPaymentMethodType = formatToSentenceCase(user.billing.paymentMethod?.type)
  }

  let formattedPaymentMethodIdentifier = user.billing.paymentMethod?.identifier
  if (user.billing.paymentMethod?.type === 'card' && user.billing.paymentMethod?.identifier) {
    formattedPaymentMethodIdentifier = `**** **** **** ${user.billing.paymentMethod.identifier}`
  }

  return (
    <>
      <FormItem
        inputField={<TextField className="max-w-lg" disabled value={formattedPaymentMethodType} type="text" />}
        labelClassName="mt-0 pt-0"
        labelText="Type"
      />
      {formattedPaymentMethodIdentifier && (
        <FormItem
          inputField={<TextField className="max-w-lg" disabled value={formattedPaymentMethodIdentifier} type="text" />}
          labelClassName="mt-0 pt-0"
        />
      )}
    </>
  )
}

function RemovePaymentMethodModalButton() {
  const { user, mutateUser } = useUser()
  const dispatch = useDispatch()

  const [isBillingRemoveModalOpen, setIsBillingRemoveModalOpen] = useState(false)
  const [removeBillingDetailsStatus, setRemoveBillingDetailsStatus] = useState(StatusEnum.Idle)

  if (!user) {
    throw Error('No user')
  }

  if (!user.billing.hasPaymentSetup) {
    return null
  }

  async function handleRemoveBillingDetails() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setRemoveBillingDetailsStatus(StatusEnum.Loading)

    try {
      await fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/removepayment`, { method: 'POST' })
      await mutateUser()
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setRemoveBillingDetailsStatus(StatusEnum.Success)
      handleOpenChange(false)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setRemoveBillingDetailsStatus(StatusEnum.Error)
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setRemoveBillingDetailsStatus(StatusEnum.Idle)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
    setIsBillingRemoveModalOpen(isOpen)
  }

  return (
    <>
      <Button
        className="inline-flex"
        variant="secondary"
        theme="danger"
        onClick={() => setIsBillingRemoveModalOpen(true)}
      >
        <span>
          Delete<span className="hidden md:inline"> payment details</span>
        </span>
      </Button>
      <ConfirmationModal
        closeButton={{ text: 'Cancel' }}
        continueButton={{ text: 'Remove', variant: 'primary', theme: 'danger' }}
        isLoading={removeBillingDetailsStatus === StatusEnum.Loading}
        open={isBillingRemoveModalOpen}
        setOpen={handleOpenChange}
        title="Remove billing details?"
        onContinue={handleRemoveBillingDetails}
        onCancel={() => handleOpenChange(false)}
      >
        <span>You won&apos;t be able to place an order until you add new billing details</span>
      </ConfirmationModal>
      {removeBillingDetailsStatus === StatusEnum.Loading && <OverlayPortal />}
    </>
  )
}
