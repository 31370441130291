import * as TabsPrimitive from '@radix-ui/react-tabs'
import { AnimatePresence, motion } from 'motion/react'
import { ComponentPropsWithoutRef, ElementRef, forwardRef } from 'react'

import { cn } from '../../helpers'

/**
 * Component adapted from shadcn/ui
 * Visit https://ui.shadcn.com/docs/components/tabs for more info and docs
 */
const Tabs = TabsPrimitive.Root

const TabsList = forwardRef<ElementRef<typeof TabsPrimitive.List>, ComponentPropsWithoutRef<typeof TabsPrimitive.List>>(
  ({ className, ...props }, ref) => (
    <TabsPrimitive.List ref={ref} className={cn('flex max-w-full overflow-x-auto', className)} {...props} />
  )
)
TabsList.displayName = TabsPrimitive.List.displayName

const TabsTrigger = forwardRef<
  ElementRef<typeof TabsPrimitive.Trigger>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Trigger> & {
    isSelected: boolean
  }
>(({ className, children, isSelected, ...props }, ref) => (
  <TabsPrimitive.Trigger
    asChild
    ref={ref}
    className={cn(
      'relative flex shrink-0 items-center justify-center overflow-hidden px-6 pb-6 text-sm font-medium',
      isSelected ? 'text-purple-500' : 'text-gray-800',
      className
    )}
    {...props}
  >
    <motion.button whileTap={{ scale: 0.95 }}>
      {children}
      <AnimatePresence initial={false}>
        {isSelected && (
          <motion.div
            initial={{ y: 5 }}
            animate={{ y: 0 }}
            exit={{ y: 5 }}
            className="absolute bottom-0 left-0 right-0 h-[4px]  bg-purple-200"
            transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
          />
        )}
      </AnimatePresence>
    </motion.button>
  </TabsPrimitive.Trigger>
))
TabsTrigger.displayName = TabsPrimitive.Trigger.displayName

const TabsContent = forwardRef<
  ElementRef<typeof TabsPrimitive.Content>,
  ComponentPropsWithoutRef<typeof TabsPrimitive.Content>
>(({ className, ...props }, ref) => (
  <TabsPrimitive.Content ref={ref} className={cn('border border-gray-200 p-8 lg:p-10', className)} {...props} />
))
TabsContent.displayName = TabsPrimitive.Content.displayName

export { Tabs, TabsList, TabsTrigger, TabsContent }
