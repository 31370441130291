import { Link } from 'react-router-dom'
import { ClipboardDocumentIcon } from '@heroicons/react/24/outline'
import { ChevronUpDownIcon, EllipsisHorizontalIcon, PencilSquareIcon, PlusIcon } from '@heroicons/react/24/solid'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../DropdownMenu'
import Button from '../Button'
import Skeleton from '../Skeleton'
import SearchField from '../SearchField'
import { ROUTE_PATHS } from '../../constants'

export default function ProductLibrary() {
  return (
    <div className="container xl:max-w-full">
      <div className="flex flex-col gap-6 sm:flex-row sm:items-center">
        <h1 className="mr-auto mt-0">Your products</h1>
        <div>
          <Button startIcon={<PlusIcon className="h-7 w-7" />}>Create Product</Button>
        </div>
      </div>

      <div className="sticky top-nav-bar-height z-10 my-10 bg-white p-4">
        <div className="flex flex-wrap items-center gap-2">
          <div className="w-64 flex-grow sm:flex-grow-0 lg:w-96">
            <SearchField placeholder="Search" onClearSearch={console.log} />
          </div>

          <div className="ml-auto w-64 flex-grow sm:flex-grow-0">
            <DropdownMenu>
              <DropdownMenuTrigger>
                <ChevronUpDownIcon className="mr-2 h-7 w-7 text-gray-500" />
                <span>Newest First</span>
              </DropdownMenuTrigger>

              <DropdownMenuContent align="end">
                <DropdownMenuCheckboxItem>Newest first</DropdownMenuCheckboxItem>
                <DropdownMenuCheckboxItem>Oldest first</DropdownMenuCheckboxItem>
              </DropdownMenuContent>
            </DropdownMenu>
          </div>
        </div>
      </div>

      <div className="mb-12 grid max-w-full grid-cols-[repeat(auto-fill,minmax(270px,1fr))] gap-6 overflow-x-auto md:gap-10">
        {Array.from({ length: 50 }).map((_, index) => (
          <div key={index} className="mx-auto flex min-h-[330px] w-[275px] flex-col p-10">
            <div>
              <img
                className="h-[225px] w-[225px] object-contain object-center"
                alt=""
                src="/img/v2/product-catalogue/prints_fine-art-prints.jpg"
              />
            </div>
            <div className="mt-4 flex gap-3">
              <div className="mr-auto truncate">
                <div className="truncate font-medium text-black">Snacktopus</div>
                <div className="truncate text-sm text-gray-700">Classic frame</div>
              </div>
              <div>
                <DropdownMenu>
                  <DropdownMenuTrigger className="aspect-square border-purple-600">
                    <EllipsisHorizontalIcon className="h-6 w-6 text-purple-600" style={{ transform: 'scale(1.5)' }} />
                    <span className="sr-only">Options</span>
                  </DropdownMenuTrigger>

                  <DropdownMenuContent align="end">
                    <DropdownMenuItem asChild>
                      <Link to={`${ROUTE_PATHS.PRODUCT_LIBRARY}/1`}>
                        <PencilSquareIcon className="text-inherit h-6 w-6" />
                        Edit product
                      </Link>
                    </DropdownMenuItem>
                    <DropdownMenuItem>
                      <ClipboardDocumentIcon className="text-inherit h-6 w-6" />
                      Duplicate
                    </DropdownMenuItem>
                  </DropdownMenuContent>
                </DropdownMenu>
              </div>
            </div>
          </div>
        ))}

        <ProductLibraryTileLoadingSkeleton />
        <ProductLibraryTileLoadingSkeleton />
        <ProductLibraryTileLoadingSkeleton />
        <ProductLibraryTileLoadingSkeleton />
      </div>
    </div>
  )
}

function ProductLibraryTileLoadingSkeleton() {
  return (
    <div className="mx-auto flex h-[330px] w-[275px] flex-col p-10">
      <Skeleton className="h-[225px] w-[225px] bg-purple-200/50" />
      <Skeleton className="mt-2 bg-purple-200/50" />
      <Skeleton className="mt-2 bg-purple-200/50" />
    </div>
  )
}
