import { areCompanyDetailsValid } from '../../helpers'
import { PaymentMethod } from './components/PaymentMethod'
import { useSplitToggle, useUser } from '../../../../hooks'
import { SettingsHeader } from '../SettingsHeader.component'
import { IncompleteCompanyDetailsPanel } from './components'
import { SettingsContainer } from '../SettingsContainer.component'
import { FEATURE_NAMES } from '../../../../../split-io/feature-names'
import { BillingInformationFormPanel } from './components/BillingInformationForm'
import { PaymentMethodDetails } from './components/PaymentMethod/PaymentMethodDetails.component'

export function SettingsBilling() {
  const { user } = useUser()
  const { splitIsOn: isDirectDebitOn } = useSplitToggle({
    toggle: FEATURE_NAMES.DIRECT_DEBIT,
    attributes: user ? { invoiceFrequency: user.billing.invoiceFrequency } : undefined
  })

  if (!user) {
    throw Error('No user')
  }

  const userHasFilledCompanyDetails = areCompanyDetailsValid({
    companyName: user.company.name,
    addressLine1: user.company.address1,
    city: user.company.townCity,
    postCode: user.company.postcode,
    county: user.company.countyState,
    countryCode: user.company.countryCode
  })

  return (
    <SettingsContainer>
      <SettingsHeader />

      <BillingInformationFormPanel />

      {userHasFilledCompanyDetails ? (
        <>{isDirectDebitOn ? <PaymentMethodDetails /> : <PaymentMethod />}</>
      ) : (
        <IncompleteCompanyDetailsPanel />
      )}
    </SettingsContainer>
  )
}
