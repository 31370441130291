import styles from './ShippingAndTotal.module.css'
import { useBrandingDetails, useSplitToggle } from '../../../../v2/hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { BrandingInsertQuoteInterface, QuoteInterface } from '../../../../v2/interfaces'

export function InsertsTotal({
  doesQuoteIncludeAllInserts,
  selectedQuote,
  totalInsertsPrice
}: {
  doesQuoteIncludeAllInserts: boolean
  selectedQuote: QuoteInterface
  totalInsertsPrice?: string
}) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  if (!isBrandingSettingsOn || !totalInsertsPrice) {
    return null
  }

  return (
    <tr>
      <td className={styles.label}>
        <div>Inserts:</div>
        {!doesQuoteIncludeAllInserts && <QuoteInserts inserts={selectedQuote?.branding?.inserts ?? []} />}
      </td>
      <td className={styles.value} data-test="inserts-value">
        {totalInsertsPrice}
      </td>
    </tr>
  )
}

function QuoteInserts({ inserts }: { inserts: BrandingInsertQuoteInterface[] }) {
  const { brandingDetails } = useBrandingDetails()

  if (!inserts || inserts.length === 0) {
    return null
  }

  const quoteInsertNames: string[] = []
  inserts.forEach((insert) => {
    const insertName =
      brandingDetails?.branding?.find((brandingDetail) => brandingDetail?.id === insert?.id)?.name ?? insert?.id ?? ''
    quoteInsertNames.push(insertName)
  })

  return (
    <div className="tailwind">
      <span className="text-sm text-gray-500">{quoteInsertNames.join(', ')}</span>
    </div>
  )
}
