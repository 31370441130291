import { useState } from 'react'
import { format } from 'date-fns'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import { useUser } from '../../../hooks'
import { StatusEnum } from '../../../enums'
import { useUserSubscription } from '../hooks'
import OverlayPortal from '../../OverlayPortal'
import ConfirmationModal from '../../ConfirmationModal'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { updateUserSuccess } from '../../../../actions/user'
import { refreshAccessToken } from '../../../../actions/auth'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'
import { updateSubscription } from '../helpers'

export function CancelProSubscriptionModal({
  isModalOpen,
  setIsModalOpen
}: {
  isModalOpen: boolean
  setIsModalOpen: (open: boolean) => void
}) {
  const { mutateUser } = useUser()
  const { subscription, mutateSubscription } = useUserSubscription()
  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  async function switchToCore() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setUpdateStatus(StatusEnum.Loading)

    try {
      await updateSubscription('Core')
      await mutateSubscription()
      await mutateUser()
      handleOpenChange(false)
      setUpdateStatus(StatusEnum.Success)
      createToast({
        duration: 3000,
        heading: 'Subscription update is being processed',
        id: SETTINGS_UPDATE_SUCCESS_TOAST_ID,
        type: 'success'
      })
      dispatch(refreshAccessToken())
      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update subscription',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setUpdateStatus(StatusEnum.Error)
    }
  }

  const isLoading = updateStatus === StatusEnum.Loading

  const billingPeriodEnd =
    subscription && subscription.currentBillingPeriodEnd ? new Date(subscription.currentBillingPeriodEnd) : null

  return (
    <>
      <ConfirmationModal
        className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
        contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
        continueButton={{
          variant: 'primary',
          theme: 'danger',
          text: 'Yes, cancel my Pro subscription'
        }}
        closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'No, do not cancel' }}
        isLoading={isLoading}
        open={isModalOpen}
        title="Cancel your Pro subscription"
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={switchToCore}
      >
        <p>
          Your Prodigi Pro subscription is active until{' '}
          {billingPeriodEnd ? (
            <span className="font-medium">{format(billingPeriodEnd, 'dd MMMM YYY')}</span>
          ) : (
            'your subscription expires'
          )}
          . You&apos;ll continue to enjoy all Pro benefits until then.
        </p>

        <p className="mt-8">
          After {billingPeriodEnd ? format(billingPeriodEnd, 'dd MMMM YYY') : 'your subscription expires'}, your account
          will revert to free status with the following standard features:
        </p>

        <ul className="mt-4 list-inside list-disc">
          <li className="mt-2">
            <span className="italic line-through">10% off all product prices</span> Walkup pricing
          </li>
          <li className="mt-2">
            <span className="italic line-through">75% discount on all branded packaging inserts</span> Free packing
            slips
          </li>
          <li className="mt-2">
            <span className="italic line-through">50% savings on all sample packs and free first sample order</span> Not
            included
          </li>
          <li className="mt-2">
            <span className="italic line-through">Unlimited image library storage</span> 10Gb image library storage
          </li>
          <li className="mt-2">
            <span className="italic line-through">Dedicated account manager for personalised support</span> Not included
          </li>
        </ul>
      </ConfirmationModal>

      {isLoading && <OverlayPortal />}
    </>
  )
}
