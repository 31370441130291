import { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/24/outline'

import {
  CancelProSubscriptionModal,
  ChangeToProModal,
  CoreSubscriptionPanel,
  EnterpriseSubscriptionPanel,
  ProSubscriptionPanel,
  ViewProSubscriptionDetailsModal
} from './components'
import { useUser } from '../../hooks'
import SupportLink from '../SupportLink'
import { useUserSubscription } from './hooks'
import { ProBillingOption } from './interfaces'
import { getProBillingOptions } from './helpers'
import { SubscriptionTierType } from '../../interfaces'

export default function BilingSubscriptionPlans() {
  const { user } = useUser()

  if (!user) {
    throw Error('No user')
  }

  const proPricing = getProBillingOptions(user.merchantSubscriptionSettings.subscriptionTierPricing)

  return (
    <BilingPlansPanel
      currency={Object.values(proPricing)[0].currency}
      currentSubscription={user.merchantSubscriptionSettings.subscriptionTier}
      proPricing={proPricing}
      userHasToAddBillingDetails={!user.billing.hasPaymentSetup}
    />
  )
}

function BilingPlansPanel({
  currency,
  currentSubscription,
  proPricing,
  userHasToAddBillingDetails
}: {
  currency: string
  currentSubscription: SubscriptionTierType
  proPricing: Record<ProBillingOption, { cost: number; currency: string }>
  userHasToAddBillingDetails: boolean
}) {
  const { error } = useUserSubscription()

  const [isChangeToProModalOpen, setIsChangeToProModalOpen] = useState(false)
  const [isViewSubscriptionModalOpen, setIsViewSubscriptionModalOpen] = useState(false)
  const [isCancelProSubscriptionModalOpen, setIsCancelProSubscriptionModalOpen] = useState(false)

  const [selectedProSubscriptionTier, setSelectedProSubscriptionTier] = useState<ProBillingOption>(
    currentSubscription === 'ProMonthlyBilling' ? 'ProMonthlyBilling' : 'ProYearlyBilling'
  )

  return (
    <>
      <div id="BillingPlans" className="gap-y-12 border border-b bg-white px-12 pb-20 pt-16 md:gap-x-8 lg:gap-y-0">
        <div className="col-span-4 flex flex-col pb-6 md:col-span-4 lg:col-span-4">
          <h2>Plans</h2>

          {userHasToAddBillingDetails && (
            <div className="mt-6 inline-flex items-center space-x-6 border border-magenta-700 bg-magenta-100 p-4 text-magenta-700">
              <span className="flex items-center">
                <ExclamationCircleIcon aria-hidden="false" className="h-8 w-8 text-magenta-700" />
              </span>
              <span>You will need to set up billing details before you can update your plan</span>
            </div>
          )}

          {error && (
            <div className="mt-6 inline-flex items-center space-x-6 border border-magenta-700 bg-magenta-100 p-4 text-magenta-700">
              <span className="flex items-center">
                <ExclamationCircleIcon aria-hidden="false" className="h-8 w-8 text-magenta-700" />
              </span>
              <span>
                An unexpected error occured when loading plan details. Please try again and{' '}
                <SupportLink className="text-magenta-700 underline">contact us</SupportLink> if the issue persists. Code{' '}
                {error?.status ?? '0'}
              </span>
            </div>
          )}
        </div>

        <div className="mt-8 lg:col-span-full lg:col-start-6 xl:col-span-6 xl:col-start-5">
          <ul className="grid gap-y-6 xl:grid-cols-3 xl:gap-x-6">
            <li>
              <CoreSubscriptionPanel
                currency={currency}
                currentSubscription={currentSubscription}
                userHasToAddBillingDetails={userHasToAddBillingDetails}
                setIsCancelProSubscriptionModalOpen={setIsCancelProSubscriptionModalOpen}
              />
            </li>
            <li>
              <ProSubscriptionPanel
                proPlanBillingOptions={proPricing}
                currentSubscription={currentSubscription}
                selectedProSubscriptionTier={selectedProSubscriptionTier}
                userHasToAddBillingDetails={userHasToAddBillingDetails}
                setIsChangeToProModalOpen={setIsChangeToProModalOpen}
                setIsViewSubscriptionModalOpen={setIsViewSubscriptionModalOpen}
                setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
              />
            </li>
            <li>
              <EnterpriseSubscriptionPanel currentSubscription={currentSubscription} />
            </li>
          </ul>
        </div>
      </div>

      <ChangeToProModal
        isModalOpen={isChangeToProModalOpen}
        proPlanBillingOptions={proPricing}
        selectedProSubscriptionTier={selectedProSubscriptionTier}
        setIsModalOpen={setIsChangeToProModalOpen}
        setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <ViewProSubscriptionDetailsModal
        isModalOpen={isViewSubscriptionModalOpen}
        proPlanBillingOptions={proPricing}
        selectedProSubscriptionTier={selectedProSubscriptionTier}
        openCancelProPlanModal={() => {
          setIsViewSubscriptionModalOpen(false)
          setIsCancelProSubscriptionModalOpen(true)
        }}
        setIsModalOpen={setIsViewSubscriptionModalOpen}
        setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <CancelProSubscriptionModal
        isModalOpen={isCancelProSubscriptionModalOpen}
        setIsModalOpen={setIsCancelProSubscriptionModalOpen}
      />
    </>
  )
}
