import toast from 'react-hot-toast'
import { useState, useEffect } from 'react'

import {
  SALES_CHANNELS_STORE_CONNECTED_SUCCESS_TOAST_ID,
  SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID
} from '../constants'
import Button from '../../Button'
import { useMerchantService } from '../../../hooks'
import { APP_ENVIRONMENTS } from '../../../constants'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { StatusEnum, RutterPlatformEnum } from '../../../enums'
import { RutterSupportedPlatformInterface } from '../interfaces'
import SalesChannelOnboardingModal from '../../SalesChannelOnboardingModal'
import { AuthenticationLoadingModal } from './AuthenticationLoadingModal.component'
import { NewRutterSalesChannelErrorInterface, postNewRutterSalesChannel } from '../helpers'
import SupportLink from '../../SupportLink'

export function RutterPlatformCard({ platform }: { platform: RutterSupportedPlatformInterface }) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()

  const [authenticationStatus, setAuthenticationStatus] = useState(StatusEnum.Idle)
  const [newSalesChannelId, setNewSalesChannelId] = useState<string | undefined>()
  const [rutterInstance, setRutterInstance] = useState<RutterInstanceType | null>(null)

  useEffect(() => {
    const rutterInstance = window.Rutter.create({
      publicKey: process.env.REACT_APP_RUTTER_PUBLIC_KEY ?? '',
      onSuccess: async (publicToken: string) => {
        try {
          if (!merchantDetails) {
            throw Error('No merchant details')
          }

          const result = await postNewRutterSalesChannel({
            merchantId: merchantDetails.id,
            publicToken,
            platform: platform.prodigiPlatformName
          })
          await mutateMerchantDetails()
          setNewSalesChannelId(result.data.id)

          createToast({
            content: 'We are currently retrieving your products from your store.',
            duration: 4000,
            heading: `${platform.prodigiPlatformName} store connected`,
            id: SALES_CHANNELS_STORE_CONNECTED_SUCCESS_TOAST_ID,
            type: 'success'
          })
          setAuthenticationStatus(StatusEnum.Success)
          window.analytics.track('Sales Channel Connected', { platformName: platform.prodigiPlatformName })
        } catch (error) {
          const errorResponse = error as FetchErrorInterface<NewRutterSalesChannelErrorInterface>
          const failures = errorResponse.responseBodyJson?.failures ?? {}
          const channelAlreadyExists = failures['SalesChannel']?.find((failure) => failure?.code === 'AlreadyExists')

          const errorMessage = channelAlreadyExists ? (
            <>
              <p className="mb-2">Store is already connected to Prodigi.</p>
              <p>
                If you cannot see the store connection listed as a sales channel, please{' '}
                <SupportLink>contact support</SupportLink>
              </p>
            </>
          ) : (
            'We could not connect to your store.'
          )

          createErrorToast({
            errorCode: errorResponse.status ?? '0',
            heading: 'Store connection failed',
            id: SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID,
            errorMessage,
            showLink: false
          })
          setAuthenticationStatus(StatusEnum.Error)
          window.analytics.track('Sales Channel Connection Failed', { platformName: platform.prodigiPlatformName })
        }
      },
      onExit: (error: unknown) => {
        if (typeof error === 'string' && error?.toUpperCase() === 'MERCHANT_CLOSED') {
          setAuthenticationStatus(StatusEnum.Idle)
          return
        }

        if (typeof error === 'string' && error?.toUpperCase() === 'UNKNOWN_ERROR') {
          createErrorToast({
            errorCode: 'RL-unknown-error',
            heading: 'Store connection failed',
            id: SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID,
            errorMessage: 'We could not connect to your store.'
          })
          setAuthenticationStatus(StatusEnum.Error)
          window.analytics.track('Sales Channel Connection Failed', { platformName: platform.prodigiPlatformName })
        }
      }
    })
    setRutterInstance(rutterInstance)
  }, [merchantDetails, mutateMerchantDetails, platform.prodigiPlatformName])

  useEffect(() => {
    return () => {
      toast.dismiss(SALES_CHANNELS_STORE_CONNECTED_ERROR_TOAST_ID)
    }
  }, [])

  function handleConnectNewRutterSalesChannel() {
    if (!rutterInstance) {
      throw Error('No rutter instance')
    }

    setAuthenticationStatus(StatusEnum.Loading)
    rutterInstance.open({
      platform: platform.name
    })
    window.analytics.track('Sales Channel Connection Started', {
      platformName: platform.name
    })
  }

  function handleReset() {
    setAuthenticationStatus(StatusEnum.Idle)
    setNewSalesChannelId(undefined)
  }

  return (
    <>
      <li className="flex w-full flex-col border border-gray-200 bg-white p-10">
        <div>
          <RutterPlatformLogoFactory platformName={platform.name} />
        </div>

        <p className="mb-16 mt-8 max-w-lg">{platform.description}</p>

        <Button isLoading={!rutterInstance} className="whitespace w-fit" onClick={handleConnectNewRutterSalesChannel}>
          {platform.ctaButtonText}
        </Button>
        <p className="mt-8 max-w-lg text-sm">{platform.disclaimer}</p>
      </li>

      <AuthenticationLoadingModal
        newPlatformName={platform.prodigiPlatformName}
        open={Boolean(authenticationStatus === StatusEnum.Loading)}
        rutterPlatform={platform.name}
        setOpen={(isOpen) => {
          if (!isOpen) {
            setAuthenticationStatus(StatusEnum.Idle)
          }
        }}
      />

      <SalesChannelOnboardingModal
        newSalesChannelId={newSalesChannelId as string}
        open={Boolean(authenticationStatus === StatusEnum.Success && newSalesChannelId)}
        onCompleteOnboarding={handleReset}
        setOpen={(isOpen) => {
          if (!isOpen) {
            handleReset()
          }
        }}
      />
    </>
  )
}

function RutterPlatformLogoFactory({ platformName }: { platformName: RutterPlatformEnum }) {
  switch (platformName) {
    case RutterPlatformEnum.Etsy:
      return (
        <img
          alt="Etsy logo"
          src="/img/logo/etsy-orange.png"
          className="-ml-1 block h-16"
          style={{ transform: 'scale(1.2)' }}
        />
      )
    case RutterPlatformEnum.WooCommerce:
      return <img alt="WooCommerce logo" src="/img/logo/woocommerce.svg" className="-ml-1 block h-16" />
    case RutterPlatformEnum.Wix:
      return (
        <img alt="Wix logo" src="/img/logo/wix.png" className="-ml-6 block h-16" style={{ transform: 'scale(0.7)' }} />
      )
    case RutterPlatformEnum.BigCommerce:
      return <img alt="BigCommerce logo" src="/img/logo/bigcommerce.svg" className="block h-16" />
    case RutterPlatformEnum.AdobeCommerce:
      return <img alt="Adobe Commerce logo" src="/img/logo/adobecommerce.svg" className="block h-16" />
    case RutterPlatformEnum.Squarespace:
      return (
        <img
          alt="Squarespace logo"
          src="/img/logo/squarespace.svg"
          className="ml-2 block h-16"
          style={{ transform: 'scale(1.2)' }}
        />
      )
    case RutterPlatformEnum.Shopify:
      return <img alt="Shopify logo" src="/img/logo/shopify-colour.png" className="block h-16" />
    case RutterPlatformEnum.Amazon:
      return (
        <img
          alt="Amazon logo"
          src="/img/logo/amazon.svg"
          className="-ml-4 block h-16"
          style={{ transform: 'scale(0.8)' }}
        />
      )
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
