import { ProBillingOption } from '../interfaces'
import { RadioGroup, RadioGroupItem } from '../../RadioGroup'
import { calculatePlanSaving, formatBillingCost } from '../helpers'

export function ProPlanBillingRadioButtons({
  proPlanBillingOptions,
  selectedSubscriptionTier,
  setSelectedSubscriptionTier
}: {
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedSubscriptionTier: ProBillingOption
  setSelectedSubscriptionTier: (value: React.SetStateAction<ProBillingOption>) => void
}) {
  const saving = calculatePlanSaving(proPlanBillingOptions)

  return (
    <RadioGroup
      className="mt-4"
      value={selectedSubscriptionTier}
      onValueChange={(newValue) => setSelectedSubscriptionTier(newValue as ProBillingOption)}
    >
      {Object.entries(proPlanBillingOptions).map(([id, costData]) => {
        return (
          <div key={id} className="flex items-center gap-4">
            <RadioGroupItem id={id} value={id} />
            <label htmlFor={id}>{formatLabel({ costData, id: id as ProBillingOption, saving })}</label>
          </div>
        )
      })}
    </RadioGroup>
  )
}

function formatLabel({
  costData,
  id,
  saving
}: {
  costData: { cost: number; currency: string }
  id: ProBillingOption
  saving: string
}) {
  if (id === 'ProMonthlyBilling') {
    return <span>Pay monthly: {formatBillingCost(costData)} per month</span>
  }

  return (
    <span>
      Pay annually: {formatBillingCost(costData)} per year <span className="text-red-800">SAVE {saving}</span>
    </span>
  )
}
