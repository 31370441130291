// @flow

import type { RsaaStatus, AppState, DispatchFunc, Country } from '../../../types'

import React, { Component } from 'react'
import { compose } from 'redux'
import cx from 'classnames'
import { connect } from 'react-redux'
import Page from '../../../components/Page'
import WhitePanel from '../../../components/WhitePanel'
import TextField from '../../../components/inputs/TextField'
import Loading from '../components/Loading'
import { clearOrderData, changeDeliveryCountry, MOF_ORDER_LOGGER_ID } from '../../../actions/manualOrderForm'
import { resetProductSearchResults, selectItemCategory, getItemCategories } from '../../../actions/catalogue'
import { getFormattedCountries } from '../../../selectors/manualOrderForm'
import { selectCountryCode } from '../../../selectors/user'
import { NO_SELECTION } from '../../../data/catalogue'
import WithUserDetails from '../../../components/hoc/WithUserDetails'
import styles from './OrderSelectCountry.module.css'
import { pushRedirect } from '../../../actions/router'
import { CREATE_V4_ORDER } from '../../../actions/orders'
import { buildRsaaActionId } from '../../../helpers/rsaa'
import { resetRsaaStatus } from '../../../actions/rsaa'
import { getRsaaStatus } from '../../../selectors/rsaa'
import { IDLE } from '../../../data/rsaa'
import { FEATURE_NAMES } from '../../../split-io/feature-names'

// $FlowFixMe: TypeScript file
import { useSplitTreatment } from '../../../v2/hooks'
// $FlowFixMe: TypeScript file
import OrderSelectCountryV2 from './OrderSelectCounty.component'

const popularCountries = [
  { isoCode: 'AU', name: 'Australia' },
  { isoCode: 'CA', name: 'Canada' },
  { isoCode: 'FR', name: 'France' },
  { isoCode: 'DE', name: 'Germany' },
  { isoCode: 'IE', name: 'Ireland' },
  { isoCode: 'ES', name: 'Spain' },
  { isoCode: 'GB', name: 'United Kingdom' },
  { isoCode: 'US', name: 'United States' }
]

type Props = {|
  countries: Country[],
  deliveryCountry: string,
  defaultDeliveryCountry: string,
  defaultCountry: string,
  selectedDeliveryCountry: string,
  fetchError: boolean,
  fetching: boolean,
  orderCreationStatus: RsaaStatus,
  itemsToBeCreated: Array<any>,
  changeDeliveryCountry: (string) => void,
  selectDefaultDeliveryCountry: (string) => void,
  resetOrderCreationStatus: () => void,
  redirect: (string) => void,
  fetchError: boolean,
  location: Location
|}

type State = {|
  search: string,
  filteredCountries: Array<Country>
|}

class OrderSelectCountry extends Component<Props, State> {
  constructor(props) {
    super(props)
    this.state = {
      search: '',
      filteredCountries: props.countries
    }
  }

  componentDidMount() {
    if (this.props.orderCreationStatus.status !== IDLE) {
      this.props.resetOrderCreationStatus()
    }
    this.selectDefaultDeliveryCountry()
    window.analytics.page('MOF', 'Country')
  }

  componentDidUpdate(prevProps) {
    if (prevProps.countries !== this.props.countries) {
      this.filterCountries(this.state.search)
    }
  }

  selectDefaultDeliveryCountry = () => {
    if (!this.props.selectedDeliveryCountry) {
      this.props.changeDeliveryCountry(this.props.defaultDeliveryCountry)
    }
  }

  filterCountries = (search: string) => {
    let query = search.toLowerCase()
    const filteredCountries = this.props.countries.filter(
      (country) => country.name.toLowerCase().includes(query) || country.isoCode.toLowerCase() === query
    )
    this.setState({ search, filteredCountries })
  }

  selectCountry = (country: Country) => {
    const { selectedDeliveryCountry, changeDeliveryCountry, itemsToBeCreated, redirect } = this.props

    if (selectedDeliveryCountry !== country.isoCode) {
      changeDeliveryCountry(country.isoCode)
    }

    if (!itemsToBeCreated || itemsToBeCreated.length === 0) {
      redirect('/orders/create/select-item')
    } else {
      redirect('/orders/create/add-image')
    }
  }

  onChangeSearch = (e) => {
    this.filterCountries(e.target.value)
  }

  onClickCard = (country: Country) => {
    window.analytics.track('Popular Country Selected', country)
    this.selectCountry(country)
  }

  onClickListItem = (country: Country) => {
    window.analytics.track('Country Selected', {
      ...country,
      searchQuery: this.state.search
    })
    this.selectCountry(country)
  }

  onClickContinue = () => this.props.redirect('/orders/create/select-item')

  renderCountryCard = (country: Country) => (
    <li key={country.isoCode}>
      <button className={styles.countryCard} onClick={() => this.onClickCard(country)}>
        <WhitePanel>
          <div className={styles.country} data-test={`country-card-${country.name}`}>
            <img
              data-test={`country-card-${country.name}-image`}
              src={`/img/flags-100/${country.isoCode.toLowerCase()}.png`}
              alt="flag"
              width="56"
              height="32"
            />
            <p className={styles.countryTitle}>{country.name}</p>
          </div>
        </WhitePanel>
      </button>
    </li>
  )

  renderCountryListItem = (country: Country) => (
    <li key={country.isoCode}>
      <button
        data-test={`country-list-item-${country.isoCode.toLowerCase()}`}
        className={cx(styles.country, styles.countryListItem)}
        onClick={() => this.onClickListItem(country)}
      >
        <img src={`/img/flags-100/${country.isoCode.toLowerCase()}.png`} alt="flag" width="32" />
        <p data-test="country-name" className={styles.countryTitle}>
          {country.name}
        </p>
      </button>
    </li>
  )

  render(): React$Node {
    const { fetching, fetchError, countries, selectedDeliveryCountry } = this.props
    const { search, filteredCountries } = this.state

    let primaryAction
    if (selectedDeliveryCountry) {
      const country = countries.find((item) => item.isoCode === selectedDeliveryCountry)
      if (country) {
        primaryAction = {
          title: `Continue with ${country.name}`,
          onClick: this.onClickContinue
        }
      }
    }

    return (
      <Page
        title="Delivery country"
        description="Select your destination country so that we can show you products available in that region."
        primaryAction={primaryAction}
        showActionsInFooter={true}
      >
        {fetching || fetchError ? (
          <Loading error={fetchError} errorMessage={'Something went wrong.'} />
        ) : (
          <div className={styles.content}>
            <section data-test="popular-countries">
              <h2 className={styles.heading}>Popular countries</h2>
              <ul className={styles.popularCountries}>{popularCountries.map(this.renderCountryCard)}</ul>
            </section>

            <section>
              <h2 className={styles.heading}>All countries</h2>
              <ul data-test="country-list" className={styles.countryList}>
                <TextField
                  dataTest="country-search"
                  onChange={this.onChangeSearch}
                  value={search}
                  placeholder="Search all countries..."
                />
                {filteredCountries.map(this.renderCountryListItem)}
              </ul>
            </section>
          </div>
        )}
      </Page>
    )
  }
}

const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)

function mapStateToProps(state: AppState) {
  return {
    defaultDeliveryCountry: selectCountryCode(state),
    countries: getFormattedCountries(state),
    selectedDeliveryCountry: state.manualOrderForm.deliveryCountry,
    fetchError: state.manualOrderForm.fetchError,
    fetching: state.manualOrderForm.fetching,
    orderCreationStatus: getRsaaStatus(state, createOrderActionId)
  }
}

function mapDispatchToProps(dispatch: DispatchFunc) {
  function resetOrderCreationStatus() {
    dispatch(clearOrderData())
    dispatch(resetRsaaStatus(createOrderActionId))
  }

  return {
    resetOrderCreationStatus,
    changeDeliveryCountry: (country: string) => {
      resetOrderCreationStatus()
      dispatch(resetProductSearchResults())
      dispatch(selectItemCategory(NO_SELECTION))
      dispatch(getItemCategories())
      dispatch(changeDeliveryCountry(country))
    },
    redirect: (address: string) => {
      dispatch(pushRedirect(address))
    }
  }
}

export default (compose(WithUserDetails, connect(mapStateToProps, mapDispatchToProps))(OrderSelectCountrySplit): any)

function OrderSelectCountrySplit(props: Props) {
  const showSelectCountrySplitData = useSplitTreatment(FEATURE_NAMES.DASHBOARD_SELECT_COUNTRY_V2)
  const showSelectCountryV2 = !showSelectCountrySplitData.isLoadingSplit && showSelectCountrySplitData.isTreatmentOn

  if (showSelectCountryV2) {
    return <OrderSelectCountryV2 />
  }

  return <OrderSelectCountry {...props} />
}
