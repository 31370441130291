import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'

import InlineMenu from '../../InlineMenu'
import { QUERY_PARAMS } from '../../../constants'
import { useDebouncedUrlUpdate } from '../../../hooks/useDebouncedUrlUpdate.hook'

const FILE_TYPE_OPTIONS = [
  {
    content: <span className="pr-10">png</span>,
    value: 'png',
    searchLabel: 'png'
  },
  {
    content: <span className="pr-10">jpg</span>,
    value: 'jpg',
    searchLabel: 'jpg'
  },
  {
    content: <span className="pr-10">pdf (multi-page)</span>,
    value: 'pdf (multi-page)',
    searchLabel: 'pdf (multi-page)'
  },
  {
    content: <span className="pr-10">pdf (single page)</span>,
    value: 'pdf (single page)',
    searchLabel: 'pdf (single page)'
  }
]

export function ImageLibraryListActionFileFormat() {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const selectedParam = searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
  const selectedDropdownValues = useMemo(() => (selectedParam ? selectedParam.split(',') : []), [selectedParam])
  const [selectedValues, setSelectedValues] = useState(selectedDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedValues(selectedDropdownValues)
  }, [selectedDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  const updateSelected = (selectedValues: string[]) => {
    setSelectedValues(selectedValues)

    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE,
      routePath: pathname,
      selectedValues
    })
  }

  return (
    <InlineMenu
      className="max-h-96"
      ariaLabel="File format menu"
      data-test="image-library-actions__-filter"
      multiple={true}
      options={FILE_TYPE_OPTIONS}
      selected={selectedValues}
      updateSelected={updateSelected}
    />
  )
}
