import { motion } from 'motion/react'
import clsx from 'clsx'
import { Link, useRouteMatch } from 'react-router-dom'

import { useSplitTreatment } from '../../../hooks'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'

export function SettingsHeader() {
  return (
    <header>
      <h1>Settings</h1>
      <nav className="relative flex max-w-full gap-6 overflow-x-auto pt-8">
        <SettingsLinks />
      </nav>
    </header>
  )
}

function SettingsLinks() {
  const routeMatch = useRouteMatch()
  const { isTreatmentOn: isBrandingSettingsOn } = useSplitTreatment(FEATURE_NAMES.BRANDING_SETTINGS)
  const { isTreatmentOn: isProdigiProOn } = useSplitTreatment(FEATURE_NAMES.DASHBOARD_PRODIGI_PRO)
  const isSandboxEnvironment = process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX

  if (isSandboxEnvironment) {
    return null
  }

  const settingsNavItems = [
    {
      name: 'Account',
      path: ROUTE_PATHS.SETTINGS.ACCOUNT
    },
    {
      name: 'Preferences',
      path: ROUTE_PATHS.SETTINGS.PREFERENCES
    },
    {
      name: 'Billing',
      path: ROUTE_PATHS.SETTINGS.BILLING
    },
    isProdigiProOn
      ? {
          name: 'Subscriptions',
          path: ROUTE_PATHS.SETTINGS.SUBSCRIPTIONS
        }
      : undefined,
    isBrandingSettingsOn
      ? {
          name: 'Branding',
          path: ROUTE_PATHS.SETTINGS.BRANDING
        }
      : undefined,
    {
      name: 'API',
      path: ROUTE_PATHS.SETTINGS.API
    }
  ].filter(Boolean)

  return (
    <>
      {settingsNavItems.map((settingsNavItem) => {
        if (!settingsNavItem) {
          return null
        }

        return (
          <Link
            className={clsx(
              'relative px-6 pb-6 font-medium',
              routeMatch.path === settingsNavItem.path && 'text-purple-500'
            )}
            key={settingsNavItem.path}
            to={settingsNavItem.path}
          >
            {settingsNavItem.name}
            <div>
              {routeMatch.path === settingsNavItem.path && (
                <motion.div
                  layoutId="activeSettingsLink"
                  className="absolute bottom-0 left-0 right-0 h-[4px] bg-purple-200"
                  transition={{ type: 'spring', bounce: 0, duration: 0.4 }}
                />
              )}
            </div>
          </Link>
        )
      })}
    </>
  )
}
