import { SubscriptionTierPricingInterface } from '../../../interfaces'
import { ProBillingOption } from '../interfaces'

export function getProBillingOptions(
  pricing: SubscriptionTierPricingInterface
): Record<ProBillingOption, { cost: number; currency: string }> {
  return {
    ProYearlyBilling: pricing.proYearlyBilling,
    ProMonthlyBilling: pricing.proMonthlyBilling
  }
}
