import { useState } from 'react'
import { twMerge } from 'tailwind-merge'
import { PhotoIcon, TagIcon as SolidTagIcon } from '@heroicons/react/20/solid'
import { ChevronDownIcon, TagIcon, TrashIcon } from '@heroicons/react/24/outline'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger
} from '../../DropdownMenu'
import { ImageLibraryImageInterface } from '../../../hooks'
import { ImageLibraryFullPageModeType } from '../ImageLibraryFullPage.component'
import { AddTagsModal, DeleteImagesModal, RemoveTagsModal, UpdateTypeModal } from '.'

export type ManageActionsValueType = 'change-type' | 'add-tags' | 'remove-tags' | 'delete'

export function ManageActions({
  selectedImages,
  onSetImageLibraryMode
}: {
  selectedImages: ImageLibraryImageInterface[]
  onSetImageLibraryMode: (mode: ImageLibraryFullPageModeType) => void
}) {
  const [actionModalToOpen, setActionModalToOpen] = useState<ManageActionsValueType | null>(null)

  const atLeastOneSelectedImagesHasATag = selectedImages.some((image) => image.tags.length > 0)

  return (
    <>
      <span className="flex h-fit flex-grow items-center sm:flex-grow-0">
        <DropdownMenu>
          <DropdownMenuTrigger
            className={twMerge(
              'border-purple-500 pr-5 disabled:border-gray-600 disabled:opacity-50',
              selectedImages.length > 0 ? 'text-purple-500' : 'text-gray-600'
            )}
            disabled={selectedImages.length === 0}
          >
            <ChevronDownIcon className="mr-2 h-6 w-6" />
            Actions
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="pb-4 pl-8 pr-8 pt-4"
              onSelect={() => {
                setActionModalToOpen('change-type')
              }}
            >
              <PhotoIcon className="mr-2 h-7 w-7 text-gray-600" aria-hidden="true" />
              Change type
            </DropdownMenuItem>
            <DropdownMenuItem
              className="pb-4 pl-8 pr-8 pt-4"
              onSelect={() => {
                setActionModalToOpen('add-tags')
              }}
            >
              <SolidTagIcon className="mr-2 h-7 w-7 text-gray-600" aria-hidden="true" />
              Add tags
            </DropdownMenuItem>
            <DropdownMenuItem
              className="pb-4 pl-8 pr-8 pt-4"
              disabled={!atLeastOneSelectedImagesHasATag}
              onSelect={() => {
                setActionModalToOpen('remove-tags')
              }}
            >
              <TagIcon className="mr-2 h-7 w-7 text-gray-600" aria-hidden="true" />
              Remove tags
            </DropdownMenuItem>
            <DropdownMenuSeparator className="mx-8" />
            <DropdownMenuItem
              className="pb-4 pl-8 pr-8 pt-4 text-magenta-800"
              onSelect={() => {
                setActionModalToOpen('delete')
              }}
            >
              <TrashIcon className="mr-2 h-7 w-7 text-magenta-800" aria-hidden="true" />
              Delete
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </span>
      <UpdateTypeModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <AddTagsModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <RemoveTagsModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
      <DeleteImagesModal
        actionModalToOpen={actionModalToOpen}
        imageLibraryImages={selectedImages}
        onSetActionModalToOpen={setActionModalToOpen}
        onSetImageLibraryMode={onSetImageLibraryMode}
      />
    </>
  )
}
