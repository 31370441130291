import { useState } from 'react'
import { PlusIcon } from '@heroicons/react/20/solid'

import Button from '../Button'
import { PrintAreasArtworkDataType } from '../ImageEditor'
import { AddLineItemSelectProductModal } from './components'
import { ProductDetailPrintAreaInterface } from '../../hooks'
import { ItemMetaDataInterface, OrderDetailItemAssetInterface } from '../../interfaces'

export const ADD_LINE_ITEM_TOASTS = {
  ERROR: 'prodigiLineItemErrorToast'
}

export type AddLineItemEditorDataType = {
  lineItemId?: string
  artworkData?: PrintAreasArtworkDataType | null
  assetRequirementData?: Record<string, { required: boolean }> | null
  selectedProductData?: {
    sku: string
    attributes: Record<string, string> | null
    metaData?: ItemMetaDataInterface
    productType: string
    price?: string
  } | null
  assetData?: Record<string, OrderDetailItemAssetInterface> | null
  selectedPrintArea?: string | null
  printAreas?: Record<string, ProductDetailPrintAreaInterface>
} | null

export default function OrderEditAddLineItem() {
  const [lineItemModalToOpen, setLineItemModalToOpen] = useState<'select-product' | null>(null)

  return (
    <>
      <div className="flex border border-dashed p-6">
        <div className="bg-white">
          <Button
            startIcon={<PlusIcon className="h-7 w-7" />}
            variant="secondary"
            theme="greyscale"
            size="sm"
            onClick={() => {
              window.analytics.track('Clicked: Add product to draft order')
              setLineItemModalToOpen('select-product')
            }}
          >
            Add another item
          </Button>
        </div>
      </div>

      <AddLineItemSelectProductModal
        isSelectProductModalOpen={lineItemModalToOpen === 'select-product'}
        onSelectProductModalOpenChange={(isOpen) => {
          if (isOpen) {
            setLineItemModalToOpen('select-product')
          } else {
            setLineItemModalToOpen(null)
          }
        }}
        onSelectProductSuccess={() => setLineItemModalToOpen(null)}
      />
    </>
  )
}
