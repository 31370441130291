import LoadingSpinner from '../LoadingSpinner'
import { MockupViewInterface, useMockups3D } from '../../hooks'
import { DictionaryInterface, StatusType } from '../../interfaces'
import { MockupError, Mockups3DPreviewDisplay, MockupsPigPreviewDisplay } from './components'

interface MockupsPreviewInterface {
  attributes: DictionaryInterface<string>
  mockupErrorState: { message: string; code: string } | null
  mockupGenerationStatus: StatusType
  orientation: string
  productSku: string
  transformationId: string | null
  viewKey: string | null
}

export default function MockupPreview({
  attributes,
  mockupErrorState,
  mockupGenerationStatus,
  orientation,
  productSku,
  transformationId,
  viewKey
}: MockupsPreviewInterface) {
  const { isLoadingMockups, mockups, mockupsFetchError } = useMockups3D({ attributes, orientation, productSku })

  if (mockupGenerationStatus === 'error') {
    return <MockupError code={mockupErrorState?.code ?? '0'} message={mockupErrorState?.message ?? 'MGSE'} />
  }

  if (mockupsFetchError) {
    return <MockupError code="UMS" message={mockupsFetchError.message} />
  }

  if (mockupGenerationStatus === 'loading' || isLoadingMockups || !mockups || !transformationId || !viewKey) {
    return (
      <div className="relative block w-auto">
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
          <LoadingSpinner className="h-16 w-16" />
        </div>
      </div>
    )
  }

  const views3D: Record<string, MockupViewInterface> = {}
  Object.entries(mockups.views).forEach(([viewKey, viewData]) => {
    if (viewData.renderer === 'PorcusLardum') {
      views3D[viewKey] = viewData
    }
  })

  const view3dData = views3D[viewKey]
  if (view3dData) {
    return (
      <Mockups3DPreviewDisplay
        attributes={attributes}
        key={viewKey}
        orientation={orientation}
        productSku={productSku}
        transformationId={transformationId}
        viewKey={viewKey}
        viewData={view3dData}
      />
    )
  }

  const viewsPig: Record<string, MockupViewInterface> = {}
  Object.entries(mockups.views).forEach(([viewKey, viewData]) => {
    if (viewKey === 'cover' && viewData.renderer === 'PIG') {
      viewsPig[viewKey] = viewData
    }
  })

  const viewPigData = viewsPig[viewKey]
  if (viewPigData) {
    return (
      <MockupsPigPreviewDisplay
        attributes={attributes}
        key={viewKey}
        orientation={orientation}
        productSku={productSku}
        transformationId={transformationId}
        viewKey={viewKey}
        viewData={viewPigData}
      />
    )
  }

  return <div className="relative block w-auto">Mockup not available</div>
}
