import { useEffect, useMemo, useState } from 'react'
import { Bars3Icon } from '@heroicons/react/24/outline'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import {
  DropdownMenu,
  DropdownMenuCheckboxItem,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSelectedCounter,
  DropdownMenuTrigger
} from '../../DropdownMenu'
import { useDebouncedUrlUpdate } from '../../../hooks'
import { QUERY_PARAMS, ROUTE_PATHS } from '../../../constants'

const OPTIONS = ['Fulfilled', 'Ignored']

export function ProductsFilter() {
  const location = useLocation()
  const history = useHistory()
  const { salesChannelId } = useParams<{ salesChannelId: string }>()
  const searchParams = useMemo(() => new URLSearchParams(location.search), [location.search])

  const selectedStatusParam = searchParams.get(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS)
  const selectedStatusDropdownValues = useMemo(
    () => (selectedStatusParam ? selectedStatusParam.split(',') : []),
    [selectedStatusParam]
  )

  const [selectedStatusFilters, setSelectedStatusFilters] = useState(selectedStatusDropdownValues)

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedStatusFilters(selectedStatusDropdownValues)
  }, [selectedStatusDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  function handleUpdateSelectedValues(selectedValues: string[]) {
    setSelectedStatusFilters(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS,
      routePath: ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(salesChannelId),
      selectedValues
    })
  }

  function handleSelection(value: string) {
    let updatedSelectedValues: string[] = []
    if (selectedStatusFilters.includes(value)) {
      updatedSelectedValues = selectedStatusFilters.filter((selectedValue) => selectedValue !== value)
    } else {
      updatedSelectedValues = [...selectedStatusFilters, value]
    }
    handleUpdateSelectedValues(updatedSelectedValues)
  }

  function handleClear() {
    handleUpdateSelectedValues([])
    searchParams.delete(QUERY_PARAMS.SALES_CHANNEL.CONFIGURE.STATUS)
    history.push(`?${searchParams.toString()}`)
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger className="flex">
        <Bars3Icon className="mr-2 h-7 w-7 text-gray-500" aria-hidden="true" />
        <span>Filter by</span>

        <DropdownMenuSelectedCounter selectedCount={selectedStatusFilters.length} />
      </DropdownMenuTrigger>

      <DropdownMenuContent align="end">
        {OPTIONS.map((value) => (
          <DropdownMenuCheckboxItem
            checked={selectedStatusFilters.includes(value)}
            key={value.toString()}
            onSelect={(e) => {
              e.preventDefault()
              handleSelection(value)
            }}
          >
            {value}
          </DropdownMenuCheckboxItem>
        ))}
        <DropdownMenuItem
          className="pl-10 text-purple-600"
          onSelect={() => {
            handleClear()
          }}
        >
          Clear
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}
