// @flow

export const APPAREL = 'Apparel'
export const HOMEWARES = 'Homewares'
export const WALL_ART = 'Wall art'
export const FRAMED = 'Framed'
export const FRAMED_PRINTS = 'Framed Prints'
export const FINE_ART_PRINTS = 'artPrints'
export const PRINTS = 'Prints & posters'
export const ACCESSORIES = 'Accessories'
export const DRINKWARE = 'Drinkware'

export const PRODUCT_CATEGORIES_WITH_BORDERS = [FINE_ART_PRINTS, PRINTS, WALL_ART, FRAMED, FRAMED_PRINTS]

export const ROLLED_CANVAS = 'Rolled Canvas'

export const PRODUCT_TYPES_WITH_BORDERS = [ROLLED_CANVAS]
