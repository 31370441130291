import { useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'
import { PlusCircleIcon, XCircleIcon } from '@heroicons/react/24/outline'

import { QUERY_PARAMS } from '../../../constants'
import { V1DashboardArtworkInterface } from '../../../interfaces'
import { TemplateServiceSuccessResponseInterface } from '../../../hooks'
import { EditorImageLibraryModal } from './EditorImageLibraryModal.component'
import { ArtworkInterface, ArtworkTransformationsInterface } from '../interfaces'
import { ImageLibraryConfigPropInterface } from '../../ImageLibrary/ImageLibrary.component'
import { generateImageLibraryFilterParamsForArtwork } from '../../../helpers/generateImageLibraryFilterParamsForArtwork.helper'

interface PrintAreasPanelPropsInterface {
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  itemCategory: string
  productType: string
  printAreaArtworkEntries: [
    string,
    { artwork?: ArtworkInterface; artworkTransformations?: ArtworkTransformationsInterface }
  ][]
  selectedPrintArea: string
  templates: TemplateServiceSuccessResponseInterface
  onChangePrintArea: (printAreaName: string) => void
  onRemoveArtwork: (printAreaName: string) => void
  onSelectImageLibraryImage: (artworkData: {
    artwork: V1DashboardArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) => void
  onTogglePreviewMode: (isPreviewOnly: boolean) => void
}

export function PrintAreasPanel({
  imageLibraryConfig,
  itemCategory,
  productType,
  printAreaArtworkEntries,
  selectedPrintArea,
  templates,
  onChangePrintArea,
  onRemoveArtwork,
  onSelectImageLibraryImage,
  onTogglePreviewMode
}: PrintAreasPanelPropsInterface) {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const [isImageLibraryModalOpenForPrintArea, setIsImageLibraryModalOpenForPrintArea] = useState<string | null>(null)

  function onSetIsImageLibraryModalOpenForPrintArea(open: boolean, printArea: string | null) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpenForPrintArea(printArea)
  }

  return (
    <div className="mt-3 text-gray-300">
      {printAreaArtworkEntries.map(([printAreaName, { artwork }]) => (
        <div
          className={`pointer flex transition-colors hover:bg-white hover:bg-opacity-20 ${
            printAreaName === selectedPrintArea ? 'bg-white bg-opacity-10' : ''
          }`}
          key={printAreaName}
        >
          <button
            data-test={`image-editor-panel-print-area-${printAreaName}`}
            className="mr-auto flex-1 bg-transparent p-3 text-left capitalize text-white"
            onClick={() => onChangePrintArea(printAreaName)}
          >
            {printAreaName}
          </button>

          {artwork ? (
            <button
              className="flex items-center gap-x-4 bg-transparent p-3 text-red-500"
              onClick={(event) => {
                onRemoveArtwork(printAreaName)
                event.stopPropagation()
              }}
            >
              Remove <XCircleIcon className="h-10 w-10" />
            </button>
          ) : (
            <>
              <button
                className="flex items-center gap-x-4 bg-transparent p-3 text-purple-500"
                onClick={() => {
                  const newSearchParams = generateImageLibraryFilterParamsForArtwork({ searchParams, productType })
                  history.replace({ pathname, search: newSearchParams.toString() })

                  setIsImageLibraryModalOpenForPrintArea(printAreaName)
                  window.analytics.track(`Image library opened: MOF`)
                }}
              >
                <PlusCircleIcon className="h-10 w-10" />
              </button>
              <EditorImageLibraryModal
                open={isImageLibraryModalOpenForPrintArea === printAreaName}
                imageLibraryConfig={imageLibraryConfig}
                itemCategory={itemCategory}
                productType={productType}
                printAreaName={printAreaName}
                templates={templates}
                setOpen={(isOpen) => {
                  if (isOpen) {
                    onSetIsImageLibraryModalOpenForPrintArea(isOpen, printAreaName)
                  } else {
                    onSetIsImageLibraryModalOpenForPrintArea(isOpen, null)
                  }
                }}
                onSelectImageLibraryImage={onSelectImageLibraryImage}
                onTogglePreviewMode={onTogglePreviewMode}
              />
            </>
          )}
        </div>
      ))}
    </div>
  )
}
