import { Link } from 'react-router-dom'

import { useUser } from '../../../hooks'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { calculatePlanSaving, formatBillingCost, getProBillingOptions } from '../../BillingSubscriptionPlans'

export function ProdigiProPanel() {
  const { user } = useUser()

  if (!user || process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return null
  }

  if (user.merchantSubscriptionSettings.subscriptionTier === 'Enterprise') {
    return null
  }

  const proPlanBillingOptions = getProBillingOptions(user.merchantSubscriptionSettings?.subscriptionTierPricing)
  const saving = calculatePlanSaving(proPlanBillingOptions)

  if (user.merchantSubscriptionSettings.subscriptionTier === 'Core') {
    return (
      <div className="container mx-auto">
        <div className="bg-white shadow-md lg:mt-4">
          <div className="flex flex-col justify-between p-12 lg:flex-row-reverse lg:items-center">
            <div className="mb-4 w-24 lg:mb-0 lg:w-72">
              <svg
                viewBox="1 1.6 12 10.8"
                role="img"
                focusable="false"
                aria-hidden="true"
                xmlns="http://www.w3.org/2000/svg"
              >
                <defs>
                  <linearGradient id="tealgrad" x1="0%" y1="75%" x2="100%" y2="0%">
                    <stop offset="0%" stopColor="#e1fafb" />
                    <stop offset="90%" stopColor="#a8eced" />
                  </linearGradient>
                </defs>
                <g>
                  <path
                    fill="#f4f1fd"
                    d="M11.8 5.5H13v6.9h-1.2zM10 7.6h1.2v4.8H10zM8.2 6.1h1.2v6.3H8.2zM6.4 7.9h1.2v4.5H6.4zm-1.8.9h1.2v3.6H4.6zm-1.8-.6H4v4.2H2.8zM1 9.4h1.2v3H1z"
                  />
                  <g fill="#388e3c">
                    <path
                      d="M8.8 4.24l1.2 1.2 2.67-2.67-.84-.84L10 3.76l-1.2-1.2-3 3-1.5-1.5-3.12 3.12.84.84L4.3 5.74l1.5 1.5z"
                      fill="url(#tealgrad)"
                    />
                    <path d="M13 1.6h-2.7L13 4.3z" fill="#a8eced" />
                  </g>
                </g>
              </svg>
            </div>
            <div>
              <span className="mb-4 inline-block text-sm font-bold text-teal-500 ">NEW!</span>
              <h2 className="text-black">Save money and scale faster with Prodigi Pro </h2>
              <p className="mt-4 text-gray-600">
                Get instant access to exclusive benefits including 10% off all products, 75% off branded inserts and
                24/7 customer support.
              </p>
              <p className="mt-4 text-gray-600">
                All for just {formatBillingCost(proPlanBillingOptions.ProYearlyBilling)}/year when you choose annual
                billing (save {saving}/year)
              </p>
              <Link
                className="btn btn-secondary mt-12 w-fit"
                to={ROUTE_PATHS.SETTINGS.SUBSCRIPTIONS}
                onClick={() => window.analytics.track('Clicked - subscribe to Pro')}
              >
                Subscribe to Pro
              </Link>
            </div>
          </div>
        </div>
      </div>
    )
  }

  return (
    <div className="w-full">
      <div className="container mx-auto">
        <div className="bg-white p-12 shadow-md lg:mt-4">
          <div className="mb-4 inline-block text-sm font-bold text-status-orange ">PRO</div>
          <h2 className="text-black">Maximise your Prodigi Pro benefits</h2>
          <p className="mt-4 text-gray-600">
            From dedicated account management for personalised support to early access to new products and features –
            make the most of your Pro subscription.
          </p>
          <Link
            className="btn btn-secondary mt-12 w-fit"
            to={ROUTE_PATHS.SETTINGS.SUBSCRIPTIONS}
            onClick={() => window.analytics.track('Clicked - subscribe to Pro')}
          >
            See what&apos;s possible
          </Link>
        </div>
      </div>
    </div>
  )
}
