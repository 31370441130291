import { useState } from 'react'

import {
  BrandedInsertsOrderModal,
  OrderInsertsContent,
  OrderInsertsDisclaimer,
  OrderInsertsError,
  OrderInsertsLoading,
  OrderInsertsNoContent
} from '../../../v2/components/BrandedInsertsOrderModal'
import Button from '../../../v2/components/Button'
import { InsertDataType } from '../../../v2/interfaces'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { useBrandingDetails, useMerchantBranding, useSplitToggle } from '../../../v2/hooks'

type OrderSummaryBrandedInsertsPropsType = {
  orderBrandedInserts: InsertDataType[]
  onChangeOrderBrandedInserts: (newBrandedInserts: InsertDataType[]) => void
}

export function OrderSummaryBrandedInserts({
  orderBrandedInserts,
  onChangeOrderBrandedInserts
}: OrderSummaryBrandedInsertsPropsType) {
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })
  const { brandingDetails, isLoadingBrandingDetails, brandingDetailsFetchError } = useBrandingDetails()
  const { merchantBrandingResponse, isLoadingMerchantBranding, merchantBrandingFetchError } = useMerchantBranding()

  const [isOrderBrandedInsertsModalOpen, setIsOrderBrandedInsertsModalOpen] = useState(false)

  if (!isBrandingSettingsOn) {
    return null
  }

  function handleRemoveAllInserts() {
    onChangeOrderBrandedInserts([])
  }

  if (brandingDetailsFetchError) {
    return (
      <>
        <OrderInsertsError
          code={`BDFE-${brandingDetailsFetchError.status ?? 0}`}
          orderBrandedInserts={orderBrandedInserts}
          onRemove={handleRemoveAllInserts}
        />
        <OrderInsertsDisclaimer className="mt-2" />
      </>
    )
  }

  if (merchantBrandingFetchError) {
    return (
      <>
        <OrderInsertsError
          code={`MBFE-${merchantBrandingFetchError.status ?? 0}`}
          orderBrandedInserts={orderBrandedInserts}
          onRemove={handleRemoveAllInserts}
        />
        <OrderInsertsDisclaimer className="mt-2" />
      </>
    )
  }

  if (isLoadingBrandingDetails || !brandingDetails || isLoadingMerchantBranding || !merchantBrandingResponse) {
    return <OrderInsertsLoading />
  }

  const hasInserts = orderBrandedInserts.length > 0
  const merchantBranding = merchantBrandingResponse.data

  return (
    <>
      <div className="border bg-white p-6">
        <div className="flex items-center gap-2">
          <h2 className="mt-0 text-lg">Inserts</h2>

          <div className="ml-auto">
            <Button variant="secondary" size="sm" onClick={() => setIsOrderBrandedInsertsModalOpen(true)}>
              {hasInserts ? 'View & edit' : 'Add inserts'}
            </Button>
          </div>
        </div>

        {hasInserts ? (
          <OrderInsertsContent brandingDetails={brandingDetails} orderBrandedInserts={orderBrandedInserts} />
        ) : (
          <OrderInsertsNoContent />
        )}
      </div>

      <OrderInsertsDisclaimer className="mt-2" />

      <BrandedInsertsOrderModal
        brandingDetails={brandingDetails}
        inserts={orderBrandedInserts}
        insertSets={merchantBranding.insertSets}
        open={isOrderBrandedInsertsModalOpen}
        setOpen={setIsOrderBrandedInsertsModalOpen}
        onSave={(newBrandedInserts) => {
          const brandedInsertsWithImageIds = newBrandedInserts.filter((insert) => Boolean(insert.imageLibraryId))
          onChangeOrderBrandedInserts(brandedInsertsWithImageIds)
          setIsOrderBrandedInsertsModalOpen(false)
        }}
        onClose={() => setIsOrderBrandedInsertsModalOpen(false)}
      />
    </>
  )
}
