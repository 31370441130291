import { CheckCircleIcon } from '@heroicons/react/20/solid'

import { cn } from '../../../helpers'
import Skeleton from '../../Skeleton'
import { formatBillingCost } from '../helpers'
import { ProBillingOption } from '../interfaces'
import { SALES_EMAIL } from '../../SalesLink/constants'
import { SubscriptionTierType } from '../../../interfaces'
import ToggleGroup, { ToggleGroupItem } from '../../ToggleGroup'
import { ScheduledUpdateMessage } from './ScheduledUpdateMessage.component'
import { SubscriptionScheduledChangesInterface, useUserSubscription } from '../hooks'

const features = [
  '10% off all product prices',
  '75% discount on all branded inserts',
  '50% savings all sample packs and free first sample order',
  'Unlimited image library storage',
  'Dedicated account manager for personalised support'
]

export function ProSubscriptionPanel({
  currentSubscription,
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  userHasToAddBillingDetails,
  setIsChangeToProModalOpen,
  setIsViewSubscriptionModalOpen,
  setSelectedProSubscriptionTier
}: {
  currentSubscription: SubscriptionTierType
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  userHasToAddBillingDetails: boolean
  setIsChangeToProModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setIsViewSubscriptionModalOpen: React.Dispatch<React.SetStateAction<boolean>>
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const isUsersCurrentSubscription =
    currentSubscription === 'ProMonthlyBilling' || currentSubscription === 'ProYearlyBilling'

  return (
    <div
      className={cn(
        'flex h-full w-full flex-col justify-between gap-y-4 rounded-2xl border-2 border-gray-200 bg-white p-8 transition-colors duration-300',
        isUsersCurrentSubscription && 'border-purple-700 bg-purple-100/75'
      )}
    >
      <div>
        <div className="flex justify-between">
          <h3 className="m-0 text-xl font-medium lg:text-2xl">Pro</h3>
          <span className="rounded-full bg-teal-400 px-4 py-1 text-sm text-white">New!</span>
        </div>

        <p className="mt-1 text-gray-600">For growing businesses looking to scale</p>

        <ul className="my-8 max-w-fit list-inside list-disc">
          {features.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>
      </div>

      <SubscriptionDetails
        proPlanBillingOptions={proPlanBillingOptions}
        selectedProSubscriptionTier={selectedProSubscriptionTier}
        userHasToAddBillingDetails={userHasToAddBillingDetails}
        setIsChangeToProModalOpen={setIsChangeToProModalOpen}
        setIsViewSubscriptionModalOpen={setIsViewSubscriptionModalOpen}
        setSelectedProSubscriptionTier={setSelectedProSubscriptionTier}
      />
    </div>
  )
}

function SubscriptionDetails({
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  userHasToAddBillingDetails,
  setIsChangeToProModalOpen,
  setIsViewSubscriptionModalOpen,
  setSelectedProSubscriptionTier
}: {
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  userHasToAddBillingDetails: boolean
  setIsChangeToProModalOpen: (open: boolean) => void
  setIsViewSubscriptionModalOpen: (open: boolean) => void
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const { subscription, isLoading, error } = useUserSubscription()

  if (error) {
    return null
  }

  if (isLoading || !subscription) {
    return (
      <div>
        <div className="h-[38px]"></div>
        <div className="mt-4">
          <Skeleton className="h-[43px] w-[130px]" />
        </div>
      </div>
    )
  }

  const isUsersCurrentSubscription =
    subscription.subscriptionTier === 'ProMonthlyBilling' || subscription.subscriptionTier === 'ProYearlyBilling'

  return (
    <div>
      <ProBillingPlanToggleGroup
        proPlanBillingOptions={proPlanBillingOptions}
        selectedSubscriptionTier={selectedProSubscriptionTier}
        setSelectedSubscriptionTier={setSelectedProSubscriptionTier}
      />

      <ProPlanButton
        currentSubscription={subscription.subscriptionTier}
        scheduledChanges={subscription.scheduledChanges}
        isUsersCurrentSubscription={isUsersCurrentSubscription}
        userHasToAddBillingDetails={userHasToAddBillingDetails}
        setIsChangeToProModalOpen={setIsChangeToProModalOpen}
        setIsViewSubscriptionModalOpen={setIsViewSubscriptionModalOpen}
      />

      {subscription.scheduledChanges.changeHasBeenScheduled && (
        <ScheduledUpdateMessage
          isUsersCurrentSubscription={isUsersCurrentSubscription}
          scheduledChanges={subscription.scheduledChanges}
        />
      )}

      {subscription.subscriptionTier === 'Enterprise' && !subscription.scheduledChanges.changeHasBeenScheduled && (
        <p className="mt-4 text-gray-600">This will cancel your current plan</p>
      )}
    </div>
  )
}

function ProPlanButton({
  currentSubscription,
  scheduledChanges,
  isUsersCurrentSubscription,
  userHasToAddBillingDetails,
  setIsChangeToProModalOpen,
  setIsViewSubscriptionModalOpen
}: {
  currentSubscription: SubscriptionTierType
  isUsersCurrentSubscription: boolean
  scheduledChanges: SubscriptionScheduledChangesInterface
  userHasToAddBillingDetails: boolean
  setIsChangeToProModalOpen: (open: boolean) => void
  setIsViewSubscriptionModalOpen: (open: boolean) => void
}) {
  function handleButtonClick() {
    if (isUsersCurrentSubscription) {
      setIsViewSubscriptionModalOpen(true)
    } else {
      setIsChangeToProModalOpen(true)
    }
  }

  if (currentSubscription === 'Enterprise') {
    return (
      <a
        className={cn(
          'mt-4 w-fit transition-colors duration-300',
          isUsersCurrentSubscription
            ? 'pointer-events-none relative flex flex-row-reverse items-center gap-2 border-transparent bg-transparent text-purple-600 hover:border-transparent hover:text-purple-600 md:flex-row md:py-[0.9rem]'
            : 'btn btn-secondary'
        )}
        href={`mailto:${SALES_EMAIL}`}
        rel="noreferrer"
        target="_blank"
      >
        Contact sales to switch to Pro
      </a>
    )
  }

  if (!isUsersCurrentSubscription) {
    return (
      <button
        className="btn btn-secondary mt-4 w-fit transition-colors duration-300"
        disabled={userHasToAddBillingDetails || scheduledChanges.changeHasBeenScheduled}
        onClick={handleButtonClick}
      >
        Subscribe to Pro
      </button>
    )
  }

  return (
    <div className="relative mt-4 flex flex-row-reverse items-start gap-2 md:flex-row md:py-[0.9rem]">
      <div className="flex items-center">
        <CheckCircleIcon className="h-10 w-10 text-purple-600" />
      </div>

      <div className="inline">
        You&apos;re currently subscribed to Pro, paying{' '}
        {currentSubscription === 'ProMonthlyBilling' ? 'monthly' : 'annually'}.{' '}
        <button
          className="text-purple-600"
          disabled={userHasToAddBillingDetails || scheduledChanges.changeHasBeenScheduled}
          onClick={handleButtonClick}
        >
          Switch to {currentSubscription === 'ProMonthlyBilling' ? 'annually' : 'monthly'}
        </button>
      </div>
    </div>
  )
}

function ProBillingPlanToggleGroup({
  proPlanBillingOptions,
  selectedSubscriptionTier,
  setSelectedSubscriptionTier
}: {
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedSubscriptionTier: ProBillingOption
  setSelectedSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  return (
    <div className="flex flex-wrap items-center justify-between gap-2">
      <p className="text-4xl font-medium text-purple-600">
        {formatBillingCost(proPlanBillingOptions[selectedSubscriptionTier])}
      </p>

      <ToggleGroup
        value={selectedSubscriptionTier}
        size="sm"
        type="single"
        onValueChange={(selectedValue: string) => {
          const noChange = selectedSubscriptionTier === selectedValue
          if (selectedValue && !noChange) {
            setSelectedSubscriptionTier(selectedValue as ProBillingOption)
          }
        }}
      >
        <ToggleGroupItem className="whitespace-nowrap text-xs" value="ProYearlyBilling" aria-label="ProYearlyBilling">
          Pay annually
        </ToggleGroupItem>
        <ToggleGroupItem className="whitespace-nowrap text-xs" value="ProMonthlyBilling" aria-label="ProMonthlyBilling">
          Pay monthly
        </ToggleGroupItem>
      </ToggleGroup>
    </div>
  )
}
