import toast from 'react-hot-toast'
import { useCallback } from 'react'
import { EmbeddedCheckout, EmbeddedCheckoutProvider } from '@stripe/react-stripe-js'

import Button from '../../../../../Button'
import { fetcher } from '../../../../../../helpers'
import { createErrorToast } from '../../../../../Toast'
import { BillingTerms } from './BillingTerms.component'
import { FetchErrorInterface } from '../../../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID } from '../../../../constants'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../../../../constants'
import { stripePromise } from '../../../../../../helpers/stripePromise.helper'
import { STRIPE_CHECKOUT_SETUP_PARAMS } from './CheckoutSetupStripeRedirectModal.component'
import { STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS } from '../../../../../../constants/stripePaymentMethodConfiuration.const'

export function CheckoutPaymentMethod({ onClose }: { onClose: () => void }) {
  const fetchClientSecret = useCallback(async () => {
    try {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      const configId =
        process.env.REACT_APP_ENV === APP_ENVIRONMENTS.LIVE
          ? STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.WITH_BACS_DIRECT_DEBIT.LIVE
          : STRIPE_PAYMENT_METHOD_CONFIGURATION_IDS.WITH_BACS_DIRECT_DEBIT.TEST

      const response = await fetcher<{ data: { secret: string } }>(
        `${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/createcheckoutsession`,
        {
          method: 'POST',
          body: JSON.stringify({
            PaymentMethodConfigurationId: configId,
            ReturnUrl:
              window.location.origin +
              ROUTE_PATHS.SETTINGS.BILLING +
              // Stripe replaces the placeholder {CHECKOUT_SESSION_ID} with the actual session ID
              `?${STRIPE_CHECKOUT_SETUP_PARAMS.SESSION_ID}={CHECKOUT_SESSION_ID}`
          })
        }
      )
      return response.data.secret
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string; statusTxt?: string }>
      createErrorToast({
        errorCode: `FCS-${errorResponse.status ?? errorResponse.message ?? '0'}`,
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.responseBodyJson?.statusTxt,
        heading: 'Failed to edit billing details',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      return ''
    }
  }, [])

  return (
    <div>
      <EmbeddedCheckoutProvider stripe={stripePromise} options={{ fetchClientSecret }}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>

      <div className="mt-8">
        <Button theme="brand" variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </div>

      <BillingTerms />
    </div>
  )
}
