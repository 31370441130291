import { useSplitTreatment } from '../../../../hooks'
import { SettingsHeader } from '../SettingsHeader.component'
import { SettingsContainer } from '../SettingsContainer.component'
import { FEATURE_NAMES } from '../../../../../split-io/feature-names'
import BillingSubscriptionPlans from '../../../BillingSubscriptionPlans'

export function SettingsSubscriptions() {
  const { isTreatmentOn: isProdigiProOn } = useSplitTreatment(FEATURE_NAMES.DASHBOARD_PRODIGI_PRO)

  return (
    <SettingsContainer>
      <SettingsHeader />

      {isProdigiProOn && <BillingSubscriptionPlans />}
    </SettingsContainer>
  )
}
