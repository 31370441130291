import React, { useEffect } from 'react'
import { useHistory } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import {
  getFormattedCountries,
  selectBasketItems,
  selectDeliveryCountry,
  selectFetchError,
  selectFetching
} from '../../../selectors/manualOrderForm'
import Page from '../../../components/Page'
import { CountryInterface } from './interfaces'
import { NO_SELECTION } from '../../../v2/constants'
import Skeleton from '../../../v2/components/Skeleton'
import { getRsaaStatus } from '../../../selectors/rsaa'
import { resetRsaaStatus } from '../../../actions/rsaa'
import { buildRsaaActionId } from '../../../helpers/rsaa'
import { CREATE_V4_ORDER } from '../../../actions/orders'
import SupportLink from '../../../v2/components/SupportLink'
import { RsaaStatusInterface } from '../../../v2/interfaces'
import { CountrySearch, PopularCountries } from './components'
import { changeDeliveryCountry, clearOrderData, MOF_ORDER_LOGGER_ID } from '../../../actions/manualOrderForm'
import { getItemCategories, resetProductSearchResults, selectItemCategory } from '../../../actions/catalogue'

export default function OrderSelectCountryV2() {
  const dispatch = useDispatch()
  const history = useHistory()

  const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)

  const fetching = useSelector(selectFetching)
  const fetchError = useSelector(selectFetchError)
  const itemsToBeCreated = useSelector(selectBasketItems)
  const selectedDeliveryCountry = useSelector(selectDeliveryCountry)
  const countries: CountryInterface[] = useSelector(getFormattedCountries)
  const orderCreationStatus: RsaaStatusInterface = useSelector((state) => getRsaaStatus(state, createOrderActionId))

  useEffect(() => {
    if (orderCreationStatus.status !== 'IDLE') {
      dispatch(clearOrderData())
      dispatch(resetRsaaStatus(createOrderActionId))
    }

    window.analytics.page('MOF', 'Country')
  }, [createOrderActionId, dispatch, orderCreationStatus.status])

  async function updateDeliveryCountry(countryCode: string) {
    dispatch(clearOrderData())
    dispatch(resetRsaaStatus(createOrderActionId))
    dispatch(resetProductSearchResults())
    dispatch(selectItemCategory(NO_SELECTION))
    dispatch(getItemCategories())
    dispatch(changeDeliveryCountry(countryCode))
  }

  async function selectCountry(country: CountryInterface) {
    if (selectedDeliveryCountry !== country.isoCode) {
      await updateDeliveryCountry(country.isoCode)
      history.push('/orders/create/select-item')
      return
    }

    if (!itemsToBeCreated || itemsToBeCreated.length === 0) {
      history.push('/orders/create/select-item')
    } else {
      history.push('/orders/create/add-image')
    }
  }

  if (fetchError) {
    return (
      <SelectCountryContainer countries={countries} selectedDeliveryCountry={selectedDeliveryCountry}>
        <div className="mx-auto py-6 pl-5 pr-4 text-red-500">
          An error occurred while loading. Please try again later or{' '}
          <SupportLink className="text-red-500 underline">contact support</SupportLink> if the issue persists (Code 0)
        </div>
      </SelectCountryContainer>
    )
  }

  if (fetching) {
    return (
      <SelectCountryContainer countries={countries} selectedDeliveryCountry={selectedDeliveryCountry}>
        <>
          <h2 className="mb-4 text-xl">Popular countries</h2>
          <div className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 overflow-x-auto pt-8">
            {Array.from({ length: 8 }, (_, i) => i).map((value) => (
              <Skeleton key={value} className="h-[65px] w-full bg-purple-200/50" />
            ))}
          </div>

          <h2 className="mb-4 mt-14 text-xl">All countries</h2>
          <Skeleton className="h-[32px] w-full bg-purple-200/50 sm:max-w-[400px]" />
        </>
      </SelectCountryContainer>
    )
  }

  return (
    <SelectCountryContainer countries={countries} selectedDeliveryCountry={selectedDeliveryCountry}>
      <div className="flex flex-col gap-14">
        <PopularCountries selectCountry={selectCountry} />
        <CountrySearch countries={countries} selectCountry={selectCountry} />
      </div>
    </SelectCountryContainer>
  )
}

function SelectCountryContainer({
  children,
  countries,
  selectedDeliveryCountry
}: {
  children: React.ReactNode
  countries: CountryInterface[]
  selectedDeliveryCountry: string
}) {
  const history = useHistory()

  const selectedCountry = countries.find((x) => x.isoCode.toLowerCase() === selectedDeliveryCountry.toLowerCase())

  let primaryAction
  if (selectedCountry) {
    primaryAction = {
      title: `Continue with ${selectedCountry.name}`,
      onClick: () => history.push('/orders/create/select-item')
    }
  }

  return (
    <Page
      title="Delivery country"
      description="Select your destination country so that we can show you products available in that region."
      primaryAction={primaryAction}
      showActionsInFooter={true}
    >
      <div className="tailwind">{children}</div>
    </Page>
  )
}
