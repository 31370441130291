import { useState } from 'react'
import { format } from 'date-fns'

import Button from '../../Button'
import FormItem from '../../FormItem'
import TextField from '../../TextField'
import { useUserV1 } from '../../../hooks'
import { EXPORT_OPTIONS } from '../constants'
import { isEmailValid } from '../../../helpers'
import { APP_ENVIRONMENTS } from '../../../constants'
import { RadioGroup, RadioGroupItem } from '../../RadioGroup'
import { getOrdersExportRequestTimestamps } from '../helpers'
import { ExportOptionsFormDataInterface } from '../interfaces'

interface OrdersExportFormPropsInterface {
  isLoading: boolean
  onCancel: () => void
  onSubmit: (data: ExportOptionsFormDataInterface) => void
}

export function OrdersExportForm({ isLoading, onCancel, onSubmit }: OrdersExportFormPropsInterface) {
  const user = useUserV1()

  const [exportOptionsFormData, setExportOptionsFormData] = useState<ExportOptionsFormDataInterface>({
    createdSinceDateOption: EXPORT_OPTIONS[0],
    email: { value: user?.email ?? '', isValid: Boolean(user?.email) }
  })

  function handleExportFormSubmit(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault()
    onSubmit(exportOptionsFormData)
  }

  return (
    <form onSubmit={handleExportFormSubmit}>
      <RadioGroup
        className="grid gap-4 sm:grid-flow-col sm:grid-rows-3"
        value={exportOptionsFormData.createdSinceDateOption}
        onValueChange={(newValue) => {
          setExportOptionsFormData({
            ...exportOptionsFormData,
            createdSinceDateOption: newValue as (typeof EXPORT_OPTIONS)[number]
          })
        }}
      >
        {EXPORT_OPTIONS.map((exportOption) => (
          <div key={exportOption} className="flex items-start gap-4">
            <RadioGroupItem className="mt-1" id={exportOption} value={exportOption} />
            <div>
              <label htmlFor={exportOption}>{exportOption}</label>
              <div className="text-sm text-gray-600">{generateOptionSubTitle(exportOption)}</div>
            </div>
          </div>
        ))}
      </RadioGroup>

      <FormItem
        errorMessage="Please enter a valid email"
        inputField={
          <TextField
            required
            type="email"
            value={exportOptionsFormData.email.value}
            onChange={(event) =>
              setExportOptionsFormData({
                ...exportOptionsFormData,
                email: {
                  ...exportOptionsFormData.email,
                  isValid: isEmailValid(event.target.value),
                  value: event.target.value
                }
              })
            }
          />
        }
        labelText="Send to"
        className="pt-4 md:pt-8"
        valid={exportOptionsFormData.email.isValid}
        validate={true}
      />

      <div className="mt-6 flex flex-col gap-4 sm:flex-row">
        <Button
          disabled={isLoading || !exportOptionsFormData.email.isValid}
          isLoading={isLoading}
          type="submit"
          variant="primary"
        >
          Export
        </Button>

        <Button type="button" variant="secondary" onClick={onCancel}>
          Cancel
        </Button>
      </div>
    </form>
  )
}

function generateOptionSubTitle(option: (typeof EXPORT_OPTIONS)[number]) {
  const { createdBeforeOrOn, createdSince } = getOrdersExportRequestTimestamps(option)

  switch (option) {
    case 'Previous 7 days':
    case 'Previous 30 days': {
      return format(new Date(createdSince), 'eee do MMM') + ' - today'
    }
    case 'Last month':
      return format(new Date(createdSince), 'MMM yyyy')
    case 'Previous 12 months':
      return format(new Date(createdSince), 'MMM yyyy') + ' - today'
    case 'Previous 24 months':
      return format(new Date(createdSince), 'MMM yyyy') + ' - today'
    case 'Last year':
      return (
        format(new Date(createdSince), 'MMM') + ' - ' + format(new Date(createdBeforeOrOn).setMonth(-1), 'MMM yyyy')
      )
    default:
      const unhandledCase: never = option
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(`Unable to subtitle for ${unhandledCase}. Add a new case to fix this.`)
      }
      return ''
  }
}
