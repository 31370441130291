import { useEffect, useState } from 'react'
import toast from 'react-hot-toast'

import Button from '../../Button'
import { createToast } from '../../Toast'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'
import OverlayPortal from '../../OverlayPortal'
import { IMAGE_LIBRARY_TOASTS } from '../constants'
import { DropboxLogo } from '../../svg/DropboxLogo.icon'
import { IconCheckMark } from '../../InlineMenu/components'
import { ThumbnailPolling } from './ThumbnailPolling.component'
import { StatusType, FetchErrorInterface } from '../../../interfaces'
import { uploadURLToImageLibrary, getDefaultImageLibraryErrorMessage } from '../../../helpers'
import { ImageLibraryImageInterface, ImageLibraryImageType, useImageLibraryImages } from '../../../hooks'

interface UploadFromDropboxPropsInterface {
  isSaveToLibraryChecked: boolean
  type: ImageLibraryImageType
  onUploadStart: () => void
  onUploadComplete: () => void
  onSelectImage?: (imageLibraryImage: ImageLibraryImageInterface) => void
}

export function UploadFromDropbox({
  isSaveToLibraryChecked,
  type,
  onUploadStart,
  onUploadComplete,
  onSelectImage
}: UploadFromDropboxPropsInterface) {
  const { mutateImageLibraryImages } = useImageLibraryImages()

  const [dropboxUploadStatus, setDropboxUploadStatus] = useState<StatusType>('idle')
  const [pollForThumbnailImageId, setPollForThumbnailImageId] = useState<string | null>(null)

  useEffect(() => {
    return () => {
      toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    }
  }, [])

  useEffect(() => {
    let timeoutId: NodeJS.Timeout
    if (dropboxUploadStatus === 'success') {
      timeoutId = setTimeout(() => {
        setDropboxUploadStatus('idle')
      }, 1000)
    }

    return () => {
      clearTimeout(timeoutId)
    }
  }, [dropboxUploadStatus])

  async function handleUploadSuccess(imageData: ImageLibraryImageInterface) {
    if (isSaveToLibraryChecked) {
      await mutateImageLibraryImages()
    }
    setDropboxUploadStatus('success')
    onUploadComplete()

    if (onSelectImage) {
      onSelectImage(imageData)
    }
  }

  function handleUploadError({
    errorCode,
    errorMessage = null
  }: {
    errorCode: string | number
    errorMessage?: string | null
  }) {
    window.analytics.track('Image library - image upload failed', { type: 'dropbox' })
    createToast({
      content: (
        <>
          {errorMessage && <p className="mb-2">{errorMessage}</p>}
          <p>
            Please try again later or <SupportLink>contact support</SupportLink> if the issue persists
          </p>
        </>
      ),
      duration: Infinity,
      footer: (
        <span className="text-sm text-gray-600">
          Code: {errorCode} <ClipboardCopy showText={false} text={errorCode.toString()} />
        </span>
      ),
      heading: 'Image upload failed',
      id: IMAGE_LIBRARY_TOASTS.ERROR,
      type: 'error-with-close'
    })
    setDropboxUploadStatus('error')
    onUploadComplete()
  }

  const fileTypes = ['.jpg', '.jpeg', '.png', '.pdf']

  function handleDropboxUpload() {
    toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
    window.analytics.track('Image library - clicked: Upload an image', { type: 'dropbox' })
    window.Dropbox?.choose({
      linkType: 'direct',
      multiselect: false,
      extensions: fileTypes,
      folderselect: false,
      cancel: () => {
        setDropboxUploadStatus('idle')
      },
      success: async (files) => {
        toast.dismiss(IMAGE_LIBRARY_TOASTS.ERROR)
        setDropboxUploadStatus('loading')
        onUploadStart()
        try {
          const url = files[0].link
          const imageData = await uploadURLToImageLibrary({ url, shouldSaveToLibrary: isSaveToLibraryChecked, type })

          if (imageData.upload_status !== 'success') {
            setPollForThumbnailImageId(imageData.public_id)
            return
          }

          await handleUploadSuccess(imageData)
        } catch (error) {
          const errorResponse = error as FetchErrorInterface<{ detail?: string }>
          const errorMessage = getDefaultImageLibraryErrorMessage(errorResponse)
          const errorCode = errorResponse.status ?? '0'
          handleUploadError({ errorCode, errorMessage })
        }
      }
    })
  }

  return (
    <>
      <Button
        disabled={dropboxUploadStatus === 'success'}
        isLoading={dropboxUploadStatus === 'loading'}
        startIcon={dropboxUploadStatus === 'success' ? undefined : <DropboxLogo className="h-7 w-7 text-white" />}
        variant="primary"
        theme="brand"
        onClick={handleDropboxUpload}
      >
        <UploadButtonContent uploadStatus={dropboxUploadStatus} />
      </Button>

      {pollForThumbnailImageId && (
        <ThumbnailPolling
          imageLibraryId={pollForThumbnailImageId}
          onComplete={async ({ imageLibraryImage, imageLibraryImageFetchError }) => {
            setPollForThumbnailImageId(null)

            if (imageLibraryImageFetchError) {
              const errorMessage = getDefaultImageLibraryErrorMessage(imageLibraryImageFetchError)
              const errorCode = `P-${imageLibraryImageFetchError?.status ?? '0'}-${pollForThumbnailImageId}`
              handleUploadError({ errorCode, errorMessage })
              return
            }

            if (!imageLibraryImage || imageLibraryImage.upload_status !== 'success') {
              const errorCode = `P-${imageLibraryImage?.upload_status ?? 'P'}-${pollForThumbnailImageId}`
              handleUploadError({ errorCode })
              return
            }

            handleUploadSuccess(imageLibraryImage)
          }}
        />
      )}

      {dropboxUploadStatus === 'loading' && <OverlayPortal />}
    </>
  )
}

function UploadButtonContent({ uploadStatus }: { uploadStatus: StatusType }) {
  switch (uploadStatus) {
    case 'loading':
      return <>Uploading...</>
    case 'success':
      return (
        <span className="flex items-center gap-2">
          <IconCheckMark isVisible={true} initial={true} transition={{ duration: 0.5 }} />
          Uploaded
        </span>
      )
    default:
      return (
        <>
          <span className="md:hidden">Upload</span>
          <span className="hidden md:inline">Add from Dropbox</span>
        </>
      )
  }
}
