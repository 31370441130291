import { TrashIcon } from '@heroicons/react/24/outline'
import { useImageLibraryImage, useMerchantService } from '../../../hooks'
import Button from '../../Button'
import Skeleton from '../../Skeleton'
import { InsertsEditButton } from './SalesChannelInsertsSettings.component'
import toast from 'react-hot-toast'
import { useState } from 'react'
import { FetchErrorInterface, SalesChannelInterface, StatusType } from '../../../interfaces'
import { CHANNEL_CONFIGURE_TOAST_IDS } from '../../SalesChannelConfigure/constants'
import { updateSalesChannelDetails } from '../../../helpers'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'

export function SalesChannelPackingSlip({ salesChannel }: { salesChannel: SalesChannelInterface }) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()
  const { image, isLoading: isLoadingImage, error } = useImageLibraryImage({ imageId: salesChannel.packingSlipImageId })

  const [packingSlipUpdateStatus, setPackingSlipUpdateStatus] = useState<StatusType>('idle')

  async function handleRemovePackingSlip() {
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.ERROR)
    toast.dismiss(CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS)
    setPackingSlipUpdateStatus('loading')

    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: null,
        salesChannelId: salesChannel.id,
        salesChannelName: salesChannel.description,
        defaultFulfillment: salesChannel.defaultFulfillment
      })
      await mutateMerchantDetails()
      createToast({
        heading: 'Updated successfully',
        id: CHANNEL_CONFIGURE_TOAST_IDS.SUCCESS,
        type: 'success'
      })
      setPackingSlipUpdateStatus('success')
      window.analytics.track('Removed default packing slip: config')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to remove packing slip',
        id: CHANNEL_CONFIGURE_TOAST_IDS.ERROR,
        type: 'error-with-close'
      })
      setPackingSlipUpdateStatus('error')
    }
  }

  if (error) {
    return (
      <div className="flex flex-col">
        <div className="text-red-500">Failed to load packing slip ({error?.status ?? '0'})</div>
        <div className="mt-4 flex items-center gap-2">
          <InsertsEditButton />
          <Button
            isLoading={packingSlipUpdateStatus === 'loading'}
            variant="tertiary"
            size="sm"
            theme="danger"
            title="Remove packing slip"
            onClick={handleRemovePackingSlip}
          >
            <TrashIcon className="h-7 w-7" />
          </Button>
        </div>
      </div>
    )
  }

  if (isLoadingImage || !image) {
    return (
      <div className="flex flex-col gap-2">
        <Skeleton className="h-[30px] w-[100px]" />
        <Skeleton className="h-[30px] w-[90px]" />
      </div>
    )
  }

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-2">
        <div>
          <a
            className="btn btn-primary btn-sm btn-text w-fit"
            href={image.url}
            rel="noopener noreferrer"
            target="_blank"
            title="View packing slip"
          >
            Packing slip (b&amp;w)
          </a>
        </div>

        <div>
          <Button
            isLoading={packingSlipUpdateStatus === 'loading'}
            variant="tertiary"
            size="sm"
            theme="danger"
            title="Remove packing slip"
            onClick={handleRemovePackingSlip}
          >
            <TrashIcon className="h-6 w-6" />
          </Button>
        </div>
      </div>

      <div className="mt-4">
        <InsertsEditButton />
      </div>

      {packingSlipUpdateStatus === 'loading' && <OverlayPortal />}
    </div>
  )
}
