import toast from 'react-hot-toast'
import { useEffect, useRef, useState } from 'react'
import { CheckIcon, PencilSquareIcon } from '@heroicons/react/20/solid'

import {
  DEFAULT_PHOTOBOOK_META_DATA,
  PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS,
  PHOTOBOOK_SPINE_TEXT_MAX_LENGTH,
  PHOTOBOOK_SPINE_TEXT_REGEX,
  PHOTOBOOK_SPINE_TEXT_SIZE_OPTIONS
} from '../../../constants'
import Button from '../../Button'
import FormItem from '../../FormItem'
import TextField from '../../TextField'
import SelectField from '../../SelectField'
import { getProductFileCompatibilityData } from '../../../helpers'
import { ItemMetaDataInterface, ProductInterface } from '../../../interfaces'

export function SearchResultMetaData({
  searchResult,
  selectedMetaData,
  onChangeMetaData
}: {
  searchResult: ProductInterface
  selectedMetaData?: ItemMetaDataInterface
  onChangeMetaData: (newMetaData: ItemMetaDataInterface) => void
}) {
  const isPhotobookMetaDataRequired =
    getProductFileCompatibilityData({ productType: searchResult.productType })?.metaDataType === 'photobook'

  if (isPhotobookMetaDataRequired) {
    return <PhotobookMetaData selectedMetaData={selectedMetaData} onChangeMetaData={onChangeMetaData} />
  }

  return null
}

function PhotobookMetaData({
  selectedMetaData,
  onChangeMetaData
}: {
  selectedMetaData?: ItemMetaDataInterface
  onChangeMetaData: (newMetaData: ItemMetaDataInterface) => void
}) {
  useEffect(() => {
    if (selectedMetaData?.spineText === undefined || selectedMetaData?.spineText === null) {
      onChangeMetaData({
        ...selectedMetaData,
        spineText: DEFAULT_PHOTOBOOK_META_DATA.spineText
      })
    }

    if (!selectedMetaData?.spineTextSizePx) {
      onChangeMetaData({
        ...selectedMetaData,
        spineTextSizePx: DEFAULT_PHOTOBOOK_META_DATA.spineTextSizePx
      })
    }

    if (!selectedMetaData?.spineTextFontFamily) {
      onChangeMetaData({
        ...selectedMetaData,
        spineTextFontFamily: DEFAULT_PHOTOBOOK_META_DATA.spineTextFontFamily
      })
    }

    if (!selectedMetaData?.spineBackgroundColourHexCode) {
      onChangeMetaData({
        ...selectedMetaData,
        spineBackgroundColourHexCode: DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
      })
    }

    if (!selectedMetaData?.spineTextColourHexCode) {
      onChangeMetaData({
        ...selectedMetaData,
        spineTextColourHexCode: DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
      })
    }
  }, [onChangeMetaData, selectedMetaData])

  return (
    <>
      <div className="w-full">
        <PhotobookSpineText selectedMetaData={selectedMetaData} onChangeMetaData={onChangeMetaData} />
      </div>

      <FormItem
        className="w-fit"
        labelText="Spine text font"
        labelClassName="mt-0 cursor-pointer"
        inputField={
          <SelectField
            className="w-fit"
            value={selectedMetaData?.spineTextFontFamily ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextFontFamily}
            onChange={(event) => {
              const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextFontFamily
              onChangeMetaData({
                ...selectedMetaData,
                spineTextFontFamily: value
              })
            }}
          >
            {PHOTOBOOK_SPINE_FONT_FAMILY_OPTIONS.map((fontFamilyOption) => (
              <option key={fontFamilyOption.value} value={fontFamilyOption.value}>
                {fontFamilyOption.name}
              </option>
            ))}
          </SelectField>
        }
      />

      <FormItem
        className="w-fit"
        labelText="Spine text size"
        labelClassName="mt-0 cursor-pointer"
        inputField={
          <SelectField
            className="w-fit"
            value={selectedMetaData?.spineTextSizePx ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextSizePx}
            onChange={(event) => {
              const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextSizePx
              onChangeMetaData({
                ...selectedMetaData,
                spineTextSizePx: Number(value)
              })
            }}
          >
            {PHOTOBOOK_SPINE_TEXT_SIZE_OPTIONS.map((textSizeOption) => (
              <option key={textSizeOption.value} value={textSizeOption.value}>
                {textSizeOption.name}
              </option>
            ))}
          </SelectField>
        }
      />

      <FormItem
        className="w-fit"
        labelText="Spine background colour"
        labelClassName="mt-0 cursor-pointer"
        inputField={
          <TextField
            className="w-24"
            type="color"
            value={
              selectedMetaData?.spineBackgroundColourHexCode ?? DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
            }
            onChange={(event) => {
              const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineBackgroundColourHexCode
              onChangeMetaData({
                ...selectedMetaData,
                spineBackgroundColourHexCode: value
              })
            }}
          />
        }
      />

      <FormItem
        className="w-fit"
        labelText="Spine text colour"
        labelClassName="mt-0 cursor-pointer"
        inputField={
          <TextField
            className="w-24"
            type="color"
            value={selectedMetaData?.spineTextColourHexCode ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode}
            onChange={(event) => {
              const value = event?.target?.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineTextColourHexCode
              onChangeMetaData({
                ...selectedMetaData,
                spineTextColourHexCode: value
              })
            }}
          />
        }
      />
    </>
  )
}

function PhotobookSpineText({
  selectedMetaData,
  onChangeMetaData
}: {
  selectedMetaData?: ItemMetaDataInterface
  onChangeMetaData: (newMetaData: ItemMetaDataInterface) => void
}) {
  const spineTextInputRef = useRef<HTMLInputElement>(null)
  const editButtonRef = useRef<HTMLButtonElement>(null)

  const [isEditing, setIsEditing] = useState(false)
  const [value, setValue] = useState(selectedMetaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText)

  if (!isEditing) {
    return (
      <FormItem
        className="w-fit"
        labelText="Spine text"
        labelClassName="mt-0 cursor-pointer"
        inputField={
          <div className="flex items-center gap-2 pt-2">
            {(selectedMetaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText) && (
              <div className="text-black" style={{ wordBreak: 'break-word' }}>
                {selectedMetaData?.spineText ?? DEFAULT_PHOTOBOOK_META_DATA.spineText}
              </div>
            )}
            <Button
              ref={editButtonRef}
              variant="tertiary"
              size="sm"
              startIcon={<PencilSquareIcon className="h-6 w-6" />}
              onClick={() => setIsEditing(true)}
            >
              Edit spine text
            </Button>
          </div>
        }
      />
    )
  }

  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        const isValueInvalid = value !== '' && !PHOTOBOOK_SPINE_TEXT_REGEX.test(value)

        if (isValueInvalid) {
          toast.error('Your text contains an unsupported character.')
          return
        }

        onChangeMetaData({ ...selectedMetaData, spineText: value })
        setIsEditing(false)
        setTimeout(() => {
          editButtonRef?.current?.focus?.()
        }, 0)
      }}
    >
      <FormItem
        className="w-fit"
        labelText="Spine text"
        labelClassName="mt-0"
        inputField={
          <div className="flex flex-col gap-2 pt-2">
            <TextField
              autoFocus
              ref={spineTextInputRef}
              className="mt-0 h-auto w-[150px] px-4 py-2 md:w-[250px]"
              type="text"
              maxLength={PHOTOBOOK_SPINE_TEXT_MAX_LENGTH}
              value={value}
              onChange={(event) => {
                setValue(event.target.value ?? DEFAULT_PHOTOBOOK_META_DATA.spineText)
              }}
            />
            <div>
              <Button
                className="h-full"
                variant="primary"
                size="sm"
                startIcon={<CheckIcon className="h-6 w-6" />}
                type="submit"
              >
                Save
              </Button>
            </div>
          </div>
        }
      />
    </form>
  )
}
