import { motion } from 'motion/react'
import { Dispatch, MouseEvent, ReactNode } from 'react'

import Button from '../../Button'
import TextField from '../../TextField'
import { RegisterReducerActionType } from '../types'
import { RegisterReducerActionTypeEnum } from '../enums'
import { RadioGroup, RadioGroupItem } from '../../RadioGroup'
import { RegisterFormStringFieldInterface } from '../interfaces'

interface PropTypesInterface {
  children?: ReactNode
  marketingPriorityToday: RegisterFormStringFieldInterface
  onStepChange: (event: MouseEvent<HTMLAnchorElement | HTMLButtonElement>, step: number) => void
  registerFormDispatch: Dispatch<RegisterReducerActionType>
}

const PRIORITY_ANSWERS = [
  { value: 'Place an order', label: 'Place an order' },
  {
    value: 'Find pricing and shipping info',
    label: 'Find pricing and shipping info'
  },
  {
    value: 'Just explore',
    label: 'Just explore'
  }
]

const OTHER_OPTION_VALUE = 'Other'

export default function Step3({
  children,
  marketingPriorityToday,
  onStepChange,
  registerFormDispatch
}: PropTypesInterface) {
  const hasMarketingOptionSelected = PRIORITY_ANSWERS.some((answer) => answer.value === marketingPriorityToday?.value)
  const hasMarketingOtherOptionSelected = marketingPriorityToday?.value === OTHER_OPTION_VALUE

  return (
    <motion.form
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      className="flex min-h-full w-full flex-col"
    >
      <div className="flex flex-col">
        <span className="flex items-center justify-between">
          <span className="text-sm uppercase text-gray-600">I would like to</span>
        </span>

        <RadioGroup
          className="mt-4 gap-4"
          value={
            marketingPriorityToday.dirty
              ? hasMarketingOptionSelected
                ? marketingPriorityToday.value
                : OTHER_OPTION_VALUE
              : undefined
          }
          onValueChange={(newValue) => {
            registerFormDispatch({
              type: RegisterReducerActionTypeEnum.SetMarketingPriorityToday,
              payload: {
                marketingPriorityToday: newValue,
                error: null
              }
            })
          }}
        >
          {PRIORITY_ANSWERS.map((answer) => (
            <div key={answer.value} className="flex items-center gap-4">
              <RadioGroupItem id={answer.value} value={answer.value} />
              <label className="text-base" htmlFor={answer.value}>
                {answer.label}
              </label>
            </div>
          ))}
          <div className="flex items-center gap-4">
            <RadioGroupItem id={OTHER_OPTION_VALUE} value={OTHER_OPTION_VALUE} />
            <label className="text-base" htmlFor={OTHER_OPTION_VALUE}>
              {OTHER_OPTION_VALUE}
            </label>
          </div>
          {marketingPriorityToday.dirty && (hasMarketingOtherOptionSelected || !hasMarketingOptionSelected) && (
            <div>
              <TextField
                autoFocus
                dataTest="radioGroupOther"
                placeholder="Tell us more..."
                maxLength={100}
                value={
                  hasMarketingOptionSelected
                    ? ''
                    : marketingPriorityToday.value === OTHER_OPTION_VALUE
                    ? ''
                    : marketingPriorityToday.value
                }
                onChange={(event) => {
                  registerFormDispatch({
                    type: RegisterReducerActionTypeEnum.SetMarketingPriorityToday,
                    payload: {
                      marketingPriorityToday: event.target.value || OTHER_OPTION_VALUE,
                      error: null
                    }
                  })
                }}
              />
            </div>
          )}
        </RadioGroup>

        <span className="mt-1 text-right text-sm text-red-500">
          <motion.span
            animate={marketingPriorityToday.error ? { opacity: 1 } : { opacity: 0 }}
            initial={{ opacity: 0 }}
          >
            {marketingPriorityToday.error}&nbsp;
          </motion.span>
        </span>

        <Button
          type="submit"
          className="mt-12"
          disabled={Boolean(marketingPriorityToday.error)}
          onClick={(event) => {
            onStepChange(event, 4)
          }}
        >
          Continue
        </Button>

        {children}
      </div>
    </motion.form>
  )
}
