import toast from 'react-hot-toast'
import { useOs } from '@mantine/hooks'
import useSWR, { SWRConfiguration } from 'swr'
import { ArrowDownOnSquareIcon } from '@heroicons/react/24/outline'
import { useCallback, useEffect, useState } from 'react'

import Button from '../../Button'
import { createErrorToast } from '../../Toast'
import { MOCKUP_TOASTS, MockupError } from '.'
import LoadingSpinner from '../../LoadingSpinner'
import { mockupPIGImageFetcher } from '../helpers'
import { FetchErrorInterface, StatusType } from '../../../interfaces'

export function MockupPigPreviewImage({ productSku, url }: { productSku: string; url: string }) {
  const [status, setStatus] = useState<StatusType>('loading')
  const [errorCode, setErrorCode] = useState<number>()

  const handleSuccess = useCallback(() => {
    setStatus('success')
    setErrorCode(undefined)
  }, [])

  const handleError = useCallback((status: number | undefined) => {
    setErrorCode(status ?? 0)
    setStatus('error')
  }, [])

  if (status === 'loading') {
    return (
      <div className="relative block w-auto">
        <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%,-50%)' }}>
          <LoadingSpinner className="h-16 w-16" />
        </div>
        <MockupImagePoll url={url} onSuccess={handleSuccess} onError={handleError} />
      </div>
    )
  }

  if (status === 'success') {
    return (
      <div className="flex h-full flex-col">
        <div className="h-80vh flex-1">
          <img className="relative h-full w-full max-w-none object-contain" src={url} />
        </div>
        <div className="pt-6">
          <ImageEditorDownloadMockupButton mockupUrl={url} productSku={productSku} />
        </div>
      </div>
    )
  }

  return <MockupError code={`MPLE-${productSku}-${errorCode}`} message="Failed to load mockup" />
}

function MockupImagePoll({
  url,
  onSuccess,
  onError
}: {
  url: string
  onSuccess: () => void
  onError: (status: number | undefined) => void
}) {
  const { data, error } = useMockupImage(url, {
    config: { refreshInterval: 2000, revalidateOnMount: false, revalidateOnFocus: false }
  })

  useEffect(() => {
    if (error) {
      onError(error.status)
    }
    if (data === 200 || data === 302) {
      onSuccess()
    }
  }, [data, error, onError, onSuccess])

  return null
}

function useMockupImage(url: string, { config = {} }: { config?: SWRConfiguration } = {}) {
  const { data, error } = useSWR<number, FetchErrorInterface<unknown>>(url, mockupPIGImageFetcher, config)

  return { data, error }
}

function ImageEditorDownloadMockupButton({ mockupUrl, productSku }: { mockupUrl: string; productSku: string }) {
  const [downloadStatus, setDownloadStatus] = useState<StatusType>('idle')
  const os = useOs()

  async function downloadMockup() {
    window.analytics.track('Download mockup', productSku)
    toast.dismiss(MOCKUP_TOASTS.ERROR)
    setDownloadStatus('loading')

    try {
      if (!mockupUrl) {
        return
      }

      await fetch(mockupUrl).then(async (response) => {
        if (!response.ok) {
          createErrorToast({
            errorCode: response.status ?? '0',
            heading: 'Failed to download mockup',
            id: MOCKUP_TOASTS.ERROR
          })
          return
        }

        const blob = await response.blob()
        const linkElement = document.createElement('a')
        const blobUrl = URL.createObjectURL(blob)

        linkElement.style.display = 'none'
        linkElement.setAttribute('href', blobUrl)
        const fileName = 'prodigi-' + productSku + '.png'
        linkElement.setAttribute('download', fileName)
        document.body.appendChild(linkElement)

        linkElement.click()
        URL.revokeObjectURL(blobUrl)
        document.body.removeChild(linkElement)
        setDownloadStatus('success')
      })
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ detail?: string }>

      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.message,
        heading: 'Failed to download mockup',
        id: MOCKUP_TOASTS.ERROR
      })
      setDownloadStatus('error')
    }
  }

  // On iOS downloading via JS causes the user session to fail when tested on iOS 18
  // So we provide a direct link to the image in a new tab instead
  if (os === 'ios') {
    return (
      <div>
        <a className="btn btn-primary mx-auto w-fit" href={mockupUrl} target="_blank" rel="noreferrer noopener">
          <ArrowDownOnSquareIcon className="mr-2 h-8 w-8" />
          Download full-size mockup
        </a>
      </div>
    )
  }

  return (
    <Button
      className="btn btn-primary mx-auto cursor-pointer transition-opacity duration-100"
      startIcon={<ArrowDownOnSquareIcon className="h-8 w-8" />}
      isLoading={downloadStatus === 'loading'}
      onClick={downloadMockup}
    >
      Download full-size mockup
    </Button>
  )
}
