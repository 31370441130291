import { useState } from 'react'
import { groupBy } from 'lodash'

import { CountryCard } from '.'
import { CountryInterface } from '../interfaces'
import SearchField from '../../../../v2/components/SearchField'

export function CountrySearch({
  countries,
  selectCountry
}: {
  countries: CountryInterface[]
  selectCountry: (country: CountryInterface) => void
}) {
  const [search, setSearch] = useState<string>('')
  const [filteredCountries, setFilteredCountries] = useState<CountryInterface[]>([])

  return (
    <div>
      <h2 className="mb-4 text-xl">All countries</h2>
      <div className="flex flex-col gap-4 pt-4 sm:flex-row">
        <div className="w-full sm:max-w-[400px]">
          <SearchField
            dataTest="country-search"
            placeholder="Search all countries..."
            value={search}
            onChange={(event) => {
              let filteredCountries: CountryInterface[] = []
              const query = event.target.value.toLowerCase()

              if (query !== '') {
                filteredCountries = countries.filter(
                  (country) => country.name.toLowerCase().includes(query) || country.isoCode.toLowerCase() === query
                )
              }

              setSearch(query)
              setFilteredCountries(filteredCountries)
            }}
            onClearSearch={() => {
              setSearch('')
              setFilteredCountries([])
            }}
          />
        </div>

        {filteredCountries.length === 0 && (
          <button
            data-test="view-all-countries-button"
            onClick={() => {
              window.analytics.track('MOF: view all available countries clicked')
              setSearch('')
              setFilteredCountries(countries)
            }}
          >
            or <span className="text-purple-600">show all</span>
          </button>
        )}
      </div>

      {search !== '' ? (
        <SearchResults filteredCountries={filteredCountries} search={search} selectCountry={selectCountry} />
      ) : (
        <GroupedSearchResults filteredCountries={filteredCountries} selectCountry={selectCountry} />
      )}
    </div>
  )
}

function SearchResults({
  filteredCountries,
  search,
  selectCountry
}: {
  filteredCountries: CountryInterface[]
  search: string
  selectCountry: (country: CountryInterface) => void
}) {
  return (
    <div className="mb-4 mt-6">
      <p className="invisible line-clamp-1 text-lg font-bold" aria-hidden>
        All countries
      </p>
      <div
        data-test="country-list"
        className="mt-2 grid max-w-full grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 overflow-x-auto"
      >
        {filteredCountries.map((country) => (
          <CountryCard
            country={country}
            dataTest={`country-list-item-${country.isoCode.toLowerCase()}`}
            key={country.isoCode}
            onClick={() => {
              window.analytics.track('Country Selected', {
                ...country,
                searchQuery: search
              })
              selectCountry(country)
            }}
          />
        ))}
      </div>
    </div>
  )
}

function GroupedSearchResults({
  filteredCountries,
  selectCountry
}: {
  filteredCountries: CountryInterface[]
  selectCountry: (country: CountryInterface) => void
}) {
  const countriesGroupByFirstLetter = groupBy(filteredCountries, ({ name }) => name.charAt(0)) ?? []

  return (
    <>
      {Object.entries(countriesGroupByFirstLetter).map(([key, countriesInGroup]) => {
        if (countriesInGroup?.length === 0) {
          return null
        }

        return (
          <div key={key} className="my-16">
            <p className="text-lg font-bold text-gray-500">{key}</p>
            <div
              data-test="country-list"
              className="mt-4 grid max-w-full grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 overflow-x-auto"
            >
              {countriesInGroup?.map((country) => (
                <CountryCard
                  country={country}
                  dataTest={`country-list-item-${country.isoCode.toLowerCase()}`}
                  key={country.isoCode}
                  onClick={() => {
                    window.analytics.track('Country Selected', {
                      ...country,
                      searchQuery: ''
                    })
                    selectCountry(country)
                  }}
                />
              ))}
            </div>
          </div>
        )
      })}
    </>
  )
}
