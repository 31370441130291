import NotFound from '../../NotFound'
import SupportLink from '../../SupportLink'
import ClipboardCopy from '../../ClipboardCopy'

export const MOCKUP_TOASTS = {
  ERROR: 'mockupErrorToast'
}

export function MockupError({ code, message }: { code: string | number; message: string }) {
  return (
    <div className="mt-12 flex w-full justify-center">
      <NotFound
        heading="Failed to load mockup"
        body={
          <p className="break-words text-center">
            An error occurred while loading the mockup. Please refresh the page to try again and{' '}
            <SupportLink>contact us</SupportLink> if this issue persists ({message} - code {code}{' '}
            <ClipboardCopy showText={false} text={code.toString()} />)
          </p>
        }
      >
        <></>
      </NotFound>
    </div>
  )
}
