import { useEffect } from 'react'

import ProductLibrary from '../../components/ProductLibrary'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from '../../constants'

export default function ProductLibraryPage() {
  useEffect(() => {
    document.title = 'Your Products | Prodigi'
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.PRODUCT_LIBRARY)
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  return (
    <main className="tailwind">
      <ProductLibrary />
    </main>
  )
}
