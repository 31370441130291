import { motion } from 'motion/react'
import { Link } from 'react-router-dom'

import LogoProdigi from '../../LogoProdigi'
import { SalesChannelPlatformEnum } from '../../../enums'
import { useSalesChannelProductsOverview } from '../hooks'
import { SalesChannelInterface } from '../../../interfaces'
import { ROUTE_PATHS, APP_ENVIRONMENTS } from '../../../constants'

export function ActiveSalesChannelListRow({ channel }: { channel: SalesChannelInterface }) {
  const { productsOverview } = useSalesChannelProductsOverview({ salesChannelId: channel.id })

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="grid gap-4 border-t bg-white px-8 py-6 transition-colors duration-200 ease-in first:border-none sm:grid-cols-4"
    >
      <div className="block px-2 pb-2 align-top sm:my-auto sm:px-0">
        <ActiveSalesChannelListRowLogoFactory platform={channel.platform} />
      </div>
      <div className="my-auto mt-2 max-w-xl px-2 align-middle sm:mt-auto sm:px-0">
        <span className="font-medium">{channel.description}</span>
      </div>
      <div className="my-auto mt-2 px-2 align-middle sm:mt-auto sm:px-0">
        {!channel.initialProductsDownloaded && <span>Retrieving products</span>}

        {channel.initialProductsDownloaded && (
          <span>
            {productsOverview ? `Fulfilling ${productsOverview.fulfilledProducts} products` : 'Retrieved products'}
          </span>
        )}
      </div>
      <div className="mt-4 px-2 align-middle sm:mt-0 sm:px-0">
        <Link className="btn btn-secondary w-fit sm:ml-auto" to={`${ROUTE_PATHS.SALES_CHANNELS.CONFIGURE(channel.id)}`}>
          Configure
        </Link>
      </div>
      {channel.reauthoriseLink && (
        <div className="mt-4 text-sm text-red-600 sm:col-span-full sm:mt-0">
          Your connection requires an update. Please click on the following link to{' '}
          <a
            className="cursor-pointer underline"
            rel="noreferrer noopener"
            target="_blank"
            href={channel.reauthoriseLink}
          >
            update your connection.
          </a>
        </div>
      )}
    </motion.div>
  )
}

function ActiveSalesChannelListRowLogoFactory({ platform }: { platform: SalesChannelPlatformEnum }) {
  switch (platform) {
    case SalesChannelPlatformEnum.Etsy:
      return (
        <img alt="Etsy logo" src="/img/logo/etsy-orange.png" className="-ml-2 h-14 w-64 object-contain object-left" />
      )
    case SalesChannelPlatformEnum.WooCommerce:
      return (
        <img alt="WooCommerce logo" src="/img/logo/woocommerce.svg" className="h-12 w-64 object-contain object-left" />
      )
    case SalesChannelPlatformEnum.Wix:
      return <img alt="Wix logo" src="/img/logo/wix.png" className="h-8 w-64 object-contain object-left" />
    case SalesChannelPlatformEnum.BigCommerce:
      return (
        <img alt="BigCommerce logo" src="/img/logo/bigcommerce.svg" className="h-12 w-64 object-contain object-left" />
      )
    case SalesChannelPlatformEnum.AdobeCommerce:
      return (
        <img
          alt="Adobe Commerce logo"
          src="/img/logo/adobecommerce.svg"
          className="h-10 w-64 object-contain object-left"
        />
      )
    case SalesChannelPlatformEnum.Squarespace:
      return (
        <img
          alt="Squarespace logo"
          src="/img/logo/squarespace.svg"
          className=" -ml-4 h-14 w-64 object-contain object-left"
        />
      )
    case SalesChannelPlatformEnum.Shopify:
      return <img alt="Shopify logo" src="/img/logo/shopify.svg" className="h-11 w-64 object-contain object-left" />
    case SalesChannelPlatformEnum.Amazon:
      return <img alt="Amazon logo" src="/img/logo/amazon.svg" className="h-9 w-64 object-contain object-left" />
    case SalesChannelPlatformEnum.Prodigi:
      return <LogoProdigi className="h-7 w-7" />
    default:
      const platformNameWithUnhandledCase: never = platform
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
