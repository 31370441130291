import toast from 'react-hot-toast'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'

import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import { useOrderDetail } from '../../../hooks'
import { isNoImageProduct } from '../../../helpers'
import SelectProductModal from '../../SelectProductModal'
import { FetchErrorInterface, OMSErrorResponseInterface } from '../../../interfaces'
import { ProductAdditionalDataInterface, SelectedProductInterface } from '../../SelectProduct'
import { ADD_LINE_ITEM_TOASTS, AddLineItemEditorDataType } from '../OrderEditAddLineItem.component'
import { LINE_ITEM_QUERY_PARAMS } from '../../OrderEditLineItem/constants/lineItemQueryParams.const'
import { formatNewLineItemData, formatNoImageProductLineItemData, saveNewLineItem } from '../helpers'

export function AddLineItemSelectProductModal({
  isSelectProductModalOpen,
  onSelectProductModalOpenChange,
  onSelectProductSuccess
}: {
  isSelectProductModalOpen: boolean
  onSelectProductModalOpenChange: (isOpen: boolean) => void
  onSelectProductSuccess: () => void
}) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order
  const { search, pathname } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const history = useHistory()

  const [selectingSkuName, setSelectingSkuName] = useState<string>()

  useEffect(() => {
    if (!isSelectProductModalOpen) {
      setSelectingSkuName(undefined)
      toast.dismiss(ADD_LINE_ITEM_TOASTS.ERROR)
    }
  }, [isSelectProductModalOpen])

  async function handleNoImageProductSelection(newLineItemData: AddLineItemEditorDataType) {
    setSelectingSkuName(newLineItemData?.selectedProductData?.sku)
    toast.dismiss(ADD_LINE_ITEM_TOASTS.ERROR)

    try {
      await saveNewLineItem({ newLineItemData, orderId })
      await mutateOrderDetails()
      setSelectingSkuName(undefined)
      onSelectProductSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code OMS-${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while adding another item',
        id: ADD_LINE_ITEM_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setSelectingSkuName(undefined)
    }
  }

  async function handleProductSelection(lineItemData: AddLineItemEditorDataType) {
    setSelectingSkuName(lineItemData?.selectedProductData?.sku)
    toast.dismiss(ADD_LINE_ITEM_TOASTS.ERROR)

    try {
      const { data: newLineItemData } = await saveNewLineItem({ newLineItemData: lineItemData, orderId })
      await mutateOrderDetails()

      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.set(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ID, newLineItemData.lineItemId)
      newSearchParams.set(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ACTION, 'image-editor')

      history.replace({ pathname, search: newSearchParams.toString() })
      setSelectingSkuName(undefined)
      onSelectProductSuccess()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code OMS-${
          errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
        })`,
        heading: 'An error occurred while adding another item',
        id: ADD_LINE_ITEM_TOASTS.ERROR,
        type: 'error-with-close'
      })
      setSelectingSkuName(undefined)
    }
  }

  async function handleSelectProduct(
    newSelectedProduct: SelectedProductInterface,
    { productDetails, templates }: ProductAdditionalDataInterface
  ) {
    const assetRequirementData = Object.entries(productDetails.product.printAreas).reduce(
      (
        assetRequirementDataAcc: Record<string, { required: boolean }>,
        [productPrintAreaName, productPrintAreaData]
      ) => {
        return {
          ...assetRequirementDataAcc,
          [productPrintAreaName]: { required: productPrintAreaData.required }
        }
      },
      {}
    )

    if (isNoImageProduct(newSelectedProduct.searchResultData.productType)) {
      const noProductLineItemData = formatNoImageProductLineItemData({
        newSelectedProduct,
        productDetails,
        assetRequirementData
      })

      handleNoImageProductSelection(noProductLineItemData)
      return
    }

    const productLineItemData = formatNewLineItemData({
      newSelectedProduct,
      productDetails,
      templates,
      assetRequirementData
    })

    handleProductSelection(productLineItemData)
  }

  if (!orderDetails) {
    return <>No order details</>
  }

  return (
    <>
      <SelectProductModal
        countryCode={orderDetails.recipient.address.countryCode}
        closeOnEscape={!selectingSkuName}
        closeOnInteractionOutside={!selectingSkuName}
        defaultSearchQuery={undefined}
        defaultSelectedAttributes={undefined}
        defaultSelectedMetaData={undefined}
        selectingSkuName={selectingSkuName}
        open={isSelectProductModalOpen}
        showCountryPicker={false}
        showMetaData={true}
        setOpen={onSelectProductModalOpenChange}
        onSelectProductWithAdditionalData={handleSelectProduct}
      />

      {Boolean(selectingSkuName) && <OverlayPortal />}
    </>
  )
}
