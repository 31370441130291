import Button from '../Button'
import { useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'
import { ChevronDownIcon } from '@heroicons/react/24/solid'

import {
  ProductLibraryDetailPanel,
  ProductLibraryDetailPanelContent,
  ProductLibraryDetailPanelSubTitle,
  ProductLibraryDetailPanelTitle
} from './components/ProductLibraryDetailPanel.component'
import { Tabs, TabsContent, TabsList, TabsTrigger } from '../Tabs'
import { ProductLibraryTabContent } from './components/ProductLibraryTabContent.component'

export default function ProductLibraryDetail() {
  return (
    <div className="container">
      <div className="flex flex-col gap-6 sm:flex-row sm:items-center">
        <div className="mr-auto">
          <h1 className="text-black">Filename Product Type</h1>
          <div className="mt-2 font-medium text-gray-700">Classic frame</div>
        </div>

        <div>
          <Button startIcon={<ChevronDownIcon className="h-7 w-7" />}>Actions</Button>
        </div>
      </div>

      <div className="mt-4">
        <img
          className="mx-auto h-[400px] w-full max-w-[400px] object-contain object-center sm:h-[480px]"
          alt=""
          src="/img/v2/product-catalogue/prints_fine-art-prints.jpg"
        />
      </div>

      <div className="mb-14 mt-6">
        <div className="mx-auto w-fit bg-white">
          <Button variant="secondary">Edit image</Button>
        </div>
      </div>

      <div>
        <ProductLibraryDetailTabs />
      </div>

      <div className="mt-4 bg-white">
        <ProductLibraryDetailPanel>
          <div>
            <ProductLibraryDetailPanelTitle>Settings</ProductLibraryDetailPanelTitle>
            <ProductLibraryDetailPanelSubTitle>
              Lorem ipsum dolor, sit amet consectetur adipisicing elit. Quibusdam, distinctio.
            </ProductLibraryDetailPanelSubTitle>
          </div>
          <ProductLibraryDetailPanelContent>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Facilis, animi quisquam tenetur tempore
            exercitationem aliquid impedit libero natus molestiae rem?
          </ProductLibraryDetailPanelContent>
        </ProductLibraryDetailPanel>
      </div>
    </div>
  )
}

function ProductLibraryDetailTabs() {
  const [selectedProductTab, setSelectedProductTab] = useState('classic-frame')

  return (
    <Tabs value={selectedProductTab} onValueChange={setSelectedProductTab}>
      <TabsList>
        <TabsTrigger value="classic-frame" isSelected={selectedProductTab === 'classic-frame'}>
          Classic frame
        </TabsTrigger>
        <TabsTrigger value="stretched-canvas" isSelected={selectedProductTab === 'stretched-canvas'}>
          Stretched canvas
        </TabsTrigger>
      </TabsList>

      <AnimatePresence initial={false}>
        <TabsContent key="classic-frame" className="border-0 bg-white" value="classic-frame">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ bounce: 0, duration: 0.35, type: 'spring' }}
          >
            <ProductLibraryTabContent />
          </motion.div>
        </TabsContent>

        <TabsContent key="stretched-canvas" className="border-0 bg-white" value="stretched-canvas">
          <motion.div
            initial={{ opacity: 0, y: 30 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ bounce: 0, duration: 0.35, type: 'spring' }}
          >
            <ProductLibraryTabContent />
          </motion.div>
        </TabsContent>
      </AnimatePresence>
    </Tabs>
  )
}
