import { useParams } from 'react-router-dom'

import { useBrandingDetails, useOrderDetail } from '../../../hooks'
import { OrderInsertsError, OrderInsertsLoading } from '../../BrandedInsertsOrderModal'

export function OrderDetailBrandedInserts() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const { brandingDetails, isLoadingBrandingDetails, brandingDetailsFetchError } = useBrandingDetails()

  if (brandingDetailsFetchError) {
    return <OrderInsertsError code={`BDFE-${brandingDetailsFetchError.status ?? 0}`} />
  }

  if (isLoadingBrandingDetails || !brandingDetails) {
    return <OrderInsertsLoading />
  }

  const orderDetail = orderDetailsResponse?.data
  const orderShipments = orderDetail?.order?.shipments
  const orderBrandedInsertsToDisplay: { name: string; quantity?: number }[] = []

  Object.entries(orderDetail?.order.branding?.inserts ?? {}).forEach(([insertId, insertData]) => {
    const brandingDetailDataForInsert = brandingDetails?.branding?.find(
      (brandingDetail) => brandingDetail?.id === insertId
    )
    if (!brandingDetailDataForInsert) {
      return
    }

    orderBrandedInsertsToDisplay.push({
      name: brandingDetailDataForInsert?.name ?? insertId,
      quantity: insertData.shipmentsCosts?.quantity
    })
  })

  return (
    <div className="border bg-white p-6">
      <h2 className="mt-0 text-lg">Inserts</h2>

      {orderBrandedInsertsToDisplay.length > 0 ? (
        <div className="mt-6 text-black">
          <div className="font-medium">
            {orderBrandedInsertsToDisplay.length === 1
              ? `${orderBrandedInsertsToDisplay.length} insert`
              : `${orderBrandedInsertsToDisplay.length} inserts`}
          </div>

          <ul>
            {orderBrandedInsertsToDisplay.map((item, i) => (
              <li className="inline" key={item.name}>
                {item.name}
                {orderShipments && orderShipments.length > 1 && item.quantity && (
                  <span className="text-gray-600"> [x{item.quantity}]</span>
                )}
                {i < orderBrandedInsertsToDisplay.length - 1 ? ', ' : ''}
              </li>
            ))}
          </ul>
        </div>
      ) : (
        <>
          {orderDetail?.order.packingSlipUrl ? (
            <div className="mt-6">
              <a
                className="btn btn-primary btn-sm btn-text w-fit"
                href={orderDetail.order.packingSlipUrl}
                rel="noopener noreferrer"
                target="_blank"
                title="View packing slip"
              >
                Packing slip (b&amp;w)
              </a>
            </div>
          ) : (
            <div className="mt-6 font-medium">No inserts</div>
          )}
        </>
      )}
    </div>
  )
}
