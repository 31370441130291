import { useState } from 'react'
import { useDispatch } from 'react-redux'

import Button from '../../../../../Button'
import { useUser } from '../../../../../../hooks'
import { fetcher } from '../../../../../../helpers'
import { createErrorToast, createToast } from '../../../../../Toast'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { FetchErrorInterface, PaymentMethodType, StatusType } from '../../../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'
import toast from 'react-hot-toast'

export function MakePaymentMethodPrimaryButton({ paymentMethod }: { paymentMethod: PaymentMethodType }) {
  const { mutateUser } = useUser()
  const dispatch = useDispatch()

  const [makePrimaryStatus, setMakePrimaryStatus] = useState<StatusType>('idle')

  async function handleMakePrimary() {
    setMakePrimaryStatus('loading')
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)

    try {
      await fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/makepaymentmethodprimary`, {
        body: JSON.stringify({ PaymentMethodId: paymentMethod.paymentMethodId }),
        method: 'POST'
      })
      await mutateUser()
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setMakePrimaryStatus('success')
      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ statusTxt?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.statusTxt ?? errorResponse.message,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setMakePrimaryStatus('error')
    }
  }

  return (
    <Button
      isLoading={makePrimaryStatus === 'loading'}
      size="xs"
      theme="brand"
      variant="text"
      onClick={handleMakePrimary}
    >
      Make primary
    </Button>
  )
}
