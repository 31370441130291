import { motion } from 'motion/react'

import { SalesChannelPlatformEnum } from '../../enums'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import { useMerchantService, useSplitTreatment } from '../../hooks'
import { ActiveSalesChannelList, SalesChannelsHeader } from './components'
import { ConnectNewSalesChannel } from './components/ConnectNewSalesChannel.component'

export default function SalesChannels() {
  const { merchantDetails } = useMerchantService()
  const salesChannels = merchantDetails?.salesChannels

  const amazonSplitData = useSplitTreatment(FEATURE_NAMES.SALES_CHANNEL_AMAZON)

  let externalActiveSalesChannels =
    salesChannels?.filter((salesChannel) => salesChannel.platform !== SalesChannelPlatformEnum.Prodigi) || []

  const showAmazon = !amazonSplitData.isLoadingSplit && amazonSplitData.isTreatmentOn

  if (!showAmazon) {
    externalActiveSalesChannels =
      externalActiveSalesChannels.filter((salesChannel) => salesChannel.platform !== SalesChannelPlatformEnum.Amazon) ||
      []
  }

  return (
    <motion.div animate={{ opacity: 1 }} className="container mx-auto flex flex-col" initial={{ opacity: 0 }}>
      <SalesChannelsHeader />
      {externalActiveSalesChannels?.length > 0 && (
        <ActiveSalesChannelList className="mt-16" activeChannels={externalActiveSalesChannels} />
      )}
      <ConnectNewSalesChannel className="mt-16" />
    </motion.div>
  )
}
