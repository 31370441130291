import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'

import Button from '../../../../../Button'
import { useUser } from '../../../../../../hooks'
import { fetcher } from '../../../../../../helpers'
import OverlayPortal from '../../../../../OverlayPortal'
import ConfirmationModal from '../../../../../ConfirmationModal'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { createErrorToast, createToast } from '../../../../../Toast/helpers'
import { FetchErrorInterface, PaymentMethodType, StatusType } from '../../../../../../interfaces'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'
import { PaymentMethodIdentifier } from './PaymentMethodIdentifier.component'

export function RemovePaymentMethodModalButton({ paymentMethod }: { paymentMethod: PaymentMethodType }) {
  const { user, mutateUser } = useUser()
  const dispatch = useDispatch()

  const [isBillingRemoveModalOpen, setIsBillingRemoveModalOpen] = useState(false)
  const [removeBillingDetailsStatus, setRemoveBillingDetailsStatus] = useState<StatusType>('idle')

  async function handleRemoveBillingDetails() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setRemoveBillingDetailsStatus('loading')

    try {
      await fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/removepayment`, {
        body: JSON.stringify({
          PaymentMethodId: paymentMethod.paymentMethodId
        }),
        method: 'POST'
      })
      await mutateUser()
      createToast({ heading: 'Updated successfully', id: SETTINGS_UPDATE_SUCCESS_TOAST_ID, type: 'success' })
      setRemoveBillingDetailsStatus('success')
      handleOpenChange(false)

      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setRemoveBillingDetailsStatus('error')
    }
  }

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      setRemoveBillingDetailsStatus('idle')
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
    setIsBillingRemoveModalOpen(isOpen)
  }

  return (
    <>
      <Button size="sm" theme="danger" variant="text" onClick={() => setIsBillingRemoveModalOpen(true)}>
        Remove
      </Button>

      <ConfirmationModal
        closeButton={{ text: 'Cancel', variant: 'tertiary', theme: 'greyscale' }}
        continueButton={{ text: 'Remove', variant: 'primary', theme: 'danger' }}
        isLoading={removeBillingDetailsStatus === 'loading'}
        open={isBillingRemoveModalOpen}
        setOpen={handleOpenChange}
        title="Remove payment method?"
        onContinue={handleRemoveBillingDetails}
        onCancel={() => handleOpenChange(false)}
      >
        {user?.billing.paymentMethods.length === 1 ? (
          <div>You won&apos;t be able to place an order until you add new billing details</div>
        ) : (
          <span>
            <PaymentMethodIdentifier identifier={paymentMethod.identifier} type={paymentMethod.type} /> will be removed
          </span>
        )}
      </ConfirmationModal>

      {removeBillingDetailsStatus === 'loading' && <OverlayPortal />}
    </>
  )
}
