import { SelectedProductInterface } from '../../SelectProduct'
import { generateDefaultArtworkData } from '../../OrderEditLineItem/helpers'
import { AddLineItemEditorDataType } from '../OrderEditAddLineItem.component'
import { ProductDetailSuccessResponseInterface, TemplateServiceSuccessResponseInterface } from '../../../hooks'

export function formatNewLineItemData({
  newSelectedProduct,
  productDetails,
  templates,
  assetRequirementData
}: {
  newSelectedProduct: SelectedProductInterface
  productDetails: ProductDetailSuccessResponseInterface
  templates: TemplateServiceSuccessResponseInterface
  assetRequirementData: Record<string, { required: boolean }>
}) {
  const printAreas = Object.keys(productDetails.product.printAreas)
  const firstAvailablePrintArea = printAreas[0]

  const newArtworkData = generateDefaultArtworkData({
    lineItemArtworkData: null,
    printAreas,
    templates,
    newSelectedProduct
  })

  const costInPounds = newSelectedProduct.searchResultData.basePriceFrom / 100
  const price = Number(costInPounds).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    useGrouping: true,
    currency: newSelectedProduct.searchResultData.currency
  })

  const newLineItemData: AddLineItemEditorDataType = {
    assetRequirementData,
    artworkData: newArtworkData,
    selectedPrintArea: firstAvailablePrintArea,
    selectedProductData: {
      attributes: newSelectedProduct.selectedAttributes,
      metaData: newSelectedProduct.selectedMetaData,
      sku: newSelectedProduct.sku,
      productType: newSelectedProduct.searchResultData.productType,
      price
    },
    printAreas: productDetails.product.printAreas
  }

  return newLineItemData
}
