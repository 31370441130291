import { cn } from '../../../../v2/helpers'
import { CountryInterface } from '../interfaces'

export function CountryCard({
  country,
  dataTest,
  onClick
}: {
  country: CountryInterface
  dataTest: string
  onClick: () => void
}) {
  return (
    <button
      className="flex h-[65px] w-full cursor-pointer flex-row items-center gap-4 bg-white pl-8 pr-6 text-lg outline-offset-1 outline-purple-500"
      data-test={dataTest}
      onClick={onClick}
    >
      <div className="mr-2">
        <Flag countryCode={country.isoCode} dataTest={`country-card-${country.name}-image`} />
      </div>
      <p data-test="country-name" className="my-0 text-left">
        {country.name}
      </p>
    </button>
  )
}

function Flag({ className, countryCode, dataTest }: { className?: string; countryCode: string; dataTest?: string }) {
  return (
    <div className={cn('h-10 w-10 shrink-0 items-center', className)}>
      <img
        alt={countryCode}
        data-test={dataTest}
        className={cn('h-10 w-10 rounded-full border border-gray-200 object-cover object-center', className)}
        key={countryCode}
        src={`/img/flags-100/${countryCode.toLowerCase()}.png`}
        title={countryCode}
      />
    </div>
  )
}
