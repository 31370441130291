import { CountryCard } from '.'
import { CountryInterface } from '../interfaces'

const popularCountries = [
  { isoCode: 'AU', name: 'Australia' },
  { isoCode: 'CA', name: 'Canada' },
  { isoCode: 'FR', name: 'France' },
  { isoCode: 'DE', name: 'Germany' },
  { isoCode: 'IE', name: 'Ireland' },
  { isoCode: 'ES', name: 'Spain' },
  { isoCode: 'GB', name: 'United Kingdom' },
  { isoCode: 'US', name: 'United States' }
]

export function PopularCountries({ selectCountry }: { selectCountry: (country: CountryInterface) => void }) {
  return (
    <div data-test="popular-countries">
      <h2 className="mb-4 text-xl">Popular countries</h2>
      <div
        className="grid max-w-full grid-cols-[repeat(auto-fill,minmax(250px,1fr))] gap-4 overflow-x-auto pt-4"
        data-test="popular-countries"
      >
        {popularCountries.map((country) => (
          <CountryCard
            country={country}
            dataTest={`country-card-${country.name}`}
            key={country.isoCode}
            onClick={() => {
              window.analytics.track('Popular Country Selected', country)
              selectCountry(country)
            }}
          />
        ))}
      </div>
    </div>
  )
}
