import { ALL, NO_SELECTION } from '../../data/catalogue'

import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../v2/components/SelectField'

export default function DropdownFilters({
  categories,
  productTypes,
  selectedItemCategory,
  selectedProductType,
  onSelectItemCategory,
  onSelectProductType
}: {
  categories: string[]
  productTypes: string[]
  selectedItemCategory: string
  selectedProductType: string
  onSelectItemCategory: (category: string) => void
  onSelectProductType: (productType: string) => void
}) {
  const hasCategoriesToShow = categories && categories.length > 0
  const showProductTypeFilter = selectedItemCategory !== NO_SELECTION && productTypes && productTypes.length > 1

  if (!hasCategoriesToShow) {
    return null
  }

  return (
    <>
      <div className="w-full md:w-[300px]">
        <label htmlFor="searchCategory">Category</label>

        <Select value={selectedItemCategory} onValueChange={(value) => onSelectItemCategory(value)}>
          <SelectTrigger id="searchCategory" className="w-full sm:max-w-[300px]" data-test="category-input">
            <SelectValue asChild>
              <p className="truncate">{formatSelectedCategory(selectedItemCategory)}</p>
            </SelectValue>
          </SelectTrigger>
          <SelectContent className="max-h-[400px]" data-test="category-input-options">
            <SelectItem
              value={NO_SELECTION}
              data-test="category-input-option"
              data-test-value="category-input-options-NO_SELECTION"
              disabled
            >
              Please choose...
            </SelectItem>
            <SelectItem value={ALL} data-test="category-input-option" data-test-value="category-input-options-ALL">
              All Categories
            </SelectItem>
            {categories.map((category) => (
              <SelectItem
                key={category}
                value={category}
                data-test="category-input-option"
                data-test-value={`category-input-options-${category}`}
              >
                {formatSelectedCategory(category)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {showProductTypeFilter && (
        <div className="w-full md:w-[300px]">
          <label htmlFor="searchProductType">Product type</label>

          <Select value={selectedProductType} onValueChange={(value) => onSelectProductType(value)}>
            <SelectTrigger id="searchProductType" className="w-full sm:max-w-[300px]" data-test="product-type-input">
              <SelectValue asChild>
                <p className="truncate">{formatSelectedProductType(selectedItemCategory, selectedProductType)}</p>
              </SelectValue>
            </SelectTrigger>
            <SelectContent className="max-h-[400px]" data-test="product-type-input-options">
              <SelectItem
                value={ALL}
                data-test="product-type-input-option"
                data-test-value="product-type-input-options-ALL"
              >
                {formatSelectedProductType(selectedItemCategory, ALL)}
              </SelectItem>
              {productTypes.map((productType) => (
                <SelectItem
                  data-test="product-type-input-option"
                  data-test-value={`product-type-input-options-${productType}`}
                  key={productType}
                  value={productType}
                >
                  {formatSelectedProductType(selectedItemCategory, productType)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>
      )}
    </>
  )
}

function formatSelectedCategory(category: string) {
  switch (category) {
    case ALL:
      return 'All Categories'
    case NO_SELECTION:
      return 'Please choose...'
    default:
      return category
  }
}

function formatSelectedProductType(category: string, productType: string) {
  if (productType === ALL) {
    return category !== ALL ? `All ${category.toLowerCase()}` : 'All Product Types'
  }
  return productType
}
