// @flow
export const apparelColours = [
  {
    name: 'apple',
    hex: '#0eaf45'
  },
  {
    name: 'army',
    hex: '#514221'
  },
  {
    name: 'asphalt',
    hex: '#575759'
  },
  {
    name: 'athletic_heather',
    hex: '#a8abb2'
  },
  {
    name: 'baby_blue',
    hex: '#9fbdd5'
  },
  {
    name: 'berry',
    hex: '#b54470'
  },
  {
    name: 'black',
    hex: '#27262b'
  },
  {
    name: 'bright_white',
    hex: '#f4f3f8'
  },
  {
    name: 'brown',
    hex: '#372d2c'
  },
  {
    name: 'butter',
    hex: '#ffe8b2'
  },
  {
    name: 'candy_pink',
    hex: '#E782A9'
  },
  {
    name: 'candyfloss_pink',
    hex: '#E782A9'
  },
  {
    name: 'cardinal_red',
    hex: '#a30f33'
  },
  {
    name: 'cardinal',
    hex: '#a30f33'
  },
  {
    name: 'cardinal_triblend',
    hex: '#723238'
  },
  {
    name: 'charcoal',
    hex: '#66676c'
  },
  {
    name: 'charcoal_black_triblend',
    hex: '#2b2e35'
  },
  {
    name: 'charcoal_heather',
    hex: '#626367'
  },
  {
    name: 'chocolate',
    hex: '#312c29'
  },
  {
    name: 'coral',
    hex: '#fa5e45'
  },
  {
    name: 'dark_chocolate',
    hex: '#27201b'
  },
  {
    name: 'dark_green',
    hex: '#31443e'
  },
  {
    name: 'dark_grey_heather',
    hex: '#3e3c3d'
  },
  {
    name: 'dark_heather_grey',
    hex: '#3e3c3d'
  },
  {
    name: 'dark_heather',
    hex: '#3f424b'
  },
  {
    name: 'deep_royal',
    hex: '#2e4a8b'
  },
  {
    name: 'denim_heather',
    hex: '#4c5467'
  },
  {
    name: 'forest_green',
    hex: '#014421'
  },
  {
    name: 'gold',
    hex: '#fdb12a'
  },
  {
    name: 'graphite_heather',
    hex: '#858585'
  },
  {
    name: 'grass_green_triblend',
    hex: '#507158'
  },
  {
    name: 'grey',
    hex: '#757575'
  },
  {
    name: 'heather_grey',
    hex: '#8c8f90'
  },
  {
    name: 'heathered_cardinal',
    hex: '#724349'
  },
  {
    name: 'heathered_kelly_green',
    hex: '#28826e'
  },
  {
    name: 'heathered_light_grey',
    hex: '#b6b6b6'
  },
  {
    name: 'heathered_nickel',
    hex: '#b0b0b2'
  },
  {
    name: 'ice_blue',
    hex: '#afceeb'
  },
  {
    name: 'kelly_green',
    hex: '#278470'
  },
  {
    name: 'light_blue',
    hex: '#b5d0ed'
  },
  {
    name: 'light_pink',
    hex: '#ffc2cd'
  },
  {
    name: 'marine_olive',
    hex: '#525b4e'
  },
  {
    name: 'maroon',
    hex: '#452027'
  },
  {
    name: 'military_green',
    hex: '#515641'
  },
  {
    name: 'navy',
    hex: '#1f2035'
  },
  {
    name: 'navy_triblend',
    hex: '#474960'
  },
  {
    name: 'new_navy',
    hex: '#1f2035'
  },
  {
    name: 'new_red',
    hex: '#b8192a'
  },
  {
    name: 'olive',
    hex: '#434c31'
  },
  {
    name: 'orange',
    hex: '#fc6925'
  },
  {
    name: 'pink',
    hex: '#f3bec4'
  },
  {
    name: 'purple',
    hex: '#5d426d'
  },
  {
    name: 'red',
    hex: '#d60024'
  },
  {
    name: 'royal',
    hex: '#274e9d'
  },
  {
    name: 'royal_blue',
    hex: '#2858ba'
  },
  {
    name: 'royal_heather',
    hex: '#3b5eaf'
  },
  {
    name: 'sand',
    hex: '#dcceb2'
  },
  {
    name: 'sangria',
    hex: '#cc4c7c'
  },
  {
    name: 'silver',
    hex: '#d0cfcb'
  },
  {
    name: 'soft_cream',
    hex: '#e2d9c3'
  },
  {
    name: 'sports_grey',
    hex: '#a8abaf'
  },
  {
    name: 'sport_grey',
    hex: '#a8abaf'
  },
  {
    name: 'team_purple',
    hex: '#5f1a8c'
  },
  {
    name: 'true_royal',
    hex: '#1c438c'
  },
  {
    name: 'true_royal_triblend',
    hex: '#2f55a4'
  },
  {
    name: 'turf_green',
    hex: '#1e8c4f'
  },
  {
    name: 'turquoise',
    hex: '#3dd2ed'
  },
  {
    name: 'white',
    hex: '#f1f0f5'
  },
  {
    name: 'airforce_blue',
    hex: '#4F758B'
  },
  {
    name: 'arctic_white',
    hex: '#f9f9f9'
  },
  {
    name: 'ash',
    hex: '#dadada'
  },
  {
    name: 'athletic_grey',
    hex: '#b0acab'
  },
  {
    name: 'azalea',
    hex: '#ff75b2'
  },
  {
    name: 'bottle_green',
    hex: '#205c40'
  },
  {
    name: 'burgundy',
    hex: '#57093b'
  },
  {
    name: 'cornsilk',
    hex: '#f0ec74'
  },
  {
    name: 'daisy',
    hex: '#fedc66'
  },
  {
    name: 'dark_grey',
    hex: '#272729'
  },
  {
    name: 'fire_red',
    hex: '#BA0C2F'
  },
  {
    name: 'fuschia',
    hex: '#ec1472'
  },
  {
    name: 'hawaiian_blue',
    hex: '#00A9E0'
  },
  {
    name: 'heather_green',
    hex: '#169a61'
  },
  {
    name: 'heather_irish_green',
    hex: '#169a61'
  },
  {
    name: 'heather_purple',
    hex: '#725982'
  },
  {
    name: 'hot_chocolate',
    hex: '#382e2c'
  },
  {
    name: 'hot_pink',
    hex: '#CE0F69'
  },
  {
    name: 'irish_green',
    hex: '#0ba454'
  },
  {
    name: 'jade',
    hex: '#1a5c5b'
  },
  {
    name: 'jet_black',
    hex: '#161616'
  },
  {
    name: 'kelly',
    hex: '#018165'
  },
  {
    name: 'lime_green',
    hex: '#5fc44f'
  },
  {
    name: 'natural',
    hex: '#fff5dc'
  },
  {
    name: 'navy_blue',
    hex: '#242e44'
  },
  {
    name: 'new_french_navy',
    hex: '#0f1626'
  },
  {
    name: 'orange_crush',
    hex: '#FF6900'
  },
  {
    name: 'oxford_navy',
    hex: '#1F2A44'
  },
  {
    name: 'plum',
    hex: '#612C51'
  },
  {
    name: 'red_hot_chilli',
    hex: '#A6093D'
  },
  {
    name: 'sapphire',
    hex: '#0077B5'
  },
  {
    name: 'sapphire_blue',
    hex: '#005EB8'
  },
  {
    name: 'sky_blue',
    hex: '#aed8ff'
  },
  {
    name: 'steel_grey',
    hex: '#54585a'
  },
  {
    name: 'sun_yellow',
    hex: '#ffd100'
  },
  {
    name: 'turquoise_surf',
    hex: '#05C3DE'
  },
  {
    name: 'vintage_black',
    hex: '#100e11'
  },
  {
    name: 'vintage_navy',
    hex: '#0d1037'
  },
  {
    name: 'vintage_red',
    hex: '#b6022a'
  },
  {
    name: 'vintage_royal',
    hex: '#1d4590'
  },
  {
    name: 'black_and_white',
    hex: '#27262b',
    hex2: '#f1f0f5'
  },
  {
    name: 'white_and_asphalt',
    hex: '#f1f0f5',
    hex2: '#575759'
  },
  {
    name: 'white_and_black',
    hex: '#f1f0f5',
    hex2: '#27262b'
  },
  {
    name: 'white_and_kelly_green',
    hex: '#f1f0f5',
    hex2: '#00805e'
  },
  {
    name: 'white_and_navy',
    hex: '#f1f0f5',
    hex2: '#37384a'
  },
  {
    name: 'white_and_red',
    hex: '#f1f0f5',
    hex2: '#d60024'
  },
  {
    name: 'white_and_true_royal',
    hex: '#f1f0f5',
    hex2: '#1c438c'
  },
  {
    name: 'cream',
    hex: '#eeead5'
  },
  {
    name: 'dusk',
    hex: '#40436c'
  },
  {
    name: 'french_navy',
    hex: '#1c192a'
  },
  {
    name: 'khaki',
    hex: '#56544a'
  },
  {
    name: 'natural_raw',
    hex: '#feefd7'
  },
  {
    name: 'pink_joy',
    hex: '#d896a5'
  },
  {
    name: 'stone',
    hex: '#a29e8f'
  }
]
