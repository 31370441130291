import clsx from 'clsx'
import toast from 'react-hot-toast'
import { useParams } from 'react-router'
import { useEffect, useState } from 'react'

import { Checkbox } from '../../Checkbox'
import { createToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import LoadingSpinner from '../../LoadingSpinner'
import { fetcher, formatCost } from '../../../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { CostInterface } from '../../../interfaces/Cost.interface'
import { useMerchantService, useOrderDetail } from '../../../hooks'
import { OrderEditInsertsCostSummaryRow } from './OrderEditInsertsCostSummaryRow.component'
import { FetchErrorInterface, OrderDetailDataErrorInterface, StatusType } from '../../../interfaces'

export function OrderEditCostSummary() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  if (!orderDetails) {
    throw Error('No order details')
  }

  return (
    <div className="border bg-white p-6" aria-labelledby="cost-summary-heading">
      <h2 id="cost-summary-heading" className="mt-0 text-lg">
        Cost summary
      </h2>
      <div className="mt-4 flex flex-col justify-between lg:flex-row lg:space-x-8">
        <CostBreakdown
          itemCost={orderDetailsResponse?.data.draftCosts?.costSummary.items}
          shippingCost={orderDetailsResponse?.data.draftCosts?.costSummary.shipping}
          taxCost={orderDetailsResponse?.data.draftCosts?.costSummary.tax}
          totalCost={orderDetailsResponse?.data.draftCosts?.costSummary.totalCost}
        />
        <div className="md:w-1/3">
          <USSalesTaxCheckbox />
        </div>
      </div>
    </div>
  )
}

const TAX_COLLECTED_ERROR_TOAST = 'prodigiTaxCollectedErrorToast'

function USSalesTaxCheckbox() {
  const { id: orderId } = useParams<{ id: string }>()
  const { merchantDetails } = useMerchantService()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order

  const [updateTaxCollectedStatus, setUpdateTaxCollectedStatus] = useState<StatusType>('idle')

  useEffect(() => {
    return () => {
      toast.dismiss(TAX_COLLECTED_ERROR_TOAST)
    }
  }, [])

  if (!orderDetails) {
    throw Error('No order details')
  }

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  const salesChannel = merchantDetails.salesChannels.find((channel) => channel.id === orderDetails.salesChannelId)

  if (orderDetails.recipient.address.countryCode !== 'US' && salesChannel?.platform !== SalesChannelPlatformEnum.Etsy) {
    return null
  }

  async function handleChangeUSSalesTaxCollected(isCollected: boolean) {
    toast.dismiss(TAX_COLLECTED_ERROR_TOAST)
    setUpdateTaxCollectedStatus('loading')
    try {
      await fetcher(`${process.env.REACT_APP_PRODIGI_OMS}/orders/${orderId}/ussalestax`, {
        body: JSON.stringify(isCollected),
        method: 'PUT'
      })
      await mutateOrderDetails()
      setUpdateTaxCollectedStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OrderDetailDataErrorInterface>
      const errorCode = errorResponse.responseBodyJson?.traceParent ?? errorResponse.status ?? '0'
      createToast({
        content: `Please try again later or contact support if the issue persists`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorCode})`,
        heading: 'An error occurred while updating tax status',
        id: TAX_COLLECTED_ERROR_TOAST,
        type: 'error-with-close'
      })
      setUpdateTaxCollectedStatus('error')
    }
  }

  const usSalesTaxCollected = orderDetails.usSalesTaxCollected

  return (
    <div data-test="order-detail-cost-summary__tax-declaration">
      <h3 className="mb-4 mt-3 text-base">Tax summary</h3>
      <div className="relative">
        <div className="flex items-start gap-4">
          <Checkbox
            id="taxCollectedCheckbox"
            checked={usSalesTaxCollected}
            disabled={updateTaxCollectedStatus === 'loading'}
            onCheckedChange={(isChecked) => handleChangeUSSalesTaxCollected(Boolean(isChecked))}
          />

          <label htmlFor="taxCollectedCheckbox">I declare that this order has already had sales tax collected.</label>
        </div>

        {updateTaxCollectedStatus === 'loading' && (
          <div className="absolute left-0 top-0">
            <LoadingSpinner className={clsx('h-8 w-8', { 'text-white': usSalesTaxCollected })} />
            <OverlayPortal />
          </div>
        )}
      </div>
    </div>
  )
}

function CostBreakdown({
  itemCost,
  shippingCost,
  taxCost,
  totalCost
}: {
  itemCost?: CostInterface
  shippingCost?: CostInterface
  taxCost?: CostInterface
  totalCost?: CostInterface
}) {
  return (
    <div className="max-w-xl md:w-1/2" data-test="order-detail-cost-summary-breakdown">
      <table className="w-full">
        <tbody>
          {itemCost && (
            <tr>
              <td className="py-2 pr-2">Items</td>
              <td className="py-2 text-right" data-test="order-detail-cost-summary__item-cost">
                {formatCost({
                  amount: itemCost?.amount,
                  currencyCode: itemCost?.currencyCode
                })}
              </td>
            </tr>
          )}

          <OrderEditInsertsCostSummaryRow />

          {shippingCost && (
            <tr>
              <td className="py-2 pr-2">Shipping</td>
              <td className="relative py-2 text-right" data-test="order-detail-cost-summary__shipping-cost">
                {formatCost({
                  amount: shippingCost?.amount,
                  currencyCode: shippingCost?.currencyCode
                })}
              </td>
            </tr>
          )}
          {taxCost && (
            <tr>
              <td className="py-2 pr-2">Tax</td>
              <td className="relative pb-4 pt-2 text-right" data-test="order-detail-cost-summary__tax-cost">
                {formatCost({
                  amount: taxCost?.amount,
                  currencyCode: taxCost?.currencyCode
                })}
              </td>
            </tr>
          )}
          <tr>
            <td className="py-4 pr-2 font-medium">Total</td>
            <td className="relative py-4 text-right font-medium" data-test="order-detail-cost-summary__total-cost">
              {formatCost({
                amount: totalCost?.amount,
                currencyCode: totalCost?.currencyCode
              }) || 'Pending'}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  )
}
