import toast from 'react-hot-toast'
import { AnimatePresence, motion } from 'motion/react'
import { useHistory, useLocation } from 'react-router-dom'
import { useEffect, useMemo, useRef, useState } from 'react'

import Button from '../../Button'
import FormItem from '../../FormItem'
import SelectField from '../../SelectField'
import { formatCost } from '../../../helpers'
import { createErrorToast } from '../../Toast'
import { QUERY_PARAMS } from '../../../constants'
import { InsertDataType } from '../../../interfaces'
import ImageLibraryModal from '../../ImageLibraryModal'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../../PackingSlip'
import { BRANDED_INSERT_ERROR_TOAST_ID } from '../../BrandedInsertItem'
import { getInsertLocationLabel } from '../helpers/getInsertLocationLabel.helper'
import { BrandedInsertIdType, BrandingDetailsType, ImageLibraryImageInterface } from '../../../hooks'

export function InsertsAddPanel({
  brandingDetailsWithoutImage,
  selectedInsertId,
  onChangeSelectedInsertId,
  onChangeInsertData
}: {
  brandingDetailsWithoutImage: BrandingDetailsType[]
  selectedInsertId: string
  onChangeSelectedInsertId: (newSelectedInsertId: string) => void
  onChangeInsertData: (newInsertData: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) => void
}) {
  if (brandingDetailsWithoutImage.length === 0) {
    return <div className="text-black">All inserts added</div>
  }

  const selectedInsertDetails = brandingDetailsWithoutImage.find(
    (brandingDetail) => brandingDetail.id === selectedInsertId
  )

  const groupedInsertOptionsByLocation: Record<string, BrandingDetailsType[]> = {}

  brandingDetailsWithoutImage.forEach((brandingDetail) => {
    const label = getInsertLocationLabel(brandingDetail.location)
    groupedInsertOptionsByLocation[label] = groupedInsertOptionsByLocation[label]
      ? [...groupedInsertOptionsByLocation[label], brandingDetail]
      : [brandingDetail]
  })

  return (
    <div className="flex flex-col gap-10 md:flex-row">
      <div className="flex-1">
        <FormItem
          className="w-full"
          labelText="Select insert type"
          labelClassName="mt-0 text-black"
          inputField={
            <SelectField
              value={selectedInsertId}
              onChange={(event) => onChangeSelectedInsertId(event?.target?.value ?? '')}
            >
              <option disabled value="">
                Select insert...
              </option>

              {Object.entries(groupedInsertOptionsByLocation).map(([groupedInsertOptionLabel, insertOptions]) => (
                <optgroup key={groupedInsertOptionLabel} label={groupedInsertOptionLabel}>
                  {insertOptions.map((insertOption) => {
                    return (
                      <option key={insertOption.id} value={insertOption.id}>
                        {insertOption.name}
                      </option>
                    )
                  })}
                </optgroup>
              ))}
            </SelectField>
          }
        />
      </div>

      <div className="flex-1">
        <AnimatePresence initial={false}>
          {selectedInsertDetails ? (
            <motion.div
              initial={{ x: -5, opacity: 0 }}
              animate={{ x: 0, opacity: 1 }}
              transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
            >
              <InsertDetails
                key={selectedInsertDetails.id}
                brandingDetail={selectedInsertDetails}
                onChangeInsertData={onChangeInsertData}
              />
            </motion.div>
          ) : null}
        </AnimatePresence>
      </div>
    </div>
  )
}

function InsertDetails({
  brandingDetail,
  onChangeInsertData
}: {
  brandingDetail: BrandingDetailsType
  onChangeInsertData: (newInsertData: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) => void
}) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const buttonRef = useRef<HTMLButtonElement>(null)
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  useEffect(() => {
    buttonRef.current?.scrollIntoView({ block: 'center', behavior: 'smooth' })
  }, [])

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)

    if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      handleOpenImageLibraryChange(false)
      createErrorToast({
        errorMessage: `${
          imageLibraryImage.file_type
        } is not supported for inserts. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        heading: 'Failed to update insert',
        id: BRANDED_INSERT_ERROR_TOAST_ID
      })
      return
    }

    onChangeInsertData({
      newInsertId: brandingDetail.id,
      newInsertData: { imageLibraryId: imageLibraryImage.public_id, imageUrl: imageLibraryImage.url }
    })
    handleOpenImageLibraryChange(false)
  }

  function handleOpenImageLibraryChange(open: boolean) {
    const newSearchParams = new URLSearchParams(searchParams)

    if (open) {
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
    } else {
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    }

    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)
    history.replace({ search: newSearchParams.toString() })
    setIsImageLibraryModalOpen(open)
  }

  return (
    <div className="relative">
      <InsertDetailArrow />

      <div className="isolate rounded-lg bg-gray-100 px-10 py-8">
        <div className="flex justify-between gap-2 font-medium text-black">
          <div>{brandingDetail.name}</div>
          <div>
            {formatCost({ amount: brandingDetail.cost.amount.toString(), currencyCode: brandingDetail.cost.currency })}
          </div>
        </div>

        <div className="text-sm text-gray-800">{brandingDetail.dimensionsDescription}</div>

        <div className="mt-4 text-sm text-gray-800" style={{ wordBreak: 'break-word' }}>
          {brandingDetail.description}
        </div>

        <div className="mt-4 text-sm text-gray-800">
          File requirements:{' '}
          <span className="font-medium text-black">{brandingDetail.fileRequirementsDescription}</span>
        </div>

        <div className="mt-8">
          <motion.div
            initial={{ scale: 1.05 }}
            animate={{ scale: 1 }}
            transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
            className="w-fit bg-white"
          >
            <Button ref={buttonRef} variant="secondary" onClick={() => handleOpenImageLibraryChange(true)}>
              Select &amp; add image
            </Button>
          </motion.div>
        </div>
      </div>

      <ImageLibraryModal
        config={{ forceSaveToLibrary: true }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={handleOpenImageLibraryChange}
        onSelectImage={handleSelectImage}
      />
    </div>
  )
}

function InsertDetailArrow() {
  return (
    <>
      <div
        className="absolute left-0 top-[21px] hidden h-[52px] w-[52px] bg-gray-100 md:block"
        style={{ transform: 'rotate(45deg)' }}
      ></div>

      <div
        className="absolute left-1/2 block h-[52px] w-[52px] bg-gray-100 md:hidden"
        style={{ transform: 'translateX(-50%) rotate(45deg)' }}
      ></div>
    </>
  )
}
