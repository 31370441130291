import { CalendarContainer, default as ReactDatePicker } from 'react-datepicker'
import { getMonth, getYear } from 'date-fns'
import Button from '../../Button'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { CustomInputs } from '.'

// Documentation with tons of examples:
// https://reactdatepicker.com/

/* -------------------------------------------------------------------------- */
/*                               Main component                               */
/* -------------------------------------------------------------------------- */

const months = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December'
]

interface DatePickerPropsinterface {
  endDate: Date | null
  formatDateToString: (date: Date | null) => string
  showClearButton?: boolean
  showInline?: boolean
  startDate: Date | null
  updateSelectedSortOption: (startDate: Date | null, endDate: Date | null) => void
}

export function DatePicker({
  endDate,
  formatDateToString,
  showClearButton = false,
  showInline = true,
  startDate,
  updateSelectedSortOption
}: DatePickerPropsinterface) {
  function onChange(dates: [Date | null, Date | null]) {
    const [start, end] = dates
    updateSelectedSortOption(start, end)
  }

  function onSelect(date: Date) {
    if (!startDate && endDate) {
      updateSelectedSortOption(date, null)
    }
  }

  function getDatesInTimeframe(length: number) {
    const endDate = new Date()
    const startDate = new Date()
    startDate.setDate(startDate.getDate() - length)
    updateSelectedSortOption(startDate, endDate)
  }

  function getLastMonth() {
    const today = new Date()
    const startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1)
    const endDate = new Date(today.getFullYear(), today.getMonth(), 0)
    updateSelectedSortOption(startDate, endDate)
  }

  function CustomCalendarContainer({ className, children }: { className: string; children: React.ReactNode }) {
    return (
      <CalendarContainer
        className={`w-[calc(100vw-5rem)] p-8 pt-4 font-body sm:w-full ${
          showInline ? 'border-none' : 'border border-gray-200 bg-white shadow-lg'
        } ${className}`}
      >
        <div className="flex w-full flex-wrap justify-between gap-4">
          <CustomInputs
            endDate={endDate}
            formatDateToString={formatDateToString}
            startDate={startDate}
            updateSelectedSortOption={updateSelectedSortOption}
          />
          {showClearButton && (
            <Button
              className="relative w-fit cursor-pointer rounded-lg bg-white px-3 py-2 text-left text-sm text-purple-600 focus:outline-none focus-visible:outline-purple-500"
              onClick={() => updateSelectedSortOption(null, null)}
              variant="none"
              theme="none"
            >
              Clear
            </Button>
          )}
        </div>
        <div className="ml-1 flex space-x-6 py-2">
          <button className="text-sm text-purple-600" onClick={() => getDatesInTimeframe(7)}>
            Last 7 days
          </button>
          <button className="text-sm text-purple-600" onClick={() => getDatesInTimeframe(30)}>
            Last 30 days
          </button>
          <button className="text-sm text-purple-600" onClick={() => getLastMonth()}>
            Previous month
          </button>
        </div>
        <div className="relative flex w-full flex-col">{children}</div>
      </CalendarContainer>
    )
  }

  return (
    <span
      className={`left-0 top-[40px] z-10 max-h-[calc(var(--radix-popover-content-available-height)-10vh)] w-fit overflow-auto sm:top-[36px] sm:w-[42rem] lg:w-[44rem] ${
        showInline ? '' : 'absolute'
      }`}
    >
      <ReactDatePicker
        calendarContainer={CustomCalendarContainer}
        className="w-full placeholder:text-gray-800 focus:outline-none focus:ring-0"
        dateFormat="yyyy/MM/dd"
        endDate={endDate}
        inline
        onChange={onChange}
        onSelect={onSelect}
        renderCustomHeader={({
          date,
          decreaseMonth,
          increaseMonth,
          nextMonthButtonDisabled,
          prevMonthButtonDisabled
        }) => (
          <CustomCalendarHeader
            date={date}
            decreaseMonth={decreaseMonth}
            increaseMonth={increaseMonth}
            nextMonthButtonDisabled={nextMonthButtonDisabled}
            prevMonthButtonDisabled={prevMonthButtonDisabled}
          />
        )}
        selected={startDate}
        selectsRange
        startDate={startDate}
        useWeekdaysShort
      />
    </span>
  )
}

/* -------------------------------------------------------------------------- */
/*                               Pure components                              */
/* -------------------------------------------------------------------------- */

function CustomCalendarHeader({
  date,
  decreaseMonth,
  increaseMonth,
  nextMonthButtonDisabled,
  prevMonthButtonDisabled
}: {
  date: Date
  decreaseMonth: () => void
  increaseMonth: () => void
  nextMonthButtonDisabled: boolean
  prevMonthButtonDisabled: boolean
}) {
  return (
    <div className="flex w-full justify-between px-1 py-4 text-black">
      <Button
        className="flex w-fit items-center rounded-md border border-gray-200 p-1 text-sm focus:outline-none focus-visible:outline-purple-500"
        variant="none"
        theme="none"
        onClick={decreaseMonth}
        disabled={prevMonthButtonDisabled}
      >
        <ChevronLeftIcon className="h-8 w-8 text-gray-600" />
      </Button>

      <span className="col-span-4 text-sm">{months[getMonth(date)] + ' ' + getYear(date)}</span>
      <Button
        className="flex w-fit items-center rounded-md border border-gray-200 p-1 text-sm focus:outline-none focus-visible:outline-purple-500"
        variant="none"
        theme="none"
        onClick={increaseMonth}
        disabled={nextMonthButtonDisabled}
      >
        <ChevronRightIcon className="h-8 w-8 text-gray-600" />
      </Button>
    </div>
  )
}
