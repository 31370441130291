import { format } from 'date-fns'

import { SubscriptionTierType } from '../../../interfaces'
import { SubscriptionScheduledChangesInterface } from '../hooks'

export function ScheduledUpdateMessage({
  isUsersCurrentSubscription,
  scheduledChanges
}: {
  isUsersCurrentSubscription: boolean
  scheduledChanges: SubscriptionScheduledChangesInterface
}) {
  if (scheduledChanges.changeDate && scheduledChanges.newSubscription && isUsersCurrentSubscription) {
    return (
      <p className="mt-4 text-gray-600">
        Your subscription has been scheduled to change to {formatSubscriptionTier(scheduledChanges.newSubscription)} on{' '}
        {format(new Date(scheduledChanges.changeDate), 'dd MMMM YYY')}. Contact us to cancel this change.
      </p>
    )
  }

  if (!isUsersCurrentSubscription) {
    return null
  }

  return <p className="mt-4 text-gray-600">Validating subscription update</p>
}

function formatSubscriptionTier(tier: SubscriptionTierType) {
  if (tier === 'ProMonthlyBilling') {
    return 'Pro - monthly billing'
  } else if (tier === 'ProYearlyBilling') {
    return 'Pro - yearly billing'
  }

  return tier
}
