import useSWR, { SWRConfiguration } from 'swr'

import { fetcher } from '../../../helpers'
import { FetchErrorInterface, SubscriptionTierType } from '../../../interfaces'

export interface UserSubscriptionInterface {
  subscriptionTier: SubscriptionTierType
  currentBillingPeriodEnd?: string
  currentBillingPeriodStart?: string
  scheduledChanges: SubscriptionScheduledChangesInterface
}

export interface SubscriptionScheduledChangesInterface {
  changeHasBeenScheduled: boolean
  changeDate?: string
  newSubscription?: SubscriptionTierType
}

interface UserSubscriptionSuccessResponseInterface {
  data: UserSubscriptionInterface
  statusTxt: string
  statusCode: number
}

interface UserErrorResponseInterface {
  message?: string
}

export function useUserSubscription({ config }: { config?: SWRConfiguration } = {}) {
  const {
    data: subscription,
    error,
    mutate
  } = useSWR<UserSubscriptionInterface, FetchErrorInterface<UserErrorResponseInterface>>(
    `${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/subscription`,
    userFetcher,
    config
  )

  return {
    isLoading: !subscription && !error,
    subscription,
    error,
    mutateSubscription: mutate
  }
}

async function userFetcher(url: string) {
  return fetcher<UserSubscriptionSuccessResponseInterface>(url).then((userSuccessResponse) => userSuccessResponse.data)
}
