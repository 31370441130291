import { RutterPlatformEnum, SalesChannelPlatformEnum } from '../../../enums'
import { RutterSupportedPlatformInterface } from '../interfaces'

export const RUTTER_SUPPORTED_PLATFORMS: RutterSupportedPlatformInterface[] = [
  {
    name: RutterPlatformEnum.Shopify,
    description: 'Automatically import new orders from your Shopify store.',
    ctaButtonText: 'Connect your Shopify store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.Shopify
  },
  {
    name: RutterPlatformEnum.Etsy,
    description: 'Automatically import new orders from your Etsy store.',
    ctaButtonText: 'Connect your Etsy store',
    disclaimer:
      "The term 'Etsy' is a trademark of Etsy, Inc. This application uses the Etsy API but is not endorsed or certified by Etsy, Inc.",
    prodigiPlatformName: SalesChannelPlatformEnum.Etsy
  },
  {
    name: RutterPlatformEnum.WooCommerce,
    description: 'Automatically import new orders from your WooCommerce store.',
    ctaButtonText: 'Connect your WooCommerce store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.WooCommerce
  },
  {
    name: RutterPlatformEnum.Wix,
    description: 'Automatically import new orders from your Wix store.',
    ctaButtonText: 'Connect your Wix store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.Wix
  },
  {
    name: RutterPlatformEnum.Squarespace,
    description: 'Automatically import new orders from your Squarespace store.',
    ctaButtonText: 'Connect your Squarespace store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.Squarespace
  },
  {
    name: RutterPlatformEnum.AdobeCommerce,
    description: 'Automatically import new orders from your Adobe Commerce (formerly Magento) store.',
    ctaButtonText: 'Connect your Adobe Commerce store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.AdobeCommerce
  },
  {
    name: RutterPlatformEnum.BigCommerce,
    description: 'Automatically import new orders from your BigCommerce store.',
    ctaButtonText: 'Connect your BigCommerce store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.BigCommerce
  },
  {
    name: RutterPlatformEnum.Amazon,
    description: 'Automatically import new orders from your Amazon store.',
    ctaButtonText: 'Connect your Amazon store',
    disclaimer: '',
    prodigiPlatformName: SalesChannelPlatformEnum.Amazon
  }
]
