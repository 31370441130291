import { useParams } from 'react-router-dom'

import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { BrandingInsertQuoteInterface } from '../../../interfaces'
import { areStringArraysEqual, formatCost } from '../../../helpers'
import { useBrandingDetails, useOrderDetail, useSplitToggle } from '../../../hooks'

export function OrderEditInsertsCostSummaryRow() {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })

  const quoteInsertsCost = orderDetailsResponse?.data.draftCosts?.costSummary.branding
  const formattedInsertsCost = quoteInsertsCost ? formatCost(quoteInsertsCost) : null

  if (!isBrandingSettingsOn || !formattedInsertsCost) {
    return null
  }

  const quoteInserts = orderDetailsResponse?.data.draftCosts?.branding?.inserts ?? []
  const userSelectedInsertIds = Object.keys(orderDetailsResponse?.data.order.branding?.inserts ?? {})
  const quoteInsertIds: string[] = quoteInserts?.map((insert) => insert.id) ?? []
  const doesQuoteIncludeAllInserts = areStringArraysEqual(quoteInsertIds, userSelectedInsertIds)

  return (
    <tr>
      <td className="py-2 pr-2">
        <div>Inserts</div>
        {!doesQuoteIncludeAllInserts && <CostSummaryInserts inserts={quoteInserts} />}
      </td>
      <td className="py-2 text-right">{formattedInsertsCost}</td>
    </tr>
  )
}

function CostSummaryInserts({ inserts }: { inserts: BrandingInsertQuoteInterface[] }) {
  const { brandingDetails } = useBrandingDetails()

  if (!inserts || inserts.length === 0) {
    return null
  }

  const quoteInsertNames: string[] = []
  inserts.forEach((insert) => {
    const insertName =
      brandingDetails?.branding?.find((brandingDetail) => brandingDetail?.id === insert?.id)?.name ?? insert?.id ?? ''
    quoteInsertNames.push(insertName)
  })

  return <div className="text-xs text-gray-500">{quoteInsertNames.join(', ')}</div>
}
