import useSWR from 'swr'

import { fetcher } from '../helpers'
import { DictionaryInterface, FetchErrorInterface } from '../interfaces'

export interface MockupViewInterface {
  variants: DictionaryInterface<string>
  thumbnail_image: string | null
  renderer: 'PIG' | 'PorcusLardum'
}

export interface MockupInterface {
  views: DictionaryInterface<MockupViewInterface>
}

export const PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING: Record<string, string | undefined> = {
  mountColour: 'mountColor',
  frameColour: 'color'
}

export function useMockups3D({
  attributes,
  orientation,
  productSku
}: {
  attributes: Record<string, string>
  orientation: string
  productSku: string
}) {
  const formattedSku = formatSkuForMockup(productSku)

  const url = new URL(`${process.env.REACT_APP_PRODIGI_IMAGE_LIBRARY}/mockups/${formattedSku}`)

  Object.entries(attributes).forEach(([attributeName, attributeValue]) => {
    url.searchParams.set(
      PRODIGI_TO_MOCKUP_ATTRIBUTE_MAPPING[attributeName] ?? attributeName,
      attributeValue.toUpperCase()
    )
  })

  if (orientation && orientation.toUpperCase() !== 'SQUARE') {
    url.searchParams.set('orientation', orientation.toUpperCase())
  }

  const { data, error } = useSWR<MockupInterface, FetchErrorInterface<unknown>>(
    productSku ? url.toString() : null,
    fetcher
  )

  return {
    isLoadingMockups: !data && !error,
    mockups: data,
    mockupsFetchError: error
  }
}

// the image library expects skus to be in the format GLOBAL-TEST-8x12 but the FE uses GLOBAL-TEST-8X12
// this function lowercases the x in the size
export function formatSkuForMockup(sku?: string) {
  if (!sku) {
    return null
  }

  const elements = sku.split('-')

  const sizeQuery = /^\d+X\d+$/i
  const updatedElements = elements.map((x) => {
    if (!x.match(sizeQuery)) {
      return x
    }

    return x.toLowerCase()
  })

  return updatedElements.join('-')
}
