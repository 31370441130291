import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { differenceInDays, format } from 'date-fns'

import { useUser } from '../../../hooks'
import { StatusEnum } from '../../../enums'
import { ProPlanBillingRadioButtons } from '.'
import { useUserSubscription } from '../hooks'
import OverlayPortal from '../../OverlayPortal'
import { ProBillingOption } from '../interfaces'
import { PRO_FEATURE_SHORT_LIST } from '../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { updateUserSuccess } from '../../../../actions/user'
import { refreshAccessToken } from '../../../../actions/auth'
import { formatBillingCost, updateSubscription } from '../helpers'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'

export function ViewProSubscriptionDetailsModal({
  isModalOpen,
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  openCancelProPlanModal,
  setIsModalOpen,
  setSelectedProSubscriptionTier
}: {
  isModalOpen: boolean
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  openCancelProPlanModal: () => void
  setIsModalOpen: (open: boolean) => void
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const { mutateUser } = useUser()
  const dispatch = useDispatch()
  const { subscription, mutateSubscription } = useUserSubscription()

  const [updateBillingPlanStatus, setUpdateBillingPlanStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  async function switchBillingPlan() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setUpdateBillingPlanStatus(StatusEnum.Loading)

    try {
      await updateSubscription(selectedProSubscriptionTier)
      await mutateSubscription()
      await mutateUser()
      setUpdateBillingPlanStatus(StatusEnum.Success)
      handleOpenChange(false)
      createToast({
        duration: 3000,
        heading: 'Subscription update is being processed',
        id: SETTINGS_UPDATE_SUCCESS_TOAST_ID,
        type: 'success'
      })
      dispatch(refreshAccessToken())
      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update subscription',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setUpdateBillingPlanStatus(StatusEnum.Error)
    }
  }

  const isLoading = updateBillingPlanStatus === StatusEnum.Loading

  if (!subscription) {
    return null
  }

  const nextPaymentDate = subscription.currentBillingPeriodEnd ? new Date(subscription.currentBillingPeriodEnd) : null
  const currentDate = new Date()
  const daysUntilNextPaymentDue = nextPaymentDate ? differenceInDays(nextPaymentDate, currentDate) : null

  return (
    <>
      <ConfirmationModal
        className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
        contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
        continueButton={{
          variant: 'primary',
          text: 'Change payment plan',
          disabled: selectedProSubscriptionTier === subscription.subscriptionTier
        }}
        closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'Close' }}
        isLoading={isLoading}
        footer={
          <p className="mt-8 max-w-5xl text-gray-600">
            Changing your payment plan will be effective from when your existing billing period ends.{' '}
            <button className="text-purple-500 underline" onClick={openCancelProPlanModal}>
              Cancel at any time
            </button>{' '}
            online and your Pro subscription and benefits will stop at the end of the next paid period.
          </p>
        }
        open={isModalOpen}
        title="Your Pro account"
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={switchBillingPlan}
      >
        <p className="font-medium">Pro features include:</p>
        <ul className="max-w-git mb-4 mt-2 list-inside list-disc lg:mb-8">
          {PRO_FEATURE_SHORT_LIST.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>

        <h3 className="m-0 mb-4 mt-16 font-medium">Payment</h3>
        {subscription.currentBillingPeriodEnd && daysUntilNextPaymentDue && (
          <p>
            Your current billing period ends in <span className="font-medium">{daysUntilNextPaymentDue}</span> days
          </p>
        )}

        <ProPlanBillingRadioButtons
          proPlanBillingOptions={proPlanBillingOptions}
          selectedSubscriptionTier={selectedProSubscriptionTier}
          setSelectedSubscriptionTier={setSelectedProSubscriptionTier}
        />

        {nextPaymentDate && (
          <p className="mt-8">
            We will take your next payment of{' '}
            <span className="font-medium">{formatBillingCost(proPlanBillingOptions[selectedProSubscriptionTier])}</span>{' '}
            on the <span className="font-medium">{format(nextPaymentDate, 'dd MMMM YYY')}</span>
          </p>
        )}
      </ConfirmationModal>

      {isLoading && <OverlayPortal />}
    </>
  )
}
