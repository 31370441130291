import { Link } from 'react-router-dom'
import { CreditCardIcon, CodeBracketIcon } from '@heroicons/react/20/solid'
import { UserIcon, AdjustmentsVerticalIcon, CheckBadgeIcon, CalendarDateRangeIcon } from '@heroicons/react/24/outline'

import { useSplitTreatment } from '../../../hooks'
import { NavGroupTitle } from './NavGroupTitle.component'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { APP_ENVIRONMENTS, ROUTE_PATHS } from '../../../constants'
import { NavGroupListContainer } from './NavGroupListContainer.component'

export function NavGroupSettings({ className }: { className?: string }) {
  const { isTreatmentOn: isBrandingSettingsOn } = useSplitTreatment(FEATURE_NAMES.BRANDING_SETTINGS)
  const { isTreatmentOn: isProdigiProOn } = useSplitTreatment(FEATURE_NAMES.DASHBOARD_PRODIGI_PRO)

  if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.SANDBOX) {
    return (
      <div className={className}>
        <NavGroupTitle>Settings</NavGroupTitle>

        <NavGroupListContainer>
          <li>
            <Link to={ROUTE_PATHS.SETTINGS.API} className="side-nav-item">
              <CodeBracketIcon className="h-7 w-7" />
              API
            </Link>
          </li>
        </NavGroupListContainer>
      </div>
    )
  }

  return (
    <div className={className}>
      <NavGroupTitle>Settings</NavGroupTitle>

      <NavGroupListContainer>
        <li>
          <Link to={ROUTE_PATHS.SETTINGS.ACCOUNT} className="side-nav-item">
            <UserIcon className="h-7 w-7" />
            Account
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.PREFERENCES} className="side-nav-item">
            <AdjustmentsVerticalIcon className="h-7 w-7" />
            Preferences
          </Link>
        </li>

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.BILLING} className="side-nav-item">
            <CreditCardIcon className="h-7 w-7" />
            Billing
          </Link>
        </li>

        {isProdigiProOn && (
          <li>
            <Link to={ROUTE_PATHS.SETTINGS.SUBSCRIPTIONS} className="side-nav-item">
              <CalendarDateRangeIcon className="h-7 w-7" />
              Subscriptions
            </Link>
          </li>
        )}

        {isBrandingSettingsOn && (
          <li>
            <Link to={ROUTE_PATHS.SETTINGS.BRANDING} className="side-nav-item">
              <CheckBadgeIcon className="h-7 w-7" />
              Branding
            </Link>
          </li>
        )}

        <li>
          <Link to={ROUTE_PATHS.SETTINGS.API} className="side-nav-item">
            <CodeBracketIcon className="h-7 w-7" />
            API
          </Link>
        </li>
      </NavGroupListContainer>
    </div>
  )
}
