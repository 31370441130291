import { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'

import {
  clearProductSearch,
  getItemCategories,
  resetProductSearchResults,
  selectItemCategory,
  selectProductType
} from '../../../../actions/catalogue'
import {
  getFormattedCountries,
  getHasOrderInProgress,
  selectDeliveryCountry
} from '../../../../selectors/manualOrderForm'
import { ROUTE_PATHS } from '../../../../v2/constants'
import { resetRsaaStatus } from '../../../../actions/rsaa'
import { CREATE_V4_ORDER } from '../../../../actions/orders'
import { buildRsaaActionId } from '../../../../helpers/rsaa'
import { ALL, NO_SELECTION } from '../../../../data/catalogue'
import { setupRecentItems } from '../../../../actions/recentItems'
import ConfirmationModal from '../../../../v2/components/ConfirmationModal'
import { selectSelectedItemCategory, selectSelectedProductType } from '../../../../selectors/catalogue'
import { changeDeliveryCountry, clearOrderData, MOF_ORDER_LOGGER_ID } from '../../../../actions/manualOrderForm'
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from '../../../../v2/components/SelectField'

type CountriesType = Array<{ name: string; isoCode: string }>

export default function CountrySelection() {
  const dispatch = useDispatch()
  const history = useHistory()
  const selectedItemCategory = useSelector(selectSelectedItemCategory)
  const selectedProductType = useSelector(selectSelectedProductType)
  const hasOrderInProgress = useSelector(getHasOrderInProgress)

  const countries: CountriesType = useSelector(getFormattedCountries)
  const selectedDeliveryCountry: string = useSelector(selectDeliveryCountry)
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)
  const [shippingCountryCode, setShippingCountryCode] = useState<string | undefined>()

  function onResetSearch(countryCode: string) {
    history.replace(ROUTE_PATHS.MOF.SEARCH)
    dispatch(clearOrderData())
    const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
    dispatch(resetRsaaStatus(createOrderActionId))
    dispatch(resetProductSearchResults())
    dispatch(selectItemCategory(NO_SELECTION))
    dispatch(changeDeliveryCountry(countryCode))
    dispatch(getItemCategories())
    dispatch(clearProductSearch())

    if (selectedItemCategory !== NO_SELECTION) {
      dispatch(selectItemCategory(selectedItemCategory))
    }
    if (selectedItemCategory !== NO_SELECTION && selectedProductType !== ALL) {
      dispatch(selectProductType(selectedProductType))
    }
    dispatch(setupRecentItems())
  }

  function onChangeDeliveryCountry(countryCode: string) {
    if (selectedDeliveryCountry === countryCode) {
      return
    }
    setShippingCountryCode(countryCode)

    if (hasOrderInProgress) {
      setShowConfirmationModal(true)
      return
    }

    onResetSearch(countryCode)
  }

  const selectedCountry = countries.find((x) => x.isoCode.toLowerCase() === selectedDeliveryCountry.toLowerCase()) ?? {
    name: selectedDeliveryCountry,
    isoCode: selectedDeliveryCountry
  }

  return (
    <div className="mb-6 flex flex-wrap items-center gap-2 border border-gray-200 bg-white p-4">
      <label htmlFor="countrySelection" className="mr-6 whitespace-nowrap">
        Shipping to
      </label>

      <Select value={selectedCountry.isoCode} onValueChange={(value) => onChangeDeliveryCountry(value)}>
        <SelectTrigger id="countrySelection" className="w-full sm:max-w-[300px]">
          <SelectValue asChild>
            <div className="flex gap-2 break-words">
              <Flag countryCode={selectedCountry.isoCode} />
              <div>{selectedCountry.name}</div>
            </div>
          </SelectValue>
        </SelectTrigger>
        <SelectContent className="max-h-[400px]">
          {countries.map((country) => (
            <SelectItem key={country.isoCode} value={country.isoCode}>
              <div className="flex gap-2 break-words">
                <Flag countryCode={country.isoCode} />
                <div>{country.name}</div>
              </div>
            </SelectItem>
          ))}
        </SelectContent>
      </Select>

      <CountryChangeConfirmationModal
        open={showConfirmationModal}
        countryName={countries.find((country) => country.isoCode === shippingCountryCode)?.name}
        setOpen={setShowConfirmationModal}
        onCancel={() => {
          setShowConfirmationModal(false)
        }}
        onContinue={() => {
          if (shippingCountryCode) {
            onResetSearch(shippingCountryCode)
          }
          setShowConfirmationModal(false)
        }}
      />
    </div>
  )
}

function Flag({ countryCode }: { countryCode: string }) {
  return (
    <div className="mr-2 h-8 w-8 shrink-0 items-center">
      <img
        alt={countryCode}
        className="h-8 w-8 rounded-full border border-gray-200 object-cover object-center"
        key={countryCode}
        loading="lazy"
        src={`/img/flags-100/${countryCode.toLowerCase()}.png`}
        title={countryCode}
      />
    </div>
  )
}

function CountryChangeConfirmationModal({
  open,
  countryName,
  setOpen,
  onContinue,
  onCancel
}: {
  open: boolean
  countryName: string | undefined
  setOpen: (isOpen: boolean) => void
  onContinue: () => void
  onCancel: () => void
}) {
  const title = countryName ? `Change country to ${countryName}` : 'Change country'

  return (
    <ConfirmationModal
      open={open}
      continueButton={{ text: 'Yes' }}
      title={title}
      closeButton={{ text: 'No' }}
      setOpen={setOpen}
      onContinue={onContinue}
      onCancel={onCancel}
    >
      <p>Changing the shipping country will empty your basket. Do you want to continue?</p>
    </ConfirmationModal>
  )
}
