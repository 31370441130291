import { useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'

import {
  BrandedInsertIdType,
  BrandingDataInterface,
  BrandingDetailsType,
  MerchantBrandingType,
  useMerchantBranding,
  useMerchantService
} from '../../hooks'
import {
  InsertDataType,
  InsertSetSalesChannelType,
  InsertSetType,
  MerchantDetailsInterface,
  StatusType
} from '../../interfaces'
import Button from '../Button'
import { createToast } from '../Toast'
import OverlayPortal from '../OverlayPortal'
import { cn, formatCost } from '../../helpers'
import Modal, { ModalPropsInterface } from '../Modal'
import { BrandedInsertItem } from '../BrandedInsertItem'
import { InsertsPanel } from './components/InsertsPanel.component'
import { InsertSetSettingsType } from './components/InsertsSettingsPanel.component'

interface BrandedInsertsSetModalPropsIntereface
  extends BrandedInsertsSetPropsIntereface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {}

export function BrandedInsertsSetModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  saveStatus,
  title = 'Add insert set',
  setOpen,
  ...props
}: BrandedInsertsSetModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[1000px]"
      closeOnEscape={saveStatus === 'loading' ? false : closeOnEscape}
      closeOnInteractionOutside={saveStatus === 'loading' ? false : closeOnInteractionOutside}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <div className="h-60vh overflow-y-auto">
        <BrandedInsertsSet {...props} saveStatus={saveStatus} />
      </div>
    </Modal>
  )
}

interface BrandedInsertsSetPropsIntereface {
  brandingDetails: BrandingDataInterface
  insertSet?: InsertSetType
  saveStatus?: StatusType
  onSave: (savedInsertSetData: Omit<InsertSetType, 'id'>) => void
  onCancel?: () => void
}

function getInitialSetSettings({
  insertSet,
  merchantBranding,
  merchantDetails
}: {
  insertSet?: InsertSetType
  merchantBranding?: MerchantBrandingType
  merchantDetails?: MerchantDetailsInterface
}): InsertSetSettingsType {
  if (!merchantBranding || !merchantDetails) {
    return {
      name: insertSet?.name ?? 'My insert set',
      salesChannels: insertSet?.salesChannels ?? []
    }
  }

  let defaultNameForSet = insertSet?.name
  if (!defaultNameForSet) {
    defaultNameForSet = `Insert set ${merchantBranding.insertSets.length + 1}`
  }

  let defaultSalesChannelsForSet: InsertSetSalesChannelType[] = insertSet?.salesChannels ?? []
  const userHasNoInsertSets = merchantBranding.insertSets.length === 0
  if (userHasNoInsertSets) {
    defaultSalesChannelsForSet = []
    merchantDetails.salesChannels.forEach((salesChannel) => {
      defaultSalesChannelsForSet.push({ id: salesChannel.id })
    })
  }

  return {
    name: defaultNameForSet ?? 'My insert set',
    salesChannels: defaultSalesChannelsForSet ?? []
  }
}

function BrandedInsertsSet({
  brandingDetails,
  insertSet,
  saveStatus = 'idle',
  onCancel,
  onSave
}: BrandedInsertsSetPropsIntereface) {
  const { merchantDetails } = useMerchantService()
  const { merchantBrandingResponse } = useMerchantBranding()

  const [allInsertsData, setAllInsertsData] = useState(insertSet?.inserts ?? [])
  const [insertSetSettings, setInsertSetSettings] = useState<InsertSetSettingsType>(() =>
    getInitialSetSettings({ insertSet, merchantBranding: merchantBrandingResponse?.data, merchantDetails })
  )

  function handleChangeInsertData({
    newInsertId,
    newInsertData
  }: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) {
    const doesInsertExist = allInsertsData.some((insertData) => insertData.id === newInsertId)

    let newAllInsertsData = allInsertsData
    if (doesInsertExist) {
      newAllInsertsData = allInsertsData.map((insertData) => {
        if (insertData.id !== newInsertId) {
          return insertData
        }

        return { ...insertData, id: newInsertId, ...newInsertData }
      })
    } else {
      newAllInsertsData = [...allInsertsData, { id: newInsertId, ...newInsertData }]
    }

    setAllInsertsData(newAllInsertsData)
  }

  const brandingDetailsWithImage: BrandingDetailsType[] = []
  const brandingDetailsWithoutImage: BrandingDetailsType[] = []

  brandingDetails?.branding.forEach((brandingDetail) => {
    const insertDataForBrandingDetail = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

    if (insertDataForBrandingDetail?.imageLibraryId || insertDataForBrandingDetail?.imageUrl) {
      brandingDetailsWithImage.push(brandingDetail)
    } else {
      brandingDetailsWithoutImage.push(brandingDetail)
    }
  })

  const numberOfInsertsWithImage = brandingDetailsWithImage.length

  const totalCost = {
    amount: brandingDetailsWithImage.reduce((amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount, 0),
    currency: brandingDetailsWithImage[0]?.cost.currency
  }

  function handleSave() {
    if (!insertSetSettings.name.trim()) {
      createToast({
        type: 'error-with-close',
        heading: 'Please enter a name for this set'
      })
      return
    }

    onSave({
      inserts: allInsertsData.filter((insert) => Boolean(insert.imageLibraryId)),
      name: insertSetSettings.name,
      salesChannels: insertSetSettings.salesChannels
    })
  }

  return (
    <motion.div layoutScroll className="flex h-full flex-col overflow-auto text-black">
      <div className="flex-1">
        {numberOfInsertsWithImage > 0 && (
          <div className="mb-6 flex items-center justify-end">
            <div className="rounded-full bg-purple-100 px-6 py-2">
              {numberOfInsertsWithImage} {numberOfInsertsWithImage === 1 ? 'insert' : 'inserts'}, total cost:{' '}
              {formatCost({ amount: totalCost.amount.toString(), currencyCode: totalCost.currency })}
            </div>
          </div>
        )}

        <ul className={cn(brandingDetailsWithImage.length > 0 && 'mb-6')}>
          <AnimatePresence initial={false} mode="popLayout">
            {brandingDetailsWithImage.map((brandingDetail) => {
              const insertDataForItem = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

              return (
                <motion.li
                  key={brandingDetail.id}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  layout
                  transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
                >
                  <BrandedInsertItem
                    brandingDetail={brandingDetail}
                    insertData={insertDataForItem}
                    onChangeInsertData={(newInsertData) =>
                      handleChangeInsertData({ newInsertId: brandingDetail.id, newInsertData })
                    }
                  />
                </motion.li>
              )
            })}
          </AnimatePresence>
        </ul>

        <motion.div layout transition={{ type: 'spring', bounce: 0, duration: 0.3 }}>
          <InsertsPanel
            brandingDetailsWithoutImage={brandingDetailsWithoutImage}
            insertSetSettings={insertSetSettings}
            onChangeInsertSetSettings={setInsertSetSettings}
            onChangeInsertData={handleChangeInsertData}
          />
        </motion.div>
      </div>

      <div className="sticky bottom-0 flex flex-wrap-reverse items-center gap-2 bg-white pt-10">
        <div className="flex gap-2">
          <Button isLoading={saveStatus === 'loading'} variant="primary" onClick={handleSave}>
            Save
          </Button>

          {onCancel && (
            <Button variant="tertiary" theme="greyscale" onClick={onCancel}>
              Cancel
            </Button>
          )}
        </div>
      </div>

      {saveStatus === 'loading' && <OverlayPortal />}
    </motion.div>
  )
}
