import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation, useParams } from 'react-router-dom'
import { PencilSquareIcon, MagnifyingGlassIcon, ArrowsUpDownIcon } from '@heroicons/react/20/solid'

import Button from '../../Button'
import { OrderStatusEnum } from '../../../enums'
import { mapV4AttributesToV3 } from '../../../helpers'
import { ImagePreview } from './ImagePreview.component'
import { PrintQuality } from './PrintQuality.component'
import PrintAreaSelector from '../../PrintAreaSelector'
import { LineItemError } from './LineItemError.component'
import { LineItemLoading } from './LineItemLoading.component'
import { LineItemChannelInfo } from './LineItemChannelInfo.component'
import ImageLibraryFileTypePill from '../../ImageLibraryFileTypePill'
import { LineItemImageEditorModal } from './LineItemImageEditorModal.component'
import { LINE_ITEM_QUERY_PARAMS } from '../constants/lineItemQueryParams.const'
import { LineItemSelectProductModal } from './LineItemSelectProductModal.component'
import { PrintAreasArtworkDataType, calculatePrintQuality } from '../../ImageEditor'
import { useOrderDetail, useProductSearch, useProductDetails, useTemplateService } from '../../../hooks'
import { ItemMetaDataInterface, OrderDetailItemAssetInterface, OrderDetailItemInterface } from '../../../interfaces'
import { OrderDetailAttributesDisplay } from '../../OrderDetailShipmentDisplay/components/OrderDetailAttributesDisplay.component'

export type LineItemEditorDataType = {
  artworkData?: PrintAreasArtworkDataType | null
  assetRequirementData?: Record<string, { required: boolean }> | null
  selectedProductData?: {
    sku: string
    attributes: Record<string, string> | null
    metaData?: ItemMetaDataInterface
    productType: string
    price?: string
  } | null
  assetData?: Record<string, OrderDetailItemAssetInterface> | null
  selectedPrintArea?: string | null
} | null

function getLineItemEditorDataFromOrderDetail(
  lineItem: OrderDetailItemInterface,
  isOrderPaused: boolean
): LineItemEditorDataType {
  const lineItemArtworkData: PrintAreasArtworkDataType = {}
  const lineItemAssetRequirementData: Record<string, { required: boolean }> = {}
  const assetDataFromOrderDetail: Record<string, OrderDetailItemAssetInterface> = {}

  lineItem.assets.forEach((lineItemAssetData) => {
    lineItemArtworkData[lineItemAssetData.printAreaName] = lineItemAssetData.additionalUiDetail
    lineItemAssetRequirementData[lineItemAssetData.printAreaName] = { required: lineItemAssetData.required }
    assetDataFromOrderDetail[lineItemAssetData.printAreaName] = { ...lineItemAssetData }

    if (isOrderPaused) {
      lineItemArtworkData[lineItemAssetData.printAreaName] = lineItemAssetData.additionalUiDetail ?? {
        printImageUrl: lineItemAssetData.assetUrl,
        previewImageUrl: lineItemAssetData.assetUrl,
        transformImageUrl: lineItemAssetData.assetUrl
      }
    }
  })

  let price
  if (lineItem?.itemCost?.amount && lineItem.itemCost.currencyCode) {
    const costInPounds = Number.parseInt(lineItem.itemCost.amount) / 100
    price = Number(costInPounds).toLocaleString('en-US', {
      minimumFractionDigits: 2,
      useGrouping: true,
      currency: lineItem.itemCost.currencyCode
    })
  }

  const defaultLineItemEditorData: LineItemEditorDataType = {
    artworkData: Object.keys(lineItemArtworkData).length > 0 ? lineItemArtworkData : null,
    assetRequirementData: Object.keys(lineItemAssetRequirementData).length > 0 ? lineItemAssetRequirementData : null,
    assetData: Object.keys(assetDataFromOrderDetail).length > 0 ? assetDataFromOrderDetail : null,
    selectedProductData: lineItem.prodigiSku
      ? {
          sku: lineItem.prodigiSku,
          productType: lineItem.productType,
          attributes: lineItem.attributes ? mapV4AttributesToV3(lineItem.attributes) : null,
          metaData: lineItem.metadata,
          price
        }
      : null
  }
  return defaultLineItemEditorData
}

export function LineItemProductData({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse } = useOrderDetail(orderId)
  const orderDetails = orderDetailsResponse?.data.order
  const { search, pathname } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])
  const history = useHistory()

  const isOrderPaused = Boolean(orderDetails && orderDetails.status === OrderStatusEnum.ON_HOLD)

  const [lineItemModalToOpen, setLineItemModalToOpen] = useState<'select-product' | 'image-editor' | null>(null)
  const [lineItemEditorData, setLineItemEditorData] = useState<LineItemEditorDataType>(() =>
    getLineItemEditorDataFromOrderDetail(lineItem, isOrderPaused)
  )

  useEffect(() => {
    if (
      searchParams.get(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ID) === lineItem.id &&
      searchParams.get(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ACTION) === 'image-editor'
    ) {
      setLineItemModalToOpen('image-editor')
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ID)
      newSearchParams.delete(LINE_ITEM_QUERY_PARAMS.LINE_ITEM_ACTION)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
  }, [history, lineItem.id, lineItem.ignore, pathname, searchParams])

  const {
    searchResults,
    isLoading: isLoadingSearchResults,
    error: searchResultsFetchError
  } = useProductSearch({
    countryCode: orderDetails?.recipient.address.countryCode,
    query: lineItemEditorData?.selectedProductData?.sku,
    config: { revalidateOnFocus: false }
  })

  const skuSearchResult = searchResults?.find(
    (searchResult) => searchResult.sku.toUpperCase() === lineItemEditorData?.selectedProductData?.sku.toUpperCase()
  )

  const {
    productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsFetchError
  } = useProductDetails({ sku: lineItemEditorData?.selectedProductData?.sku, config: { revalidateOnFocus: false } })
  const {
    templates,
    isLoading: isLoadingTemplates,
    error: templatesFetchError
  } = useTemplateService({
    sku: lineItemEditorData?.selectedProductData?.sku,
    attributes: lineItemEditorData?.selectedProductData?.attributes ?? undefined,
    config: { revalidateOnFocus: false },
    enableNoImageProductCheck: true,
    productType: skuSearchResult?.productType
  })

  if (!orderDetails) {
    throw Error('No order details')
  }

  const productPrintAreas = Object.keys(productDetails?.product.printAreas ?? {})
  const validSelectedPrintArea =
    lineItemEditorData?.selectedPrintArea && productPrintAreas.includes(lineItemEditorData.selectedPrintArea)
      ? lineItemEditorData.selectedPrintArea
      : productPrintAreas[0]

  function handleChooseProductOnError() {
    setLineItemEditorData(null)
    setLineItemModalToOpen('select-product')
  }

  const productModals = (
    <>
      <LineItemSelectProductModal
        isSelectProductModalOpen={lineItemModalToOpen === 'select-product'}
        lineItem={lineItem}
        lineItemEditorData={lineItemEditorData}
        onLineItemEditorDataChange={setLineItemEditorData}
        onSelectProductModalOpenChange={(isOpen) => {
          if (isOpen) {
            setLineItemModalToOpen('select-product')
          } else {
            setLineItemModalToOpen(null)
          }
        }}
        onSelectProductSuccess={(nextStepToOpen) => setLineItemModalToOpen(nextStepToOpen)}
      />

      <LineItemImageEditorModal
        isImageEditorModalOpen={
          Boolean(lineItemEditorData?.selectedProductData) && lineItemModalToOpen === 'image-editor'
        }
        lineItem={lineItem}
        lineItemEditorData={lineItemEditorData}
        printAreas={productDetails?.product.printAreas}
        validSelectedPrintArea={validSelectedPrintArea}
        onImageEditorModalOpenChange={(isOpen) => {
          if (isOpen) {
            setLineItemModalToOpen('image-editor')
          } else {
            setLineItemModalToOpen(null)
          }
        }}
        onLineItemEditorDataChange={setLineItemEditorData}
        onSaveImageEditsSuccess={() => setLineItemModalToOpen(null)}
      />
    </>
  )

  if (productDetailsFetchError) {
    return (
      <>
        <LineItemError
          errorCode={`PDFE-${lineItemEditorData?.selectedProductData?.sku ?? '0'}-${
            productDetailsFetchError.responseBodyJson?.traceParent ??
            productDetailsFetchError.status ??
            productDetailsFetchError.message
          }`}
          lineItem={lineItem}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  if (templatesFetchError) {
    return (
      <>
        <LineItemError
          errorCode={`TSFE-${lineItemEditorData?.selectedProductData?.sku ?? '0'}-${
            templatesFetchError.status ?? templatesFetchError.message
          }`}
          lineItem={lineItem}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  if (searchResultsFetchError || (searchResults && !skuSearchResult)) {
    return (
      <>
        <LineItemError
          errorCode={`SRFE-${lineItemEditorData?.selectedProductData?.sku ?? '0'}-${
            searchResultsFetchError?.status ?? searchResultsFetchError?.message ?? '0'
          }`}
          lineItem={lineItem}
          onChooseProduct={handleChooseProductOnError}
        />
        {productModals}
      </>
    )
  }

  const isProductDataLoading =
    lineItemEditorData?.selectedProductData && (isLoadingProductDetails || isLoadingTemplates || isLoadingSearchResults)
  if (isProductDataLoading) {
    return (
      <>
        <LineItemLoading lineItem={lineItem} />
        {productModals}
      </>
    )
  }

  const selectedArtwork = validSelectedPrintArea && lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artwork
  const selectedArtworkTransformations =
    validSelectedPrintArea && lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artworkTransformations

  const printQuality =
    selectedArtwork && validSelectedPrintArea && templates
      ? calculatePrintQuality({
          artwork: selectedArtwork,
          artworkTransformations: selectedArtworkTransformations || undefined,
          category: skuSearchResult?.category,
          printAreaName: validSelectedPrintArea,
          templates
        })
      : null

  return (
    <>
      <td
        className="col-span-3 row-span-5 m-2 mr-4 w-[160px] align-top sm:col-span-1 md:p-2 md:pb-12 md:pr-4 xl:pr-8"
        data-test={`line-item-preview-${lineItem.id}`}
      >
        <ImagePreview
          artwork={lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artwork}
          artworkTransformations={lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artworkTransformations}
          assetDataFromOrderDetail={lineItemEditorData?.assetData?.[validSelectedPrintArea]}
          attributes={lineItemEditorData?.selectedProductData?.attributes ?? {}}
          countryCode={orderDetails.recipient.address.countryCode}
          lineItem={lineItem}
          selectedPrintArea={validSelectedPrintArea}
          selectedProductData={lineItemEditorData?.selectedProductData ?? null}
          previewImageUrl={lineItemEditorData?.artworkData?.[validSelectedPrintArea ?? '']?.previewImageUrl}
          productType={skuSearchResult?.productType}
          editableContent={
            <>
              {lineItemEditorData?.selectedProductData && (
                <div className="flex flex-col items-center">
                  <Button
                    className="mt-4"
                    startIcon={<PencilSquareIcon className="h-6 w-6" />}
                    variant="secondary"
                    size="sm"
                    onClick={() => {
                      window.analytics.track('Order editing - clicked edit image')
                      setLineItemModalToOpen('image-editor')
                    }}
                  >
                    {lineItemEditorData?.artworkData?.[validSelectedPrintArea ?? '']?.previewImageUrl
                      ? lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artworkTransformations
                        ? 'Edit'
                        : 'Replace'
                      : 'Upload'}
                  </Button>

                  <div className="mt-4 flex items-center justify-center gap-4">
                    <ImageLibraryFileTypePill
                      fileType={lineItemEditorData?.artworkData?.[validSelectedPrintArea]?.artwork?.fileType}
                    />

                    <PrintQuality printQualityLabel={printQuality?.label} />
                  </div>
                </div>
              )}

              {productPrintAreas.length > 1 && validSelectedPrintArea && (
                <PrintAreaSelector
                  className="mt-4 border-t border-gray-200 pt-4"
                  printAreas={productPrintAreas}
                  selectedPrintArea={validSelectedPrintArea}
                  onPrintAreaChange={(newPrintArea) => {
                    setLineItemEditorData({
                      ...lineItemEditorData,
                      selectedPrintArea: newPrintArea
                    })
                  }}
                />
              )}
            </>
          }
        />
      </td>

      <td className="col-span-3 p-2 align-top sm:col-start-2 md:table-cell">
        <LineItemChannelInfo lineItem={lineItem} />

        <div className="mt-6 flex flex-col gap-2">
          {lineItemEditorData?.selectedProductData ? (
            <>
              <div className="text-xs uppercase tracking-wider text-gray-600">Product</div>
              <div className="text-md max-w-[60ch] font-medium text-black">{productDetails?.product.description}</div>
              <div className="text-sm text-gray-600">{lineItemEditorData?.selectedProductData.sku}</div>
              {(Object.keys(lineItemEditorData?.selectedProductData.attributes ?? {}).length > 0 ||
                Object.keys(lineItemEditorData?.selectedProductData.metaData ?? {}).length > 0) && (
                <OrderDetailAttributesDisplay
                  attributes={lineItemEditorData?.selectedProductData.attributes ?? {}}
                  metaData={lineItemEditorData?.selectedProductData.metaData}
                  category={skuSearchResult?.category}
                  sku={lineItemEditorData?.selectedProductData.sku}
                />
              )}
              <div className="mt-4">
                <Button
                  dataTest={`change-line-item-product-button-${lineItem.id}`}
                  startIcon={<ArrowsUpDownIcon className="h-7 w-7" />}
                  variant="secondary"
                  size="sm"
                  onClick={() => {
                    window.analytics.track('Order editing - clicked change product')
                    setLineItemModalToOpen('select-product')
                  }}
                >
                  Change
                </Button>
              </div>
            </>
          ) : (
            <div>
              <Button
                dataTest={`choose-product-button-${lineItem.id}`}
                startIcon={<MagnifyingGlassIcon className="h-7 w-7" />}
                variant="secondary"
                onClick={() => {
                  window.analytics.track('Order editing - clicked choose product')
                  setLineItemModalToOpen('select-product')
                }}
              >
                Choose product
              </Button>
            </div>
          )}
        </div>
      </td>

      {productModals}
    </>
  )
}
