import { ErrorBoundary } from 'react-error-boundary'

import SupportLink from '../../SupportLink'
import { VoteForPlatformEnum } from '../enums'
import { VOTE_FOR_PLATFORMS } from '../constants'
import { RutterPlatformEnum } from '../../../enums'
import { useSplitToggle, useSplitTreatment } from '../../../hooks'
import { FEATURE_NAMES } from '../../../../split-io/feature-names'
import { RutterPlatformCard } from './RutterPlatformCard.component'
import { VoteForPlatformCard } from './VoteForPlatformCard.component'
import { RUTTER_SUPPORTED_PLATFORMS } from '../constants/rutterSupportedPlatforms.const'

export function ConnectNewSalesChannel({ className = '' }: { className?: string }) {
  // TODO: remove split, option logic and footer logic
  const { splitIsOn: isPlatformVoteSplitOn } = useSplitToggle({ toggle: FEATURE_NAMES.SALES_CHANNEL_VOTE_FOR_PLATFORM })
  const amazonSplitData = useSplitTreatment(FEATURE_NAMES.SALES_CHANNEL_AMAZON)

  let options = RUTTER_SUPPORTED_PLATFORMS
  let voteOptions = VOTE_FOR_PLATFORMS

  if (!amazonSplitData.isLoadingSplit && amazonSplitData.isTreatmentOn) {
    voteOptions = voteOptions.filter((option) => option.name !== VoteForPlatformEnum.Amazon)
  } else {
    options = options.filter((option) => option.name !== RutterPlatformEnum.Amazon)
  }

  return (
    <section className={className}>
      <h2>Connect a new channel</h2>
      <ErrorBoundary
        fallbackRender={({ error }) => (
          <div className="mt-4 text-red-500">
            <div>
              An error occurred while displaying new channels. Please try again later or{' '}
              <SupportLink className="text-inherit underline">contact support</SupportLink> if the issue persists.
            </div>
            <div className="text-sm">({error.message})</div>
          </div>
        )}
      >
        <p className="mt-6">
          {!isPlatformVoteSplitOn && 'We currently integrate with the following ecommerce platforms.'}
          Can&apos;t see your platform? <SupportLink>Let us know</SupportLink>
        </p>

        <ul className="mt-8 grid gap-y-6 lg:grid-cols-3 lg:gap-x-6">
          {options.map((platform) => (
            <RutterPlatformCard key={platform.name} platform={platform} />
          ))}
          {isPlatformVoteSplitOn &&
            voteOptions.map((platform) => <VoteForPlatformCard key={platform.name} platform={platform} />)}
        </ul>
      </ErrorBoundary>
    </section>
  )
}
