// @flow
import { selectProdigiApiEndpoint } from '../../selectors/appSettings'
import { RSAA } from 'redux-api-middleware'
import { LOG_STATUS } from '../../data/rsaa'
import type { Quote, DispatchFunc, GetStateFunc, QuoteRequestItem, QuoteEndpointResponse, Thunk } from '../../types'
import { selectCurrencyCode } from '../../selectors/user'
import { V4_API_ACTION_OUTCOME } from '../../data/v4ApiActionOutcome'
import type { InsertDataType } from '../../types/branding'

export const GET_QUOTES = 'GET_QUOTES'
export const GET_QUOTES_SUCCESS = 'GET_QUOTES_SUCCESS'
export const GET_QUOTES_ERROR = 'GET_QUOTES_ERROR'
export const GET_MOF_QUOTES_REQUEST_ID = 'MOF'

export function getQuotes(
  requestId: string,
  items: QuoteRequestItem[],
  destinationCountryCode: string,
  brandedInserts?: InsertDataType[] = []
): Thunk<*> {
  return async (dispatch: DispatchFunc, getState: GetStateFunc) => {
    const currencyCode = selectCurrencyCode(getState())

    const actionResult = await dispatch(
      getQuotesRsaa(requestId, items, destinationCountryCode, currencyCode, brandedInserts)
    )

    if (actionResult.payload.outcome === V4_API_ACTION_OUTCOME.NOT_AVAILABLE) {
      const skus = items.map(({ sku }) => sku)
      return dispatch({
        type: GET_QUOTES_ERROR,
        payload: { skus },
        message: V4_API_ACTION_OUTCOME.NOT_AVAILABLE,
        meta: {
          requestId,
          loggerId: requestId,
          skus
        }
      })
    }

    return actionResult
  }
}

function getQuotesRsaa(
  requestId: string,
  items: QuoteRequestItem[],
  destinationCountryCode: string,
  currencyCode: string,
  brandedInserts?: InsertDataType[] = []
) {
  const endpoint = selectProdigiApiEndpoint() + '/v4.0/quotes'

  const insertIds = brandedInserts?.map(({ id }) => id)

  return {
    [RSAA]: {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      endpoint,
      body: JSON.stringify({
        branding: insertIds?.length > 0 ? { inserts: insertIds } : undefined,
        items,
        destinationCountryCode,
        currencyCode
      }),
      types: [
        {
          type: GET_QUOTES,
          meta: {
            enhancements: [LOG_STATUS],
            loggerId: requestId,
            requestId
          }
        },
        {
          type: GET_QUOTES_SUCCESS,
          meta: { requestId },
          payload: async (_, _2, res) => {
            const jsonResponse = await res.json()
            return mapQuoteResponse(jsonResponse)
          }
        },
        {
          type: GET_QUOTES_ERROR,
          meta: {
            requestId,
            skus: items.map(({ sku }) => sku)
          }
        }
      ]
    }
  }
}

function mapQuoteResponse(response: QuoteEndpointResponse): QuoteEndpointResponse {
  if (response.outcome !== V4_API_ACTION_OUTCOME.CREATED && response.outcome !== V4_API_ACTION_OUTCOME.ON_HOLD) {
    return response
  }

  return {
    ...response,
    quotes: response.quotes.map(convertQuoteItemSkusToUpperCase)
  }
}

function convertQuoteItemSkusToUpperCase(quote: Quote): Quote {
  const mappedQuoteItems = quote.items.map((quoteItem) => ({
    ...quoteItem,
    sku: quoteItem.sku.toUpperCase()
  }))

  return {
    ...quote,
    items: mappedQuoteItems
  }
}
