import toast from 'react-hot-toast'
import { useMemo, useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'
import { useHistory, useLocation } from 'react-router-dom'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { PencilSquareIcon, NoSymbolIcon } from '@heroicons/react/24/outline'

import Skeleton from '../Skeleton'
import { createErrorToast } from '../Toast'
import { QUERY_PARAMS } from '../../constants'
import { cn, formatCost } from '../../helpers'
import { InsertDataType } from '../../interfaces'
import ImageLibraryModal from '../ImageLibraryModal'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../PackingSlip'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../DropdownMenu'
import { BrandingDetailsType, ImageLibraryImageInterface, useImageLibraryThumbnailGeneration } from '../../hooks'

type InsertOptionValueType = 'change-image' | 'remove-image'

export const BRANDED_INSERT_ERROR_TOAST_ID = 'prodigiBrandedInsertImageErrorToast'

export function BrandedInsertItem({
  brandingDetail,
  insertData,
  onChangeInsertData
}: {
  brandingDetail: BrandingDetailsType
  insertData?: InsertDataType
  onChangeInsertData: (newInsertData: Omit<InsertDataType, 'id'>) => void
}) {
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  const history = useHistory()
  const { search } = useLocation()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  function handleSelectImage(imageLibraryImage: ImageLibraryImageInterface) {
    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)

    if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
      handleOpenImageLibraryChange(false)
      createErrorToast({
        errorMessage: `${
          imageLibraryImage.file_type
        } is not supported for inserts. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
          ', '
        )}`,
        heading: 'Failed to update insert',
        id: BRANDED_INSERT_ERROR_TOAST_ID
      })
      return
    }

    onChangeInsertData({
      ...insertData,
      imageLibraryId: imageLibraryImage.public_id,
      imageUrl: imageLibraryImage.url
    })
    handleOpenImageLibraryChange(false)
  }

  function handleDeleteImage() {
    onChangeInsertData({ ...insertData, imageLibraryId: undefined, imageUrl: undefined })
  }

  function handleOpenImageLibraryChange(open: boolean) {
    const newSearchParams = new URLSearchParams(searchParams)

    if (open) {
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
      newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
    } else {
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
    }

    toast.dismiss(BRANDED_INSERT_ERROR_TOAST_ID)
    history.replace({ search: newSearchParams.toString() })
    setIsImageLibraryModalOpen(open)
  }

  function handleSelectInsertOption(value: InsertOptionValueType) {
    if (value === 'change-image') {
      handleOpenImageLibraryChange(true)
    } else if (value === 'remove-image') {
      handleDeleteImage()
    }
  }

  return (
    <div className="mb-4 flex gap-6 bg-gray-50 p-8 lg:gap-8 lg:p-10">
      <AnimatePresence initial={false}>
        <motion.div
          key={`${insertData?.imageLibraryId ?? ''}-${insertData?.imageUrl ?? ''}`}
          className="flex flex-shrink-0 items-start justify-start"
          initial={{ opacity: 0, scale: 0.5, filter: 'blur(4px)' }}
          animate={{ opacity: 1, scale: 1, filter: 'blur(0px)' }}
          transition={{ type: 'spring', bounce: 0, duration: 0.5 }}
        >
          <InsertImage
            key={`${insertData?.imageLibraryId ?? ''}-${insertData?.imageUrl ?? ''}`}
            insertData={insertData}
          />
        </motion.div>
      </AnimatePresence>

      <div className="flex-1">
        <div className="font-medium md:text-lg">{brandingDetail.name}</div>
        <div className="text-sm text-gray-600 md:text-base" style={{ wordBreak: 'break-word' }}>
          {brandingDetail.description}
        </div>
      </div>

      <div className="flex justify-center">
        <div className="flex pt-2">
          {formatCost({ amount: brandingDetail.cost.amount.toString(), currencyCode: brandingDetail.cost.currency })}
        </div>
      </div>

      <div>
        <DropdownMenu>
          <DropdownMenuTrigger className="aspect-square border-gray-300">
            <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
            <span className="sr-only">Options</span>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end">
            <DropdownMenuItem
              className="text-purple-600"
              onSelect={() => {
                handleSelectInsertOption('change-image')
              }}
            >
              <PencilSquareIcon className="text-inherit h-6 w-6 " />
              Change image
            </DropdownMenuItem>

            <DropdownMenuItem
              className="text-magenta-700"
              onSelect={() => {
                handleSelectInsertOption('remove-image')
              }}
            >
              <NoSymbolIcon className="text-inherit h-6 w-6" />
              Remove
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <ImageLibraryModal
        config={{ forceSaveToLibrary: true }}
        open={isImageLibraryModalOpen}
        type="packing"
        setOpen={handleOpenImageLibraryChange}
        onSelectImage={handleSelectImage}
      />
    </div>
  )
}

function InsertImage({ insertData }: { insertData?: InsertDataType }) {
  if (insertData?.imageLibraryId) {
    return <InsertImageWithId insertData={insertData} imageLibraryId={insertData.imageLibraryId} />
  }

  return <InsertImageRender insertData={insertData} src={insertData?.imageUrl} />
}

function InsertImageWithId({ imageLibraryId, insertData }: { imageLibraryId: string; insertData?: InsertDataType }) {
  const { imageLibraryImage, imageLibraryImageFetchError, isLoadingImageLibraryImage } =
    useImageLibraryThumbnailGeneration(imageLibraryId, {
      touch: false,
      config: { revalidateOnFocus: false }
    })

  if (imageLibraryImageFetchError) {
    return (
      <div className="h-[75px] w-[75px] overflow-auto text-center text-sm text-red-500">
        Error {imageLibraryImageFetchError?.status ?? '0'} loading image
      </div>
    )
  }

  if (isLoadingImageLibraryImage) {
    return <Skeleton className="h-[75px] w-[75px]"></Skeleton>
  }

  return <InsertImageRender insertData={insertData} src={imageLibraryImage?.thumbnail_urls.small} />
}

function InsertImageRender({ insertData, src }: { insertData?: InsertDataType; src?: string }) {
  const isRoundInsert = insertData?.id.toLowerCase().includes('round')

  return (
    <div className="flex h-[75px] w-[75px] justify-center">
      <img
        className={cn('h-[75px]', isRoundInsert ? 'w-[75px] rounded-full object-cover' : 'w-[53px] object-contain')}
        src={src}
        alt={insertData?.id.replaceAll('_', ' ')}
      />
    </div>
  )
}
