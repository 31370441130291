import ImageCarousel from '../../ImageCarousel'
import { Variants } from './Variants.component'
import { ProductBreadcrumbs, ProductDescription } from '.'
import { ProductDetailTabs } from './ProductDetailTabs.component'
import { ProductCatalogueProductInterface } from '../../../hooks'

export function ProductDetailsLoaded({ productData }: { productData: ProductCatalogueProductInterface }) {
  return (
    <>
      <div className="flex h-full flex-col justify-between gap-2 lg:flex-row lg:gap-6">
        <div className="lg:w-3/5 xl:w-1/2">
          <ProductBreadcrumbs productData={productData} />
          <ProductDescription productData={productData} />
        </div>

        <div className="grid content-start justify-center lg:-mt-10 lg:justify-end">
          {productData.images.length > 1 ? (
            <div className="mx-auto">
              <ImageCarousel
                className="h-[400px] max-w-[400px] xl:h-[600px] xl:max-w-[600px]"
                images={productData.images}
                options={{ loop: true }}
              />
            </div>
          ) : (
            <img
              className="h-[400px] max-w-full object-contain object-center xl:h-[600px] xl:w-[600px]"
              alt={productData.name}
              src={productData.heroImage}
            />
          )}
        </div>
      </div>

      <Variants productData={productData} />

      <ProductDetailTabs productData={productData} />
    </>
  )
}
