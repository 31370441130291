import { AnimatePresence, motion } from 'motion/react'

import { OrderDetailItemInterface } from '../../interfaces'
import { IgnoredLineItemHeader, LineItemCostData, LineItemHeader, LineItemProductData } from './components'

export default function OrderEditLineItem({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  return (
    <div>
      {lineItem.ignore ? <IgnoredLineItemHeader lineItem={lineItem} /> : <LineItemHeader lineItem={lineItem} />}

      <AnimatePresence initial={false}>
        {!lineItem.ignore && (
          <motion.div
            key={lineItem.id + Boolean(lineItem.ignore).toString()}
            initial={{ opacity: 0, height: 0 }}
            animate={{ opacity: 1, height: 'auto' }}
            exit={{ opacity: 0, height: 0 }}
          >
            <div className="border border-t-0 bg-white p-6 pb-10">
              <table className="block w-full md:table" data-test="shipment-display__items">
                <thead className="hidden text-sm md:table-header-group">
                  <tr>
                    <th></th>
                    <th className="md:w-[550px]"></th>
                    <th className="px-2 pb-4 text-right">Cost</th>
                    <th className="px-2 pb-4 text-right">Qty</th>
                    <th className="px-2 pb-4 text-right">Total</th>
                  </tr>
                </thead>

                <tbody className="block space-y-8 md:table-row-group">
                  <tr className="grid grid-cols-[min-content_min-content_auto] sm:grid-cols-[max-content_min-content_min-content_auto] md:table-row">
                    <LineItemProductData lineItem={lineItem} />
                    <LineItemCostData lineItem={lineItem} />
                  </tr>
                </tbody>
              </table>
            </div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
