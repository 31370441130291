import { HEADER_NAMES } from '../constants'

export function getDisplayHeaderForVariant({ name, parentSlug }: { name: string; parentSlug: string }) {
  if (
    name === HEADER_NAMES.PAPER &&
    ['wall-art/canvas/framed-canvas', 'wall-art/canvas/rolled-canvas', 'wall-art/canvas/stretched-canvas'].includes(
      parentSlug
    )
  ) {
    return 'Substrate'
  }

  return name
}
