import { getNoImageProductData } from '../../../helpers'
import { SelectedProductInterface } from '../../SelectProduct'
import { ProductDetailSuccessResponseInterface } from '../../../hooks'
import { AddLineItemEditorDataType } from '../OrderEditAddLineItem.component'

export function formatNoImageProductLineItemData({
  newSelectedProduct,
  productDetails,
  assetRequirementData
}: {
  newSelectedProduct: SelectedProductInterface
  productDetails: ProductDetailSuccessResponseInterface
  assetRequirementData: Record<string, { required: boolean }>
}) {
  const noImageProductData = getNoImageProductData({
    attributes: newSelectedProduct.selectedAttributes ?? {},
    productType: newSelectedProduct.searchResultData.productType,
    sku: newSelectedProduct.sku
  })
  const printAreas = Object.keys(productDetails.product.printAreas)
  const firstAvailablePrintArea = printAreas[0]

  const costInPounds = newSelectedProduct.searchResultData.basePriceFrom / 100
  const price = Number(costInPounds).toLocaleString('en-US', {
    minimumFractionDigits: 2,
    useGrouping: true,
    currency: newSelectedProduct.searchResultData.currency
  })

  const newLineItemData: AddLineItemEditorDataType = {
    assetRequirementData,
    artworkData: {
      [firstAvailablePrintArea]: {
        artwork: undefined,
        artworkTransformations: undefined,
        previewImageUrl: noImageProductData?.labImage,
        printImageUrl: noImageProductData?.labImage,
        transformImageUrl: noImageProductData?.labImage
      }
    },
    selectedPrintArea: firstAvailablePrintArea,
    selectedProductData: {
      attributes: newSelectedProduct.selectedAttributes,
      metaData: newSelectedProduct.selectedMetaData,
      sku: newSelectedProduct.sku,
      productType: newSelectedProduct.searchResultData.productType,
      price
    },
    printAreas: productDetails.product.printAreas
  }

  return newLineItemData
}
