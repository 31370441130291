import { useContext } from 'react'
import { SplitContext, useTreatments } from '@splitsoftware/splitio-react'

/**
 * @deprecated useSplitToggle is deprecated. Please use useSplitTreatment instead.
 */
export function useSplitToggle({
  toggle,
  merchantId = '',
  attributes = {}
}: {
  toggle: string
  merchantId?: string
  attributes?: Record<string, string>
}) {
  const { isReady, hasTimedout } = useContext(SplitContext)
  const treatments = useTreatments([toggle], {
    merchantId,
    ...attributes
  })

  const { treatment, config } = treatments[toggle] ?? 'false'

  return {
    splitIsOn: isReady && treatment === 'on',
    splitIsLoading: !hasTimedout && !isReady,
    config
  }
}
