import { useEffect } from 'react'

import ProductLibraryDetail from '../../components/ProductLibraryDetail'
import { DEFAULT_DOCUMENT_TITLE, ANALYTICS_PAGE_CATEGORIES } from '../../constants'

export default function ProductLibraryDetailPage() {
  useEffect(() => {
    document.title = 'Product Detail | Prodigi'
    return () => {
      document.title = DEFAULT_DOCUMENT_TITLE
    }
  }, [])

  useEffect(() => {
    window.analytics.page(ANALYTICS_PAGE_CATEGORIES.PRODUCT_LIBRARY, 'Product Library Detail')
  }, [])

  return (
    <main className="tailwind">
      <ProductLibraryDetail />
    </main>
  )
}
