import { ImageLibraryFileType } from '../hooks'

const PRODUCT_FILES_COMPATIBILITY_DATA: Array<{
  productType: string
  fileTypesAllowed: ImageLibraryFileType[]
  metaDataType?: 'photobook'
}> = [
  { productType: 'Hardcover Books', fileTypesAllowed: ['pdf (multi-page)'], metaDataType: 'photobook' },
  { productType: 'Softcover Books', fileTypesAllowed: ['pdf (multi-page)'], metaDataType: 'photobook' },
  { productType: 'Layflat Books', fileTypesAllowed: ['pdf (multi-page)'], metaDataType: 'photobook' },
  { productType: 'Full-Layout Calendars', fileTypesAllowed: ['pdf (multi-page)'] },
  { productType: 'Image-Only Calendars', fileTypesAllowed: ['png', 'jpg', 'pdf (single page)'] }
]

export function getProductFileCompatibilityData({ productType }: { productType: string }) {
  const productFileCompatibilityData = PRODUCT_FILES_COMPATIBILITY_DATA.find(
    (productFileData) => productFileData.productType.toUpperCase() === productType?.toUpperCase()
  )
  return productFileCompatibilityData
}
