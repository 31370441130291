export enum RutterPlatformEnum {
  Etsy = 'Etsy',
  WooCommerce = 'WooCommerce',
  Shopify = 'Shopify',
  Wix = 'Wix',
  Squarespace = 'Squarespace',
  AdobeCommerce = 'Magento',
  BigCommerce = 'BigCommerce',
  Amazon = 'Amazon'
}
