import { useEffect } from 'react'
import { motion } from 'motion/react'
import { useParams } from 'react-router-dom'

import {
  OrderEditSummary,
  OrderEditPackingSlip,
  OrderEditShippingSelector,
  OrderEditCostSummary
} from '../OrderEditDisplay/components'
import { ROUTE_PATHS } from '../../constants'
import { OrderPausedWindow } from './components'
import Banner, { BannerTypeEnum } from '../Banner'
import OrderEditLineItem from '../OrderEditLineItem'
import OrderDetailHeader from '../OrderDetailHeader'
import { OrderDetailCSIssueInterface } from '../../interfaces'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import OrderEditAddLineItem from '../OrderEditDisplayAddLineItem'
import { useLogToInsightOps } from '../../hooks/useLogToInsightOps'
import { OrderDetailIssueStatusEnum, OrderDetailIssueTypeEnum } from '../../enums'
import { OrderDetailAddress, OrderDetailOrderHistory } from '../OrderDetail/components'
import { useOrderActions, useOrderDetail, useSplitToggle, useSplitTreatment, useUser } from '../../hooks'
import { OrderEditBrandedInserts } from '../OrderEditDisplay/components/OrderEditBrandedInserts.component'

export default function OrderPausedDisplay() {
  const { user } = useUser()
  const { logToInsightOps } = useLogToInsightOps()
  const { id: orderId } = useParams<{ id: string }>()
  const { orderDetailsResponse, mutateOrderDetails } = useOrderDetail(orderId)
  const { orderActions, orderActionsFetchError, mutateOrderActions } = useOrderActions(orderId)
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })
  const addDraftLineItemTreatment = useSplitTreatment(FEATURE_NAMES.ADD_DRAFT_ORDER_ITEM)

  const orderDetails = orderDetailsResponse?.data.order

  useEffect(() => {
    if (orderActionsFetchError && orderId) {
      logToInsightOps('Failed to fetch order actions', {
        props: {
          orderId,
          traceparent: orderActionsFetchError.responseBodyJson?.traceParent
        },
        level: 'error'
      })
    }
  }, [logToInsightOps, orderActionsFetchError, orderId])

  if (!orderDetails) {
    throw Error('No order details')
  }

  function handleOrderDetailMutate() {
    mutateOrderDetails()
    mutateOrderActions()
  }

  const unresolvedIssues = orderDetails.issues
    .filter(
      (issue) =>
        issue.type === OrderDetailIssueTypeEnum.FulfillmentIssue &&
        Boolean(
          (issue.data as OrderDetailCSIssueInterface)?.status === OrderDetailIssueStatusEnum.InProgress ||
            (issue.data as OrderDetailCSIssueInterface)?.status === OrderDetailIssueStatusEnum.Open
        )
    )
    .map((issue) => (issue.data as OrderDetailCSIssueInterface)?.issueType)

  const cancellationIsAvailable = Boolean(orderActions?.data.cancel.isAvailable)
  const canUpdateAddress = Boolean(orderActions?.data.updateRecipient.isAvailable)

  const userHasToAddBillingDetails = !user?.billing.hasPaymentSetup && user?.billing.invoiceFrequency === 'PerOrder'

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="container mx-auto min-h-screen space-y-4 pb-36 pt-8 xl:space-y-8"
    >
      <OrderDetailHeader
        cancellationIsAvailable={cancellationIsAvailable}
        createdDate={orderDetails.createdDate}
        orderId={orderDetails.id}
        recipientName={orderDetails.recipient.name}
        status={orderDetails.status}
        unresolvedIssues={unresolvedIssues}
        mutateOrderDetails={handleOrderDetailMutate}
      />

      {userHasToAddBillingDetails && (
        <Banner
          type={BannerTypeEnum.Error}
          buttonLink={ROUTE_PATHS.SETTINGS.BILLING}
          buttonText="Add billing details"
          message="You will need to set up billing details before you can submit this order."
        />
      )}

      <div className="grid grid-cols-1 gap-4 lg:grid-cols-3 xl:gap-8">
        <div className="row-span-full space-y-4 lg:col-span-2 lg:row-auto xl:space-y-8">
          {orderDetails.items.map((lineItem) => (
            <OrderEditLineItem key={lineItem.id} lineItem={lineItem} />
          ))}
          {!addDraftLineItemTreatment.isLoadingSplit && addDraftLineItemTreatment.isTreatmentOn && (
            <OrderEditAddLineItem />
          )}
        </div>

        <div className="space-y-4 lg:row-span-4 xl:space-y-8">
          <OrderPausedWindow />
          <OrderEditSummary />
          <OrderDetailAddress
            mutateOrderDetails={mutateOrderDetails}
            orderId={orderDetails.id}
            orderRecipient={orderDetails.recipient}
            showEditButton={canUpdateAddress}
          />
          <OrderEditShippingSelector />
          {isBrandingSettingsOn ? <OrderEditBrandedInserts /> : <OrderEditPackingSlip />}
        </div>

        <div className="lg:col-span-2">
          <OrderEditCostSummary />
        </div>

        <div className="lg:col-span-2">
          <OrderDetailOrderHistory history={orderDetails.history ?? []} />
        </div>
      </div>
    </motion.div>
  )
}
