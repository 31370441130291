import clsx from 'clsx'

import {
  ArtworkInterface,
  ArtworkTransformationsInterface,
  BorderUnitType,
  DimensionsInterface,
  ImageEditorTransformationsInterface,
  PointInterface
} from '../interfaces'
import { ToggleGroup } from './ToggleGroup.component'
import { PrintAreasPanel } from './PrintAreasPanel.component'
import { RadioGroup, RadioGroupItem } from '../../RadioGroup'
import ImageEditorControls from './ImageEditorControls.component'
import { V1DashboardArtworkInterface } from '../../../interfaces'
import { AlignmentPointType } from '../interfaces/AlignmentPoint.type'
import { RecommendedResolution } from './RecommendedResolution.component'
import ImageEditorBorderControl from './ImageEditorBorderControl.component'
import ImageEditorAlignmentTools from './ImageEditorAlignmentTools.component'
import { TemplateOrientationType, TemplateServiceSuccessResponseInterface } from '../../../hooks'

export interface EditorControlPanelPropsInterface {
  artwork?: ArtworkInterface
  artworkUploadStatus: 'LOADING' | 'SUCCESS' | 'ERROR' | 'IDLE'
  printDimensionsInPx: DimensionsInterface
  artworkDimensionsInPx: DimensionsInterface
  printDpi: number
  productName: string
  imageLibraryConfig?: {
    forceSaveToLibrary?: boolean
  }
  itemCategory: string
  productType: string
  displayedPosition: PointInterface
  displayedPositionUnit: BorderUnitType
  displayedScaleFactor: number
  imageQuality?: number | null
  hasArtwork: boolean
  transformations: ImageEditorTransformationsInterface
  showBorderControlPanel: boolean
  areImagesLoading: boolean
  showTemplateOrientationToggle: boolean
  templateOrientation: TemplateOrientationType
  printAreaArtworkEntries: [
    string,
    { artwork?: ArtworkInterface; artworkTransformations?: ArtworkTransformationsInterface }
  ][]
  supportsTiling: boolean
  selectedPrintArea: string
  templates: TemplateServiceSuccessResponseInterface
  onToggleTiling: (isTilingOn: boolean) => void
  onChangeTemplateOrientation: (newOrientation: 'portrait' | 'landscape') => void
  onSelectImageLibraryImage: (artworkData: {
    artwork: V1DashboardArtworkInterface
    artworkTransformations: ArtworkTransformationsInterface
    selectedPrintArea: string
  }) => void
  onRemoveArtwork: (printAreaName: string) => void
  onChangePrintArea: (printAreaName: string) => void
  onAlignmentPointChange: (event: CustomEvent<AlignmentPointType>) => void
  onFit: (event: CustomEvent<void>) => void
  onFill: (event: CustomEvent<void>) => void
  onBorderChange: (event: CustomEvent<number>) => void
  onPositionChange: (event: CustomEvent<PointInterface>) => void
  onRotationAngleChange: (event: CustomEvent<number>) => void
  onScaleChange: (event: CustomEvent<number>) => void
  onPositionUnitChange: (newUnit: BorderUnitType) => void
  onTogglePreviewMode: (isPreviewOnly: boolean) => void
}

export function EditorControlPanel(props: EditorControlPanelPropsInterface) {
  return (
    <>
      {props.printAreaArtworkEntries.length > 1 && (
        <>
          <p className="text-xs font-medium uppercase text-white">SIDE</p>
          <PrintAreasPanel
            imageLibraryConfig={props.imageLibraryConfig}
            itemCategory={props.itemCategory}
            productType={props.productType}
            printAreaArtworkEntries={props.printAreaArtworkEntries}
            selectedPrintArea={props.selectedPrintArea}
            templates={props.templates}
            onChangePrintArea={props.onChangePrintArea}
            onRemoveArtwork={props.onRemoveArtwork}
            onSelectImageLibraryImage={props.onSelectImageLibraryImage}
            onTogglePreviewMode={props.onTogglePreviewMode}
          />
          <Divider />
        </>
      )}

      <ImageEditorControls
        scaleFactor={props.displayedScaleFactor}
        position={props.displayedPosition}
        positionUnit={props.displayedPositionUnit}
        rotationAngle={props.transformations.rotationAngle}
        onPositionChange={props.onPositionChange}
        onRotationAngleChange={props.onRotationAngleChange}
        onScaleChange={props.onScaleChange}
        onPositionUnitChange={({ detail }) => props.onPositionUnitChange(detail)}
      />

      {props.imageQuality && (
        <>
          <Divider />
          <RecommendedResolution
            supportsTiling={props.supportsTiling}
            printDpi={props.printDpi}
            imageQuality={props.imageQuality}
            printDimensionsInPx={props.printDimensionsInPx}
            artworkDimensionsInPx={props.artworkDimensionsInPx}
          />
        </>
      )}

      <Divider />

      <div className={clsx({ 'pointer-events-none': props.areImagesLoading })}>
        <ImageEditorAlignmentTools
          onAlignmentPointChange={props.onAlignmentPointChange}
          onFit={props.onFit}
          onFill={props.onFill}
        />
      </div>

      {props.showBorderControlPanel && (
        <>
          <Divider />
          <ImageEditorBorderControl
            printDpi={props.printDpi}
            printDimensionsInPx={props.printDimensionsInPx}
            borderWidth={props.transformations.borderWidth}
            borderUnit={props.transformations.borderUnit}
            onBorderChange={props.onBorderChange}
          />
        </>
      )}

      {props.showTemplateOrientationToggle && props.hasArtwork && (
        <>
          <Divider />
          <p className="mb-4 text-xs font-medium uppercase text-white">Orientation</p>

          <RadioGroup
            className="flex max-w-[320px] flex-row flex-wrap gap-8"
            disabled={props.areImagesLoading}
            orientation="horizontal"
            value={props.templateOrientation}
            onValueChange={(value) => {
              const noChangeInOrientation = value === props.templateOrientation
              if (noChangeInOrientation) {
                return
              }

              if (['landscape', 'portrait'].includes(value)) {
                props.onChangeTemplateOrientation(value as 'landscape' | 'portrait')
              }
            }}
          >
            {[
              { value: 'portrait', label: 'Portrait' },
              { value: 'landscape', label: 'Landscape' }
            ].map((item) => (
              <div key={item.value} className="flex items-center gap-3">
                <RadioGroupItem
                  className="focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500 data-[state=checked]:border-gray-600 data-[state=checked]:bg-gray-600"
                  value={item.value}
                  id={item.value}
                />
                <label className="text-gray-300" htmlFor={item.value}>
                  {item.label}
                </label>
              </div>
            ))}
          </RadioGroup>
        </>
      )}

      {Boolean(props.supportsTiling && props.hasArtwork) && (
        <div>
          <Divider />
          <p className="mb-4 text-xs font-medium uppercase text-white">Tiling</p>
          <RadioGroup
            className="flex max-w-[320px] flex-row flex-wrap gap-8"
            disabled={props.areImagesLoading}
            orientation="horizontal"
            value={props.transformations.isTiled ? 'on' : 'off'}
            onValueChange={(value) => {
              const newIsTiledValue = value === 'on'
              const noChangeInTiling = newIsTiledValue === props.transformations.isTiled
              if (noChangeInTiling) {
                return
              }

              props.onToggleTiling(newIsTiledValue)
            }}
          >
            {[
              { value: 'on', label: 'On' },
              { value: 'off', label: 'Off' }
            ].map((item) => (
              <div key={item.value} className="flex items-center gap-3">
                <RadioGroupItem
                  className="focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-purple-500 data-[state=checked]:border-gray-600 data-[state=checked]:bg-gray-600"
                  value={item.value}
                  id={item.value}
                />
                <label className="text-gray-300" htmlFor={item.value}>
                  {item.label}
                </label>
              </div>
            ))}
          </RadioGroup>
        </div>
      )}

      {props.hasArtwork && (
        <div>
          <Divider />
          <ToggleGroup
            className="max-w-[100px]"
            layoutId={'unit-' + props.selectedPrintArea}
            theme="muted"
            value={props.displayedPositionUnit}
            viewTabs={[
              { id: 'cm', label: 'Cm' },
              { id: 'in', label: 'In' }
            ]}
            onChange={(value) => {
              const noChangeInUnit = value === props.displayedPositionUnit
              if (noChangeInUnit) {
                return
              }

              if (['cm', 'in'].includes(value)) {
                props.onPositionUnitChange(value as BorderUnitType)
              }
            }}
          />
        </div>
      )}
    </>
  )
}

export function Divider() {
  return <div className="my-10 h-[1px] w-full bg-gray-200"></div>
}
