import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'
import { EllipsisHorizontalIcon, SquaresPlusIcon } from '@heroicons/react/20/solid'
import { NoSymbolIcon, CheckCircleIcon, ExclamationCircleIcon } from '@heroicons/react/24/outline'

import {
  FetchErrorInterface,
  OMSErrorResponseInterface,
  OrderDetailItemInterface,
  StatusType
} from '../../../interfaces'
import { formatToSentenceCase } from '../../../helpers'
import { SalesChannelPlatformEnum } from '../../../enums'
import { createErrorToast, createToast } from '../../Toast'
import OverlayLoadingSpinner from '../../OverlayLoadingSpinner'
import { IgnoreProductModal } from './IgnoreProductModal.component'
import { useMerchantService, useOrderDetail } from '../../../hooks'
import { LINE_ITEM_TOASTS } from '../constants/lineItemToasts.const'
import { mapLineItemToVariantData, saveLineItemVariantData } from '../helpers'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../DropdownMenu'

type LineItemOptionValueType = 'remove' | 'save'

export function LineItemHeader({ lineItem }: { lineItem: OrderDetailItemInterface }) {
  const { id: orderId } = useParams<{ id: string }>()
  const { merchantDetails } = useMerchantService()
  const { orderDetailsResponse } = useOrderDetail(orderId)

  const [isIgnoreModalOpen, setIsIgnoreModalOpen] = useState(false)
  const [saveStatus, setSaveStatus] = useState<StatusType>('idle')

  const orderDetails = orderDetailsResponse?.data.order
  if (!orderDetails) {
    throw Error('No order details')
  }

  if (!merchantDetails) {
    throw Error('No merchant details')
  }

  const salesChannel = merchantDetails.salesChannels.find((channel) => channel.id === orderDetails.salesChannelId)

  const hasProduct = Boolean(lineItem.prodigiSku)
  const hasAllRequiredImages = lineItem.assets.every((lineItemAsset) =>
    lineItemAsset.required ? Boolean(lineItemAsset.assetUrl && lineItemAsset.assetUrl !== '') : true
  )
  const hasAnyPrintImage = lineItem.assets.some((lineItemAsset) => Boolean(lineItemAsset.assetUrl))

  const requiredAssetsWithoutArtwork = lineItem.assets.filter((asset) => {
    if (!asset?.required) {
      return false
    }
    const noArtworkForPrintArea = !asset.assetUrl
    return noArtworkForPrintArea
  })

  const lineItemCostData = orderDetailsResponse?.data.draftCosts?.items[lineItem.id]
  const price = lineItemCostData?.itemCost.amount

  async function handleSave() {
    if (!salesChannel) {
      return
    }

    setSaveStatus('loading')
    toast.dismiss(LINE_ITEM_TOASTS.ERROR)

    try {
      const newVariantData = mapLineItemToVariantData(lineItem, price)
      await saveLineItemVariantData({
        merchantId: merchantDetails?.id ?? null,
        newVariantData,
        productId: lineItem.salesChannelProductDetails.productId.toString(),
        salesChannelId: salesChannel.id
      })
      createSaveSuccessToast({ hasAllRequiredImages, hasAnyPrintImage, hasProduct })
      setSaveStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${lineItem.id}-${errorResponse.responseBodyJson?.traceParent ?? '0'}-${
        errorResponse.status ?? '0'
      }`
      createErrorToast({ id: LINE_ITEM_TOASTS.ERROR, errorCode, heading: 'Failed to update' })
      setSaveStatus('error')
    }
  }

  async function handleSelectedOption(value: LineItemOptionValueType) {
    if (value === 'remove') {
      setIsIgnoreModalOpen(true)
    }

    if (value === 'save') {
      await handleSave()
    }
  }

  const requiredDetails = []

  const lineItemsToBeFulfilled = orderDetails?.items.filter((item) => !item.ignore) ?? []
  const isLastLineItemToBeFulfilled = lineItemsToBeFulfilled.length === 1

  if (!hasProduct) {
    requiredDetails.push('Product')
  }

  if (!hasAllRequiredImages) {
    const pendingPrintAreaNames = requiredAssetsWithoutArtwork.map((asset) => asset.printAreaName)
    requiredDetails.push(
      lineItem.assets.length > 1 ? `Print image for ${pendingPrintAreaNames.join(', ')}` : 'Print image'
    )
  }

  const isSalesChannelOrder = Boolean(salesChannel && salesChannel.platform !== SalesChannelPlatformEnum.Prodigi)
  const isSalesChannelLineItem = Boolean(
    lineItem.salesChannelProductDetails.productId !== null && lineItem.salesChannelProductDetails.variantId !== null
  )

  return (
    <div
      className="flex items-center justify-between border border-gray-200 bg-white p-4"
      data-test={`line-item-header-${lineItem.id}`}
    >
      <div className="flex items-center gap-2 text-sm md:text-base">
        {requiredDetails.length === 0 ? (
          <>
            <CheckCircleIcon className="h-9 w-9 flex-shrink-0 text-green-700" />
            <strong className="text-green-800">OK</strong>
          </>
        ) : (
          <>
            <ExclamationCircleIcon className="h-9 w-9 flex-shrink-0 text-cyan-700" />
            <div>
              <strong className="text-cyan-700">Required: </strong>
              <span>{formatToSentenceCase(requiredDetails.join(', '))}</span>
            </div>
          </>
        )}
      </div>

      <div>
        <DropdownMenu>
          <DropdownMenuTrigger className="aspect-square border-gray-300" data-test="line-item-header__trigger">
            <EllipsisHorizontalIcon className="h-6 w-6 text-gray-500" />
            <span className="sr-only">Options</span>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="end" data-test="line-item-header__options">
            {isSalesChannelOrder && isSalesChannelLineItem && (
              <DropdownMenuItem
                data-test-value="line-item-header__option-save"
                disabled={!hasProduct}
                onSelect={() => {
                  handleSelectedOption('save')
                }}
              >
                <SquaresPlusIcon className="mr-2 h-6 w-6 text-gray-600" aria-hidden="true" />
                Save this for future orders
              </DropdownMenuItem>
            )}
            <DropdownMenuItem
              data-test-value="line-item-header__option-remove"
              onSelect={() => {
                handleSelectedOption('remove')
              }}
            >
              <NoSymbolIcon className="mr-2 h-7 w-7 text-magenta-700" aria-hidden="true" />
              Remove
            </DropdownMenuItem>
          </DropdownMenuContent>
        </DropdownMenu>
      </div>

      <IgnoreProductModal
        isOpen={isIgnoreModalOpen}
        isLastLineItemToBeFulfilled={isLastLineItemToBeFulfilled}
        lineItem={lineItem}
        setIsOpen={setIsIgnoreModalOpen}
      />

      <OverlayLoadingSpinner isVisible={saveStatus === 'loading'} />
    </div>
  )
}

function createSaveSuccessToast({
  hasAllRequiredImages,
  hasAnyPrintImage,
  hasProduct
}: {
  hasAllRequiredImages: boolean
  hasAnyPrintImage: boolean
  hasProduct: boolean
}) {
  if (hasAllRequiredImages) {
    createToast({
      content:
        'Next time we receive an order for this exact item, we will automatically fulfil with the same product & image.',
      duration: 5000,
      id: LINE_ITEM_TOASTS.SUCCESS,
      heading: 'Saved',
      type: 'success'
    })
    return
  }

  if (hasAnyPrintImage) {
    createToast({
      content:
        'Next time we receive an order for this exact item, we will assign these image(s). Save with all required images for complete automated fulfilment.',
      duration: 5000,
      iconTheme: {
        primary: '#FC7B1E',
        secondary: 'white'
      },
      id: LINE_ITEM_TOASTS.SUCCESS,
      heading: 'Image saved',
      type: 'success'
    })
    return
  }

  if (hasProduct) {
    createToast({
      content:
        'Next time we receive an order for this exact item, we will assign this product. Save with an image from your image library for complete automated fulfilment.',
      duration: 5000,
      iconTheme: {
        primary: '#FC7B1E',
        secondary: 'white'
      },
      id: LINE_ITEM_TOASTS.SUCCESS,
      heading: 'Product saved',
      type: 'success'
    })
  }
}
