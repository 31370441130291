import { QUERY_PARAMS } from '../../../constants'

export function hasImageFilterParamsInSearch(search: string) {
  const params = [
    QUERY_PARAMS.IMAGE_LIBRARY.SEARCH,
    QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE,
    QUERY_PARAMS.IMAGE_LIBRARY.ORIENTATION,
    QUERY_PARAMS.IMAGE_LIBRARY.TYPE,
    QUERY_PARAMS.IMAGE_LIBRARY.TAG
  ]

  return params.some((param) => search.includes(param))
}
