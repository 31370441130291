import clsx from 'clsx'
import { ReactNode } from 'react'
import { AnimatePresence } from 'motion/react'
import { EllipsisHorizontalIcon } from '@heroicons/react/20/solid'
import { CloudArrowUpIcon, GlobeAmericasIcon } from '@heroicons/react/24/outline'

import { DropboxLogo } from '../../svg/DropboxLogo.icon'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '../../DropdownMenu'

export type UploadOptionValueType = 'file' | 'url' | 'dropbox'

interface UploadOptionsPropsInterface {
  children: ReactNode
  manageLibrary: boolean
  uploadChoice: UploadOptionValueType
  setUploadChoice: (value: UploadOptionValueType) => void
}

export function UploadOptions({ children, manageLibrary, uploadChoice, setUploadChoice }: UploadOptionsPropsInterface) {
  return (
    <div
      className={clsx('flex gap-3 transition-opacity', {
        'pointer-events-none opacity-60': manageLibrary
      })}
    >
      <div>
        <AnimatePresence initial={false} mode="wait">
          {children}
        </AnimatePresence>
      </div>

      <div className="mr-4">
        <DropdownMenu>
          <DropdownMenuTrigger
            className="aspect-square h-full w-fit border-gray-500"
            data-test="image-library-upload__trigger"
          >
            <EllipsisHorizontalIcon className="mx-auto h-7 w-7 text-black" />
            <span className="sr-only">Options</span>
          </DropdownMenuTrigger>

          <DropdownMenuContent align="start" data-test="image-library-upload__options">
            {uploadChoice !== 'file' && (
              <DropdownMenuItem
                data-test="image-library-upload__option"
                data-test-value={`image-library-upload__option-file`}
                onSelect={() => {
                  setUploadChoice('file')
                }}
              >
                <CloudArrowUpIcon className="mr-2 h-7 w-7 text-gray-700" />
                Upload images
              </DropdownMenuItem>
            )}
            {uploadChoice !== 'url' && (
              <DropdownMenuItem
                data-test="image-library-upload__option"
                data-test-value={`image-library-upload__option-url`}
                onSelect={() => {
                  setUploadChoice('url')
                }}
              >
                <GlobeAmericasIcon className="mr-2 h-7 w-7 text-gray-700" />
                Add from URL
              </DropdownMenuItem>
            )}
            {uploadChoice !== 'dropbox' && (
              <DropdownMenuItem
                data-test-value={`image-library-upload__option-dropbox`}
                data-test="image-library-upload__option"
                onSelect={() => {
                  setUploadChoice('dropbox')
                }}
              >
                <DropboxLogo className="mr-2 h-7 w-7 text-gray-500" />
                Add from Dropbox
              </DropdownMenuItem>
            )}
          </DropdownMenuContent>
        </DropdownMenu>
      </div>
    </div>
  )
}
