import { ReactNode } from 'react'
import { motion } from 'motion/react'

import EmptyState from '../../EmptyState'
import LogoProdigi from '../../LogoProdigi'
import { APP_ENVIRONMENTS } from '../../../constants'
import { SalesChannelPlatformEnum } from '../../../enums'

export function ProductsRetrieving({ platformName }: { platformName: SalesChannelPlatformEnum }) {
  return (
    <EmptyState>
      <div className="mx-auto max-w-5xl">
        <LoadingLogo platformName={platformName} />
        <h2>We&apos;re importing your products</h2>

        <p className="mt-10">
          This page will automatically refresh once we&apos;ve imported some of your products, which may take several
          minutes.
        </p>
        <p className="mt-10">Don&apos;t fancy waiting? We&apos;ll email you once your store connection is complete.</p>
      </div>
    </EmptyState>
  )
}

function LoadingLogo({ platformName }: { platformName: SalesChannelPlatformEnum }) {
  switch (platformName) {
    case SalesChannelPlatformEnum.Etsy:
      return (
        <LoadingContainer>
          <img
            alt="Etsy logo"
            src="/img/logo/etsy-orange.png"
            className="block h-20 w-36 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.WooCommerce:
      return (
        <LoadingContainer>
          <img
            alt="WooCommerce logo"
            src="/img/logo/woocommerce.svg"
            className="block h-20 w-36 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.Wix:
      return (
        <LoadingContainer>
          <img
            alt="Wix logo"
            src="/img/logo/wix.png"
            className="block h-20 w-36 object-contain object-center"
            style={{ transform: 'scale(0.7)' }}
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.Squarespace:
      return (
        <LoadingContainer>
          <img
            alt="Squarespace logo"
            src="/img/logo/squarespace.svg"
            className="w-42 block h-20 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.AdobeCommerce:
      return (
        <LoadingContainer>
          <img
            alt="Adobe Commerce logo"
            src="/img/logo/adobecommerce.svg"
            className="w-42 block h-20 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.BigCommerce:
      return (
        <LoadingContainer>
          <img
            alt="BigCommerce logo"
            src="/img/logo/bigcommerce.svg"
            className="w-42 block h-20 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.Shopify:
      return (
        <LoadingContainer>
          <img
            alt="Shopify logo"
            src="/img/logo/shopify.svg"
            className="w-42 block h-20 object-contain object-center"
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.Amazon:
      return (
        <LoadingContainer>
          <img
            alt="Amazon logo"
            src="/img/logo/amazon.svg"
            className="w-42 block h-16 object-contain object-center"
            style={{ transform: 'scale(0.9)' }}
          />
        </LoadingContainer>
      )
    case SalesChannelPlatformEnum.Prodigi:
      return null
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}

function LoadingContainer({ children }: { children: ReactNode }) {
  return (
    <div className="mb-8 flex items-center justify-center">
      {children}
      <AnimatedLoadingProdigi />
    </div>
  )
}

function AnimatedLoadingProdigi() {
  return (
    <>
      <motion.div
        animate={{ opacity: [1, 0.6] }}
        transition={{ duration: 3, ease: [0.2, 1.19, 0.91, 0.01], repeat: Infinity }}
      >
        <div className="overflow-hidden">
          <motion.div
            animate={{ x: [-40, 40] }}
            transition={{ duration: 3, ease: [0.2, 1.19, 0.91, 0.01], repeat: Infinity }}
          >
            <AnimatedArrowRight className="h-20 w-24 shrink-0" strokeWidth={4} />
          </motion.div>
        </div>
      </motion.div>

      <motion.span
        animate={{ opacity: [0.5, 1], scale: [0.9, 1] }}
        transition={{ duration: 3, ease: [0.2, 1.19, 0.91, 0.01], repeat: Infinity }}
      >
        <LogoProdigi className="h-16 w-auto" />
      </motion.span>
    </>
  )
}

function AnimatedArrowRight({ className = 'h-6 w-12', strokeWidth = 1.5, ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg
      className={className}
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 48 24"
      strokeWidth={strokeWidth}
      stroke="currentColor"
      {...props}
    >
      <path strokeLinecap="butt" d="M13.5 4.5L21 12l-7.5 7.5M21 12H3" />
    </svg>
  )
}
