import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { useState, useEffect } from 'react'
import { useLocation, useHistory } from 'react-router'

import Modal from '../../../../../Modal'
import { useUser } from '../../../../../../hooks'
import { fetcher } from '../../../../../../helpers'
import { ROUTE_PATHS } from '../../../../../../constants'
import LoadingSpinner from '../../../../../LoadingSpinner'
import { FetchErrorInterface } from '../../../../../../interfaces'
import { createErrorToast, createToast } from '../../../../../Toast'
import { updateUserSuccess } from '../../../../../../../actions/user'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../../../constants'

export const STRIPE_CHECKOUT_SETUP_PARAMS = {
  SESSION_ID: 'prodigiSCID'
}

export function CheckoutSetupStripeRedirectModal() {
  const [isOpen, setIsOpen] = useState(false)

  const { search } = useLocation()
  const searchParams = new URLSearchParams(search)
  const checkoutSessionId = searchParams.get(STRIPE_CHECKOUT_SETUP_PARAMS.SESSION_ID)

  useEffect(() => {
    return () => {
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    }
  }, [])

  useEffect(() => {
    if (checkoutSessionId) {
      setIsOpen(true)
    } else {
      setIsOpen(false)
    }
  }, [checkoutSessionId])

  return (
    <Modal
      open={isOpen}
      setOpen={setIsOpen}
      showHeader={false}
      showBorder={false}
      closeOnInteractionOutside={false}
      closeOnEscape={false}
    >
      <div className="max-w-3xl p-8 sm:min-w-[500px] sm:p-12">
        <StripeCheckoutRedirectStatus />
      </div>
    </Modal>
  )
}

function StripeCheckoutRedirectStatus() {
  const { mutateUser } = useUser()
  const dispatch = useDispatch()
  const history = useHistory()

  useEffect(() => {
    async function verifyPaymentMethodSetup() {
      try {
        const checkoutSessionId = new URLSearchParams(window.location.search).get(
          STRIPE_CHECKOUT_SETUP_PARAMS.SESSION_ID
        )

        await fetcher(`${process.env.REACT_APP_ENDPOINT}/dashboard/merchant/savepaymentmethod`, {
          method: 'POST',
          body: JSON.stringify({ CheckoutSessionId: checkoutSessionId })
        })
        await mutateUser()

        window.analytics.track('Payment method setup success', { type: 'Stripe Checkout' })
        createToast({
          heading: 'Payment method added successfully',
          id: SETTINGS_UPDATE_SUCCESS_TOAST_ID,
          type: 'success'
        })
        // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
        dispatch(updateUserSuccess())
      } catch (error) {
        const errorResponse = error as { message?: string }
        const fetchErrorResponse = error as FetchErrorInterface<{ message?: string }>
        const errorCode = `SI-CC-${fetchErrorResponse.status ?? '0'}`
        const errorMessage = fetchErrorResponse.responseBodyJson?.message ?? errorResponse.message
        createErrorToast({
          errorCode,
          errorMessage,
          heading: 'Failed to setup payment method',
          id: SETTINGS_UPDATE_ERROR_TOAST_ID
        })
        window.analytics.track('Payment method setup error', { type: 'Stripe Checkout' })
      }
      history.replace(ROUTE_PATHS.SETTINGS.BILLING)
    }

    verifyPaymentMethodSetup()
  }, [dispatch, history, mutateUser])

  return (
    <div className="grid place-content-center">
      <div className="flex justify-center">
        <LoadingSpinner className="h-8 w-8" />
      </div>
      <div className="mt-4">Verifying payment method setup</div>
    </div>
  )
}
