import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import useInfiniteScroll from 'react-infinite-scroll-hook'

import { QUERY_PARAMS } from '../../../constants'
import InlineMenuWithInfiniteScroll from '../../InlineMenuWithInfiniteScroll'
import { useDebouncedUrlUpdate } from '../../../hooks/useDebouncedUrlUpdate.hook'
import { IMAGE_LIBRARY_TAG_PAGE_SIZE, useImageLibraryImageTags } from '../../../hooks'
import { ImageLibraryListActionTagSearchBar } from './ImageLibraryListActionTagSearchBar.component'

export function ImageLibraryListActionTag() {
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const selectedParam = searchParams.get(QUERY_PARAMS.IMAGE_LIBRARY.TAG)
  const selectedDropdownValues = useMemo(() => (selectedParam ? selectedParam.split(',') : []), [selectedParam])
  const [selectedValues, setSelectedValues] = useState(selectedDropdownValues)

  const {
    imageLibraryTagPages,
    isLoadingImageLibraryTagPages,
    imageLibraryFetchError,
    imageLibraryTagPagesToLoadSize,
    setImageLibraryTagPagesToLoadSize
  } = useImageLibraryImageTags()

  const loadedImageLibraryTagsSize = imageLibraryTagPages?.length ?? 1
  const hasNextPage = Boolean(
    imageLibraryTagPages && imageLibraryTagPages?.[loadedImageLibraryTagsSize - 1]?.length > 0
  )
  const isLoadingNextPageOfImageLibraryTags = hasNextPage && loadedImageLibraryTagsSize < imageLibraryTagPagesToLoadSize

  const [paginationTriggerRef] = useInfiniteScroll({
    disabled: Boolean(imageLibraryFetchError),
    loading: isLoadingNextPageOfImageLibraryTags,
    delayInMs: 200,
    hasNextPage,
    onLoadMore: () => {
      setImageLibraryTagPagesToLoadSize(imageLibraryTagPagesToLoadSize + 1)
    }
  })

  // Ensures URL changes are captured when using browser navigation buttons
  useEffect(() => {
    setSelectedValues(selectedDropdownValues)
  }, [selectedDropdownValues, searchParams])

  const debouncedUrlUpdate = useDebouncedUrlUpdate({ history, searchParams })

  const updateSelected = (selectedValues: string[]) => {
    setSelectedValues(selectedValues)
    debouncedUrlUpdate({
      paramJoin: ',',
      queryParam: QUERY_PARAMS.IMAGE_LIBRARY.TAG,
      routePath: pathname,
      selectedValues
    })
  }

  const optionPages = imageLibraryTagPages?.map((page) =>
    page.map((tag) => {
      return {
        content: (
          <div className={`flex min-w-fit break-words pr-5 text-white`}>
            <span className="flex items-center rounded-l-lg rounded-r-lg bg-teal-500 px-3 py-2">
              <span className="break-all text-sm">{tag}</span>
            </span>
          </div>
        ),
        value: tag,
        searchLabel: tag
      }
    })
  ) ?? [[]]

  return (
    <InlineMenuWithInfiniteScroll
      ariaLabel="Tagged"
      className={`max-h-[min(350px,calc(var(--radix-popover-content-available-height)-10vh))] min-h-[200px] max-w-[300px] overflow-x-hidden sm:min-w-[300px]`}
      data-test="image-library-actions__-filter-tag"
      fetchError={
        imageLibraryFetchError && {
          status: imageLibraryFetchError.status
        }
      }
      header={<ImageLibraryListActionTagSearchBar />}
      isLoadingFirstSetOfData={isLoadingImageLibraryTagPages}
      itemsToloadSize={imageLibraryTagPagesToLoadSize}
      multiple={true}
      pageSize={IMAGE_LIBRARY_TAG_PAGE_SIZE}
      paginationTriggerRef={paginationTriggerRef}
      optionPages={optionPages}
      queryParam={QUERY_PARAMS.IMAGE_LIBRARY.TAG_SEARCH}
      searchingFor="tags"
      selected={selectedValues}
      onTriggerScroll={() => setImageLibraryTagPagesToLoadSize(imageLibraryTagPagesToLoadSize + 1)}
      updateSelected={updateSelected}
    />
  )
}
