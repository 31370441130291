import { PaymentMethodOptionType } from '../../../../../../interfaces'

export function PaymentMethodIdentifier({ identifier, type }: { identifier?: string; type?: PaymentMethodOptionType }) {
  let formattedPaymentMethodIdentifier = identifier || type || 'N/A'
  if (type === 'card' && identifier) {
    formattedPaymentMethodIdentifier = `**** ${identifier}`
  }

  return <>{formattedPaymentMethodIdentifier}</>
}
