import { ReactNode } from 'react'
import { cn } from '../../../helpers'

export function ProductLibraryDetailPanel({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={cn('flex flex-col gap-8 p-8 lg:flex-row lg:gap-20 lg:p-10', className)}>{children}</div>
}

export function ProductLibraryDetailPanelTitle({ children, className }: { children: ReactNode; className?: string }) {
  return <h2 className={cn('mt-0 text-2xl font-medium text-black', className)}>{children}</h2>
}

export function ProductLibraryDetailPanelSubTitle({
  children,
  className
}: {
  children: ReactNode
  className?: string
}) {
  return <div className={cn('mt-2 max-w-96 text-sm text-gray-600', className)}>{children}</div>
}

export function ProductLibraryDetailPanelContent({ children, className }: { children: ReactNode; className?: string }) {
  return <div className={cn('max-w-3xl', className)}>{children}</div>
}
