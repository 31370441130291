import toast from 'react-hot-toast'
import { motion } from 'motion/react'
import { PlusIcon } from '@heroicons/react/20/solid'
import { useEffect, useMemo, useState } from 'react'
import { useHistory, useLocation } from 'react-router'

import {
  SALES_CHANNELS_STORE_PACKING_SLIP_ERROR_TOAST_ID,
  SALES_CHANNELS_STORE_PACKING_SLIP_SUCCESS_TOAST_ID
} from '../constants'
import Button from '../../Button'
import { createToast } from '../../Toast'
import { StatusEnum } from '../../../enums'
import OverlayPortal from '../../OverlayPortal'
import { QUERY_PARAMS } from '../../../constants'
import ImageLibraryModal from '../../ImageLibraryModal'
import { FetchErrorInterface } from '../../../interfaces'
import { updateSalesChannelDetails } from '../../../helpers'
import { PACKING_SLIP_ALLOWED_FILE_TYPES } from '../../PackingSlip'
import { ImageLibraryImageInterface, useMerchantService } from '../../../hooks'

export function AddSalesChannelPackingSlip({
  newSalesChannelId,
  onStepAdvance
}: {
  newSalesChannelId: string
  onStepAdvance: () => void
}) {
  const { merchantDetails, mutateMerchantDetails } = useMerchantService()
  const { search, pathname } = useLocation()
  const history = useHistory()
  const searchParams = useMemo(() => new URLSearchParams(search), [search])

  const newSalesChannel = merchantDetails?.salesChannels.find((salesChannel) => salesChannel.id === newSalesChannelId)

  const [addSalesChannelPackingSlipStatus, setAddSalesChannelPackingSlipStatus] = useState<StatusEnum>(StatusEnum.Idle)
  const [isImageLibraryModalOpen, setIsImageLibraryModalOpen] = useState(false)

  useEffect(() => {
    return () => {
      toast.dismiss(SALES_CHANNELS_STORE_PACKING_SLIP_ERROR_TOAST_ID)
    }
  }, [])

  function onSetIsImageLibraryModalOpen(open: boolean) {
    if (!open) {
      const newSearchParams = new URLSearchParams(searchParams)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.TYPE)
      newSearchParams.delete(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE)
      history.replace({ pathname, search: newSearchParams.toString() })
    }
    setIsImageLibraryModalOpen(open)
  }

  async function handleUploadPackingUpdate(imageLibraryImage: ImageLibraryImageInterface) {
    setIsImageLibraryModalOpen(false)
    try {
      if (!merchantDetails) {
        throw Error('No merchant details')
      }
      if (!newSalesChannel) {
        throw Error('No new sales channel')
      }

      toast.dismiss(SALES_CHANNELS_STORE_PACKING_SLIP_ERROR_TOAST_ID)
      toast.dismiss(SALES_CHANNELS_STORE_PACKING_SLIP_SUCCESS_TOAST_ID)
      setAddSalesChannelPackingSlipStatus(StatusEnum.Loading)

      if (!PACKING_SLIP_ALLOWED_FILE_TYPES.includes(imageLibraryImage.file_type)) {
        createToast({
          content: `${
            imageLibraryImage.file_type
          } is not supported for packing slips. Please select one of the following file types: ${PACKING_SLIP_ALLOWED_FILE_TYPES.join(
            ', '
          )}`,
          duration: Infinity,
          heading: 'Failed to update packing slip',
          id: SALES_CHANNELS_STORE_PACKING_SLIP_ERROR_TOAST_ID,
          type: 'error-with-close'
        })
        setAddSalesChannelPackingSlipStatus(StatusEnum.Error)
        return
      }

      await updateSalesChannelDetails({
        merchantId: merchantDetails.id,
        packingSlipImageId: imageLibraryImage.public_id,
        salesChannelId: newSalesChannelId,
        salesChannelName: newSalesChannel.description,
        defaultFulfillment: newSalesChannel.defaultFulfillment
      })
      await mutateMerchantDetails()

      setAddSalesChannelPackingSlipStatus(StatusEnum.Success)

      window.analytics.track('Added default packing slip: connection')
      onStepAdvance()
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<unknown>
      createToast({
        content: `Please try again later`,
        duration: Infinity,
        footer: `${errorResponse.message} (Code ${errorResponse.status ?? '0'})`,
        heading: 'Failed to add packing slip',
        id: SALES_CHANNELS_STORE_PACKING_SLIP_ERROR_TOAST_ID,
        type: 'error-with-close'
      })
      setAddSalesChannelPackingSlipStatus(StatusEnum.Error)
    }
  }

  if (!newSalesChannel) {
    throw Error('New sales channel not found')
  }

  return (
    <motion.div animate={{ opacity: 1 }} exit={{ opacity: 0 }} initial={{ opacity: 0 }}>
      <div className="flex items-center gap-4">
        <Button
          dataTest="add-packing-slip-button"
          isLoading={addSalesChannelPackingSlipStatus === StatusEnum.Loading}
          size="sm"
          variant="secondary"
          startIcon={<PlusIcon className="h-8 w-8" />}
          onClick={() => {
            const newSearchParams = new URLSearchParams(searchParams)
            newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.TYPE, 'packing')
            newSearchParams.set(QUERY_PARAMS.IMAGE_LIBRARY.FILE_TYPE, PACKING_SLIP_ALLOWED_FILE_TYPES.join(','))
            history.replace({ pathname, search: newSearchParams.toString() })

            setIsImageLibraryModalOpen(true)
            window.analytics.track(`Image library opened for packing slip: Sales channel connection`)
          }}
        >
          Add packing slip
        </Button>

        <ImageLibraryModal
          config={{ forceSaveToLibrary: false }}
          open={isImageLibraryModalOpen}
          type="packing"
          setOpen={onSetIsImageLibraryModalOpen}
          onSelectImage={handleUploadPackingUpdate}
        />
      </div>

      <div className="mt-auto flex justify-end pt-2">
        <Button
          disabled={addSalesChannelPackingSlipStatus === StatusEnum.Loading}
          isLoading={addSalesChannelPackingSlipStatus === StatusEnum.Loading}
          onClick={onStepAdvance}
        >
          Skip
        </Button>
      </div>

      {addSalesChannelPackingSlipStatus === StatusEnum.Loading && <OverlayPortal />}
    </motion.div>
  )
}
