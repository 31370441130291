import { useRef, useState } from 'react'

import { InsertsSetPanel } from './InsertsSetPanel.component'
import { InsertDataType, InsertSetType } from '../../../interfaces'
import { InsertsAddPanel, InsertsPanelItem } from '../../BrandedInsertSetModal'
import { BrandedInsertIdType, BrandingDataInterface, BrandingDetailsType } from '../../../hooks'

export function OrderInsertsPanel({
  brandingDetails,
  brandingDetailsWithoutImage,
  insertSets,
  onChangeInsertData,
  onChangeAllInsertsData
}: {
  brandingDetails: BrandingDataInterface
  brandingDetailsWithoutImage: BrandingDetailsType[]
  insertSets: InsertSetType[]
  onChangeInsertData: (newInsertData: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) => void
  onChangeAllInsertsData: (newAllInsertsData: InsertDataType[]) => void
}) {
  const [selectedPanel, setSelectedPanel] = useState<'add-inserts' | 'assign-set'>(
    insertSets.length > 0 ? 'assign-set' : 'add-inserts'
  )
  const [selectedInsertId, setSelectedInsertId] = useState<string>('')
  const [selectedInsertSetId, setSelectedInsertSetId] = useState<string>('')

  const panelContainerRef = useRef<HTMLDivElement>(null)

  function handleSelectPanel(selectedPanel: 'add-inserts' | 'assign-set') {
    setSelectedPanel(selectedPanel)
    setTimeout(() => {
      panelContainerRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' })
    }, 0)
  }

  return (
    <div ref={panelContainerRef}>
      <ul className="flex">
        <InsertsPanelItem isSelected={selectedPanel === 'assign-set'} onClick={() => handleSelectPanel('assign-set')}>
          Assign a set
        </InsertsPanelItem>
        <InsertsPanelItem isSelected={selectedPanel === 'add-inserts'} onClick={() => handleSelectPanel('add-inserts')}>
          Add individual inserts
        </InsertsPanelItem>
      </ul>

      <div className="border border-gray-200 p-8 lg:p-10">
        {selectedPanel === 'assign-set' && (
          <InsertsSetPanel
            brandingDetails={brandingDetails}
            insertSets={insertSets}
            selectedInsertSetId={selectedInsertSetId}
            onChangeSelectedInsertSetId={setSelectedInsertSetId}
            onChangeAllInsertsData={onChangeAllInsertsData}
          />
        )}

        {selectedPanel === 'add-inserts' && (
          <InsertsAddPanel
            brandingDetailsWithoutImage={brandingDetailsWithoutImage}
            selectedInsertId={selectedInsertId}
            onChangeSelectedInsertId={setSelectedInsertId}
            onChangeInsertData={(newInsertData) => {
              setSelectedInsertId('')
              onChangeInsertData(newInsertData)
            }}
          />
        )}
      </div>
    </div>
  )
}
