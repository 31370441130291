import { useState } from 'react'
import { AnimatePresence, motion } from 'motion/react'

import Button from '../Button'
import OverlayPortal from '../OverlayPortal'
import { cn, formatCost } from '../../helpers'
import Modal, { ModalPropsInterface } from '../Modal'
import { BrandedInsertItem } from '../BrandedInsertItem'
import { OrderInsertsPanel } from './components/OrderInsertsPanel.component'
import { InsertDataType, InsertSetType, StatusType } from '../../interfaces'
import { BrandedInsertIdType, BrandingDataInterface, BrandingDetailsType } from '../../hooks'

interface BrandedInsertsOrderModalPropsIntereface
  extends BrandedInsertsOrderPropsInterface,
    Pick<ModalPropsInterface, 'open' | 'closeOnEscape' | 'closeOnInteractionOutside' | 'title' | 'setOpen'> {}

export function BrandedInsertsOrderModal({
  open,
  closeOnEscape,
  closeOnInteractionOutside,
  title = 'Inserts on this order',
  saveStatus,
  setOpen,
  ...props
}: BrandedInsertsOrderModalPropsIntereface) {
  return (
    <Modal
      className="w-90vw max-w-[1000px]"
      closeOnEscape={saveStatus === 'loading' ? false : closeOnEscape}
      closeOnInteractionOutside={saveStatus === 'loading' ? false : closeOnInteractionOutside}
      title={title}
      open={open}
      setOpen={setOpen}
    >
      <div className="h-[60vh] overflow-y-auto">
        <BrandedInsertsOrder {...props} saveStatus={saveStatus} />
      </div>
    </Modal>
  )
}

interface BrandedInsertsOrderPropsInterface {
  brandingDetails: BrandingDataInterface
  inserts?: InsertDataType[]
  insertSets?: InsertSetType[]
  saveStatus?: StatusType
  onSave: (insertsData: InsertDataType[]) => void
  onClose?: () => void
}

function BrandedInsertsOrder({
  brandingDetails,
  inserts = [],
  insertSets = [],
  saveStatus = 'idle',
  onClose,
  onSave
}: BrandedInsertsOrderPropsInterface) {
  const [allInsertsData, setAllInsertsData] = useState(inserts)

  function handleChangeInsertData({
    newInsertId,
    newInsertData
  }: {
    newInsertId: BrandedInsertIdType
    newInsertData: Omit<InsertDataType, 'id'>
  }) {
    const doesInsertExist = allInsertsData.some((insertData) => insertData.id === newInsertId)

    let newAllInsertsData = allInsertsData
    if (doesInsertExist) {
      newAllInsertsData = allInsertsData.map((insertData) => {
        if (insertData.id !== newInsertId) {
          return insertData
        }

        return { ...insertData, id: newInsertId, ...newInsertData }
      })
    } else {
      newAllInsertsData = [...allInsertsData, { id: newInsertId, ...newInsertData }]
    }

    setAllInsertsData(newAllInsertsData)
  }

  const brandingDetailsWithImage: BrandingDetailsType[] = []
  const brandingDetailsWithoutImage: BrandingDetailsType[] = []

  brandingDetails?.branding.forEach((brandingDetail) => {
    const insertDataForBrandingDetail = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

    if (insertDataForBrandingDetail?.imageLibraryId || insertDataForBrandingDetail?.imageUrl) {
      brandingDetailsWithImage.push(brandingDetail)
    } else {
      brandingDetailsWithoutImage.push(brandingDetail)
    }
  })

  const numberOfInsertsWithImage = brandingDetailsWithImage.length

  const totalCost = {
    amount: brandingDetailsWithImage.reduce((amountAcc, brandingDetail) => amountAcc + brandingDetail.cost.amount, 0),
    currency: brandingDetailsWithImage[0]?.cost.currency
  }

  return (
    <motion.div layoutScroll className="flex h-full flex-col overflow-auto">
      <div className="flex-1">
        {numberOfInsertsWithImage > 0 && (
          <div className="mb-6 flex items-center justify-end">
            <div className="rounded-full bg-purple-100 px-6 py-2">
              {numberOfInsertsWithImage} {numberOfInsertsWithImage === 1 ? 'insert' : 'inserts'}, total cost:{' '}
              {formatCost({ amount: totalCost.amount.toString(), currencyCode: totalCost.currency })}
            </div>
          </div>
        )}

        <ul className={cn(brandingDetailsWithImage.length > 0 && 'mb-6')}>
          <AnimatePresence initial={false} mode="popLayout">
            {brandingDetailsWithImage.map((brandingDetail) => {
              const insertDataForItem = allInsertsData.find((insertData) => insertData.id === brandingDetail.id)

              return (
                <motion.li
                  key={brandingDetail.id}
                  initial={{ opacity: 0, scale: 0.8 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.8 }}
                  layout
                  transition={{ type: 'spring', bounce: 0, duration: 0.3 }}
                >
                  <BrandedInsertItem
                    brandingDetail={brandingDetail}
                    insertData={insertDataForItem}
                    onChangeInsertData={(newInsertData: Omit<InsertDataType, 'id'>) =>
                      handleChangeInsertData({ newInsertId: brandingDetail.id, newInsertData })
                    }
                  />
                </motion.li>
              )
            })}
          </AnimatePresence>
        </ul>

        <motion.div layout transition={{ type: 'spring', bounce: 0, duration: 0.3 }}>
          <OrderInsertsPanel
            brandingDetails={brandingDetails}
            brandingDetailsWithoutImage={brandingDetailsWithoutImage}
            insertSets={insertSets}
            onChangeInsertData={handleChangeInsertData}
            onChangeAllInsertsData={setAllInsertsData}
          />
        </motion.div>
      </div>

      <div className="sticky bottom-0 flex flex-wrap-reverse items-center gap-2 bg-white pt-12">
        <Button isLoading={saveStatus === 'loading'} variant="primary" onClick={() => onSave(allInsertsData)}>
          Save
        </Button>

        {onClose && (
          <Button variant="tertiary" theme="greyscale" onClick={onClose}>
            Cancel
          </Button>
        )}
      </div>

      {saveStatus === 'loading' && <OverlayPortal />}
    </motion.div>
  )
}
