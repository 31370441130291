import { IUseSplitTreatmentsOptions, useSplitTreatments } from '@splitsoftware/splitio-react'

import { useUser } from './useUser.hook'
import { generateUserId } from '../helpers'

export function useSplitTreatment(featureName: string, options?: Omit<IUseSplitTreatmentsOptions, 'flagSets'>) {
  const { user } = useUser()
  const splitData = useSplitTreatments({
    names: [featureName],
    splitKey: user ? generateUserId(user.merchantUniqueId) : undefined,
    ...options
  })

  return {
    splitData,
    treatmentData: splitData.treatments[featureName],
    isTreatmentOn: splitData.treatments[featureName]?.treatment === 'on',
    isLoadingSplit: !splitData.hasTimedout && !splitData.isReady
  }
}
