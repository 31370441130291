import { CatalogueProductDetailsInterface } from '../interfaces/catalogueProducts.interface'

export const CATALOGUE_PRODUCTS: Record<string, CatalogueProductDetailsInterface | undefined> = {
  'Prints & posters': {
    image: '/img/v2/product-catalogue/prints.jpg',
    productTypes: {
      'Art prints': {
        image: '/img/v2/product-catalogue/prints_fine-art-prints.jpg'
      },
      'Photo prints': {
        image: '/img/v2/product-catalogue/prints_c-types.jpg'
      },
      'Specialist prints': {
        image: '/img/v2/product-catalogue/metallic-foil-prints.jpg'
      },
      Posters: {
        image: '/img/v2/product-catalogue/prints_posters.jpg'
      },
      'Mounted prints': {
        image: '/img/v2/product-catalogue/prints_mounted-prints.jpg'
      },
      'Poster hangers': {
        image: '/img/v2/product-catalogue/poster-hangers.jpg'
      },
      'Poster hangers with prints': {
        image: '/img/v2/product-catalogue/poster-hangers-with-prints.jpg'
      }
    }
  },
  'Wall art': {
    image: '/img/v2/product-catalogue/framed-prints.jpg',
    productTypes: {
      'Stretched canvas': {
        image: '/img/v2/product-catalogue/canvases.jpg'
      },
      'Framed Canvas': {
        image: '/img/v2/product-catalogue/canvases_framed-canvas.jpg'
      },
      'Rolled Canvas': {
        image: '/img/v2/product-catalogue/canvases_rolled-canvas.jpg'
      },
      'Framed prints': {
        image: '/img/v2/product-catalogue/framed-prints.jpg'
      },
      'Photo tiles': {
        image: '/img/v2/product-catalogue/photo-tiles.jpg'
      },
      'Dibond Prints': {
        image: '/img/v2/product-catalogue/metal.jpg'
      },
      'Acrylic Panel': {
        image: '/img/v2/product-catalogue/panels_acrylic-panel.jpg'
      },
      'Multi Photo Frames': {
        image: '/img/v2/product-catalogue/multi-photo-frames.jpg'
      }
    }
  },
  Stickers: {
    image: '/img/v2/product-catalogue/stickers.jpg',
    productTypes: {}
  },
  Tattoos: {
    image: '/img/v2/product-catalogue/tattoos.jpg',
    productTypes: {}
  },
  Magnets: {
    image: '/img/v2/product-catalogue/magnets.jpg',
    productTypes: {}
  },
  Patches: {
    image: '/img/v2/product-catalogue/patches.jpg',
    productTypes: {}
  },
  'Device Cases': {
    image: '/img/v2/product-catalogue/technology.jpg',
    productTypes: {
      'Phone Case': {
        image: '/img/v2/product-catalogue/technology_phone-case.jpg'
      },
      'Tablet Case': {
        image: '/img/v2/product-catalogue/technology_tablet-case.jpg'
      },
      'Watch Strap': {
        image: '/img/v2/product-catalogue/technology_watch-strap.jpg'
      }
    }
  },
  Technology: {
    image: '/img/v2/product-catalogue/gaming-mats.jpg',
    productTypes: {
      'Desk Mats': {
        image: '/img/v2/product-catalogue/gaming-mat.jpg'
      },
      'Mouse Mats': {
        image: '/img/v2/product-catalogue/mouse-mat.jpeg'
      }
    }
  },
  'Gallery Boards': {
    image: '/img/v2/product-catalogue/gallery-boards.jpg',
    productTypes: {
      'Gallery Boards': {
        image: '/img/v2/product-catalogue/gallery-boards_gallery-boards.jpg'
      },
      'Gallery Boards with stand': {
        image: '/img/v2/product-catalogue/gallery-board-with-stand.jpg'
      },
      'Picture Stands': {
        image: '/img/v2/product-catalogue/gallery-boards_picture-stands.jpg'
      }
    }
  },
  Drinkware: {
    image: '/img/v2/product-catalogue/home-and-living.jpg',
    productTypes: {
      Mugs: {
        image: '/img/v2/product-catalogue/home-and-living_mugs.jpg'
      },
      'Water Bottles': {
        image: '/img/v2/product-catalogue/sport-and-games_water-bottles.jpg'
      },
      Tumblers: {
        image: '/img/v2/product-catalogue/11oz-double-walled-tumblers.jpg'
      },
      Cups: {
        image: '/img/v2/product-catalogue/home-and-living.jpg'
      }
    }
  },
  'Home & Living': {
    image: '/img/v2/product-catalogue/home-and-living_cushions.jpg',
    productTypes: {
      'Acrylic Prism': {
        image: '/img/v2/product-catalogue/home-and-living_acrylic-prism.jpg'
      },
      Aprons: {
        image: '/img/v2/product-catalogue/home-and-living_aprons.jpg'
      },
      'Bath Mats': {
        image: '/img/v2/product-catalogue/home-and-living_bath-mats.jpg'
      },
      Blankets: {
        image: '/img/v2/product-catalogue/home-and-living_blankets.jpg'
      },
      'Christmas tree ornaments': {
        image: '/img/v2/product-catalogue/home-and-living_christmas-tree-ornaments.jpg'
      },
      'Wall Clocks': {
        image: '/img/v2/product-catalogue/home-and-living_clocks.jpg'
      },
      Coasters: {
        image: '/img/v2/product-catalogue/home-and-living_coasters.jpg'
      },
      Cushions: {
        image: '/img/v2/product-catalogue/home-and-living_cushions.jpg'
      },
      'Cushions (Cover Only)': {
        image: '/img/v2/product-catalogue/home-and-living_cushions-cover-only.jpg'
      },
      Kitchenwares: {
        image: '/img/v2/product-catalogue/home-and-living_kitchenwares.jpg'
      },
      Pendants: {
        image: '/img/v2/product-catalogue/home-and-living_pendants.jpg'
      },
      'Pillow Case': {
        image: '/img/v2/product-catalogue/home-and-living_pillow-case.jpg'
      },
      Placemats: {
        image: '/img/v2/product-catalogue/home-and-living_placemats.jpg'
      },
      'Shower Curtains': {
        image: '/img/v2/product-catalogue/home-and-living_shower-curtains.jpg'
      },
      Tapestries: {
        image: '/img/v2/product-catalogue/home-and-living_tapestries.jpg'
      },
      Towels: {
        image: '/img/v2/product-catalogue/home-and-living_towels.jpg'
      },
      'Wash Bags': {
        image: '/img/v2/product-catalogue/home-and-living_wash-bags.jpg'
      },
      'Pillow Shams': {
        image: '/img/v2/product-catalogue/microfibre-pillow-shams.jpg'
      },
      'Duvet Covers': {
        image: '/img/v2/product-catalogue/microfibre-duvet-covers.webp'
      },
      'Woven Pillows': {
        image: '/img/v2/product-catalogue/woven-pillows.webp'
      },
      'Woven Totes': {
        image: '/img/v2/product-catalogue/woven-tote-bags.webp'
      },
      'Woven Wall Tapestries': {
        image: '/img/v2/product-catalogue/woven-wall-hangings.webp'
      },
      Candles: {
        image: '/img/v2/product-catalogue/candles.webp'
      },
      'Laptop Sleeves': {
        image: '/img/v2/product-catalogue/laptop-sleeves.webp'
      },
      'Tea Towels': {
        image: '/img/v2/product-catalogue/tea-towels.jpg'
      },
      'Area Rugs': {
        image: '/img/v2/product-catalogue/area-rugs.webp'
      },
      Curtains: {
        image: '/img/v2/product-catalogue/curtains.jpg'
      },
      'Metal Tin': {
        image: '/img/v2/product-catalogue/personalised-tins.webp'
      },
      'Outdoor Area Rugs': {
        image: '/img/v2/product-catalogue/outdoor-area-rugs.jpg'
      },
      'Plant Pot': {
        image: '/img/v2/product-catalogue/plant-pots.webp'
      }
    }
  },
  Pets: {
    image: '/img/v2/product-catalogue/pets.jpg',
    productTypes: {
      'Pet Beds': {
        image: '/img/v2/product-catalogue/home-and-living_pet-beds.jpg'
      },
      Pets: {
        image: '/img/v2/product-catalogue/pets.jpg'
      },
      'Pet tags': {
        image: '/img/v2/product-catalogue/home-and-living_pet-tags.jpg'
      }
    }
  },
  'Sports & games': {
    image: '/img/v2/product-catalogue/sport-and-games.jpg',
    productTypes: {
      Jigsaws: {
        image: '/img/v2/product-catalogue/sport-and-games_jigsaws.jpg'
      },
      'Playing Cards': {
        image: '/img/v2/product-catalogue/sport-and-games_playing-cards.jpg'
      }
    }
  },
  Stationery: {
    image: '/img/v2/product-catalogue/stationery.jpg',
    productTypes: {
      'Greetings Cards': {
        image: '/img/v2/product-catalogue/stationery_greetings-cards.jpg'
      },
      Journals: {
        image: '/img/v2/product-catalogue/stationery_journals.jpg'
      },
      Notebooks: {
        image: '/img/v2/product-catalogue/stationery_notebooks.jpg'
      },
      Postcards: {
        image: '/img/v2/product-catalogue/stationery_postcards.jpg'
      },
      'Square invitations': {
        image: '/img/v2/product-catalogue/stationery_square-invitations.jpg'
      },
      'Wrapping paper': {
        image: '/img/v2/product-catalogue/stationery_wrapping-paper.jpg'
      }
    }
  },
  Apparel: {
    image: '/img/v2/product-catalogue/apparel.jpg',
    productTypes: {
      'Baby Bodysuits': {
        image: '/img/v2/product-catalogue/apparel_baby-bodysuits.jpg'
      },
      'Baby T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_baby-t-shirts.jpg'
      },
      'Face Masks': {
        image: '/img/v2/product-catalogue/apparel_face-masks.jpg'
      },
      'Kids Hoodies': {
        image: '/img/v2/product-catalogue/apparel_kids-hoodies.jpg'
      },
      'Kids Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_kids-sweatshirts.jpg'
      },
      'Kids T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_kids-t-shirts.jpg'
      },
      'Mens Hoodies': {
        image: '/img/v2/product-catalogue/apparel_mens-hoodies.jpg'
      },
      'Mens Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_mens-long-sleeved-t-shirts.jpg'
      },
      'Mens Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_mens-sweatshirts.jpg'
      },
      'Mens T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_mens-t-shirts.jpg'
      },
      'Mens Tanktops': {
        image: '/img/v2/product-catalogue/apparel_mens-tanktops.jpg'
      },
      Socks: {
        image: '/img/v2/product-catalogue/apparel_socks.jpg'
      },
      'Unisex Baseball T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-baseball-t-shirts.jpg'
      },
      'Unisex Hoodies': {
        image: '/img/v2/product-catalogue/apparel_unisex-hoodies.jpg'
      },
      'Unisex Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-long-sleeved-t-shirts.jpg'
      },
      'Unisex Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-sweatshirts.jpg'
      },
      'Unisex T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_unisex-t-shirts.jpg'
      },
      'Unisex Tanktops': {
        image: '/img/v2/product-catalogue/apparel_unisex-tanktops.jpg'
      },
      'Womens Hoodies': {
        image: '/img/v2/product-catalogue/apparel_womens-hoodies.jpg'
      },
      'Womens Long Sleeved T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_womens-long-sleeved-t-shirts.jpg'
      },
      'Womens Sweatshirts': {
        image: '/img/v2/product-catalogue/apparel_womens-sweatshirts.jpg'
      },
      'Womens T-Shirts': {
        image: '/img/v2/product-catalogue/apparel_womens-t-shirts.jpg'
      },
      'Womens Tanktops': {
        image: '/img/v2/product-catalogue/apparel_womens-tanktops.jpg'
      }
    }
  },
  Accessories: {
    image: '/img/v2/product-catalogue/canvas-tote-bags.jpg',
    productTypes: {
      'Button pin badges': {
        image: '/img/v2/product-catalogue/pin-badges.jpg'
      },
      Bags: {
        image: '/img/v2/product-catalogue/home-and-living_bags.jpg'
      },
      Keyrings: {
        image: '/img/v2/product-catalogue/plastic-keyrings.jpg'
      }
    }
  },
  Footwear: {
    image: '/img/v2/product-catalogue/footwear.jpg',
    productTypes: {}
  },
  'Sample Pack': {
    image: '/img/v2/product-catalogue/sample-pack.jpg',
    productTypes: {}
  },
  'Books & Magazines': {
    image: '/img/v2/product-catalogue/hardcover-photo-book.jpg',
    productTypes: {
      'Softcover Books': {
        image: '/img/v2/product-catalogue/softcover-photo-book.webp'
      },
      'Hardcover Books': {
        image: '/img/v2/product-catalogue/hardcover-photo-book.jpg'
      },
      'Layflat Books': {
        image: '/img/v2/product-catalogue/layflat-photo-book.webp'
      }
    }
  },
  Calendars: {
    image: '/img/v2/product-catalogue/calendars.webp',
    productTypes: {}
  }
}
