import Modal from '../../Modal'
import { RutterPlatformEnum, SalesChannelPlatformEnum } from '../../../enums'
import { APP_ENVIRONMENTS } from '../../../constants'

export function AuthenticationLoadingModal({
  newPlatformName,
  open,
  rutterPlatform,
  setOpen
}: {
  newPlatformName: SalesChannelPlatformEnum
  open: boolean
  rutterPlatform: RutterPlatformEnum
  setOpen: (isOpen: boolean) => void
}) {
  return (
    <Modal open={open} setOpen={setOpen} closeOnInteractionOutside={false}>
      <div className="min-h-[200px] min-w-80vw text-center sm:min-w-[500px]">
        <RutterPlatformLogoFactory platformName={rutterPlatform} />
        <span className="text-xl">Connecting to {newPlatformName}...</span>
      </div>
    </Modal>
  )
}

function RutterPlatformLogoFactory({ platformName }: { platformName: RutterPlatformEnum }) {
  switch (platformName) {
    case RutterPlatformEnum.Etsy:
      return <img alt="Etsy logo" src="/img/logo/etsy-orange.png" className="mx-auto block h-20" />
    case RutterPlatformEnum.WooCommerce:
      return <img alt="WooCommerce logo" src="/img/logo/woocommerce.svg" className="mx-auto block h-20" />
    case RutterPlatformEnum.Wix:
      return (
        <img
          alt="Wix logo"
          src="/img/logo/wix.png"
          className="mx-auto block h-20"
          style={{ transform: 'scale(0.7)' }}
        />
      )
    case RutterPlatformEnum.BigCommerce:
      return <img alt="BigCommerce logo" src="/img/logo/bigcommerce.svg" className="mx-auto block h-20" />
    case RutterPlatformEnum.AdobeCommerce:
      return <img alt="Adobe Commerce logo" src="/img/logo/adobecommerce.svg" className="mx-auto mb-6 block h-20" />
    case RutterPlatformEnum.Squarespace:
      return <img alt="Squarespace logo" src="/img/logo/squarespace.svg" className="mx-auto block h-20" />
    case RutterPlatformEnum.Shopify:
      return <img alt="Shopify logo" src="/img/logo/shopify-colour.png" className="mx-auto mb-2 block h-20" />
    case RutterPlatformEnum.Amazon:
      return (
        <img
          alt="Amazon logo"
          src="/img/logo/amazon.svg"
          className="mx-auto mb-2 block h-20"
          style={{ transform: 'scale(0.8)' }}
        />
      )
    default:
      const platformNameWithUnhandledCase: never = platformName
      if (process.env.REACT_APP_ENV === APP_ENVIRONMENTS.TEST) {
        console.warn(
          `Unable to display platform logo for ${platformNameWithUnhandledCase}. Add a new case to fix this.`
        )
      }
      return null
  }
}
