// @flow
import React, { useState } from 'react'
import type { AppState, DispatchFunc } from '../../../types'
import { compose } from 'redux'
import { connect } from 'react-redux'
import { getPackingSlip, selectSelectedShippingMethodName } from '../../../selectors/manualOrderForm'
import { getRsaaStatusProps, getRsaaStatus } from '../../../selectors/rsaa'
import { getAllTemplateStatus, getAllArtworkStatus, selectTemplates } from '../../../selectors/images'
import {
  changeMerchantOrderId,
  clearOrderData,
  updatePriceToUser,
  createMofOrder,
  MOF_ORDER_LOGGER_ID,
  getMofBasketQuotes,
  BASKET_ITEM_LOGGER_ID,
  changeSelectedPrintAreaForBasketItem
} from '../../../actions/manualOrderForm'
import { getUserHasToSetUpCard, getCurrencyCode, getUser } from '../../../selectors/user'
import { selectMofQuotes } from '../../../selectors/quotes'
import WithUserDetails from '../../../components/hoc/WithUserDetails'
import { debounce } from 'lodash'
import { isAddressValid } from '../../../helpers'
import { CREATE_V4_ORDER } from '../../../actions/orders'
import { buildRsaaActionId } from '../../../helpers/rsaa/buildRsaaActionId'
import { resetRsaaStatus } from '../../../actions/rsaa'
import OrderSummaryController from './OrderSummaryController'
import WithRsaa from '../../../components/hoc/WithRsaa'
import { GET_QUOTES, GET_MOF_QUOTES_REQUEST_ID } from '../../../actions/quotes'
import { getCatalogueItemBySkus, getV4ProductDetailsBySkus } from '../../../selectors/catalogue'
import { SEARCH_PRODUCTS_BY_SKU_LIST } from '../../../actions/catalogue'
import { convertV4OrderIdToV3OrderId } from '../../../helpers/orderId'
import type { Props } from './OrderSummaryController'
import type { InsertDataType } from '../../../types/branding'
import { FEATURE_NAMES } from '../../../split-io/feature-names'
import type { AdditionalQuoteData } from '../../../actions/manualOrderForm/getMofBasketQuotes'
import Page from '../../../components/Page'
import FetchingShippingOptions from '../components/FetchingShippingOptions'

// $FlowFixMe: TypeScript file
import { useMerchantBranding, useMerchantService, useSplitToggle } from '../../../v2/hooks'
// $FlowFixMe: TypeScript file
import { LOCAL_STORAGE_KEYS, SALES_CHANNEL_IDS } from '../../../v2/constants'
import { useLocalStorage } from '@mantine/hooks'
import type { CreateMofOrderDataType } from '../../../actions/manualOrderForm/createMofOrder'

function mapStateToProps(state: AppState) {
  const packingSlip = getPackingSlip(state)
  const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
  const basketItems = state.manualOrderForm.inProgressOrder.itemsToBeCreated
  const allSkus = basketItems.map((item) => item.sku)
  const catalogueDataActionId = buildRsaaActionId(SEARCH_PRODUCTS_BY_SKU_LIST, BASKET_ITEM_LOGGER_ID)

  return {
    addressIsValid: isAddressValid(state.manualOrderForm.customer),
    currencyCode: getCurrencyCode(state),
    hasToSetUpCard: getUserHasToSetUpCard(state),
    customer: state.manualOrderForm.customer,
    deliveryCountry: state.manualOrderForm.deliveryCountry,
    errorMessage: state.manualOrderForm.errorMessage,
    inProgressOrder: state.manualOrderForm.inProgressOrder,
    itemsToBeCreated: basketItems,
    merchantOrderId: state.manualOrderForm.inProgressOrder.merchantOrderId,
    orderError: state.manualOrderForm.inProgressOrder.orderError,
    selectedShippingMethodName: selectSelectedShippingMethodName(state),
    tag: state.manualOrderForm.tag,
    packingSlipUrl: packingSlip ? packingSlip.url : null,
    orderCreationStatus: getRsaaStatus(state, createOrderActionId),
    imageStatus: getAllArtworkStatus(state),
    templateStatus: getAllTemplateStatus(state),
    templates: selectTemplates(state),
    quotes: selectMofQuotes(state),
    catalogueItems: getCatalogueItemBySkus(state, allSkus),
    v4ProductDetails: getV4ProductDetailsBySkus(state, allSkus),
    catalogueDataRsaaStatus: getRsaaStatusProps(state, catalogueDataActionId),
    merchantId: getUser(state).merchantUniqueId,
    orderId: convertV4OrderIdToV3OrderId(state.manualOrderForm.inProgressOrder.id)
  }
}

const debounceTrackingEvent = debounce(window.analytics.track, 500)

function mapDispatchToProps(dispatch: DispatchFunc) {
  return {
    resetOrderCreationStatus: () => {
      const createOrderActionId = buildRsaaActionId(CREATE_V4_ORDER, MOF_ORDER_LOGGER_ID)
      dispatch(resetRsaaStatus(createOrderActionId))
    },
    changeMerchantOrderId: (merchantOrderId: string) => {
      dispatch(changeMerchantOrderId(merchantOrderId))
    },
    clearOrderData: () => dispatch(clearOrderData()),
    createV4Order: ({ brandedInserts, isTransformUrlsOn }: CreateMofOrderDataType) => {
      dispatch(createMofOrder({ brandedInserts, isTransformUrlsOn }))
    },
    updatePriceToUser: (priceToUserAsInt: number, basketItemId: number) => {
      debounceTrackingEvent('Change customs value', { label: 'MOF' })
      dispatch(updatePriceToUser(priceToUserAsInt, basketItemId))
    },
    getMofBasketQuotes: (additionalQuoteData: AdditionalQuoteData) => {
      dispatch(getMofBasketQuotes(additionalQuoteData))
    },
    changeSelectedPrintAreaForBasketItem: (basketItemId: string, selectedPrintArea: string) => {
      dispatch(changeSelectedPrintAreaForBasketItem(basketItemId, selectedPrintArea))
    }
  }
}

const quotesRsaaActionId = buildRsaaActionId(GET_QUOTES, GET_MOF_QUOTES_REQUEST_ID)

export default (compose(
  WithUserDetails,
  WithRsaa(quotesRsaaActionId, 'quotes'),
  connect(mapStateToProps, mapDispatchToProps)
)(OrderSummaryIndex): any)

function OrderSummaryIndex(props: Props) {
  const { isLoadingMerchantBranding } = useMerchantBranding()

  if (isLoadingMerchantBranding) {
    return (
      <Page
        title="Summary"
        primaryAction={{
          disabled: true,
          title: props.hasToSetUpCard ? 'Please set up payment details' : 'Submit order',
          onClick: () => {}
        }}
        showActionsInFooter={true}
      >
        <FetchingShippingOptions back="/orders/create/address" error={false} inProgress={true} />
      </Page>
    )
  }

  return <OrderSummaryInit {...props} />
}

function OrderSummaryInit(props: Props) {
  const { splitIsOn: isTransformUrlsOn } = useSplitToggle({ toggle: FEATURE_NAMES.TRANSORM_URLS })
  const { splitIsOn: isBrandingSettingsOn } = useSplitToggle({ toggle: FEATURE_NAMES.BRANDING_SETTINGS })
  const { merchantDetails } = useMerchantService()
  const { merchantBrandingResponse } = useMerchantBranding()

  const [orderSummaryKey, setOrderSummaryKey] = useState(Math.random())
  const [orderBrandedInserts, setOrderBrandedInserts] = useLocalStorage<InsertDataType[]>({
    key: LOCAL_STORAGE_KEYS.MOF_INSERTS,
    defaultValue: () => {
      if (!isBrandingSettingsOn) {
        return []
      }

      const merchantBranding = merchantBrandingResponse?.data
      if (!merchantDetails || !merchantBranding || merchantBranding?.insertSets?.length === 0) {
        return []
      }

      const mofSalesChannelData = merchantDetails?.salesChannels?.find(
        (salesChannel) => salesChannel.id === SALES_CHANNEL_IDS.ONLINE_ORDER
      )

      const insertSetIdForMof = mofSalesChannelData?.insertsSet?.id
      const insertSetDataForMof = merchantBranding?.insertSets?.find((insertSet) => insertSet?.id === insertSetIdForMof)
      const defaultInsertsForMof = insertSetDataForMof?.inserts ?? []

      return defaultInsertsForMof
    },
    getInitialValueInEffect: false
  })

  function handleForceReRender() {
    setOrderSummaryKey(Math.random())
  }

  return (
    <OrderSummaryController
      {...props}
      key={orderSummaryKey}
      orderBrandedInserts={orderBrandedInserts}
      isTransformUrlsOn={isTransformUrlsOn}
      onForceReRender={handleForceReRender}
      onChangeOrderBrandedInserts={(newBrandedInserts) => {
        setOrderBrandedInserts(newBrandedInserts)
        handleForceReRender()
      }}
    />
  )
}
