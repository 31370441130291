import Button from '../Button'
import NotFound from '../NotFound'
import LoadingSpinner from '../LoadingSpinner'
import { PrintAreaNameType } from '../../interfaces'
import { ImageEditorController } from './components'
import { displayBorder, canProductBeTiled } from './helpers'
import { ImageEditorPrintAreaArtworkInterface } from './interfaces'
import { useProductSearch } from '../../hooks/useProductSearch.hook'
import { useProductDetails } from '../../hooks/useProductDetails.hook'
import { useTemplateService } from '../../hooks/useTemplateService.hook'
import { ImageLibraryConfigPropInterface } from '../ImageLibrary/ImageLibrary.component'

export type PrintAreasArtworkDataType = Record<
  PrintAreaNameType,
  ImageEditorPrintAreaArtworkInterface | null | undefined
>

export interface ImageEditorPropsInterface {
  attributes?: Record<string, string>
  countryCode: string
  enableImageLibraryThumbnailRefresh?: boolean
  imageLibraryConfig?: ImageLibraryConfigPropInterface
  isSaving?: boolean
  printAreasArtworkData?: PrintAreasArtworkDataType
  selectedPrintArea?: PrintAreaNameType
  sku: string
  onCancel: () => void
  onOpenImageLibrary?: () => void
  onSave: (printAreasArtworkData: PrintAreasArtworkDataType, selectedPrintArea: string) => void
}

export default function ImageEditor({
  attributes = {},
  countryCode,
  enableImageLibraryThumbnailRefresh = false,
  imageLibraryConfig,
  isSaving = false,
  printAreasArtworkData = {},
  selectedPrintArea,
  sku,
  onCancel,
  onOpenImageLibrary,
  onSave
}: ImageEditorPropsInterface) {
  const {
    searchResults,
    isLoading: isLoadingProductSearch,
    error: productSearchFetchError
  } = useProductSearch({ countryCode, query: sku, config: { revalidateOnFocus: false } })
  const {
    productDetails,
    isLoading: isLoadingProductDetails,
    error: productDetailsFetchError
  } = useProductDetails({ sku, config: { revalidateOnFocus: false } })
  const {
    templates,
    isLoading: isLoadingTemplates,
    error: templatesFetchError
  } = useTemplateService({
    sku,
    attributes,
    config: { revalidateOnFocus: false }
  })

  const errorPrefix =
    'We could not load the image editor for this product, please try again later or contact support if the issue persists'

  if (productSearchFetchError) {
    return (
      <ImageEditorError
        errorMessage={`${errorPrefix} (code: PSFE-${sku}-${productSearchFetchError.status ?? '0'})`}
        onCancel={onCancel}
      />
    )
  }

  if (productDetailsFetchError) {
    return (
      <ImageEditorError
        errorMessage={`${errorPrefix} (code: ${
          productDetailsFetchError.responseBodyJson?.traceParent ?? 'PDFE'
        }-${sku}-${productDetailsFetchError.status ?? '0'})`}
        onCancel={onCancel}
      />
    )
  }

  if (templatesFetchError) {
    return (
      <ImageEditorError
        errorMessage={`${errorPrefix} (code: TSFE-${sku}-${templatesFetchError.status ?? '0'})`}
        onCancel={onCancel}
      />
    )
  }

  const isLoadingData =
    isLoadingProductSearch ||
    !searchResults ||
    isLoadingProductDetails ||
    !productDetails ||
    isLoadingTemplates ||
    !templates

  if (isLoadingData) {
    return (
      <div className="grid h-screen place-content-center">
        <LoadingSpinner className="h-12 w-12 text-white" />
      </div>
    )
  }

  const skuSearchResult = searchResults.find((result) => result.sku.toUpperCase() === sku.toUpperCase())
  if (!skuSearchResult) {
    return <ImageEditorError errorMessage={`${errorPrefix} (code: SSRE-${sku})`} onCancel={onCancel} />
  }

  const printAreaNames = Object.keys(productDetails.product.printAreas)
  const printAreasData = printAreaNames.reduce(
    (
      printAreasAcc: Record<PrintAreaNameType, ImageEditorPrintAreaArtworkInterface | null | undefined>,
      printAreaName
    ) => {
      if (!printAreasArtworkData[printAreaName]) {
        return { ...printAreasAcc, [printAreaName]: {} }
      }
      return {
        ...printAreasAcc,
        [printAreaName]: printAreasArtworkData[printAreaName]
      }
    },
    {}
  )

  const firstAvailablePrintAreaName = printAreaNames[0]
  const initialSelectedPrintArea =
    selectedPrintArea && printAreasData[selectedPrintArea] ? selectedPrintArea : firstAvailablePrintAreaName

  const supportsPrintBorders = displayBorder({
    attributes,
    category: skuSearchResult.category,
    productType: skuSearchResult.productType
  })

  const supportsTiling = canProductBeTiled(skuSearchResult.productType)

  return (
    <ImageEditorController
      category={skuSearchResult.category}
      productType={skuSearchResult.productType}
      description={skuSearchResult.name}
      enableImageLibraryThumbnailRefresh={enableImageLibraryThumbnailRefresh}
      imageLibraryConfig={imageLibraryConfig}
      isSaving={isSaving}
      onOpenImageLibrary={onOpenImageLibrary}
      printAreas={printAreasData}
      productSku={skuSearchResult.sku}
      selectedAttributes={attributes}
      selectedPrintArea={initialSelectedPrintArea}
      supportsPrintBorders={supportsPrintBorders}
      supportsTiling={supportsTiling}
      templates={templates}
      onCancel={onCancel}
      onSave={onSave}
    />
  )
}

function ImageEditorError({ errorMessage, onCancel }: { errorMessage: string; onCancel: () => void }) {
  return (
    <div className="grid h-screen place-content-center">
      <NotFound
        heading="An error occurred"
        body={<p className="break-words text-center">{errorMessage}</p>}
        className="bg-gray-100"
      >
        <Button className="btn btn-primary mx-auto mt-8 max-w-xs" onClick={onCancel}>
          Close
        </Button>
      </NotFound>
    </div>
  )
}
