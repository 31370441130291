import { useState } from 'react'
import toast from 'react-hot-toast'
import { useParams } from 'react-router-dom'

import Switch from '../../Switch'
import { createErrorToast } from '../../Toast'
import OverlayPortal from '../../OverlayPortal'
import {
  SalesChannelProductSuccessResponseInterface,
  useSalesChannelProduct
} from '../../../hooks/useSalesChannelProduct.hook'
import { useMerchantService } from '../../../hooks'
import { updateChannelProductFulfilment } from '../../SalesChannelConfigure'
import { SALES_CHANANEL_PRODUCT_TOAST_IDS } from '../constants/toastIds.const'
import { FetchErrorInterface, OMSErrorResponseInterface, StatusType } from '../../../interfaces'

const PRODUCT_CONFIGURE_TOAST_IDS = {
  ERROR: 'productConfigureError',
  SUCCESS: 'productConfigureSuccess'
}

interface ProductFulfilmentToggleInterface {
  salesChannelProduct: SalesChannelProductSuccessResponseInterface
}
export function ProductFulfilmentToggle({ salesChannelProduct }: ProductFulfilmentToggleInterface) {
  const { merchantDetails } = useMerchantService()
  const { salesChannelId, productId } = useParams<{ salesChannelId: string; productId: string }>()
  const { mutateSalesChannelProduct } = useSalesChannelProduct({ salesChannelId, productId })

  const [changeFulfilmentStatus, setChangeFulfilmentStatus] = useState<StatusType>('idle')

  async function handleChangeFulfilment(shouldFulfill: boolean) {
    toast.dismiss(PRODUCT_CONFIGURE_TOAST_IDS.ERROR)
    setChangeFulfilmentStatus('loading')

    if (shouldFulfill) {
      window.analytics.track('Sales channels - product toggled on')
    }

    try {
      await updateChannelProductFulfilment({
        merchantId: merchantDetails?.id ?? null,
        salesChannelId,
        salesChannelProductId: productId,
        shouldFulfill
      })
      await mutateSalesChannelProduct()
      setChangeFulfilmentStatus('success')
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<OMSErrorResponseInterface>
      const errorCode = `${errorResponse.responseBodyJson?.traceParent ?? '0'}-${errorResponse.status ?? '0'}`
      createErrorToast({
        errorCode,
        heading: 'Failed to update',
        id: SALES_CHANANEL_PRODUCT_TOAST_IDS.ERROR
      })
      setChangeFulfilmentStatus('error')
    }
  }

  return (
    <>
      <div className="mt-12 flex flex-shrink-0 gap-x-3 lg:mt-0 lg:flex-col lg:self-center">
        <div className="flex flex-nowrap items-center gap-3 lg:flex-row-reverse">
          <Switch
            className="flex-shrink-0"
            checked={salesChannelProduct.data.productData.isFulfilled}
            disabled={changeFulfilmentStatus === 'loading'}
            onCheckedChange={handleChangeFulfilment}
          />
          <span>Fulfil this item with Prodigi</span>
        </div>
        <div className="text-gray-600">
          ({salesChannelProduct.data.productData.numberOfFulfilledVariants} of{' '}
          {salesChannelProduct.data.productData.totalNumberOfVariants} variants active)
        </div>
      </div>

      {changeFulfilmentStatus === 'loading' && <OverlayPortal className="cursor-wait" />}
    </>
  )
}
