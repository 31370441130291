// @flow
import { createSelector } from 'reselect'
import { sortAndFormatCountryNames } from '../helpers/sortAndFormatCountryNames'
import { identity } from './helpers'
import { selectBasketItemCount } from './shared'
import {
  selectSearchItems,
  selectSearching,
  selectFacetsLoading,
  selectShowFacets,
  selectFetchingPrices,
  getIsSearchInconclusive,
  selectV4ProductDetails
} from './catalogue'
import { selectAllTemplateStatus, selectArtworkProgress } from './images'
import type {
  ArtworkTransformations,
  V4ProductDetails,
  Country,
  CustomerAddress,
  AppState,
  MultiAssetBasketItem,
  Dictionary,
  CatalogueItem,
  InProgressOrder,
  PackingSlip,
  OrderAddressStateProps,
  Status,
  Artwork,
  ArtworkProgress,
  RsaaStatusProps
} from '../types'
import type { SelectProductStateProps } from '../pages/ManualOrderForm/components/SelectProduct/types'
import { NO_IMAGE_PRODUCT_TYPE, ZERO_COST_SKUS } from '../data/noImageProducts'
import { buildTemplateId } from '../actions/images'
import { LOADING } from '../data/rsaa'
import { buildRsaaActionId } from '../helpers/rsaa'
import { getRsaaStatusProps } from './rsaa'
import { RECENT_ITEMS_LOGGER_ID } from '../actions/recentItems'
import { SEARCH_PRODUCTS_BY_SKU_LIST } from '../actions/catalogue'
import { PRINT_AREA_NAME } from '../data'
import { selectAllRequiredItemPrintAreasAreValid } from '../helpers/getAreBasketItemArtworksValid'

export function selectSelectedPrintAreaByItem(item: ?MultiAssetBasketItem): string {
  return item?.selectedPrintArea ?? PRINT_AREA_NAME.DEFAULT
}

export function selectBasketItemById(state: AppState, itemId: number): ?MultiAssetBasketItem {
  return state.manualOrderForm.inProgressOrder.itemsToBeCreated.find((item) => +item.id === +itemId)
}

export function selectChosenAttributes(basketItem: ?MultiAssetBasketItem): Dictionary<string | number> {
  return basketItem ? basketItem.selectedAttributes : {}
}

function selectArtworkByBasketItem(basketItem: ?MultiAssetBasketItem, printAreaName: string): ?Artwork {
  return basketItem?.printAreas?.[printAreaName]?.artwork
}

function selectArtworkTransformationsByBasketItem(
  basketItem: ?MultiAssetBasketItem,
  printAreaName: string
): ?ArtworkTransformations {
  return basketItem?.printAreas?.[printAreaName]?.artworkTransformations
}

export function selectSelectedFacetMobileScreen(state: AppState): string {
  return state.manualOrderForm.selectedMobileFacetScreen
}

export function selectBasketItems(state: AppState): MultiAssetBasketItem[] {
  return state.manualOrderForm.inProgressOrder.itemsToBeCreated
}

export function selectInProgressOrder(state: AppState): InProgressOrder {
  return state.manualOrderForm.inProgressOrder
}

export function selectIsUsSalesTaxAlreadyCollected(state: AppState): boolean {
  return state.manualOrderForm.isUsSalesTaxAlreadyCollected
}

export function selectSelectedShippingMethodName(state: AppState): ?string {
  return state.manualOrderForm.selectedShippingMethodName
}

export function selectLastBasketItemId(state: AppState): number {
  return state.manualOrderForm.lastBasketItemId
}

export function selectDeliveryCountry(state: AppState): string {
  return state.manualOrderForm.deliveryCountry
}

export function selectAvailableRecentItems(state: AppState): MultiAssetBasketItem[] {
  return state.manualOrderForm.availableRecentItems
}

export function selectRecentItemsStatus(state: AppState): ?'initialised' {
  return state.manualOrderForm.recentItemsStatus
}

export function selectPackingSlip(state: AppState): ?PackingSlip {
  return state.manualOrderForm.packingSlip
}

export function selectHasOrderInProgress(order: InProgressOrder): boolean {
  return order.itemsToBeCreated.length > 0
}

export function selectNumberOfItemsInBasket(order: InProgressOrder): number {
  return order.itemsToBeCreated.length
}

export function selectCustomer(state: AppState): CustomerAddress {
  return state.manualOrderForm.customer
}

export function selectCountries(state: AppState): Country[] {
  return state.manualOrderForm.countries
}

export function selectFetching(state: AppState): boolean {
  return state.manualOrderForm.fetching
}

export function selectFetchError(state: AppState): boolean {
  return state.manualOrderForm.fetchError
}

function formatCountries(countries: Country[]): Country[] {
  return sortAndFormatCountryNames(countries)
}

function selectSearchProductsBySkuListRsaaStatus(state: AppState): RsaaStatusProps {
  const catalogueRsaaActionId = buildRsaaActionId(SEARCH_PRODUCTS_BY_SKU_LIST, RECENT_ITEMS_LOGGER_ID)
  return getRsaaStatusProps(state, catalogueRsaaActionId)
}

export function buildSelectProductProps(
  searchItems: ?(CatalogueItem[]),
  fetchingPrices: boolean,
  searching: boolean,
  facetsLoading: boolean,
  selectedMobileFacetScreen: string,
  showFacets: boolean,
  isSearchInconclusive: boolean,
  basketItemCount: number,
  searchProductsBySkuListRsaaStatus: RsaaStatusProps
): SelectProductStateProps {
  return {
    searchItems,
    fetchingPrices,
    searching,
    facetsLoading,
    selectedMobileFacetScreen,
    showFacets,
    isSearchInconclusive,
    basketItemCount,
    searchProductsBySkuListRsaaStatus
  }
}

export function selectAreAllBasketItemsValid(
  basketItems: MultiAssetBasketItem[],
  v4ProductDetails: Dictionary<V4ProductDetails>,
  allTemplateStatuses: Dictionary<Status>,
  allArtworkProgress: Dictionary<ArtworkProgress>
): boolean {
  return (
    basketItems.length > 0 &&
    basketItems.every((item) => {
      const templateId = buildTemplateId(item.sku, item.selectedAttributes)

      const templateStatus = allTemplateStatuses[templateId]

      if (!v4ProductDetails[item.sku]) {
        return false
      }

      const itemDetails = v4ProductDetails[item.sku]

      const allRequiredPrintAreasAreValid = selectAllRequiredItemPrintAreasAreValid(
        itemDetails,
        item,
        allArtworkProgress
      )

      return Boolean(
        allRequiredPrintAreasAreValid && item.sku && templateStatus !== LOADING && v4ProductDetails[item.sku]
      )
    })
  )
}

function selectOrderAddressStateProps(
  inProgressOrder,
  customer,
  countries,
  deliveryCountryCode,
  areAllBasketItemsValid
): OrderAddressStateProps {
  return {
    inProgressOrder,
    customer: { ...customer, countryCode: deliveryCountryCode },
    countries,
    deliveryCountryCode,
    areAllBasketItemsValid
  }
}

export function isDefaultArtworkRequired(productType: string): boolean {
  return Object.values(NO_IMAGE_PRODUCT_TYPE).includes(productType.toLowerCase())
}

export function isZeroPricedItem(sku: string): boolean {
  return Object.values(ZERO_COST_SKUS).includes(sku)
}

export type GetSelectedPrintAreaForBasketItem = (state: AppState, basketItemId: number) => string
export type GetArtworkByBasketItemId = (state: AppState, basketItemId: number) => ?Artwork
export type GetArtworkTransformationsByBasketItemId = (state: AppState, basketItemId: number) => ?ArtworkTransformations

/* reselect */
export const getSelectedPrintAreaForBasketItem: GetSelectedPrintAreaForBasketItem = createSelector(
  selectBasketItemById,
  selectSelectedPrintAreaByItem
)
export const getDeliveryCountry: any = createSelector(selectDeliveryCountry, identity)
export const getAvailableRecentItems: any = createSelector(selectAvailableRecentItems, identity)
export const getBasketItemById: any = createSelector(selectBasketItemById, identity)
export const getInProgressOrder: any = createSelector(selectInProgressOrder, identity)
export const getPackingSlip: any = createSelector(selectPackingSlip, identity)
export const getHasOrderInProgress: any = createSelector(selectInProgressOrder, selectHasOrderInProgress)
export const getNumberOfItemsInBasket: (state: AppState) => number = createSelector(
  selectInProgressOrder,
  selectNumberOfItemsInBasket
)
export const getFormattedCountries: any = createSelector(selectCountries, formatCountries)
export const getAreAllBasketItemsValid: any = createSelector(
  selectBasketItems,
  selectV4ProductDetails,
  selectAllTemplateStatus,
  selectArtworkProgress,
  selectAreAllBasketItemsValid
)

export const getArtworkByBasketItemId: GetArtworkByBasketItemId = createSelector(
  selectBasketItemById,
  getSelectedPrintAreaForBasketItem,
  selectArtworkByBasketItem
)

export const getArtworkTransformationsByBasketItemId: GetArtworkTransformationsByBasketItemId = createSelector(
  selectBasketItemById,
  getSelectedPrintAreaForBasketItem,
  selectArtworkTransformationsByBasketItem
)

export function makeGetBasketItemById(): any {
  return getBasketItemById
}

export const getSelectProductStateProps: any = createSelector(
  selectSearchItems,
  selectFetchingPrices,
  selectSearching,
  selectFacetsLoading,
  selectSelectedFacetMobileScreen,
  selectShowFacets,
  getIsSearchInconclusive,
  selectBasketItemCount,
  selectSearchProductsBySkuListRsaaStatus,
  buildSelectProductProps
)

export const getOrderAddressStateProps: any = createSelector(
  selectInProgressOrder,
  selectCustomer,
  selectCountries,
  selectDeliveryCountry,
  getAreAllBasketItemsValid,
  selectOrderAddressStateProps
)
