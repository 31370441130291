import { useState } from 'react'
import toast from 'react-hot-toast'
import { useDispatch } from 'react-redux'
import { addYears, format } from 'date-fns'

import { useUser } from '../../../hooks'
import { StatusEnum } from '../../../enums'
import { ProPlanBillingRadioButtons } from '.'
import { useUserSubscription } from '../hooks'
import OverlayPortal from '../../OverlayPortal'
import { updateSubscription } from '../helpers'
import { ProBillingOption } from '../interfaces'
import { PRO_FEATURE_SHORT_LIST } from '../constants'
import ConfirmationModal from '../../ConfirmationModal'
import { FetchErrorInterface } from '../../../interfaces'
import { createErrorToast, createToast } from '../../Toast'
import { updateUserSuccess } from '../../../../actions/user'
import { refreshAccessToken } from '../../../../actions/auth'
import { SETTINGS_UPDATE_ERROR_TOAST_ID, SETTINGS_UPDATE_SUCCESS_TOAST_ID } from '../../Settings/constants'

export function ChangeToProModal({
  isModalOpen,
  proPlanBillingOptions,
  selectedProSubscriptionTier,
  setIsModalOpen,
  setSelectedProSubscriptionTier
}: {
  isModalOpen: boolean
  proPlanBillingOptions: Record<ProBillingOption, { cost: number; currency: string }>
  selectedProSubscriptionTier: ProBillingOption
  setIsModalOpen: (open: boolean) => void
  setSelectedProSubscriptionTier: React.Dispatch<React.SetStateAction<ProBillingOption>>
}) {
  const { mutateUser } = useUser()
  const { mutateSubscription } = useUserSubscription()
  const dispatch = useDispatch()

  const [updateStatus, setUpdateStatus] = useState(StatusEnum.Idle)

  function handleOpenChange(isOpen: boolean) {
    if (!isOpen) {
      toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
      toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    }
    setIsModalOpen(isOpen)
  }

  async function switchToPro() {
    toast.dismiss(SETTINGS_UPDATE_SUCCESS_TOAST_ID)
    toast.dismiss(SETTINGS_UPDATE_ERROR_TOAST_ID)
    setUpdateStatus(StatusEnum.Loading)

    try {
      await updateSubscription(selectedProSubscriptionTier)
      await mutateSubscription()
      await mutateUser()
      handleOpenChange(false)
      setUpdateStatus(StatusEnum.Success)
      createToast({
        duration: 3000,
        heading: 'Subscription update is being processed',
        id: SETTINGS_UPDATE_SUCCESS_TOAST_ID,
        type: 'success'
      })
      dispatch(refreshAccessToken())
      // TODO: v2: Remove this dispatch when we no longer need v1 user details in Redux
      dispatch(updateUserSuccess())
    } catch (error) {
      const errorResponse = error as FetchErrorInterface<{ message?: string }>
      createErrorToast({
        errorCode: errorResponse.status ?? '0',
        errorMessage: errorResponse.responseBodyJson?.message ?? errorResponse.message,
        heading: 'Failed to update subscription',
        id: SETTINGS_UPDATE_ERROR_TOAST_ID
      })
      setUpdateStatus(StatusEnum.Error)
    }
  }

  const isLoading = updateStatus === StatusEnum.Loading

  return (
    <>
      <ConfirmationModal
        className="relative max-w-[95vw] bg-white sm:min-w-[600px]"
        contentClassName="max-h-60vh overflow-auto pb-4 text-base max-w-4xl"
        open={isModalOpen}
        title="Complete your Pro subscription"
        continueButton={{
          variant: 'primary',
          text: 'Activate Pro'
        }}
        footer={
          <p className="mt-8 text-gray-600">
            We&apos;ll process your first payment today using your existing billing details. Your subscription will
            automatically renew {selectedProSubscriptionTier === 'ProMonthlyBilling' ? 'each month' : 'next year'} on
            the{' '}
            {format(
              addYears(new Date(), 1),
              selectedProSubscriptionTier === 'ProMonthlyBilling' ? 'do' : 'dd MMMM YYY'
            )}
            . Cancel any time online {'–'} you can continue using your Pro benefits until the end of your current
            billing period.
          </p>
        }
        closeButton={{ variant: 'tertiary', theme: 'greyscale', text: 'Close' }}
        isLoading={isLoading}
        setOpen={handleOpenChange}
        onCancel={() => handleOpenChange(false)}
        onContinue={switchToPro}
      >
        <p className="font-medium">You&apos;re about to unlock exclusive benefits including:</p>
        <ul className="mb-4 mt-2 max-w-fit list-inside list-disc lg:mb-8">
          {PRO_FEATURE_SHORT_LIST.map((feature) => (
            <li className="mt-2" key={feature}>
              {feature}
            </li>
          ))}
        </ul>

        <h3 className="m-0 mb-4 mt-16 font-medium">Choose your billing plan</h3>
        <ProPlanBillingRadioButtons
          proPlanBillingOptions={proPlanBillingOptions}
          selectedSubscriptionTier={selectedProSubscriptionTier}
          setSelectedSubscriptionTier={setSelectedProSubscriptionTier}
        />
      </ConfirmationModal>

      {isLoading && <OverlayPortal />}
    </>
  )
}
